<div (click)="showHidePopup()" id="overlay" [ngClass]="{'hide': !hidePopup}"></div>
<div class="center-screen" [ngClass]="{'hide': !hidePopup}">
  <span class="close-out" (click)="showHidePopup()">x</span>
  <img (click)="showHidePopup()" class="image-popped-up" appSubstanceImage
       [entityId]="unit && (unit.uuid || (unit._structure && unit._structure.id))">
</div>

<div *ngIf="!simplifiedForm; else simplifiedActionsComponent">
  <div class="top-fixed" *ngIf = "!featuresOnly">
    <div class="actions-container">
      <button mat-flat-button color="primary" (click)="saveDraft()">Save Draft</button>
      <button mat-flat-button color="primary" class="draft-button" (click)="showDrafts()">Show Drafts <span
        class="chip">{{ draftCount }}</span></button>

      <button mat-flat-button color="primary" class="json-button" (click)="showJSON()">Show JSON</button>
      <button mat-flat-button color="primary" class="import-button" (click)="importDialog()">Import JSON</button>

      <button mat-flat-button class="hide-show-messages" (click)="toggleValidation()" [disabled]="isLoading"
              *ngIf="(validationMessages && validationMessages.length > 0)">
        {{ showSubmissionMessages ? 'Hide' : 'Show' }} messages
        <mat-icon [svgIcon]="showSubmissionMessages ? 'chevron_up' : 'chevron_down'"></mat-icon>
      </button>
      <div *ngIf="isAdmin === true || isUpdater === true" class="advanced-features">
        <div class="admin-functions">
          <mat-form-field>
            <mat-label>Advanced Features</mat-label>
            <mat-select name="Feature" [ngModel]="feature" (openedChange)="openedChange($event)"
                        (selectionChange)="useFeature($event)" disableOptionCentering>
              <ng-container>
                <mat-option value="changeClass" [disabled]="!id">
                  Change Substance Class
                </mat-option>
                <mat-option value="approved" [disabled]="status === 'approved'">
                  Change Status to approved
                </mat-option>
                <mat-option value="pending" [disabled]="status === 'pending'">
                  Change Status to pending
                </mat-option>
                <mat-option value="setPrivate">
                  Set Definition to private
                </mat-option>
                <mat-option value="setPublic">
                  Set Definition to public
                </mat-option>
                <mat-option value="unapprove">
                  Un-approve record (Remove approval ID)
                </mat-option>
                <mat-option value="changeApproval"
                            [disabled]="!id || status === 'pending' || (substanceClass === 'concept' && UNII === 'non-approved record')">
                  Change Approval ID
                </mat-option>
                <mat-option value="concept" [disabled]="substanceClass != 'concept'">
                  Set concept status to non-approved
                </mat-option>
                <mat-option value="merge">
                  Merge subconcept
                </mat-option>
                <mat-option value="switch">
                  Switch primary and alt definitions
                </mat-option>
              </ng-container>
              <ng-container *ngIf="isAdmin === true || isUpdater === true">
                <mat-option value="glyco" [disabled]="substanceClass !== 'protein'">
                  Predict N-Glycosylation Sites
                </mat-option>
                <mat-option value="disulfide" [disabled]="substanceClass != 'protein'">
                  Predict disulfide links by monoclonal antibody type
                </mat-option>
                <mat-option value="fragment"
                            [disabled]="substanceClass != 'protein' && substanceClass != 'nucleicAcid' ">
                  Register a Fragment
                </mat-option>
                <mat-option value="regenRefs" >
                  Regenerate reference UUIDs
                </mat-option>
                <mat-option value="regenUUID" >
                  Regenerate substance UUID
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <ng-template #demo>
          <div mat-dialog-header>Saved Drafts</div>
          <div mat-dialog-content>
            <div class="form-row">
              <div class="cell">
                <mat-icon svgIcon="delete_forever"></mat-icon>
              </div>
              <div class="cell"><a>d706be08-4015-4fa2-80e6-567cf7e2e23f</a></div>
              <div class="cell">April 1, 2022 08:05</div>
              <div class="cell">
                <button mat-button mat-primary-button>Load Draft</button>
              </div>
            </div>
            <div class="form-row">
              <div class="cell">
                <mat-icon svgIcon="delete_forever"></mat-icon>
              </div>
              <div class="cell"><a>d706be08-4015-4fa2-80e6-567cf7e2e23f</a></div>
              <div class="cell">April 1, 2022 08:00</div>
              <div class="cell">
                <button mat-button mat-primary-button>Load Draft</button>
              </div>
            </div>

            <div class="form-row">
              <div class="cell">
                <mat-icon svgIcon="delete_forever"></mat-icon>
              </div>
              <div class="cell">register = protein</div>
              <div class="cell">April 1, 2022 08:00</div>
              <div class="cell">
                <button mat-button mat-primary-button>Load Draft</button>
              </div>
            </div>


          </div>

          <div mat-dialog-actions>
            <span class="middle-fill"></span>
            <button class="mat-raised-button mat-primary" mat-dialog-close (click)="dialogRef.close()">Close</button>
          </div>
        </ng-template>


        <div class="changeClass advanced" *ngIf="feature === 'changeClass'">
          <mat-form-field>
            <mat-label>New Class</mat-label>
            <mat-select name="definitionType" (selectionChange)="changeClass($event)"
                        (openedChange)="openedChange($event)" disableOptionCentering>
              <mat-option *ngFor="let class of classes" [value]="class" [disabled]="class === substanceClass">
                {{ class }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <span class="middle-fill"></span>
      <button mat-flat-button color="primary" class="validate-button" (click)="validate()">Validate for Submission</button>
      <button mat-flat-button color="primary" class="validate-button" [disabled]="!canApprove || approving"
              *ngIf="definition && definition.substanceClass!=='concept' && !definition.approvalID && !imported && definitionType !== 'ALTERNATIVE'"
              (click)="validate('approval')">Approve
      </button>

    </div>
    <ng-container *ngTemplateOutlet=substanceSubmissionMessages></ng-container>
  </div>
</div>

<div [ngClass]="!simplifiedForm ? 'form-content-container' : 'small-form-content-container'">
  <div class="scrollable-container">
    <div class="cards-container">
      <mat-accordion multi="true">
        <mat-expansion-panel #expansionPanel
                             [class.small-mat-expansion-panel-spacing]="simplifiedForm"
                             [expanded]="expandedComponents.indexOf(section.dynamicComponentName) > -1"
                             *ngFor="let section of formSections; index as index; last as isLast"
                             [id]="section.dynamicComponentName"
                             [ngClass]="{'hidden': section.isHidden}">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2>{{ section.menuLabel }}</h2>
              <div class="middle-fill"></div>
              <div *ngIf="section.canAddItem">
                <button mat-flat-button color="primary" id="Add new {{section.menuLabel}}"
                        (click)="section.addItem(); $event.stopPropagation()"
                        matTooltip="Add new {{section.menuLabel}}">
                  Add {{ section.menuLabel }}
                  <mat-icon svgIcon="add_circle_outline"></mat-icon>
                </button>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div #dynamicComponent></div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>

<!-- Template for substance validation messages -->
<ng-template #substanceSubmissionMessages>
  <div [ngClass]="{'submission-messages': true, collapsed: !showSubmissionMessages, expanded: showSubmissionMessages}">
    <div class="submission-message" *ngIf="submissionMessage">
      {{ submissionMessage }}
    </div>
    <div *ngIf="validationMessages">
      <span *ngIf=" validationMessages.length > 0">
        <div class="submission-message" *ngIf='!serverError && !approving'>
          Please correct or dismiss the following errors and submit again:
        </div>
        <div class="validation-message" *ngFor="let message of validationMessages; index as i">
          <span class="validation-body">
          <div class="message-type"
               [ngClass]="{'error-message': message.messageType == 'ERROR', 'warning-message': message.messageType == 'WARNING', 'notice-message': message.messageType == 'NOTICE'}">
            {{ message.messageType }}</div>
          <div class="message">{{ message.message }}<span *ngFor="let link of message.links"><br/>
              <a [href]="fixLink(link.href)" class="internal-link"
                 target="_blank">{{ link.text }}</a></span>
          </div>
        </span>
        <span class="validation-dismiss">
          <button mat-icon-button matTooltip="Dismiss" *ngIf="message.messageType != 'ERROR' && !approving"
                  (click)="dismissValidationMessage(i)">
            <mat-icon svgIcon="cancel"></mat-icon>
          </button>
        </span>
        </div>
      </span>
      <div class="dismiss-container">
        <span class="middle-fill"></span>
        <button mat-flat-button color="primary" *ngIf="!approving" (click)="submit()"
                [disabled]="isLoading || (this.validationResult === false)">{{ validationMessages && validationMessages.length > 0 ? 'Dismiss All and ' : '' }}
          Submit
        </button>
        <button mat-flat-button *ngIf="!approving" (click)="showSubmissionMessages = false">
          Cancel
        </button>
        <button mat-flat-button color="primary" *ngIf="approving" (click)="approve()"
                [disabled]="isLoading || !canApprove"> Confirm Approval
        </button>

      </div>
    </div>
  </div>
</ng-template>


<!--Simplified actions-component-->
<ng-template #simplifiedActionsComponent>
  <div class="top-fixed small-top-fixed">
    <div class="actions-container small-actions-container">
      <button mat-flat-button color="primary" class="small-button" (click)="saveDraft()">Save Draft</button>
      <button mat-flat-button color="primary" class="draft-button small-button" (click)="showDrafts()">Show Drafts <span
        class="chip">{{ draftCount }}</span></button>

      <button mat-flat-button color="primary" class="json-button small-button" (click)="showJSON()">Show JSON</button>
      <button mat-flat-button color="primary" class="import-button small-button" (click)="importDialog()">Import JSON
      </button>

      <button mat-flat-button class="hide-show-messages small-button" (click)="toggleValidation()"
              [disabled]="isLoading"
              *ngIf="(validationMessages && validationMessages.length > 0)">
        {{ showSubmissionMessages ? 'Hide' : 'Show' }} messages
        <mat-icon [svgIcon]="showSubmissionMessages ? 'chevron_up' : 'chevron_down'"></mat-icon>
      </button>
      <span class="middle-fill"></span>
      <button mat-flat-button color="primary" class="validate-button small-button" (click)="validate()">Validate and
        Submit
      </button>
    </div>
    <ng-container *ngTemplateOutlet=substanceSubmissionMessages></ng-container>
  </div>
</ng-template>
