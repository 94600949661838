<div class = "alignment-container">
  <div class = "alignment-label">
    <span class = "subunit">Subunit {{alignmentArray.subunitIndex}}</span>
    <span class = "alignment-id">{{alignmentArray.id}}</span>
  </div>
  <pre class = "alignment-input" *ngIf = "text" [innerText]="text">

  </pre>

</div>
