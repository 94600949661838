<div class="top-fixed">
  <div class="actions-container">

    <span>
      <button mat-flat-button color="primary" (click)="showJSON()">Show JSON</button>
    </span>

    <span class="middle-fill"></span>

    <!-- Import Assay JSON into the Database -->
    <div class="marginleft50px" *ngIf="isAdmin">
      <button mat-flat-button color="primary" (click)="importAssayJSONIntoDatabase()" [disabled]="disableImportButton">
        Import Data into Database
      </button>
    </div>

    <!--
    <a mat-flat-button color="primary" [href]="downloadJsonHref" (click)="saveJSON()" download="{{jsonFileName}}.json">
      <span>Export JSON</span>
    </a>&nbsp;&nbsp;&nbsp;
    -->

    <!-- Do not show 'Import JSON' button when in Update Invitro Pharamcology page -->
    <!--
    <span *ngIf="assay && !assay.id">
      <button mat-flat-button color="primary" (click)="importJSON()">Import JSON</button>
    </span>
    -->

    <!--
    <button mat-flat-button class="hide-show-messages" (click)="toggleValidation()" [disabled]="isLoading"
      *ngIf="validationMessages && validationMessages.length > 0">
      {{showSubmissionMessages ? 'Hide' : 'Show'}} messages
      <mat-icon [svgIcon]="showSubmissionMessages ? 'chevron_up' : 'chevron_down'"></mat-icon>
    </button>
    <span class="middle-fill"></span>

    <!- - Assay Details Link - ->
    <a mat-flat-button color="primary" routerLink="/invitro-pharm/{{assay.id}}" target="_blank"
      matTooltip='Opens in a new window' *ngIf="assay && assay.id">
      View Assay Details
      <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window"></mat-icon>
    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


    <!- - Validate and Submit Button - ->
    &nbsp;
    <button mat-flat-button color="warn" (click)="validate()">Validate and Submit</button>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button mat-flat-button color="primary" [routerLink]="['/home']" matTooltip='Close this window'>Close</button>
  </div> <!- - div class=actions-container- ->


  <!- - Validation Message Display - ->
  <div [ngClass]="{'submission-messages': true, collapsed: !showSubmissionMessages, expanded: showSubmissionMessages}">
    <div class="submission-message" *ngIf="submissionMessage">
      {{submissionMessage}}
    </div>


    <!- - Validation Message Display and Submit Button - ->
    <div *ngIf="validationMessages">
      <span *ngIf=" validationMessages.length > 0">
        <div class="submission-message" *ngIf='!serverError && !approving'>
          Please correct or dismiss the following errors and submit again:
        </div>
        <div class="validation-message" *ngFor="let message of validationMessages; index as i">
          <div class="message-type" [ngClass]="message.messageType == 'ERROR' ? 'error-message' : 'warning-message'">
            {{message.messageType}}</div>
          <div class="message">{{message.message}}<span *ngFor="let link of message.links"><br /><a [href]="link.href"
                target="_blank">{{link.text}}</a></span></div>
          <button mat-icon-button matTooltip="Dismiss" *ngIf="message.messageType != 'ERROR' && !approving"
            (click)="dismissValidationMessage(i)">
            <mat-icon svgIcon="cancel"></mat-icon>
          </button>
        </div>
      </span>

      <div class="dismiss-container">
        <span class="middle-fill"></span>
        <button mat-flat-button color="primary" (click)="submit()"
          [disabled]="isLoading || (this.validationResult === false)">{{validationMessages &&
          validationMessages.length > 0?'Dismiss All and ':''}}
          Submit</button>
      </div>
    </div>
    <!- - Validation Message and Submit Button - ->
    -->

  </div> <!-- div class=actions-container -->
</div> <!-- div class=top-fixed -->

<div class="form-content-container">
  <div class="scrollable-container">

    <div class="divcenter margintop20px marginleft30px">

      <div class="headerstyle margintop20px bordergray">

        <div class="padtop15px font20px fontbold">
          <b>Import Assay Screening Data </b>
        </div>

      </div> <!-- class="headerstyle  -->

      <br><br>
      <div class="divflex">

        <b>Select a file to Import Assay Screening data:</b>&nbsp;&nbsp;

        <input type="file" (change)="onFileChange($event)" />

        <br><br>
        <span *ngIf="submitMessage" class="colorgreen">{{submitMessage}}</span>

      </div> <!-- class divflex -->

      <!-- DISPLAY JSON -->
      <!--
      <div *ngIf="message">
        {{message}}
      </div>
      -->

      <!-- Display Data from Excel file to import into the database -->
      <div class="divflex margintop30px font13px">

        <!-- Invitro Reference -->
        <div class="width32percent">

          <!-- Sheet -->
          <div class="headerstyle2 font15px">
            <mat-icon>list_alt</mat-icon>&nbsp;Sheet 1.Reference, Laboratory
          </div><br><br>

          <span *ngIf="invitroReference">
            <span class="font14px"><b>1. Reference Information</b></span><br><br>

            <span class="fieldKey">Reference Source Type <span class="colorred">*</span>:</span>
            <span class="fieldvalue">{{invitroReference.sourceType}}</span><br>

            <span class="fieldKey">Reference Source/Citation <span class="colorred">*</span>:</span>
            <span class="fieldvalue">{{invitroReference.sourceCitation}}</span><br>

            <span class="fieldKey">Reference Source Id:</span>
            <span class="fieldvalue">{{invitroReference.sourceId}}</span><br>

            <span class="fieldKey">Reference Digital Object Identifier:</span>
            <span class="fieldvalue">{{invitroReference.digitalObjectIdentifier}}</span>
          </span>


          <br><br>
          <!-- Invitro Laboratory -->
          <span *ngIf="invitroLaboratory">
            <span class="font14px"><b>2. Laboratory Information</b></span>
            <br><br>

            <span class="fieldKey">Laboratory Name <span class="colorred">*</span>:</span>
            <span class="fieldvalue">{{invitroLaboratory.laboratoryName}}</span><br>

            <span class="fieldKey">Laboratory Affiliation:</span>
            <span class="fieldvalue">{{invitroLaboratory.laboratoryAffiliation}}</span><br>

            <span class="fieldKey">Laboratory Type:</span>
            <span class="fieldvalue">{{invitroLaboratory.laboratoryType}}</span><br>

            <span class="fieldKey">Laboratory Street Address:</span>
            <span class="fieldvalue">{{invitroLaboratory.laboratoryStreetAddress}}</span><br>

            <span class="fieldKey">Laboratory City:</span>
            <span class="fieldvalue">{{invitroLaboratory.laboratoryCity}}</span><br>

            <span class="fieldKey">Laboratory State:</span>
            <span class="fieldvalue">{{invitroLaboratory.laboratoryState}}</span><br>

            <span class="fieldKey">Laboratory Zipcode:</span>
            <span class="fieldvalue">{{invitroLaboratory.laboratoryZipcode}}</span><br>

            <span class="fieldKey">Laboratory Country:</span>
            <span class="fieldvalue">{{invitroLaboratory.laboratoryCountry}}</span>
          </span>
        </div>


        <!-- 2nd Sheet -->
        <div class="width32percent">

          <!-- Sheet -->
          <div class="headerstyle2 font15px">
            <mat-icon>list_alt</mat-icon>&nbsp;Sheet 2.Sponsor,Submitter,Report
          </div><br><br>

          <!-- Invitro Sponsor -->
          <span *ngIf="invitroSponsor">
            <span class="font14px"><b>3. Sponsor Information</b></span><br><br>

            <span class="fieldKey">Sponsor Contact Name <span class="colorred">*</span>:</span>
            <span class="fieldvalue">{{invitroSponsor.sponsorContactName}}</span><br>

            <span class="fieldKey">Sponsor Affiliation:</span>
            <span class="fieldvalue">{{invitroSponsor.sponsorAffiliation}}</span><br>

            <span class="fieldKey">Sponsor Street Address:</span>
            <span class="fieldvalue">{{invitroSponsor.sponsorStreetAddress}}</span><br>

            <span class="fieldKey">Sponsor City:</span>
            <span class="fieldvalue">{{invitroSponsor.sponsorCity}}</span><br>

            <span class="fieldKey">Sponsor State:</span>
            <span class="fieldvalue">{{invitroSponsor.sponsorState}}</span><br>

            <span class="fieldKey">Sponsor Zipcode:</span>
            <span class="fieldvalue">{{invitroSponsor.sponsorZipcode}}</span><br>

            <span class="fieldKey">Sponsor Country:</span>
            <span class="fieldvalue">{{invitroSponsor.sponsorCountry}}</span>
          </span>

          <br><br>
          <!-- Invitro Sponsor Submitters  -->
          <span *ngIf="invitroSponsorReport">

            <span class="font14px"><b>4. Report Submitter Information</b></span><br><br>

            <div *ngFor="let submitter of invitroSponsorReport.invitroSponsorSubmitters; let index = index">

              <span class="fieldKey">Sponsor Report Submitter Name <span class="colorred">*</span>:</span>
              <span class="fieldvalue">{{submitter.sponsorReportSubmitterName}}</span><br>

              <span class="fieldKey">Sponsor Report Submitter Title:</span>
              <span class="fieldvalue">{{submitter.sponsorRepoortSubmitterTitle}}</span><br>

              <span class="fieldKey">Sponsor Report Submitter Affiliation:</span>
              <span class="fieldvalue">{{submitter.sponsorReportSubmitterAffiliation}}</span><br>

              <span class="fieldKey">Sponsor Report Submitter Email:</span>
              <span class="fieldvalue">{{submitter.sponsorReportSubmitterEmail}}</span><br>

              <span class="fieldKey">Sponsor Report Submitter Phone Number:</span>
              <span class="fieldvalue">{{submitter.sponsorReportSubmitterPhoneNumber}}</span><br>

              <span class="fieldKey">Sponsor Report Submitter Assay Type:</span>
              <span class="fieldvalue">{{submitter.sponsorReportSubmitterAssayType}}</span><br>
            </div> <!-- Loop Submitters -->

          </span>

          <br><br>
          <!-- Invitro Sponsor Report -->
          <span *ngIf="invitroSponsorReport">
            <span class="font14px"><b>5. Report Information</b></span><br><br>

            <span class="fieldKey">Report Number <span class="colorred">*</span>:</span>
            <span class="fieldvalue">{{invitroSponsorReport.reportNumber}}</span><br>

            <span class="fieldKey">Report Date <span class="colorred">*</span>:</span>
            <span class="fieldvalue">{{invitroSponsorReport.reportDate}}</span><br>
          </span>
        </div>


        <!-- 3rd Sheet -->
        <div class="width32percent">

          <!-- Sheet -->
          <div class="headerstyle2 font15px">
            <mat-icon>list_alt</mat-icon>&nbsp;Sheet 3.Test Agent,Batch Number
          </div><br><br>

          <!-- Invitro Test Agent -->
          <span *ngIf="invitroTestAgent">
            <span class="font14px"><b>6. Test Agent Information</b></span><br><br>

            <span class="fieldKey">Test Agent ID (Company Code) <span class="colorred">*</span>:</span>
            <span class="fieldvalue">{{invitroTestAgent.testAgentCompanyCode}}</span><br>

            <span class="fieldKey">Test Agent Name (FDA) <span class="colorred">*</span>:</span>
            <span class="fieldvalue">{{invitroTestAgent.testAgent}}</span><br>

            <span class="fieldKey">Test Agent Approval ID/UNII:</span>
            <span class="fieldvalue">{{invitroTestAgent.testAgentApprovalId}}</span><br>

            <span class="fieldKey">Test Agent CAS Number:</span>
            <span class="fieldvalue">{{invitroTestAgent.casRegistryNumber}}</span><br>

            <span class="fieldKey">Active Moiety:</span>
            <span class="fieldvalue">{{invitroTestAgent.activeMoiety}}</span><br>

            <span class="fieldKey">Active Moiety Approval ID/UNII:</span>
            <span class="fieldvalue">{{invitroTestAgent.activeMoiety}}</span><br>
          </span>

          <br><br>
          <!-- Batch Number -->
          <span *ngIf="invitroResultInfo">
            <span class="font14px"><b>7. Batch Information</b></span><br><br>

            <span class="fieldKey">Batch Number:</span>
            <span class="fieldvalue">{{invitroResultInfo.batchNumber}}</span><br>
          </span>
        </div>

      </div> <!-- divflex-->


      <div class="divflex margintop30px font13px">

        <!-- 4th Sheet -->
        <div class="width95percent">

          <!-- Sheet -->
          <div class="headerstyle2 font15px">
            <mat-icon>list_alt</mat-icon>&nbsp;Sheet 4.Assay Controls
          </div><br><br>

          <span *ngIf="invitroReference">
            <table class="tableStyle">
              <tr>
                <td>Number</td>
                <td>External Assay Source <span class="colorred">*</span></td>
                <td>External Assay ID <span class="colorred">*</span></td>
                <td>Assay ID</td>
                <td>Control Substance</td>
                <td>Type of Control</td>
                <td>Control Value Type</td>
                <td>Control Value</td>
                <td>Control Value Units</td>
              </tr>

              <tr valing="top" class="font11px" *ngFor="let ctrl of invitroControlsTemp; let index = index">
                <td>{{(index+1)}}</td>
                <td>{{ctrl.externalAssaySource}}</td>
                <td>{{ctrl.externalAssayId}}</td>
                <td>{{ctrl.assayId}}</td>
                <td>{{ctrl.control}}</td>
                <td>{{ctrl.controlType}}</td>
                <td>{{ctrl.controlValueType}}</td>
                <td>{{ctrl.controlValue}}</td>
                <td>{{ctrl.controlValueUnits}}</td>
              </tr>
            </table>
          </span>

        </div> <!-- class="width95percent" -->

      </div> <!-- class=divflex -->


      <!-- ************************************************************** -->
      <!-- SHEET 5. Assay Results -->
      <div class="margintop30px font13px">
        <div class="width95percent">

          <div class="headerstyle2 font15px">
            <mat-icon>list_alt</mat-icon>&nbsp;Sheet 5.Assay Results
          </div><br><br>

          <span>
            <table class="tableStyle">
              <tr>
                <td>Number</td>
                <td>Assay found in Database</td>
                <td>External Assay Source <span class="colorred">*</span></td>
                <td>External Assay ID <span class="colorred">*</span></td>
                <td>External Assay URL/Document Link</td>
                <td>Assay ID</td>
                <td>Test Date (mm/dd/yyyy)</td>
                <td>Test Agent Concentration</td>
                <td>Test Agent Concentration Units</td>
                <td>Result Value</td>
                <td>Result Value Units</td>
                <td>Ligand/Substrate Concentration</td>
                <td>Ligand/Substrate Concentration Units</td>
                <td>Plasma Protein Added?</td>
                <td>Protein</td>
                <td>Plasma Protein Concentration</td>
                <td>Plasma Protein Concentration Units</td>
                <td>Type of Data</td>
                <td>Number of Tests</td>
                <td>Comments</td>
                <td>Measurements</td>
              </tr>

              <tr valing="top" class="font11px" *ngFor="let result of invitroResultsTemp; let index = index">

                <td>{{(index+1)}}</td>

                <td>
                  <span *ngIf="result.assayFoundInDb && result.assayFoundInDb == 'true'">
                    <div class="backgroundgreen"><span class="colorwhite">YES</span></div>
                  </span>
                  <span *ngIf="result.assayFoundInDb && result.assayFoundInDb == 'false'">
                    <div class="backgroundred"><span class="colorwhite">NO
                      </span>
                    </div>
                    <br>
                    <!-- Register Assay Link -->
                    <a [routerLink]="['/invitro-pharm/assay/register']" target="_blank" *ngIf="isAdmin"
                      matTooltip='Register In-vitro Pharmacology Assay'> Register Assay
                    </a>
                  </span>
                  <span *ngIf="result.assayFoundInDb && result.assayFoundInDb == 'Error getting Assay'">
                    <div class="backgroundred"><span class="colorwhite">ERROR</span></div>
                  </span>
                </td>

                <td>
                  <div *ngIf="result.assayFoundInDb && result.assayFoundInDb == 'false'; else foundAssaySource">
                    <span class="colorred"><b>{{result.externalAssaySource}}</b></span>
                  </div>
                  <ng-template #foundAssaySource>
                    {{result.externalAssaySource}}
                  </ng-template>
                </td>

                <td>
                  <div *ngIf="result.assayFoundInDb && result.assayFoundInDb == 'false'; else foundExternalAssayId">
                    <span class="colorred"><b>{{result.externalAssayId}}</b></span>
                  </div>
                  <ng-template #foundExternalAssayId>
                    {{result.externalAssayId}}
                  </ng-template>
                </td>

                <td>{{result.externalAssayUrl}}</td>
                <td>{{result.assayId}}</td>
                <td>{{result.testDate}}</td>
                <td>{{result.testAgentConcentration}}</td>
                <td>{{result.testAgentConcentrationUnits}}</td>
                <td>{{result.resultValue}}</td>
                <td>{{result.resultValueUnits}}</td>
                <td>{{result.ligandSubstrateConcentration}}</td>
                <td>{{result.ligandSubstrateConcentrationUnits}}</td>
                <td>{{result.plasmaProteinAdded}}</td>
                <td>{{result.protein}}</td>
                <td>{{result.plasmaProteinConcentration}}</td>
                <td>{{result.plasmaProteinConcentrationUnits}}</td>
                <td>{{result.dataType}}</td>
                <td>{{result.numberOfTests}}</td>
                <td>{{result.comments}}</td>
                <td>{{result.assayMeasurement}}</td>
              </tr>
            </table>
          </span>

        </div> <!-- class="width95percent" -->
      </div> <!--  SHEET 5 class=divflex-->


      <div *ngIf="importDataList.length > 0">
        <b>Total Records ready for import into the database:&nbsp;
          <span class="colorgreen font18px">{{importDataList.length}}</span></b>

        <br><br>
        <table class="tableStyle bordergray">
          <tr class="font11px">
            <th>#</th>
            <th>Assay Set</th>
            <th>External Assay Source</th>
            <th>External Assay ID</th>
            <th>External Assay Reference URL</th>
            <th>Assay Title</th>
            <th>Assay Format</th>
            <th>Assay Mode</th>
            <th>Bioassay Type</th>
            <th>Bioassay Class</th>
            <th>Study Type</th>
            <th>Detection Method</th>
            <th>Presention Type</th>
            <th>Presentation</th>
            <th>Public Domain</th>
            <th>Assay Target Name</th>
            <th>Assay Target Name Approval ID</th>
            <th>Target Species</th>
            <th>Human Homolog Target Name</th>
            <th>Human homolog Target Name Approval ID</th>
            <th>Ligand/Substate</th>
            <th>Ligand/Substrate Approval ID</th>
            <th>Standard Ligand/Substrate Concentration</th>
            <th>Standard Ligand/Substrate Concentration Units</th>
          </tr>

          <tr valing="top" class="font11px" *ngFor="let assay of importDataList; let index = index">
            <td>{{(index+1)}}</td>
            <td></td>
            <td>{{assay.externalAssaySource}}</td>
            <td>{{assay.externalAssayId}}</td>
            <td>{{assay.externalAssayReferenceUrl}}</td>
            <td>{{assay.assayTitle}}</td>
            <td>{{assay.assayFormat}}</td>
            <td>{{assay.assayMode}}</td>
            <td>{{assay.bioassayType}}</td>
            <td>{{assay.bioassayClass}}</td>
            <td>{{assay.studyType}}</td>
            <td>{{assay.detectionMethod}}</td>
            <td>{{assay.presentationType}}</td>
            <td>{{assay.presentation}}</td>
            <td>{{assay.publicDomain}}</td>
            <td>{{assay.targetName}}</td>
            <td>{{assay.targetNameApprovalId}}</td>
            <td>{{assay.targetSpecies}}</td>
            <td>{{assay.humanHomologTarget}}</td>
            <td>{{assay.humanHomologTargetApprovalId}}</td>
            <td>{{assay.ligandSubstrate}}</td>
            <td>{{assay.ligandSubstrateApprovalId}}</td>
            <td>{{assay.standardLigandSubstrateConcentration}}</td>
            <td>{{assay.standardLigandSubstrateConcentrationUnits}}</td>
          </tr>
        </table>
      </div>
      <br>

    </div> <!-- divcenter marginto90px-->
    <br>


    <div class="marginleft30px" *ngIf="importDataList.length > 0">
      <!-- Import Assay JSON into the Database -->
      <span>
        <button mat-flat-button color="primary" (click)="importAssayJSONIntoDatabase()" [disabled]="disabled">Import
          Data into Database
        </button>
      </span>
    </div>
    <br><br>

  </div> <!-- scrollable-container -->
</div> <!-- form-content-container -->

<br><br>