<div mat-dialog-title class = "title">
                <h1 >
                 Add Term to CV - {{vocabulary.domain}}
                </h1>
              </div>
<div mat-dialog-content class = "content-fix" #scroller>
    <div *ngFor = "let term of terms; index as index; last as last">
                <div class = "form-row" *ngIf = "toggled[index] && vocabulary.vocabularyTermType === 'ix.ginas.models.v1.FragmentControlledVocabulary'"> 
                                <hr style = "width: 100%;">
                  </div>
                  <ng-template #index>
                                <div class = "form-row">
                        <app-fragment-wizard [vocab] = "vocabulary.domain" style = "margin:auto" [term] = "term" (termUpdated) = "updateStructure(term,index)"></app-fragment-wizard>
                        </div>
                  </ng-template>
               <!-- <div class = "form-row" *ngIf = "toggled[index] && vocabulary.vocabularyTermType === 'ix.ginas.models.v1.FragmentControlledVocabulary'"> 
                          <app-fragment-wizard [vocab] = "vocabulary.domain" style = "margin:auto" [term] = "term" (termUpdated) = "updateStructure(term,index)"></app-fragment-wizard>
                  </div>-->
            <div class = "form-row" [id] = "last? 'latest-term': null">
                <button mat-icon-button (click) = "deleteTerm(index)" class = "delete-icon">
                        <mat-icon svgIcon="delete_forever"></mat-icon>
                </button>
                <mat-form-field class="group">
                        <input matInput placeholder="Display" [(ngModel)]="term.display" required name="display" />
                </mat-form-field>
                <mat-form-field class="group">
                        <input matInput placeholder="Value" [(ngModel)]="term.value" required name="value" />
                </mat-form-field>

                <mat-form-field class="group">
                        <input matInput placeholder="Description" [(ngModel)]="term.description"  name="Description" />
                </mat-form-field>

                <mat-form-field class="group">
                        <input matInput placeholder="Origin" [(ngModel)]="term.origin"  name="origin" />
                </mat-form-field>
                <div class = "checkbox">
                        <mat-checkbox [(ngModel)]="term.hidden" matTooltip="hidden">
                                Hidden
                        </mat-checkbox>
                </div>
                <div class = "checkbox">
                        <mat-checkbox [(ngModel)]="term.selected" matTooltip="selected">
                                Selected
                        </mat-checkbox>
                </div >
                <div class = "checkbox" *ngIf ="vocabulary.vocabularyTermType === 'ix.ginas.models.v1.FragmentControlledVocabulary'">
                        <button mat-button (click) = "editTerms(term, index)">Edit Structure</button>

                       <!-- <button mat-button (click) = "!toggled[index]? toggled[index] = true : toggled[index] = !toggled[index]">{{toggled[index]?"Hide": "Edit Structure"}}</button>-->
                </div>
                </div>
                <div class = "form-row double-row"
                 *ngIf = "vocabulary.vocabularyTermType === 'ix.ginas.models.v1.CodeSystemControlledVocabulary'">
                        <mat-form-field class="group">
                                <input matInput placeholder="Format Regex" [(ngModel)]="term.regex"  name="Regex" />
                        </mat-form-field>
        
                        <mat-form-field class="group">
                                <input matInput placeholder="System Category" [(ngModel)]="term.systemCategory"  name="systemCategory" />
                        </mat-form-field>
                </div>
                <div class = "form-row double-row"
                *ngIf = "vocabulary.vocabularyTermType === 'ix.ginas.models.v1.FragmentControlledVocabulary'">
                        <div class = "str-column">
                                <div>
                                                <mat-form-field class="group">
                                                                <input matInput placeholder="Simplified Structure" [(ngModel)]="term.simplifiedStructure"
                                                                (input)= "checkImg(term, event)"  name="simplifiedStructure" />
                                                        </mat-form-field>
                                </div>
                                <div  *ngIf = "term.simpleSrc">
                                                <img [src] = "term.simpleSrc"/>
                                </div>
                                
                        </div>
                        <div class = "str-column">
                                <div>
                                                <mat-form-field class="group">
                                                                <input matInput placeholder="Fragment Structure" [(ngModel)]="term.fragmentStructure"
                                                                (input)= "checkImg(term, event)"  name="fragmentStructure" />                                 
                                                        </mat-form-field>
                                </div>
                                <div *ngIf = "term.fragmentSrc">
                                                <img [src] = "term.fragmentSrc" />
                                </div>
                       
                       </div>
               </div>
              
        

        </div>
        
</div>

    <div mat-dialog-actions class = "actions">
                <div class = 'validation' *ngIf = "validationMessages.length > 0"><div><b>CV not valid:</b></div>
                <div class = 'message' *ngFor = "let message of validationMessages"><span class = "{{message.messageType}} type">{{message.messageType}} :</span>{{message.message}}</div>
                
                </div>
                <button mat-button (click) = "addTerm()">Add term</button>
            <span class="middle-fill"></span>
            <button mat-button (click)="cancel()">Cancel</button>
            <button mat-button (click)="submit()">
                Save
            </button>
            </div>

