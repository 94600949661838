export const DataDictionary: any = {'Code UUID': {fieldName: 'Uuid',
    fieldPath: '/codes/?/uuid', finalField: 'uuid',
    lucenePath: 'root_codes_uuid', CVDomain: '',
   displayName: 'Code UUID',
    dataType: 'string', luceneQueryable: false},
'Mixture Component Substance UUID': {fieldName: 'Uuid',
    fieldPath: '/mixture/components/?/substance/uuid', finalField: 'uuid',
    lucenePath: 'root_mixture_components_substance_uuid', CVDomain: '',
   displayName: 'Mixture Component Substance UUID',
    dataType: 'string', luceneQueryable: false},
'Mixture Component UUID': {fieldName: 'Uuid',
    fieldPath: '/mixture/components/?/uuid', finalField: 'uuid',
    lucenePath: 'root_mixture_components_uuid', CVDomain: '',
   displayName: 'Mixture Component UUID',
    dataType: 'string', luceneQueryable: false},
'Mixture Parent Substance UUID': {fieldName: 'Uuid',
    fieldPath: '/mixture/parentSubstance/uuid', finalField: 'uuid',
    lucenePath: 'root_mixture_parentSubstance_uuid', CVDomain: '',
   displayName: 'Mixture Parent Substance UUID',
    dataType: 'string', luceneQueryable: false},
'Mixture UUID': {fieldName: 'Uuid',
    fieldPath: '/mixture/uuid', finalField: 'uuid',
    lucenePath: 'root_mixture_uuid', CVDomain: '',
   displayName: 'Mixture UUID',
    dataType: 'string', luceneQueryable: false},
'Agent Substance UUID': {fieldName: 'Uuid',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/uuid', finalField: 'uuid',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_uuid', CVDomain: '',
   displayName: 'Agent Substance UUID',
    dataType: 'string', luceneQueryable: false},
'Agent Modification Amount UUID': {fieldName: 'Uuid',
    fieldPath: '/modifications/agentModifications/?/amount/uuid', finalField: 'uuid',
    lucenePath: 'root_modifications_agentModifications_amount_uuid', CVDomain: '',
   displayName: 'Agent Modification Amount UUID',
    dataType: 'string', luceneQueryable: false},
'Agent Modification UUID': {fieldName: 'Uuid',
    fieldPath: '/modifications/agentModifications/?/uuid', finalField: 'uuid',
    lucenePath: 'root_modifications_agentModifications_uuid', CVDomain: '',
   displayName: 'Agent Modification UUID',
    dataType: 'string', luceneQueryable: false},
'Physical Modification Amount UUID': {fieldName: 'Uuid',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/uuid', finalField: 'uuid',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_uuid', CVDomain: '',
   displayName: 'Physical Modification Amount UUID',
    dataType: 'string', luceneQueryable: false},
'Physical Modification Parameter UUID': {fieldName: 'Uuid',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/uuid', finalField: 'uuid',
    lucenePath: 'root_modifications_physicalModifications_parameters_uuid', CVDomain: '',
   displayName: 'Physical Modification Parameter UUID',
    dataType: 'string', luceneQueryable: false},
'Physical Modification UUID': {fieldName: 'Uuid',
    fieldPath: '/modifications/physicalModifications/?/uuid', finalField: 'uuid',
    lucenePath: 'root_modifications_physicalModifications_uuid', CVDomain: '',
   displayName: 'Physical Modification UUID',
    dataType: 'string', luceneQueryable: false},
'Structural Modification Extent Amount UUID': {fieldName: 'Uuid',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/uuid', finalField: 'uuid',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_uuid', CVDomain: '',
   displayName: 'Structural Modification Extent Amount UUID',
    dataType: 'string', luceneQueryable: false},
'Molecular Fragment UUID': {fieldName: 'Uuid',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/uuid', finalField: 'uuid',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_uuid', CVDomain: '',
   displayName: 'Molecular Fragment UUID',
    dataType: 'string', luceneQueryable: false},
'Structural Modification UUID': {fieldName: 'Uuid',
    fieldPath: '/modifications/structuralModifications/?/uuid', finalField: 'uuid',
    lucenePath: 'root_modifications_structuralModifications_uuid', CVDomain: '',
   displayName: 'Structural Modification UUID',
    dataType: 'string', luceneQueryable: false},
'Modification UUID': {fieldName: 'Uuid',
    fieldPath: '/modifications/uuid', finalField: 'uuid',
    lucenePath: 'root_modifications_uuid', CVDomain: '',
   displayName: 'Modification UUID',
    dataType: 'string', luceneQueryable: false},
'Count Amount Average': {fieldName: 'Average',
    fieldPath: '/moieties/?/countAmount/average', finalField: 'average',
    lucenePath: 'root_moieties_countAmount_average', CVDomain: '',
   displayName: 'Count Amount Average',
    dataType: 'number', luceneQueryable: false},
'Count Amount Created': {fieldName: 'Created',
    fieldPath: '/moieties/?/countAmount/created', finalField: 'created',
    lucenePath: 'root_moieties_countAmount_created', CVDomain: '',
   displayName: 'Count Amount Created',
    dataType: 'number', luceneQueryable: false},
'Count Amount Created By': {fieldName: 'Created By',
    fieldPath: '/moieties/?/countAmount/createdBy', finalField: 'createdBy',
    lucenePath: 'root_moieties_countAmount_createdBy', CVDomain: '',
   displayName: 'Count Amount Created By',
    dataType: 'string', luceneQueryable: false},
'Count Amount High': {fieldName: 'High',
    fieldPath: '/moieties/?/countAmount/high', finalField: 'high',
    lucenePath: 'root_moieties_countAmount_high', CVDomain: '',
   displayName: 'Count Amount High',
    dataType: 'number', luceneQueryable: false},
'Count Amount Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/moieties/?/countAmount/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_moieties_countAmount_lastEdited', CVDomain: '',
   displayName: 'Count Amount Last Edited',
    dataType: 'number', luceneQueryable: false},
'Count Amount Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/moieties/?/countAmount/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_moieties_countAmount_lastEditedBy', CVDomain: '',
   displayName: 'Count Amount Last Edited By',
    dataType: 'string', luceneQueryable: false},
'Count Amount Low': {fieldName: 'Low',
    fieldPath: '/moieties/?/countAmount/low', finalField: 'low',
    lucenePath: 'root_moieties_countAmount_low', CVDomain: '',
   displayName: 'Count Amount Low',
    dataType: 'number', luceneQueryable: false},
'Count Amount Non-Numeric Value': {fieldName: 'Non Numeric Value',
    fieldPath: '/moieties/?/countAmount/nonNumericValue', finalField: 'nonNumericValue',
    lucenePath: 'root_moieties_countAmount_nonNumericValue', CVDomain: '',
   displayName: 'Count Amount Non-Numeric Value',
    dataType: 'string', luceneQueryable: false},
'Count Amount Type': {fieldName: 'Type',
    fieldPath: '/moieties/?/countAmount/type', finalField: 'type',
    lucenePath: 'root_moieties_countAmount_type', CVDomain: 'AMOUNT_TYPE',
   displayName: 'Count Amount Type',
    dataType: 'string', luceneQueryable: false},
'Count Amount Units': {fieldName: 'Units',
    fieldPath: '/moieties/?/countAmount/units', finalField: 'units',
    lucenePath: 'root_moieties_countAmount_units', CVDomain: 'AMOUNT_UNIT',
   displayName: 'Count Amount Units',
    dataType: 'string', luceneQueryable: false},
'Count Amount UUID': {fieldName: 'Uuid',
    fieldPath: '/moieties/?/countAmount/uuid', finalField: 'uuid',
    lucenePath: 'root_moieties_countAmount_uuid', CVDomain: '',
   displayName: 'Count Amount UUID',
    dataType: 'string', luceneQueryable: false},
'Moiety UUID': {fieldName: 'Uuid',
    fieldPath: '/moieties/?/uuid', finalField: 'uuid',
    lucenePath: 'root_moieties_uuid', CVDomain: '',
   displayName: 'Moiety UUID',
    dataType: 'string', luceneQueryable: false},
'Naming Organization UUID': {fieldName: 'Uuid',
    fieldPath: '/names/?/nameOrgs/?/uuid', finalField: 'uuid',
    lucenePath: 'root_names_nameOrgs_uuid', CVDomain: '',
   displayName: 'Naming Organization UUID',
    dataType: 'string', luceneQueryable: false},
'Name UUID': {fieldName: 'Uuid',
    fieldPath: '/names/?/uuid', finalField: 'uuid',
    lucenePath: 'root_names_uuid', CVDomain: '',
   displayName: 'Name UUID',
    dataType: 'string', luceneQueryable: false},
'Polymer Classification UUID': {fieldName: 'Uuid',
    fieldPath: '/notes/?/uuid', finalField: 'uuid',
    lucenePath: 'root_notes_uuid', CVDomain: '',
   displayName: 'Polymer Classification UUID',
    dataType: 'string', luceneQueryable: false},
'Linkage Created': {fieldName: 'Created',
    fieldPath: '/nucleicAcid/linkages/?/created', finalField: 'created',
    lucenePath: 'root_nucleicAcid_linkages_created', CVDomain: '',
   displayName: 'Linkage Created',
    dataType: 'number', luceneQueryable: false},
'Linkage Created By': {fieldName: 'Created By',
    fieldPath: '/nucleicAcid/linkages/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_nucleicAcid_linkages_createdBy', CVDomain: '',
   displayName: 'Linkage Created By',
    dataType: 'string', luceneQueryable: false},
'Linkage Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/nucleicAcid/linkages/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_nucleicAcid_linkages_lastEdited', CVDomain: '',
   displayName: 'Linkage Last Edited',
    dataType: 'number', luceneQueryable: false},
'Linkage Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/nucleicAcid/linkages/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_nucleicAcid_linkages_lastEditedBy', CVDomain: '',
   displayName: 'Linkage Last Edited By',
    dataType: 'string', luceneQueryable: false},
'Linkage Linkage': {fieldName: 'Linkage',
    fieldPath: '/nucleicAcid/linkages/?/linkage', finalField: 'linkage',
    lucenePath: 'root_nucleicAcid_linkages_linkage', CVDomain: 'NUCLEIC_ACID_LINKAGE',
   displayName: 'Linkage Linkage',
    dataType: 'string', luceneQueryable: false},
'Linkage UUID': {fieldName: 'Uuid',
    fieldPath: '/nucleicAcid/linkages/?/uuid', finalField: 'uuid',
    lucenePath: 'root_nucleicAcid_linkages_uuid', CVDomain: '',
   displayName: 'Linkage UUID',
    dataType: 'string', luceneQueryable: false},
'Nucleic Acid Sub Type': {fieldName: 'Nucleic Acid Sub Type',
    fieldPath: '/nucleicAcid/nucleicAcidSubType', finalField: 'nucleicAcidSubType',
    lucenePath: 'root_nucleicAcid_nucleicAcidSubType', CVDomain: 'NUCLEIC_ACID_SUBTYPE',
   displayName: 'Nucleic Acid Sub Type',
    dataType: 'string', luceneQueryable: false},
'Nucleic Acid Type': {fieldName: 'Nucleic Acid Type',
    fieldPath: '/nucleicAcid/nucleicAcidType', finalField: 'nucleicAcidType',
    lucenePath: 'root_nucleicAcid_nucleicAcidType', CVDomain: 'NUCLEIC_ACID_TYPE',
   displayName: 'Nucleic Acid Type',
    dataType: 'string', luceneQueryable: false},
'Nucleic Acid Sequence Origin': {fieldName: 'Sequence Origin',
    fieldPath: '/nucleicAcid/sequenceOrigin', finalField: 'sequenceOrigin',
    lucenePath: 'root_nucleicAcid_sequenceOrigin', CVDomain: 'NUCLEIC_ACID_SEQUENCE_ORIGIN',
   displayName: 'Nucleic Acid Sequence Origin',
    dataType: 'string', luceneQueryable: false},
'Nucleic Acid Sequence Type': {fieldName: 'Sequence Type',
    fieldPath: '/nucleicAcid/sequenceType', finalField: 'sequenceType',
    lucenePath: 'root_nucleicAcid_sequenceType', CVDomain: 'SEQUENCE_TYPE',
   displayName: 'Nucleic Acid Sequence Type',
    dataType: 'string', luceneQueryable: false},
'Nucleic Acid Subunit UUID': {fieldName: 'Uuid',
    fieldPath: '/nucleicAcid/subunits/?/uuid', finalField: 'uuid',
    lucenePath: 'root_nucleicAcid_subunits_uuid', CVDomain: '',
   displayName: 'Nucleic Acid Subunit UUID',
    dataType: 'string', luceneQueryable: false},
'Sugar Created': {fieldName: 'Created',
    fieldPath: '/nucleicAcid/sugars/?/created', finalField: 'created',
    lucenePath: 'root_nucleicAcid_sugars_created', CVDomain: '',
   displayName: 'Sugar Created',
    dataType: 'number', luceneQueryable: false},
'Sugar Created By': {fieldName: 'Created By',
    fieldPath: '/nucleicAcid/sugars/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_nucleicAcid_sugars_createdBy', CVDomain: '',
   displayName: 'Sugar Created By',
    dataType: 'string', luceneQueryable: false},
'Sugar Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/nucleicAcid/sugars/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_nucleicAcid_sugars_lastEdited', CVDomain: '',
   displayName: 'Sugar Last Edited',
    dataType: 'number', luceneQueryable: false},
'Sugar Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/nucleicAcid/sugars/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_nucleicAcid_sugars_lastEditedBy', CVDomain: '',
   displayName: 'Sugar Last Edited By',
    dataType: 'string', luceneQueryable: false},
'Sugar Sugar': {fieldName: 'Sugar',
    fieldPath: '/nucleicAcid/sugars/?/sugar', finalField: 'sugar',
    lucenePath: 'root_nucleicAcid_sugars_sugar', CVDomain: '',
   displayName: 'Sugar Sugar',
    dataType: 'string', luceneQueryable: false},
'Sugar UUID': {fieldName: 'Uuid',
    fieldPath: '/nucleicAcid/sugars/?/uuid', finalField: 'uuid',
    lucenePath: 'root_nucleicAcid_sugars_uuid', CVDomain: '',
   displayName: 'Sugar UUID',
    dataType: 'string', luceneQueryable: false},
'Nucleic Acid UUID': {fieldName: 'Uuid',
    fieldPath: '/nucleicAcid/uuid', finalField: 'uuid',
    lucenePath: 'root_nucleicAcid_uuid', CVDomain: '',
   displayName: 'Nucleic Acid UUID',
    dataType: 'string', luceneQueryable: false},
'Polymer Parent Substance UUID': {fieldName: 'Uuid',
    fieldPath: '/polymer/classification/parentSubstance/uuid', finalField: 'uuid',
    lucenePath: 'root_polymer_classification_parentSubstance_uuid', CVDomain: '',
   displayName: 'Polymer Parent Substance UUID',
    dataType: 'string', luceneQueryable: false},
'Display Structure UUID': {fieldName: 'Uuid',
    fieldPath: '/polymer/classification/uuid', finalField: 'uuid',
    lucenePath: 'root_polymer_classification_uuid', CVDomain: '',
   displayName: 'Display Structure UUID',
    dataType: 'string', luceneQueryable: false},
'Monomer Amount UUID': {fieldName: 'Uuid',
    fieldPath: '/polymer/monomers/?/amount/uuid', finalField: 'uuid',
    lucenePath: 'root_polymer_monomers_amount_uuid', CVDomain: '',
   displayName: 'Monomer Amount UUID',
    dataType: 'string', luceneQueryable: false},
'Monomer Substance UUID': {fieldName: 'Uuid',
    fieldPath: '/polymer/monomers/?/monomerSubstance/uuid', finalField: 'uuid',
    lucenePath: 'root_polymer_monomers_monomerSubstance_uuid', CVDomain: '',
   displayName: 'Monomer Substance UUID',
    dataType: 'string', luceneQueryable: false},
'Monomer UUID': {fieldName: 'Uuid',
    fieldPath: '/polymer/monomers/?/uuid', finalField: 'uuid',
    lucenePath: 'root_polymer_monomers_uuid', CVDomain: '',
   displayName: 'Monomer UUID',
    dataType: 'string', luceneQueryable: false},
'SRU Amount UUID': {fieldName: 'Uuid',
    fieldPath: '/polymer/structuralUnits/?/amount/uuid', finalField: 'uuid',
    lucenePath: 'root_polymer_structuralUnits_amount_uuid', CVDomain: '',
   displayName: 'SRU Amount UUID',
    dataType: 'string', luceneQueryable: false},
'Polymer UUID': {fieldName: 'Uuid',
    fieldPath: '/polymer/uuid', finalField: 'uuid',
    lucenePath: 'root_polymer_uuid', CVDomain: '',
   displayName: 'Polymer UUID',
    dataType: 'string', luceneQueryable: false},
'Parameter Created': {fieldName: 'Created',
    fieldPath: '/properties/?/parameters/?/created', finalField: 'created',
    lucenePath: 'root_properties_parameters_created', CVDomain: '',
   displayName: 'Parameter Created',
    dataType: 'number', luceneQueryable: false},
'Parameter Created By': {fieldName: 'Created By',
    fieldPath: '/properties/?/parameters/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_properties_parameters_createdBy', CVDomain: '',
   displayName: 'Parameter Created By',
    dataType: 'string', luceneQueryable: false},
'Parameter Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/properties/?/parameters/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_properties_parameters_lastEdited', CVDomain: '',
   displayName: 'Parameter Last Edited',
    dataType: 'number', luceneQueryable: false},
'Parameter Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/properties/?/parameters/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_properties_parameters_lastEditedBy', CVDomain: '',
   displayName: 'Parameter Last Edited By',
    dataType: 'string', luceneQueryable: false},
'Parameter Type': {fieldName: 'Type',
    fieldPath: '/properties/?/parameters/?/type', finalField: 'type',
    lucenePath: 'root_properties_parameters_type', CVDomain: '',
   displayName: 'Parameter Type',
    dataType: 'string', luceneQueryable: false},
'Parameter UUID': {fieldName: 'Uuid',
    fieldPath: '/properties/?/parameters/?/uuid', finalField: 'uuid',
    lucenePath: 'root_properties_parameters_uuid', CVDomain: '',
   displayName: 'Parameter UUID',
    dataType: 'string', luceneQueryable: false},
'Parameter Value Average': {fieldName: 'Average',
    fieldPath: '/properties/?/parameters/?/value/average', finalField: 'average',
    lucenePath: 'root_properties_parameters_value_average', CVDomain: '',
   displayName: 'Parameter Value Average',
    dataType: 'number', luceneQueryable: false},
'Parameter Value Created': {fieldName: 'Created',
    fieldPath: '/properties/?/parameters/?/value/created', finalField: 'created',
    lucenePath: 'root_properties_parameters_value_created', CVDomain: '',
   displayName: 'Parameter Value Created',
    dataType: 'number', luceneQueryable: false},
'Parameter Value Created By': {fieldName: 'Created By',
    fieldPath: '/properties/?/parameters/?/value/createdBy', finalField: 'createdBy',
    lucenePath: 'root_properties_parameters_value_createdBy', CVDomain: '',
   displayName: 'Parameter Value Created By',
    dataType: 'string', luceneQueryable: false},
'Parameter Value High': {fieldName: 'High',
    fieldPath: '/properties/?/parameters/?/value/high', finalField: 'high',
    lucenePath: 'root_properties_parameters_value_high', CVDomain: '',
   displayName: 'Parameter Value High',
    dataType: 'number', luceneQueryable: false},
'Parameter Value Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/properties/?/parameters/?/value/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_properties_parameters_value_lastEdited', CVDomain: '',
   displayName: 'Parameter Value Last Edited',
    dataType: 'number', luceneQueryable: false},
'Parameter Value Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/properties/?/parameters/?/value/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_properties_parameters_value_lastEditedBy', CVDomain: '',
   displayName: 'Parameter Value Last Edited By',
    dataType: 'string', luceneQueryable: false},
'Parameter Value Low': {fieldName: 'Low',
    fieldPath: '/properties/?/parameters/?/value/low', finalField: 'low',
    lucenePath: 'root_properties_parameters_value_low', CVDomain: '',
   displayName: 'Parameter Value Low',
    dataType: 'number', luceneQueryable: false},
'Parameter Value Non-Numeric Value': {fieldName: 'Non Numeric Value',
    fieldPath: '/properties/?/parameters/?/value/nonNumericValue', finalField: 'nonNumericValue',
    lucenePath: 'root_properties_parameters_value_nonNumericValue', CVDomain: '',
   displayName: 'Parameter Value Non-Numeric Value',
    dataType: 'string', luceneQueryable: false},
'Parameter Value Type': {fieldName: 'Type',
    fieldPath: '/properties/?/parameters/?/value/type', finalField: 'type',
    lucenePath: 'root_properties_parameters_value_type', CVDomain: 'AMOUNT_TYPE',
   displayName: 'Parameter Value Type',
    dataType: 'string', luceneQueryable: false},
'Parameter Value Units': {fieldName: 'Units',
    fieldPath: '/properties/?/parameters/?/value/units', finalField: 'units',
    lucenePath: 'root_properties_parameters_value_units', CVDomain: 'AMOUNT_UNIT',
   displayName: 'Parameter Value Units',
    dataType: 'string', luceneQueryable: false},
'Parameter Value UUID': {fieldName: 'Uuid',
    fieldPath: '/properties/?/parameters/?/value/uuid', finalField: 'uuid',
    lucenePath: 'root_properties_parameters_value_uuid', CVDomain: '',
   displayName: 'Parameter Value UUID',
    dataType: 'string', luceneQueryable: false},
'Property Type': {fieldName: 'Value type',
    fieldPath: '/properties/?/type', finalField: 'type',
    lucenePath: 'root_properties_type', CVDomain: '',
   displayName: 'Property Type',
    dataType: 'string', luceneQueryable: false},
'Property UUID': {fieldName: 'Uuid',
    fieldPath: '/properties/?/uuid', finalField: 'uuid',
    lucenePath: 'root_properties_uuid', CVDomain: '',
   displayName: 'Property UUID',
    dataType: 'string', luceneQueryable: false},
'Value Average': {fieldName: 'Average',
    fieldPath: '/properties/?/value/average', finalField: 'average',
    lucenePath: 'root_properties_value_average', CVDomain: '',
   displayName: 'Value Average',
    dataType: 'number', luceneQueryable: false},
'Value Created': {fieldName: 'Created',
    fieldPath: '/properties/?/value/created', finalField: 'created',
    lucenePath: 'root_properties_value_created', CVDomain: '',
   displayName: 'Value Created',
    dataType: 'number', luceneQueryable: false},
'Value Created By': {fieldName: 'Created By',
    fieldPath: '/properties/?/value/createdBy', finalField: 'createdBy',
    lucenePath: 'root_properties_value_createdBy', CVDomain: '',
   displayName: 'Value Created By',
    dataType: 'string', luceneQueryable: false},
'Value High': {fieldName: 'High',
    fieldPath: '/properties/?/value/high', finalField: 'high',
    lucenePath: 'root_properties_value_high', CVDomain: '',
   displayName: 'Value High',
    dataType: 'number', luceneQueryable: false},
'Value High Limit': {fieldName: 'High Limit',
    fieldPath: '/properties/?/value/highLimit', finalField: 'highLimit',
    lucenePath: 'root_properties_value_highLimit', CVDomain: '',
   displayName: 'Value High Limit',
    dataType: 'number', luceneQueryable: false},
'Value Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/properties/?/value/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_properties_value_lastEdited', CVDomain: '',
   displayName: 'Value Last Edited',
    dataType: 'number', luceneQueryable: false},
'Value Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/properties/?/value/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_properties_value_lastEditedBy', CVDomain: '',
   displayName: 'Value Last Edited By',
    dataType: 'string', luceneQueryable: false},
'Value Low': {fieldName: 'Low',
    fieldPath: '/properties/?/value/low', finalField: 'low',
    lucenePath: 'root_properties_value_low', CVDomain: '',
   displayName: 'Value Low',
    dataType: 'number', luceneQueryable: false},
'Value Low Limit': {fieldName: 'Low Limit',
    fieldPath: '/properties/?/value/lowLimit', finalField: 'lowLimit',
    lucenePath: 'root_properties_value_lowLimit', CVDomain: '',
   displayName: 'Value Low Limit',
    dataType: 'number', luceneQueryable: false},
'Value Non-Numeric Value': {fieldName: 'Non Numeric Value',
    fieldPath: '/properties/?/value/nonNumericValue', finalField: 'nonNumericValue',
    lucenePath: 'root_properties_value_nonNumericValue', CVDomain: '',
   displayName: 'Value Non-Numeric Value',
    dataType: 'string', luceneQueryable: false},
'Value Type': {fieldName: 'Type',
    fieldPath: '/properties/?/value/type', finalField: 'type',
    lucenePath: 'root_properties_value_type', CVDomain: 'AMOUNT_TYPE',
   displayName: 'Value Type',
    dataType: 'string', luceneQueryable: false},
'Value Units': {fieldName: 'Units',
    fieldPath: '/properties/?/value/units', finalField: 'units',
    lucenePath: 'root_properties_value_units', CVDomain: 'AMOUNT_UNIT',
   displayName: 'Value Units',
    dataType: 'string', luceneQueryable: false},
'Value UUID': {fieldName: 'Uuid',
    fieldPath: '/properties/?/value/uuid', finalField: 'uuid',
    lucenePath: 'root_properties_value_uuid', CVDomain: '',
   displayName: 'Value UUID',
    dataType: 'string', luceneQueryable: false},
'Protein Glycosylation UUID': {fieldName: 'Uuid',
    fieldPath: '/protein/glycosylation/uuid', finalField: 'uuid',
    lucenePath: 'root_protein_glycosylation_uuid', CVDomain: '',
   displayName: 'Protein Glycosylation UUID',
    dataType: 'string', luceneQueryable: false},
'Other Link UUID': {fieldName: 'Uuid',
    fieldPath: '/protein/otherLinks/?/uuid', finalField: 'uuid',
    lucenePath: 'root_protein_otherLinks_uuid', CVDomain: '',
   displayName: 'Other Link UUID',
    dataType: 'string', luceneQueryable: false},
'Protein Subunit UUID': {fieldName: 'Uuid',
    fieldPath: '/protein/subunits/?/uuid', finalField: 'uuid',
    lucenePath: 'root_protein_subunits_uuid', CVDomain: '',
   displayName: 'Protein Subunit UUID',
    dataType: 'string', luceneQueryable: false},
'Protein UUID': {fieldName: 'Uuid',
    fieldPath: '/protein/uuid', finalField: 'uuid',
    lucenePath: 'root_protein_uuid', CVDomain: '',
   displayName: 'Protein UUID',
    dataType: 'string', luceneQueryable: false},
'Reference UUID': {fieldName: 'Uuid',
    fieldPath: '/references/?/uuid', finalField: 'uuid',
    lucenePath: 'root_references_uuid', CVDomain: '',
   displayName: 'Reference UUID',
    dataType: 'string', luceneQueryable: false},
'Relationship Amount UUID': {fieldName: 'Uuid',
    fieldPath: '/relationships/?/amount/uuid', finalField: 'uuid',
    lucenePath: 'root_relationships_amount_uuid', CVDomain: '',
   displayName: 'Relationship Amount UUID',
    dataType: 'string', luceneQueryable: false},
'Mediator Substance UUID': {fieldName: 'Uuid',
    fieldPath: '/relationships/?/mediatorSubstance/uuid', finalField: 'uuid',
    lucenePath: 'root_relationships_mediatorSubstance_uuid', CVDomain: '',
   displayName: 'Mediator Substance UUID',
    dataType: 'string', luceneQueryable: false},
'Related Substance UUID': {fieldName: 'Uuid',
    fieldPath: '/relationships/?/relatedSubstance/uuid', finalField: 'uuid',
    lucenePath: 'root_relationships_relatedSubstance_uuid', CVDomain: '',
   displayName: 'Related Substance UUID',
    dataType: 'string', luceneQueryable: false},
'Relationship UUID': {fieldName: 'Uuid',
    fieldPath: '/relationships/?/uuid', finalField: 'uuid',
    lucenePath: 'root_relationships_uuid', CVDomain: '',
   displayName: 'Relationship UUID',
    dataType: 'string', luceneQueryable: false},
'St. Div. Hybrid Parent (m) UUID': {fieldName: 'Uuid',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/uuid', finalField: 'uuid',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_uuid', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) UUID',
    dataType: 'string', luceneQueryable: false},
'St. Div. Hybrid Parent (p) UUID': {fieldName: 'Uuid',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/uuid', finalField: 'uuid',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_uuid', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) UUID',
    dataType: 'boolean', luceneQueryable: false},
'St. Div. Hybrid Parent UUID': {fieldName: 'Uuid',
    fieldPath: '/structurallyDiverse/parentSubstance/uuid', finalField: 'uuid',
    lucenePath: 'root_structurallyDiverse_parentSubstance_uuid', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent UUID',
    dataType: 'string', luceneQueryable: false},
'Structurally Diverse UUID': {fieldName: 'Uuid',
    fieldPath: '/structurallyDiverse/uuid', finalField: 'uuid',
    lucenePath: 'root_structurallyDiverse_uuid', CVDomain: '',
   displayName: 'Structurally Diverse UUID',
    dataType: 'array <string>', luceneQueryable: false},
'Record Uuid': {fieldName: 'Uuid',
    fieldPath: '/uuid', finalField: 'uuid',
    lucenePath: 'root_uuid', CVDomain: '',
   displayName: 'Record Uuid',
    dataType: 'boolean', luceneQueryable: false},
'Mixture Component Substance Linking ID': {fieldName: 'Linking Id',
    fieldPath: '/mixture/components/?/substance/linkingID', finalField: 'linkingID',
    lucenePath: 'root_mixture_components_substance_linkingID', CVDomain: '',
   displayName: 'Mixture Component Substance Linking ID',
    dataType: 'string', luceneQueryable: false},
'Mixture Component Substance Name': {fieldName: 'Name',
    fieldPath: '/mixture/components/?/substance/name', finalField: 'name',
    lucenePath: 'root_mixture_components_substance_name', CVDomain: '',
   displayName: 'Mixture Component Substance Name',
    dataType: 'string', luceneQueryable: false},
'Mixture Parent Substance Linking ID': {fieldName: 'Linking Id',
    fieldPath: '/mixture/parentSubstance/linkingID', finalField: 'linkingID',
    lucenePath: 'root_mixture_parentSubstance_linkingID', CVDomain: '',
   displayName: 'Mixture Parent Substance Linking ID',
    dataType: 'string', luceneQueryable: false},
'Mixture Parent Substance Name': {fieldName: 'Name',
    fieldPath: '/mixture/parentSubstance/name', finalField: 'name',
    lucenePath: 'root_mixture_parentSubstance_name', CVDomain: '',
   displayName: 'Mixture Parent Substance Name',
    dataType: 'string', luceneQueryable: false},
'Agent Substance Linking ID': {fieldName: 'Linking Id',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/linkingID', finalField: 'linkingID',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_linkingID', CVDomain: '',
   displayName: 'Agent Substance Linking ID',
    dataType: 'string', luceneQueryable: false},
'Agent Substance Name': {fieldName: 'Name',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/name', finalField: 'name',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_name', CVDomain: '',
   displayName: 'Agent Substance Name',
    dataType: 'string', luceneQueryable: false},
'Molecular Fragment Linking ID': {fieldName: 'Linking Id',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/linkingID', finalField: 'linkingID',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_linkingID', CVDomain: '',
   displayName: 'Molecular Fragment Linking ID',
    dataType: 'string', luceneQueryable: false},
'Molecular Fragment Name': {fieldName: 'Name',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/name', finalField: 'name',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_name', CVDomain: '',
   displayName: 'Molecular Fragment Name',
    dataType: 'string', luceneQueryable: false},
'Moiety Hash': {fieldName: 'Hash',
    fieldPath: '/moieties/?/hash', finalField: 'hash',
    lucenePath: 'root_moieties_hash', CVDomain: '',
   displayName: 'Moiety Hash',
    dataType: 'string', luceneQueryable: false},
'Moiety ID': {fieldName: 'Id',
    fieldPath: '/moieties/?/id', finalField: 'id',
    lucenePath: 'root_moieties_id', CVDomain: '',
   displayName: 'Moiety ID',
    dataType: 'string', luceneQueryable: false},
'Linkage Sites Shorthand': {fieldName: 'Sites Shorthand',
    fieldPath: '/nucleicAcid/linkages/?/sitesShorthand', finalField: 'sitesShorthand',
    lucenePath: 'root_nucleicAcid_linkages_sitesShorthand', CVDomain: '',
   displayName: 'Linkage Sites Shorthand',
    dataType: 'string', luceneQueryable: false},
'Nucleic Acid Subunit Length': {fieldName: 'Length',
    fieldPath: '/nucleicAcid/subunits/?/length', finalField: 'length',
    lucenePath: 'root_nucleicAcid_subunits_length', CVDomain: '',
   displayName: 'Nucleic Acid Subunit Length',
    dataType: 'number', luceneQueryable: false},
'Sugar Sites Shorthand': {fieldName: 'Sites Shorthand',
    fieldPath: '/nucleicAcid/sugars/?/sitesShorthand', finalField: 'sitesShorthand',
    lucenePath: 'root_nucleicAcid_sugars_sitesShorthand', CVDomain: '',
   displayName: 'Sugar Sites Shorthand',
    dataType: 'string', luceneQueryable: false},
'Polymer Parent Substance Linking ID': {fieldName: 'Linking Id',
    fieldPath: '/polymer/classification/parentSubstance/linkingID', finalField: 'linkingID',
    lucenePath: 'root_polymer_classification_parentSubstance_linkingID', CVDomain: '',
   displayName: 'Polymer Parent Substance Linking ID',
    dataType: 'string', luceneQueryable: false},
'Polymer Parent Substance Name': {fieldName: 'Name',
    fieldPath: '/polymer/classification/parentSubstance/name', finalField: 'name',
    lucenePath: 'root_polymer_classification_parentSubstance_name', CVDomain: '',
   displayName: 'Polymer Parent Substance Name',
    dataType: 'string', luceneQueryable: false},
'Display Structure ID': {fieldName: 'Id',
    fieldPath: '/polymer/displayStructure/id', finalField: 'id',
    lucenePath: 'root_polymer_displayStructure_id', CVDomain: '',
   displayName: 'Display Structure ID',
    dataType: 'string', luceneQueryable: false},
'Idealized Structure ID': {fieldName: 'Id',
    fieldPath: '/polymer/idealizedStructure/id', finalField: 'id',
    lucenePath: 'root_polymer_idealizedStructure_id', CVDomain: '',
   displayName: 'Idealized Structure ID',
    dataType: 'string', luceneQueryable: false},
'Monomer Substance Linking ID': {fieldName: 'Linking Id',
    fieldPath: '/polymer/monomers/?/monomerSubstance/linkingID', finalField: 'linkingID',
    lucenePath: 'root_polymer_monomers_monomerSubstance_linkingID', CVDomain: '',
   displayName: 'Monomer Substance Linking ID',
    dataType: 'string', luceneQueryable: false},
'Monomer Substance Original Name': {fieldName: 'Name',
    fieldPath: '/polymer/monomers/?/monomerSubstance/name', finalField: 'name',
    lucenePath: 'root_polymer_monomers_monomerSubstance_name', CVDomain: '',
   displayName: 'Monomer Substance Original Name',
    dataType: 'string', luceneQueryable: false},
'Parameter Name': {fieldName: 'Name',
    fieldPath: '/properties/?/parameters/?/name', finalField: 'name',
    lucenePath: 'root_properties_parameters_name', CVDomain: '',
   displayName: 'Parameter Name',
    dataType: 'string', luceneQueryable: false},
'Disulfide Link Sites Shorthand': {fieldName: 'Sites Shorthand',
    fieldPath: '/protein/disulfideLinks/?/sitesShorthand', finalField: 'sitesShorthand',
    lucenePath: 'root_protein_disulfideLinks_sitesShorthand', CVDomain: '',
   displayName: 'Disulfide Link Sites Shorthand',
    dataType: 'string', luceneQueryable: false},
'Protein Subunit Length': {fieldName: 'Length',
    fieldPath: '/protein/subunits/?/length', finalField: 'length',
    lucenePath: 'root_protein_subunits_length', CVDomain: '',
   displayName: 'Protein Subunit Length',
    dataType: 'number', luceneQueryable: false},
'Mediator Substance Linking ID': {fieldName: 'Linking Id',
    fieldPath: '/relationships/?/mediatorSubstance/linkingID', finalField: 'linkingID',
    lucenePath: 'root_relationships_mediatorSubstance_linkingID', CVDomain: '',
   displayName: 'Mediator Substance Linking ID',
    dataType: 'string', luceneQueryable: false},
'Mediator Substance Name': {fieldName: 'Name',
    fieldPath: '/relationships/?/mediatorSubstance/name', finalField: 'name',
    lucenePath: 'root_relationships_mediatorSubstance_name', CVDomain: '',
   displayName: 'Mediator Substance Name',
    dataType: 'string', luceneQueryable: false},
'Related Substance Linking ID': {fieldName: 'Linking Id',
    fieldPath: '/relationships/?/relatedSubstance/linkingID', finalField: 'linkingID',
    lucenePath: 'root_relationships_relatedSubstance_linkingID', CVDomain: '',
   displayName: 'Related Substance Linking ID',
    dataType: 'string', luceneQueryable: false},
'Related Substance Original Name': {fieldName: 'Name',
    fieldPath: '/relationships/?/relatedSubstance/name', finalField: 'name',
    lucenePath: 'root_relationships_relatedSubstance_name', CVDomain: '',
   displayName: 'Related Substance Original Name',
    dataType: 'string', luceneQueryable: false},
'St. Div. Hybrid Parent (m) Linking ID': {fieldName: 'Linking Id',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/linkingID', finalField: 'linkingID',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_linkingID', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) Linking ID',
    dataType: 'string', luceneQueryable: false},
'St. Div. Hybrid Parent (m) Name': {fieldName: 'Name',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/name', finalField: 'name',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_name', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) Name',
    dataType: 'string', luceneQueryable: false},
'St. Div. Hybrid Parent (p) Linking ID': {fieldName: 'Linking Id',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/linkingID', finalField: 'linkingID',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_linkingID', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) Linking ID',
    dataType: 'string', luceneQueryable: false},
'St. Div. Hybrid Parent (p) Name': {fieldName: 'Name',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/name', finalField: 'name',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_name', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) Name',
    dataType: 'string', luceneQueryable: false},
'St. Div. Hybrid Parent Linking ID': {fieldName: 'Linking Id',
    fieldPath: '/structurallyDiverse/parentSubstance/linkingID', finalField: 'linkingID',
    lucenePath: 'root_structurallyDiverse_parentSubstance_linkingID', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent Linking ID',
    dataType: 'number', luceneQueryable: false},
'St. Div. Hybrid Parent Name': {fieldName: 'Name',
    fieldPath: '/structurallyDiverse/parentSubstance/name', finalField: 'name',
    lucenePath: 'root_structurallyDiverse_parentSubstance_name', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent Name',
    dataType: 'string', luceneQueryable: false},
'Structure Hash': {fieldName: 'Hash',
    fieldPath: '/structure/hash', finalField: 'hash',
    lucenePath: 'root_structure_hash', CVDomain: '',
   displayName: 'Structure Hash',
    dataType: 'string', luceneQueryable: false},
'Structure ID': {fieldName: 'Id',
    fieldPath: '/structure/id', finalField: 'id',
    lucenePath: 'root_structure_id', CVDomain: '',
   displayName: 'Structure ID',
    dataType: 'string', luceneQueryable: false},
'Record Codes': {fieldName: 'Codes',
    fieldPath: '/codes', finalField: 'codes',
    lucenePath: '', CVDomain: '',
   displayName: 'Record Codes',
    dataType: 'array <Code>', luceneQueryable: false},
'Code References': {fieldName: 'References',
    fieldPath: '/codes/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Code References',
    dataType: 'array <string>', luceneQueryable: false},
'Mixture': {fieldName: 'Mixture',
    fieldPath: '/mixture', finalField: 'mixture',
    lucenePath: '', CVDomain: '',
   displayName: 'Mixture',
    dataType: 'object <Mixture>', luceneQueryable: false},
'Mixture Components': {fieldName: 'Components',
    fieldPath: '/mixture/components', finalField: 'components',
    lucenePath: '', CVDomain: '',
   displayName: 'Mixture Components',
    dataType: 'array <Component>', luceneQueryable: false},
'Mixture Component References': {fieldName: 'References',
    fieldPath: '/mixture/components/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Mixture Component References',
    dataType: 'array <string>', luceneQueryable: false},
'Mixture Component Substance': {fieldName: 'Substance',
    fieldPath: '/mixture/components/?/substance', finalField: 'substance',
    lucenePath: '', CVDomain: '',
   displayName: 'Mixture Component Substance',
    dataType: 'object <SubstanceReference>', luceneQueryable: false},
'Mixture Component Substance References': {fieldName: 'References',
    fieldPath: '/mixture/components/?/substance/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Mixture Component Substance References',
    dataType: 'array <string>', luceneQueryable: false},
'Mixture Parent Substance': {fieldName: 'Parent Substance',
    fieldPath: '/mixture/parentSubstance', finalField: 'parentSubstance',
    lucenePath: '', CVDomain: '',
   displayName: 'Mixture Parent Substance',
    dataType: 'object <SubstanceReference>', luceneQueryable: false},
'Mixture Parent Substance References': {fieldName: 'References',
    fieldPath: '/mixture/parentSubstance/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Mixture Parent Substance References',
    dataType: 'array <string>', luceneQueryable: false},
'Mixture References': {fieldName: 'References',
    fieldPath: '/mixture/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Mixture References',
    dataType: 'array <string>', luceneQueryable: false},
'Modifications': {fieldName: 'Modifications',
    fieldPath: '/modifications', finalField: 'modifications',
    lucenePath: '', CVDomain: '',
   displayName: 'Modifications',
    dataType: 'object <Modification>', luceneQueryable: false},
'Agent Modifications': {fieldName: 'Agent Modifications',
    fieldPath: '/modifications/agentModifications', finalField: 'agentModifications',
    lucenePath: '', CVDomain: '',
   displayName: 'Agent Modifications',
    dataType: 'array <AgentModification>', luceneQueryable: false},
'Agent Modification Agent Substance': {fieldName: 'Agent Substance',
    fieldPath: '/modifications/agentModifications/?/agentSubstance', finalField: 'agentSubstance',
    lucenePath: '', CVDomain: '',
   displayName: 'Agent Modification Agent Substance',
    dataType: 'object <SubstanceReference>', luceneQueryable: false},
'Agent Substance References': {fieldName: 'References',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Agent Substance References',
    dataType: 'array <string>', luceneQueryable: false},
'Agent Modification Amount': {fieldName: 'Amount',
    fieldPath: '/modifications/agentModifications/?/amount', finalField: 'amount',
    lucenePath: '', CVDomain: '',
   displayName: 'Agent Modification Amount',
    dataType: 'object <Amount>', luceneQueryable: false},
'Agent Modification Amount References': {fieldName: 'References',
    fieldPath: '/modifications/agentModifications/?/amount/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Agent Modification Amount References',
    dataType: 'array <string>', luceneQueryable: false},
'Agent Modification References': {fieldName: 'References',
    fieldPath: '/modifications/agentModifications/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Agent Modification References',
    dataType: 'array <string>', luceneQueryable: false},
'Physical Modifications': {fieldName: 'Physical Modifications',
    fieldPath: '/modifications/physicalModifications', finalField: 'physicalModifications',
    lucenePath: '', CVDomain: '',
   displayName: 'Physical Modifications',
    dataType: 'array <PhysicalModification>', luceneQueryable: false},
'Physical Modification Parameters': {fieldName: 'Parameters',
    fieldPath: '/modifications/physicalModifications/?/parameters', finalField: 'parameters',
    lucenePath: '', CVDomain: 'PHYSICAL_PARAMETERS',
   displayName: 'Physical Modification Parameters',
    dataType: 'array <PhysicalModificationParameter>', luceneQueryable: false},
'Physical Modification Parameter Amount': {fieldName: 'Amount',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount', finalField: 'amount',
    lucenePath: '', CVDomain: '',
   displayName: 'Physical Modification Parameter Amount',
    dataType: 'object <Amount>', luceneQueryable: false},
'Physical Modification Amount References': {fieldName: 'References',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Physical Modification Amount References',
    dataType: 'array <string>', luceneQueryable: false},
'Physical Modification Parameter References': {fieldName: 'References',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Physical Modification Parameter References',
    dataType: 'array <string>', luceneQueryable: false},
'Physical Modification References': {fieldName: 'References',
    fieldPath: '/modifications/physicalModifications/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Physical Modification References',
    dataType: 'array <string>', luceneQueryable: false},
'Modification References': {fieldName: 'References',
    fieldPath: '/modifications/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Modification References',
    dataType: 'array <string>', luceneQueryable: false},
'Structural Modifications': {fieldName: 'Structural Modifications',
    fieldPath: '/modifications/structuralModifications', finalField: 'structuralModifications',
    lucenePath: '', CVDomain: '',
   displayName: 'Structural Modifications',
    dataType: 'array <StructuralModification>', luceneQueryable: false},
'Structural Modification Extent Amount': {fieldName: 'Extent Amount',
    fieldPath: '/modifications/structuralModifications/?/extentAmount', finalField: 'extentAmount',
    lucenePath: '', CVDomain: '',
   displayName: 'Structural Modification Extent Amount',
    dataType: 'object <Amount>', luceneQueryable: false},
'Structural Modification Extent Amount References': {fieldName: 'References',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Structural Modification Extent Amount References',
    dataType: 'array <string>', luceneQueryable: false},
'Structural Modification Molecular Fragment': {fieldName: 'Molecular Fragment',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment', finalField: 'molecularFragment',
    lucenePath: '', CVDomain: '',
   displayName: 'Structural Modification Molecular Fragment',
    dataType: 'object <SubstanceReference>', luceneQueryable: false},
'Molecular Fragment References': {fieldName: 'References',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Molecular Fragment References',
    dataType: 'array <string>', luceneQueryable: false},
'Structural Modification References': {fieldName: 'References',
    fieldPath: '/modifications/structuralModifications/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Structural Modification References',
    dataType: 'array <string>', luceneQueryable: false},
'Structural Modification Sites': {fieldName: 'Sites',
    fieldPath: '/modifications/structuralModifications/?/sites', finalField: 'sites',
    lucenePath: '', CVDomain: '',
   displayName: 'Structural Modification Sites',
    dataType: 'array <Site>', luceneQueryable: false},
'Structural Modification Site Residue Index': {fieldName: 'Residue Index',
    fieldPath: '/modifications/structuralModifications/?/sites/?/residueIndex', finalField: 'residueIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'Structural Modification Site Residue Index',
    dataType: 'number', luceneQueryable: false},
'Structural Modification Site Subunit Index': {fieldName: 'Subunit Index',
    fieldPath: '/modifications/structuralModifications/?/sites/?/subunitIndex', finalField: 'subunitIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'Structural Modification Site Subunit Index',
    dataType: 'number', luceneQueryable: false},
'Record Moieties': {fieldName: 'Moieties',
    fieldPath: '/moieties', finalField: 'moieties',
    lucenePath: '', CVDomain: '',
   displayName: 'Record Moieties',
    dataType: 'array <Structure>', luceneQueryable: false},
'Moiety Count Amount': {fieldName: 'Count Amount',
    fieldPath: '/moieties/?/countAmount', finalField: 'countAmount',
    lucenePath: '', CVDomain: '',
   displayName: 'Moiety Count Amount',
    dataType: 'object <Amount>', luceneQueryable: false},
'Count Amount References': {fieldName: 'References',
    fieldPath: '/moieties/?/countAmount/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Count Amount References',
    dataType: 'array <string>', luceneQueryable: false},
'Moiety Molfile': {fieldName: 'Molfile',
    fieldPath: '/moieties/?/molfile', finalField: 'molfile',
    lucenePath: '', CVDomain: '',
   displayName: 'Moiety Molfile',
    dataType: 'string', luceneQueryable: false},
'Moiety References': {fieldName: 'References',
    fieldPath: '/moieties/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Moiety References',
    dataType: 'array <string>', luceneQueryable: false},
'Moiety Smiles': {fieldName: 'Smiles',
    fieldPath: '/moieties/?/smiles', finalField: 'smiles',
    lucenePath: '', CVDomain: '',
   displayName: 'Moiety Smiles',
    dataType: 'string', luceneQueryable: false},
'Record Names': {fieldName: 'Names',
    fieldPath: '/names', finalField: 'names',
    lucenePath: '', CVDomain: '',
   displayName: 'Record Names',
    dataType: 'array <Name>', luceneQueryable: false},
'Name Nameorgs': {fieldName: 'Naming Organizations',
    fieldPath: '/names/?/nameOrgs', finalField: 'nameOrgs',
    lucenePath: '', CVDomain: '',
   displayName: 'Name Nameorgs',
    dataType: 'array <NameOrg>', luceneQueryable: false},
'Naming Organization References': {fieldName: 'References',
    fieldPath: '/names/?/nameOrgs/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Naming Organization References',
    dataType: 'array <string>', luceneQueryable: false},
'Name References': {fieldName: 'References',
    fieldPath: '/names/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Name References',
    dataType: 'array <string>', luceneQueryable: false},
'Record Notes': {fieldName: 'Notes',
    fieldPath: '/notes', finalField: 'notes',
    lucenePath: '', CVDomain: '',
   displayName: 'Record Notes',
    dataType: 'array <Note>', luceneQueryable: false},
'Note References': {fieldName: 'References',
    fieldPath: '/notes/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Note References',
    dataType: 'array <string>', luceneQueryable: false},
'Record Nucleic Acid': {fieldName: 'Nucleicacid',
    fieldPath: '/nucleicAcid', finalField: 'nucleicAcid',
    lucenePath: '', CVDomain: '',
   displayName: 'Record Nucleic Acid',
    dataType: 'object <Nucleicacid>', luceneQueryable: false},
'Nucleic Acid Linkages': {fieldName: 'Linkages',
    fieldPath: '/nucleicAcid/linkages', finalField: 'linkages',
    lucenePath: '', CVDomain: '',
   displayName: 'Nucleic Acid Linkages',
    dataType: 'array <Linkage>', luceneQueryable: false},
'Linkage References': {fieldName: 'References',
    fieldPath: '/nucleicAcid/linkages/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Linkage References',
    dataType: 'array <string>', luceneQueryable: false},
'Linkage Sites': {fieldName: 'Sites',
    fieldPath: '/nucleicAcid/linkages/?/sites', finalField: 'sites',
    lucenePath: '', CVDomain: '',
   displayName: 'Linkage Sites',
    dataType: 'array <Site>', luceneQueryable: false},
'Linkage Site Residue Index': {fieldName: 'Residue Index',
    fieldPath: '/nucleicAcid/linkages/?/sites/?/residueIndex', finalField: 'residueIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'Linkage Site Residue Index',
    dataType: 'number', luceneQueryable: false},
'Linkage Site Subunit Index': {fieldName: 'Subunit Index',
    fieldPath: '/nucleicAcid/linkages/?/sites/?/subunitIndex', finalField: 'subunitIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'Linkage Site Subunit Index',
    dataType: 'number', luceneQueryable: false},
'Nucleic Acid References': {fieldName: 'References',
    fieldPath: '/nucleicAcid/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Nucleic Acid References',
    dataType: 'array <string>', luceneQueryable: false},
'Nucleic Acid Subunits': {fieldName: 'Subunits',
    fieldPath: '/nucleicAcid/subunits', finalField: 'subunits',
    lucenePath: '', CVDomain: '',
   displayName: 'Nucleic Acid Subunits',
    dataType: 'array <Subunit>', luceneQueryable: false},
'Nucleic Acid Subunit References': {fieldName: 'References',
    fieldPath: '/nucleicAcid/subunits/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Nucleic Acid Subunit References',
    dataType: 'array <string>', luceneQueryable: false},
'Nucleic Acid Sugars': {fieldName: 'Sugars',
    fieldPath: '/nucleicAcid/sugars', finalField: 'sugars',
    lucenePath: '', CVDomain: 'NUCLEIC_ACID_SUGAR',
   displayName: 'Nucleic Acid Sugars',
    dataType: 'array <Sugar>', luceneQueryable: false},
'Sugar References': {fieldName: 'References',
    fieldPath: '/nucleicAcid/sugars/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Sugar References',
    dataType: 'array <string>', luceneQueryable: false},
'Sugar Sites': {fieldName: 'Sites',
    fieldPath: '/nucleicAcid/sugars/?/sites', finalField: 'sites',
    lucenePath: '', CVDomain: '',
   displayName: 'Sugar Sites',
    dataType: 'array <Site>', luceneQueryable: false},
'Sugar Site Residue Index': {fieldName: 'Residue Index',
    fieldPath: '/nucleicAcid/sugars/?/sites/?/residueIndex', finalField: 'residueIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'Sugar Site Residue Index',
    dataType: 'number', luceneQueryable: false},
'Sugar Site Subunit Index': {fieldName: 'Subunit Index',
    fieldPath: '/nucleicAcid/sugars/?/sites/?/subunitIndex', finalField: 'subunitIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'Sugar Site Subunit Index',
    dataType: 'number', luceneQueryable: false},
'Record Polymer': {fieldName: 'Polymer',
    fieldPath: '/polymer', finalField: 'polymer',
    lucenePath: '', CVDomain: '',
   displayName: 'Record Polymer',
    dataType: 'object <Polymer>', luceneQueryable: false},
'Polymer Classification': {fieldName: 'Classification',
    fieldPath: '/polymer/classification', finalField: 'classification',
    lucenePath: '', CVDomain: '',
   displayName: 'Polymer Classification',
    dataType: 'object <PolymerClassification>', luceneQueryable: false},
'Polymer Classification Parent Substance': {fieldName: 'Parent Substance',
    fieldPath: '/polymer/classification/parentSubstance', finalField: 'parentSubstance',
    lucenePath: '', CVDomain: '',
   displayName: 'Polymer Classification Parent Substance',
    dataType: 'object <SubstanceReference>', luceneQueryable: false},
'Polymer Parent Substance References': {fieldName: 'References',
    fieldPath: '/polymer/classification/parentSubstance/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Polymer Parent Substance References',
    dataType: 'array <string>', luceneQueryable: false},
'Polymer Classification References': {fieldName: 'References',
    fieldPath: '/polymer/classification/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Polymer Classification References',
    dataType: 'array <string>', luceneQueryable: false},
'Polymer Display Structure': {fieldName: 'Display Structure',
    fieldPath: '/polymer/displayStructure', finalField: 'displayStructure',
    lucenePath: '', CVDomain: '',
   displayName: 'Polymer Display Structure',
    dataType: 'object <PolymerStructure>', luceneQueryable: false},
'Display Structure Molfile': {fieldName: 'Molfile',
    fieldPath: '/polymer/displayStructure/molfile', finalField: 'molfile',
    lucenePath: '', CVDomain: '',
   displayName: 'Display Structure Molfile',
    dataType: 'string', luceneQueryable: false},
'Display Structure References': {fieldName: 'References',
    fieldPath: '/polymer/displayStructure/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Display Structure References',
    dataType: 'array <string>', luceneQueryable: false},
'Display Structure Smiles': {fieldName: 'Smiles',
    fieldPath: '/polymer/displayStructure/smiles', finalField: 'smiles',
    lucenePath: '', CVDomain: '',
   displayName: 'Display Structure Smiles',
    dataType: 'string', luceneQueryable: false},
'Polymer Idealized Structure': {fieldName: 'Idealized Structure',
    fieldPath: '/polymer/idealizedStructure', finalField: 'idealizedStructure',
    lucenePath: '', CVDomain: '',
   displayName: 'Polymer Idealized Structure',
    dataType: 'object <PolymerStructure>', luceneQueryable: false},
'Idealized Structure Molfile': {fieldName: 'Molfile',
    fieldPath: '/polymer/idealizedStructure/molfile', finalField: 'molfile',
    lucenePath: '', CVDomain: '',
   displayName: 'Idealized Structure Molfile',
    dataType: 'string', luceneQueryable: false},
'Idealized Structure References': {fieldName: 'References',
    fieldPath: '/polymer/idealizedStructure/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Idealized Structure References',
    dataType: 'array <string>', luceneQueryable: false},
'Idealized Structure Smiles': {fieldName: 'Smiles',
    fieldPath: '/polymer/idealizedStructure/smiles', finalField: 'smiles',
    lucenePath: '', CVDomain: '',
   displayName: 'Idealized Structure Smiles',
    dataType: 'string', luceneQueryable: false},
'Polymer Monomers': {fieldName: 'Monomers',
    fieldPath: '/polymer/monomers', finalField: 'monomers',
    lucenePath: '', CVDomain: '',
   displayName: 'Polymer Monomers',
    dataType: 'array <Monomer>', luceneQueryable: false},
'Monomer Amount': {fieldName: 'Amount',
    fieldPath: '/polymer/monomers/?/amount', finalField: 'amount',
    lucenePath: '', CVDomain: '',
   displayName: 'Monomer Amount',
    dataType: 'object <Amount>', luceneQueryable: false},
'Monomer Amount References': {fieldName: 'References',
    fieldPath: '/polymer/monomers/?/amount/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Monomer Amount References',
    dataType: 'array <string>', luceneQueryable: false},
'Monomer Monomer Substance': {fieldName: 'Monomer Substance',
    fieldPath: '/polymer/monomers/?/monomerSubstance', finalField: 'monomerSubstance',
    lucenePath: '', CVDomain: '',
   displayName: 'Monomer Monomer Substance',
    dataType: 'object <SubstanceReference>', luceneQueryable: false},
'Monomer Substance References': {fieldName: 'References',
    fieldPath: '/polymer/monomers/?/monomerSubstance/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Monomer Substance References',
    dataType: 'array <string>', luceneQueryable: false},
'Monomer References': {fieldName: 'References',
    fieldPath: '/polymer/monomers/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Monomer References',
    dataType: 'array <string>', luceneQueryable: false},
'Polymer References': {fieldName: 'References',
    fieldPath: '/polymer/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Polymer References',
    dataType: 'array <string>', luceneQueryable: false},
'SRU Structuralunits': {fieldName: 'Structural Units',
    fieldPath: '/polymer/structuralUnits', finalField: 'structuralUnits',
    lucenePath: '', CVDomain: '',
   displayName: 'SRU Structuralunits',
    dataType: 'array <StructuralUnit>', luceneQueryable: false},
'SRU Amount References': {fieldName: 'References',
    fieldPath: '/polymer/structuralUnits/?/amount/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'SRU Amount References',
    dataType: 'array <string>', luceneQueryable: false},
'SRU Amount Attachment Map': {fieldName: 'Attachment Map',
    fieldPath: '/polymer/structuralUnits/?/attchmentMap', finalField: 'attachmentMap',
    lucenePath: '', CVDomain: '',
   displayName: 'SRU Amount Attachment Map',
    dataType: 'object <Map>', luceneQueryable: false},
'Record Properties': {fieldName: 'Properties',
    fieldPath: '/properties', finalField: 'properties',
    lucenePath: '', CVDomain: '',
   displayName: 'Record Properties',
    dataType: 'array <Property>', luceneQueryable: false},
'Parameter References': {fieldName: 'References',
    fieldPath: '/properties/?/parameters/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Parameter References',
    dataType: 'array <string>', luceneQueryable: false},
'Parameter Value': {fieldName: 'Value',
    fieldPath: '/properties/?/parameters/?/value', finalField: 'value',
    lucenePath: '', CVDomain: '',
   displayName: 'Parameter Value',
    dataType: 'object <Amount>', luceneQueryable: false},
'Parameter Value References': {fieldName: 'References',
    fieldPath: '/properties/?/parameters/?/value/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Parameter Value References',
    dataType: 'array <string>', luceneQueryable: false},
'Property References': {fieldName: 'References',
    fieldPath: '/properties/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Property References',
    dataType: 'array <string>', luceneQueryable: false},
'Value References': {fieldName: 'References',
    fieldPath: '/properties/?/value/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Value References',
    dataType: 'array <string>', luceneQueryable: false},
'Record Protein': {fieldName: 'Protein',
    fieldPath: '/protein', finalField: 'protein',
    lucenePath: '', CVDomain: '',
   displayName: 'Record Protein',
    dataType: 'object <Protein>', luceneQueryable: false},
'Protein Disulfide Links': {fieldName: 'Disulfide Links',
    fieldPath: '/protein/disulfideLinks', finalField: 'disulfideLinks',
    lucenePath: '', CVDomain: '',
   displayName: 'Protein Disulfide Links',
    dataType: 'array <DisulfideLink>', luceneQueryable: false},
'Disulfide Link Sites': {fieldName: 'Sites',
    fieldPath: '/protein/disulfideLinks/?/sites', finalField: 'sites',
    lucenePath: '', CVDomain: '',
   displayName: 'Disulfide Link Sites',
    dataType: 'array <Site>', luceneQueryable: false},
'Disulfide Link Site Residue Index': {fieldName: 'Residue Index',
    fieldPath: '/protein/disulfideLinks/?/sites/?/residueIndex', finalField: 'residueIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'Disulfide Link Site Residue Index',
    dataType: 'number', luceneQueryable: false},
'Disulfide Link Site Subunit Index': {fieldName: 'Subunit Index',
    fieldPath: '/protein/disulfideLinks/?/sites/?/subunitIndex', finalField: 'subunitIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'Disulfide Link Site Subunit Index',
    dataType: 'number', luceneQueryable: false},
'Protein Glycosylation': {fieldName: 'Glycosylation',
    fieldPath: '/protein/glycosylation', finalField: 'glycosylation',
    lucenePath: '', CVDomain: '',
   displayName: 'Protein Glycosylation',
    dataType: 'object <Glycosylation>', luceneQueryable: false},
'Protein Glycosylation C Glycosylation Sites': {fieldName: 'C Glycosylation Sites',
    fieldPath: '/protein/glycosylation/CGlycosylationSites', finalField: 'CGlycosylationSites',
    lucenePath: '', CVDomain: '',
   displayName: 'Protein Glycosylation C Glycosylation Sites',
    dataType: 'array <Site>', luceneQueryable: false},
'C Glycosylation Site Residue Index': {fieldName: 'Residue Index',
    fieldPath: '/protein/glycosylation/CGlycosylationSites/?/residueIndex', finalField: 'residueIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'C Glycosylation Site Residue Index',
    dataType: 'number', luceneQueryable: false},
'C Glycosylation Site Subunit Index': {fieldName: 'Subunit Index',
    fieldPath: '/protein/glycosylation/CGlycosylationSites/?/subunitIndex', finalField: 'subunitIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'C Glycosylation Site Subunit Index',
    dataType: 'number', luceneQueryable: false},
'Protein Glycosylation N Glycosylation Sites': {fieldName: 'N-Glycosylation Sites',
    fieldPath: '/protein/glycosylation/NGlycosylationSites', finalField: 'NGlycosylationSites',
    lucenePath: '', CVDomain: '',
   displayName: 'Protein Glycosylation N Glycosylation Sites',
    dataType: 'array <Site>', luceneQueryable: false},
'N Glycosylation Site Residue Index': {fieldName: 'Residue Index',
    fieldPath: '/protein/glycosylation/NGlycosylationSites/?/residueIndex', finalField: 'residueIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'N Glycosylation Site Residue Index',
    dataType: 'number', luceneQueryable: false},
'N Glycosylation Site Subunit Index': {fieldName: 'Subunit Index',
    fieldPath: '/protein/glycosylation/NGlycosylationSites/?/subunitIndex', finalField: 'subunitIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'N Glycosylation Site Subunit Index',
    dataType: 'number', luceneQueryable: false},
'Protein Glycosylation O Glycosylation Sites': {fieldName: 'O-Glycosylation Sites',
    fieldPath: '/protein/glycosylation/OGlycosylationSites', finalField: 'OGlycosylationSites',
    lucenePath: '', CVDomain: '',
   displayName: 'Protein Glycosylation O Glycosylation Sites',
    dataType: 'array <Site>', luceneQueryable: false},
'O Glycosylation Site Residue Index': {fieldName: 'Residue Index',
    fieldPath: '/protein/glycosylation/OGlycosylationSites/?/residueIndex', finalField: 'residueIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'O Glycosylation Site Residue Index',
    dataType: 'number', luceneQueryable: false},
'O Glycosylation Site Subunit Index': {fieldName: 'Subunit Index',
    fieldPath: '/protein/glycosylation/OGlycosylationSites/?/subunitIndex', finalField: 'subunitIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'O Glycosylation Site Subunit Index',
    dataType: 'number', luceneQueryable: false},
'Protein Glycosylation References': {fieldName: 'References',
    fieldPath: '/protein/glycosylation/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Protein Glycosylation References',
    dataType: 'array <string>', luceneQueryable: false},
'Protein Otherlinks': {fieldName: 'Other Links',
    fieldPath: '/protein/otherLinks', finalField: 'otherLinks',
    lucenePath: '', CVDomain: '',
   displayName: 'Protein Otherlinks',
    dataType: 'array <OtherLink>', luceneQueryable: false},
'Other Link References': {fieldName: 'References',
    fieldPath: '/protein/otherLinks/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Other Link References',
    dataType: 'array <string>', luceneQueryable: false},
'Other Link Sites': {fieldName: 'Sites',
    fieldPath: '/protein/otherLinks/?/sites', finalField: 'sites',
    lucenePath: '', CVDomain: '',
   displayName: 'Other Link Sites',
    dataType: 'array <Site>', luceneQueryable: false},
'Other Link Site Residue Index': {fieldName: 'Residue Index',
    fieldPath: '/protein/otherLinks/?/sites/?/residueIndex', finalField: 'residueIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'Other Link Site Residue Index',
    dataType: 'number', luceneQueryable: false},
'Other Link Site Subunit Index': {fieldName: 'Subunit Index',
    fieldPath: '/protein/otherLinks/?/sites/?/subunitIndex', finalField: 'subunitIndex',
    lucenePath: '', CVDomain: '',
   displayName: 'Other Link Site Subunit Index',
    dataType: 'number', luceneQueryable: false},
'Protein References': {fieldName: 'References',
    fieldPath: '/protein/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Protein References',
    dataType: 'array <string>', luceneQueryable: false},
'Protein Subunits': {fieldName: 'Subunits',
    fieldPath: '/protein/subunits', finalField: 'subunits',
    lucenePath: '', CVDomain: '',
   displayName: 'Protein Subunits',
    dataType: 'array <Subunit>', luceneQueryable: false},
'Protein Subunit References': {fieldName: 'References',
    fieldPath: '/protein/subunits/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Protein Subunit References',
    dataType: 'array <string>', luceneQueryable: false},
'Record References': {fieldName: 'References',
    fieldPath: '/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Record References',
    dataType: 'array <Reference>', luceneQueryable: false},
'Record Relationships': {fieldName: 'Relationships',
    fieldPath: '/relationships', finalField: 'relationships',
    lucenePath: '', CVDomain: '',
   displayName: 'Record Relationships',
    dataType: 'array <Relationship>', luceneQueryable: false},
'Relationship Amount': {fieldName: 'Amount',
    fieldPath: '/relationships/?/amount', finalField: 'amount',
    lucenePath: '', CVDomain: '',
   displayName: 'Relationship Amount',
    dataType: 'object <Amount>', luceneQueryable: false},
'Relationship Amount References': {fieldName: 'References',
    fieldPath: '/relationships/?/amount/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Relationship Amount References',
    dataType: 'array <string>', luceneQueryable: false},
'Relationship Mediator Substance': {fieldName: 'Mediator Substance',
    fieldPath: '/relationships/?/mediatorSubstance', finalField: 'mediatorSubstance',
    lucenePath: '', CVDomain: '',
   displayName: 'Relationship Mediator Substance',
    dataType: 'object <SubstanceReference>', luceneQueryable: false},
'Mediator Substance References': {fieldName: 'References',
    fieldPath: '/relationships/?/mediatorSubstance/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Mediator Substance References',
    dataType: 'array <string>', luceneQueryable: false},
'Relationship References': {fieldName: 'References',
    fieldPath: '/relationships/?/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Relationship References',
    dataType: 'array <string>', luceneQueryable: false},
'Relationship Related Substance': {fieldName: 'Related Substance',
    fieldPath: '/relationships/?/relatedSubstance', finalField: 'relatedSubstance',
    lucenePath: '', CVDomain: '',
   displayName: 'Relationship Related Substance',
    dataType: 'object <SubstanceReference>', luceneQueryable: false},
'Related Substance References': {fieldName: 'References',
    fieldPath: '/relationships/?/relatedSubstance/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Related Substance References',
    dataType: 'array <string>', luceneQueryable: false},
'Record Structurally Diverse': {fieldName: 'Structurally Diverse',
    fieldPath: '/structurallyDiverse', finalField: 'structurallyDiverse',
    lucenePath: '', CVDomain: '',
   displayName: 'Record Structurally Diverse',
    dataType: 'array <string>', luceneQueryable: false},
'Structurally Diverse Hybrid Species Maternal Organism': {fieldName: 'Hybrid Species Maternal Organism',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism', finalField: 'hybridSpeciesMaternalOrganism',
    lucenePath: '', CVDomain: '',
   displayName: 'Structurally Diverse Hybrid Species Maternal Organism',
    dataType: 'number', luceneQueryable: false},
'St. Div. Hybrid Parent (m) References': {fieldName: 'References',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) References',
    dataType: 'string', luceneQueryable: false},
'Structurally Diverse Hybrid Species Paternal Organism': {fieldName: 'Hybrid Species Paternal Organism',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism', finalField: 'hybridSpeciesPaternalOrganism',
    lucenePath: '', CVDomain: '',
   displayName: 'Structurally Diverse Hybrid Species Paternal Organism',
    dataType: 'number', luceneQueryable: false},
'St. Div. Hybrid Parent (p) References': {fieldName: 'References',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) References',
    dataType: 'string', luceneQueryable: false},
'Structurally Diverse Parent Substance': {fieldName: 'Parent Substance',
    fieldPath: '/structurallyDiverse/parentSubstance', finalField: 'parentSubstance',
    lucenePath: '', CVDomain: '',
   displayName: 'Structurally Diverse Parent Substance',
    dataType: 'string', luceneQueryable: false},
'St. Div. Hybrid Parent References': {fieldName: 'References',
    fieldPath: '/structurallyDiverse/parentSubstance/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent References',
    dataType: 'boolean', luceneQueryable: false},
'Structurally Diverse References': {fieldName: 'References',
    fieldPath: '/structurallyDiverse/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Structurally Diverse References',
    dataType: 'string', luceneQueryable: false},
'Record Structure': {fieldName: 'Structure',
    fieldPath: '/structure', finalField: 'structure',
    lucenePath: '', CVDomain: '',
   displayName: 'Record Structure',
    dataType: 'string', luceneQueryable: false},
'Structure Molfile': {fieldName: 'Molfile',
    fieldPath: '/structure/molfile', finalField: 'molfile',
    lucenePath: '', CVDomain: '',
   displayName: 'Structure Molfile',
    dataType: 'string', luceneQueryable: false},
'Structure References': {fieldName: 'References',
    fieldPath: '/structure/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Structure References',
    dataType: 'string', luceneQueryable: false},
'Structure Smiles': {fieldName: 'Smiles',
    fieldPath: '/structure/smiles', finalField: 'smiles',
    lucenePath: '', CVDomain: '',
   displayName: 'Structure Smiles',
    dataType: 'string', luceneQueryable: false},
'SpecifiedSubstance': {fieldName: 'Specified Substance',
    fieldPath: '/specifiedSubstance', finalField: '',
    lucenePath: '', CVDomain: '',
   displayName: 'SpecifiedSubstance',
    dataType: 'string', luceneQueryable: false},
'SpecifiedSubstance Access': {fieldName: 'Access',
    fieldPath: '/specifiedSubstance/access', finalField: 'access',
    lucenePath: 'root_specifiedSubstance_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'SpecifiedSubstance Access',
    dataType: 'string', luceneQueryable: false},
'SpecifiedSubstance References': {fieldName: 'References',
    fieldPath: '/specifiedSubstance/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'SpecifiedSubstance References',
    dataType: 'string', luceneQueryable: false},
'SpecifiedSubstance UUID': {fieldName: 'Uuid',
    fieldPath: '/specifiedSubstance/uuid', finalField: 'uuid',
    lucenePath: 'root_specifiedSubstance_uuid', CVDomain: '',
   displayName: 'SpecifiedSubstance UUID',
    dataType: 'array <string>', luceneQueryable: false},
'Constituent': {fieldName: 'Constituents',
    fieldPath: '/specifiedSubstance/constituents', finalField: '',
    lucenePath: 'root_specifiedSubstance_constituent', CVDomain: '',
   displayName: 'Constituent',
    dataType: 'string', luceneQueryable: false},
'Constituent Substance': {fieldName: 'Substance',
    fieldPath: '/specifiedSubstance/constituents/?/substance', finalField: 'parentSubstance',
    lucenePath: 'root_specifiedSubstance_constituents_substance', CVDomain: '',
   displayName: 'Constituent Substance',
    dataType: 'string', luceneQueryable: false},
'Constituent Substance Access': {fieldName: 'Access',
    fieldPath: '/specifiedSubstance/constituents/?/substance/access', finalField: 'access',
    lucenePath: 'root_specifiedSubstance_constituents_substance_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Constituent Substance Access',
    dataType: 'string', luceneQueryable: false},
'Constituent Substance Linking ID': {fieldName: 'Linking Id',
    fieldPath: '/specifiedSubstance/constituents/?/substance/linkingID', finalField: 'linkingID',
    lucenePath: 'root_specifiedSubstance_constituents_substance_linkingID', CVDomain: '',
   displayName: 'Constituent Substance Linking ID',
    dataType: 'number', luceneQueryable: false},
'Constituent Substance Name': {fieldName: 'Name',
    fieldPath: '/specifiedSubstance/constituents/?/substance/name', finalField: 'name',
    lucenePath: 'root_specifiedSubstance_constituents_substance_name', CVDomain: '',
   displayName: 'Constituent Substance Name',
    dataType: 'number', luceneQueryable: false},
'Constituent Substance References': {fieldName: 'References',
    fieldPath: '/specifiedSubstance/constituents/?/substance/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Constituent Substance References',
    dataType: 'string', luceneQueryable: false},
'Constituent Substance Substance Class': {fieldName: 'Substance Class',
    fieldPath: '/specifiedSubstance/constituents/?/substance/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_specifiedSubstance_constituents_substance_substanceClass', CVDomain: '',
   displayName: 'Constituent Substance Substance Class',
    dataType: 'string', luceneQueryable: false},
'Constituent Amount': {fieldName: 'Amount',
    fieldPath: '/specifiedSubstance/constituents/?/amount', finalField: 'amount',
    lucenePath: '', CVDomain: '',
   displayName: 'Constituent Amount',
    dataType: 'string', luceneQueryable: false},
'Constituent Amount Access': {fieldName: 'Access',
    fieldPath: '/specifiedSubstance/constituents/?/amount/access', finalField: 'access',
    lucenePath: 'root_specifiedSubstance_constituents_amount_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Constituent Amount Access',
    dataType: 'string', luceneQueryable: false},
'Constituent Amount Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/specifiedSubstance/constituents/?/amount/deprecated', finalField: 'deprecated',
    lucenePath: 'root_specifiedSubstance_constituents_amount_deprecated', CVDomain: '',
   displayName: 'Constituent Amount Deprecated',
    dataType: 'string', luceneQueryable: false},
'Constituent Amount References': {fieldName: 'References',
    fieldPath: '/specifiedSubstance/constituents/?/amount/references', finalField: 'references',
    lucenePath: '', CVDomain: '',
   displayName: 'Constituent Amount References',
    dataType: 'string', luceneQueryable: false},
'Constituent Amount UUID': {fieldName: 'Uuid',
    fieldPath: '/specifiedSubstance/constituents/?/amount/uuid', finalField: 'uuid',
    lucenePath: 'root_specifiedSubstance_constituents_amount_uuid', CVDomain: '',
   displayName: 'Constituent Amount UUID',
    dataType: 'string', luceneQueryable: false},
'Moiety Atropisomerism': {fieldName: 'Atropisomerism',
    fieldPath: '/moieties/?/atropisomerism', finalField: 'atropisomerism',
    lucenePath: 'root_moieties_structure_atropisomerism', CVDomain: 'ATROPISOMERISM',
   displayName: 'Moiety Atropisomerism',
    dataType: 'string', luceneQueryable: false},
'Moiety Charge': {fieldName: 'Charge',
    fieldPath: '/moieties/?/charge', finalField: 'charge',
    lucenePath: 'root_moieties_structure_charge', CVDomain: '',
   displayName: 'Moiety Charge',
    dataType: 'number', luceneQueryable: false},
'Moiety Count': {fieldName: 'Count',
    fieldPath: '/moieties/?/count', finalField: 'count',
    lucenePath: 'root_moieties_structure_count', CVDomain: '',
   displayName: 'Moiety Count',
    dataType: 'number', luceneQueryable: false},
'Moiety Defined Stereo': {fieldName: 'Defined Stereocenters Count',
    fieldPath: '/moieties/?/definedStereo', finalField: 'definedStereo',
    lucenePath: 'root_moieties_structure_definedStereo', CVDomain: '',
   displayName: 'Moiety Defined Stereo',
    dataType: 'number', luceneQueryable: false},
'Moiety Digest': {fieldName: 'Digest',
    fieldPath: '/moieties/?/digest', finalField: 'digest',
    lucenePath: 'root_moieties_structure_digest', CVDomain: '',
   displayName: 'Moiety Digest',
    dataType: 'string', luceneQueryable: false},
'Moiety Ez Centers': {fieldName: 'E/Z Center Count',
    fieldPath: '/moieties/?/ezCenters', finalField: 'ezCenters',
    lucenePath: 'root_moieties_structure_ezCenters', CVDomain: '',
   displayName: 'Moiety Ez Centers',
    dataType: 'number', luceneQueryable: false},
'Moiety Formula': {fieldName: 'Formula',
    fieldPath: '/moieties/?/formula', finalField: 'formula',
    lucenePath: 'root_moieties_structure_formula', CVDomain: '',
   displayName: 'Moiety Formula',
    dataType: 'string', luceneQueryable: false},
'Moiety Mwt': {fieldName: 'Molecular Weight',
    fieldPath: '/moieties/?/mwt', finalField: 'mwt',
    lucenePath: 'root_moieties_structure_mwt', CVDomain: '',
   displayName: 'Moiety Mwt',
    dataType: 'number', luceneQueryable: false},
'Moiety Optical Activity': {fieldName: 'Optical Activity',
    fieldPath: '/moieties/?/opticalActivity', finalField: 'opticalActivity',
    lucenePath: 'root_moieties_structure_opticalActivity', CVDomain: 'OPTICAL_ACTIVITY',
   displayName: 'Moiety Optical Activity',
    dataType: 'string', luceneQueryable: false},
'Moiety Stereo Centers': {fieldName: 'Total Stereocenters Count',
    fieldPath: '/moieties/?/stereoCenters', finalField: 'stereoCenters',
    lucenePath: 'root_moieties_structure_stereoCenters', CVDomain: '',
   displayName: 'Moiety Stereo Centers',
    dataType: 'number', luceneQueryable: false},
'Moiety Stereo Comments': {fieldName: 'Stereo Comments',
    fieldPath: '/moieties/?/stereoComments', finalField: 'stereoComments',
    lucenePath: 'root_moieties_structure_stereoComments', CVDomain: '',
   displayName: 'Moiety Stereo Comments',
    dataType: 'string', luceneQueryable: false},
'Name Domains': {fieldName: 'Domains',
    fieldPath: '/names/?/domains', finalField: 'domains',
    lucenePath: 'root_names_domains_term', CVDomain: 'NAME_DOMAIN',
   displayName: 'Name Domains',
    dataType: 'array <string>', luceneQueryable: false},
'Name Languages': {fieldName: 'Languages',
    fieldPath: '/names/?/languages', finalField: 'languages',
    lucenePath: 'root_names_languages_term', CVDomain: 'LANGUAGE',
   displayName: 'Name Languages',
    dataType: 'array <string>', luceneQueryable: false},
'Name Name Jurisdiction': {fieldName: 'Naming Jurisdictions',
    fieldPath: '/names/?/nameJurisdiction', finalField: 'nameJurisdiction',
    lucenePath: 'root_names_nameJurisdiction_term', CVDomain: 'JURISDICTION',
   displayName: 'Name Name Jurisdiction',
    dataType: 'array <string>', luceneQueryable: false},
'Polymer Classification Polymer Subclass': {fieldName: 'Polymer Subclass',
    fieldPath: '/polymer/classification/polymerSubclass', finalField: 'polymerSubclass',
    lucenePath: 'root_polymer_classification_polymerSubclass_term', CVDomain: 'POLYMER_SUBCLASS',
   displayName: 'Polymer Classification Polymer Subclass',
    dataType: 'array <string>', luceneQueryable: false},
'Reference Tags': {fieldName: 'Tags',
    fieldPath: '/references/?/tags', finalField: 'tags',
    lucenePath: 'root_references_tags_term', CVDomain: 'DOCUMENT_COLLECTION',
   displayName: 'Reference Tags',
    dataType: 'array <string>', luceneQueryable: false},
'Structurally Diverse Part': {fieldName: 'Part',
    fieldPath: '/structurallyDiverse/part', finalField: 'part',
    lucenePath: 'root_structurallyDiverse_part_term', CVDomain: 'PART',
   displayName: 'Structurally Diverse Part',
    dataType: 'array <string>', luceneQueryable: false},
'Record Tags': {fieldName: 'Tags',
    fieldPath: '/tags', finalField: 'tags',
    lucenePath: 'root_tags_term', CVDomain: '',
   displayName: 'Record Tags',
    dataType: 'string', luceneQueryable: false},
'Record Access': {fieldName: 'Access',
    fieldPath: '/access', finalField: 'access',
    lucenePath: 'root_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Record Access',
    dataType: 'array <string>', luceneQueryable: true},
'Approval ID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/approvalID', finalField: 'approvalID',
    lucenePath: 'root_approvalID', CVDomain: '',
   displayName: 'Approval ID',
    dataType: 'string', luceneQueryable: true},
'Record Approved By': {fieldName: 'Approved By',
    fieldPath: '/approvedBy', finalField: 'approvedBy',
    lucenePath: 'root_approvedBy', CVDomain: '',
   displayName: 'Record Approved By',
    dataType: 'string', luceneQueryable: true},
'Record Change Reason': {fieldName: 'Change Reason',
    fieldPath: '/changeReason', finalField: 'changeReason',
    lucenePath: 'root_changeReason', CVDomain: '',
   displayName: 'Record Change Reason',
    dataType: 'string', luceneQueryable: true},
'Code Access': {fieldName: 'Access',
    fieldPath: '/codes/?/access', finalField: 'access',
    lucenePath: 'root_codes_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Code Access',
    dataType: 'array <string>', luceneQueryable: true},
'Code Literal': {fieldName: 'Code',
    fieldPath: '/codes/?/code', finalField: 'code',
    lucenePath: 'root_codes_code', CVDomain: '',
   displayName: 'Code Literal',
    dataType: 'string', luceneQueryable: true},
'Code System': {fieldName: 'Code System',
    fieldPath: '/codes/?/codeSystem', finalField: 'codeSystem',
    lucenePath: 'root_codes_codeSystem', CVDomain: 'CODE_SYSTEM',
   displayName: 'Code System',
    dataType: 'string', luceneQueryable: true},
'Code Comments': {fieldName: 'Comments',
    fieldPath: '/codes/?/comments', finalField: 'comments',
    lucenePath: 'root_codes_comments', CVDomain: '',
   displayName: 'Code Comments',
    dataType: 'string', luceneQueryable: true},
'Code Created': {fieldName: 'Created',
    fieldPath: '/codes/?/created', finalField: 'created',
    lucenePath: 'root_codes_created', CVDomain: '',
   displayName: 'Code Created',
    dataType: 'number', luceneQueryable: true},
'Code Created By': {fieldName: 'Created By',
    fieldPath: '/codes/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_codes_createdBy', CVDomain: '',
   displayName: 'Code Created By',
    dataType: 'string', luceneQueryable: true},
'Code Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/codes/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_codes_deprecated', CVDomain: '',
   displayName: 'Code Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Code Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/codes/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_codes_lastEdited', CVDomain: '',
   displayName: 'Code Last Edited',
    dataType: 'number', luceneQueryable: true},
'Code Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/codes/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_codes_lastEditedBy', CVDomain: '',
   displayName: 'Code Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Code Type': {fieldName: 'Code Type',
    fieldPath: '/codes/?/type', finalField: 'type',
    lucenePath: 'root_codes_type', CVDomain: 'CODE_TYPE',
   displayName: 'Code Type',
    dataType: 'string', luceneQueryable: true},
'Code URL': {fieldName: 'Url',
    fieldPath: '/codes/?/url', finalField: 'url',
    lucenePath: 'root_codes_url', CVDomain: '',
   displayName: 'Code URL',
    dataType: 'string', luceneQueryable: true},
'Record Created': {fieldName: 'Created',
    fieldPath: '/created', finalField: 'created',
    lucenePath: 'root_created', CVDomain: '',
   displayName: 'Record Created',
    dataType: 'number', luceneQueryable: true},
'Record Created By': {fieldName: 'Created By',
    fieldPath: '/createdBy', finalField: 'createdBy',
    lucenePath: 'root_createdBy', CVDomain: '',
   displayName: 'Record Created By',
    dataType: 'string', luceneQueryable: true},
'Record Definition Level': {fieldName: 'Definition Level',
    fieldPath: '/definitionLevel', finalField: 'definitionLevel',
    lucenePath: 'root_definitionLevel', CVDomain: '',
   displayName: 'Record Definition Level',
    dataType: 'string', luceneQueryable: true},
'Record Definition Type': {fieldName: 'Definition Type',
    fieldPath: '/definitionType', finalField: 'definitionType',
    lucenePath: 'root_definitionType', CVDomain: '',
   displayName: 'Record Definition Type',
    dataType: 'string', luceneQueryable: true},
'Record Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/deprecated', finalField: 'deprecated',
    lucenePath: 'root_deprecated', CVDomain: '',
   displayName: 'Record Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Record Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_lastEdited', CVDomain: '',
   displayName: 'Record Last Edited',
    dataType: 'number', luceneQueryable: true},
'Record Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_lastEditedBy', CVDomain: '',
   displayName: 'Record Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Mixture Access': {fieldName: 'Access',
    fieldPath: '/mixture/access', finalField: 'access',
    lucenePath: 'root_mixture_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Mixture Access',
    dataType: 'array <string>', luceneQueryable: true},
'Mixture Component  Access': {fieldName: 'Access',
    fieldPath: '/mixture/components/?/access', finalField: 'access',
    lucenePath: 'root_mixture_components_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Mixture Component  Access',
    dataType: 'array <string>', luceneQueryable: true},
'Mixture Component Created': {fieldName: 'Created',
    fieldPath: '/mixture/components/?/created', finalField: 'created',
    lucenePath: 'root_mixture_components_created', CVDomain: '',
   displayName: 'Mixture Component Created',
    dataType: 'number', luceneQueryable: true},
'Mixture Component Created By': {fieldName: 'Created By',
    fieldPath: '/mixture/components/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_mixture_components_createdBy', CVDomain: '',
   displayName: 'Mixture Component Created By',
    dataType: 'string', luceneQueryable: true},
'Mixture Component Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/mixture/components/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_mixture_components_deprecated', CVDomain: '',
   displayName: 'Mixture Component Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Mixture Component Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/mixture/components/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_mixture_components_lastEdited', CVDomain: '',
   displayName: 'Mixture Component Last Edited',
    dataType: 'number', luceneQueryable: true},
'Mixture Component Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/mixture/components/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_mixture_components_lastEditedBy', CVDomain: '',
   displayName: 'Mixture Component Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Mixture Component Substance Access': {fieldName: 'Access',
    fieldPath: '/mixture/components/?/substance/access', finalField: 'access',
    lucenePath: 'root_mixture_components_substance_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Mixture Component Substance Access',
    dataType: 'array <string>', luceneQueryable: true},
'Mixture Component ApprovalID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/mixture/components/?/substance/approvalID', finalField: 'approvalID',
    lucenePath: 'root_mixture_components_substance_approvalID', CVDomain: '',
   displayName: 'Mixture Component ApprovalID',
    dataType: 'string', luceneQueryable: true},
'Mixture Component Substance Created': {fieldName: 'Created',
    fieldPath: '/mixture/components/?/substance/created', finalField: 'created',
    lucenePath: 'root_mixture_components_substance_created', CVDomain: '',
   displayName: 'Mixture Component Substance Created',
    dataType: 'number', luceneQueryable: true},
'Mixture Component Substance Created By': {fieldName: 'Created By',
    fieldPath: '/mixture/components/?/substance/createdBy', finalField: 'createdBy',
    lucenePath: 'root_mixture_components_substance_createdBy', CVDomain: '',
   displayName: 'Mixture Component Substance Created By',
    dataType: 'string', luceneQueryable: true},
'Mixture Component Substance Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/mixture/components/?/substance/deprecated', finalField: 'deprecated',
    lucenePath: 'root_mixture_components_substance_deprecated', CVDomain: '',
   displayName: 'Mixture Component Substance Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Mixture Component Substance Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/mixture/components/?/substance/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_mixture_components_substance_lastEdited', CVDomain: '',
   displayName: 'Mixture Component Substance Last Edited',
    dataType: 'number', luceneQueryable: true},
'Mixture Component Substance Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/mixture/components/?/substance/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_mixture_components_substance_lastEditedBy', CVDomain: '',
   displayName: 'Mixture Component Substance Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Mixture Component Name': {fieldName: 'Ref Pname',
    fieldPath: '/mixture/components/?/substance/refPname', finalField: 'refPname',
    lucenePath: 'root_mixture_components_substance_refPname', CVDomain: '',
   displayName: 'Mixture Component Name',
    dataType: 'string', luceneQueryable: true},
'Mixture Component Substance Refuuid': {fieldName: 'Refuuid',
    fieldPath: '/mixture/components/?/substance/refuuid', finalField: 'refuuid',
    lucenePath: 'root_mixture_components_substance_refuuid', CVDomain: '',
   displayName: 'Mixture Component Substance Refuuid',
    dataType: 'string', luceneQueryable: true},
'Mixture Component Substance Substance Class': {fieldName: 'Substance Class',
    fieldPath: '/mixture/components/?/substance/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_mixture_components_substance_substanceClass', CVDomain: '',
   displayName: 'Mixture Component Substance Substance Class',
    dataType: 'string', luceneQueryable: true},
'Mixture Component Type': {fieldName: 'Type',
    fieldPath: '/mixture/components/?/type', finalField: 'type',
    lucenePath: 'root_mixture_components_type', CVDomain: 'MIXTURE_TYPE',
   displayName: 'Mixture Component Type',
    dataType: 'string', luceneQueryable: true},
'Mixture Created': {fieldName: 'Created',
    fieldPath: '/mixture/created', finalField: 'created',
    lucenePath: 'root_mixture_created', CVDomain: '',
   displayName: 'Mixture Created',
    dataType: 'number', luceneQueryable: true},
'Mixture Created By': {fieldName: 'Created By',
    fieldPath: '/mixture/createdBy', finalField: 'createdBy',
    lucenePath: 'root_mixture_createdBy', CVDomain: '',
   displayName: 'Mixture Created By',
    dataType: 'string', luceneQueryable: true},
'Mixture Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/mixture/deprecated', finalField: 'deprecated',
    lucenePath: 'root_mixture_deprecated', CVDomain: '',
   displayName: 'Mixture Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Mixture Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/mixture/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_mixture_lastEdited', CVDomain: '',
   displayName: 'Mixture Last Edited',
    dataType: 'number', luceneQueryable: true},
'Mixture Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/mixture/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_mixture_lastEditedBy', CVDomain: '',
   displayName: 'Mixture Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Mixture Parent Substance Access': {fieldName: 'Access',
    fieldPath: '/mixture/parentSubstance/access', finalField: 'access',
    lucenePath: 'root_mixture_parentSubstance_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Mixture Parent Substance Access',
    dataType: 'array <string>', luceneQueryable: true},
'Mixture Parent Substance Approval ID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/mixture/parentSubstance/approvalID', finalField: 'approvalID',
    lucenePath: 'root_mixture_parentSubstance_approvalID', CVDomain: '',
   displayName: 'Mixture Parent Substance Approval ID',
    dataType: 'string', luceneQueryable: true},
'Mixture Parent Substance Created': {fieldName: 'Created',
    fieldPath: '/mixture/parentSubstance/created', finalField: 'created',
    lucenePath: 'root_mixture_parentSubstance_created', CVDomain: '',
   displayName: 'Mixture Parent Substance Created',
    dataType: 'number', luceneQueryable: true},
'Mixture Parent Substance Created By': {fieldName: 'Created By',
    fieldPath: '/mixture/parentSubstance/createdBy', finalField: 'createdBy',
    lucenePath: 'root_mixture_parentSubstance_createdBy', CVDomain: '',
   displayName: 'Mixture Parent Substance Created By',
    dataType: 'string', luceneQueryable: true},
'Mixture Parent Substance Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/mixture/parentSubstance/deprecated', finalField: 'deprecated',
    lucenePath: 'root_mixture_parentSubstance_deprecated', CVDomain: '',
   displayName: 'Mixture Parent Substance Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Mixture Parent Substance Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/mixture/parentSubstance/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_mixture_parentSubstance_lastEdited', CVDomain: '',
   displayName: 'Mixture Parent Substance Last Edited',
    dataType: 'number', luceneQueryable: true},
'Mixture Parent Substance Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/mixture/parentSubstance/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_mixture_parentSubstance_lastEditedBy', CVDomain: '',
   displayName: 'Mixture Parent Substance Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Mixture Parent Substance Preferred Name': {fieldName: 'Ref Pname',
    fieldPath: '/mixture/parentSubstance/refPname', finalField: 'refPname',
    lucenePath: 'root_mixture_parentSubstance_refPname', CVDomain: '',
   displayName: 'Mixture Parent Substance Preferred Name',
    dataType: 'string', luceneQueryable: true},
'Mixture Parent Substance Refuuid': {fieldName: 'Refuuid',
    fieldPath: '/mixture/parentSubstance/refuuid', finalField: 'refuuid',
    lucenePath: 'root_mixture_parentSubstance_refuuid', CVDomain: '',
   displayName: 'Mixture Parent Substance Refuuid',
    dataType: 'string', luceneQueryable: true},
'Mixture Parent Substance Substance Class': {fieldName: 'Substance Class',
    fieldPath: '/mixture/parentSubstance/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_mixture_parentSubstance_substanceClass', CVDomain: '',
   displayName: 'Mixture Parent Substance Substance Class',
    dataType: 'string', luceneQueryable: true},
'Modification Access': {fieldName: 'Access',
    fieldPath: '/modifications/access', finalField: 'access',
    lucenePath: 'root_modifications_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Modification Access',
    dataType: 'array <string>', luceneQueryable: true},
'Agent Modification Access': {fieldName: 'Access',
    fieldPath: '/modifications/agentModifications/?/access', finalField: 'access',
    lucenePath: 'root_modifications_agentModifications_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Agent Modification Access',
    dataType: 'array <string>', luceneQueryable: true},
'Agent Modification Agent Modification Process': {fieldName: 'Agent Modification Process',
    fieldPath: '/modifications/agentModifications/?/agentModificationProcess', finalField: 'agentModificationProcess',
    lucenePath: 'root_modifications_agentModifications_agentModificationProcess', CVDomain: 'AGENT_MODIFICATION_PROCESS',
   displayName: 'Agent Modification Agent Modification Process',
    dataType: 'string', luceneQueryable: true},
'Agent Modification Agent Modification Role': {fieldName: 'Agent Modification Role',
    fieldPath: '/modifications/agentModifications/?/agentModificationRole', finalField: 'agentModificationRole',
    lucenePath: 'root_modifications_agentModifications_agentModificationRole', CVDomain: 'ROLE',
   displayName: 'Agent Modification Agent Modification Role',
    dataType: 'string', luceneQueryable: true},
'Agent Modification Agent Modification Type': {fieldName: 'Agent Modification Type',
    fieldPath: '/modifications/agentModifications/?/agentModificationType', finalField: 'agentModificationType',
    lucenePath: 'root_modifications_agentModifications_agentModificationType', CVDomain: 'AGENT_MODIFICATION_TYPE',
   displayName: 'Agent Modification Agent Modification Type',
    dataType: 'string', luceneQueryable: true},
'Agent Substance Access': {fieldName: 'Access',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/access', finalField: 'access',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Agent Substance Access',
    dataType: 'array <string>', luceneQueryable: true},
'Agent Substance Approval ID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/approvalID', finalField: 'approvalID',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_approvalID', CVDomain: '',
   displayName: 'Agent Substance Approval ID',
    dataType: 'string', luceneQueryable: true},
'Agent Substance Created': {fieldName: 'Created',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/created', finalField: 'created',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_created', CVDomain: '',
   displayName: 'Agent Substance Created',
    dataType: 'number', luceneQueryable: true},
'Agent Substance Created By': {fieldName: 'Created By',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/createdBy', finalField: 'createdBy',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_createdBy', CVDomain: '',
   displayName: 'Agent Substance Created By',
    dataType: 'string', luceneQueryable: true},
'Agent Substance Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/deprecated', finalField: 'deprecated',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_deprecated', CVDomain: '',
   displayName: 'Agent Substance Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Agent Substance Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_lastEdited', CVDomain: '',
   displayName: 'Agent Substance Last Edited',
    dataType: 'number', luceneQueryable: true},
'Agent Substance Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_lastEditedBy', CVDomain: '',
   displayName: 'Agent Substance Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Agent Substance Preferred Name': {fieldName: 'Ref Pname',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/refPname', finalField: 'refPname',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_refPname', CVDomain: '',
   displayName: 'Agent Substance Preferred Name',
    dataType: 'string', luceneQueryable: true},
'Agent Substance Refuuid': {fieldName: 'Refuuid',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/refuuid', finalField: 'refuuid',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_refuuid', CVDomain: '',
   displayName: 'Agent Substance Refuuid',
    dataType: 'string', luceneQueryable: true},
'Agent Substance Substance Class': {fieldName: 'Substance Class',
    fieldPath: '/modifications/agentModifications/?/agentSubstance/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_modifications_agentModifications_agentSubstance_substanceClass', CVDomain: '',
   displayName: 'Agent Substance Substance Class',
    dataType: 'string', luceneQueryable: true},
'Agent Modification Amount Access': {fieldName: 'Access',
    fieldPath: '/modifications/agentModifications/?/amount/access', finalField: 'access',
    lucenePath: 'root_modifications_agentModifications_amount_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Agent Modification Amount Access',
    dataType: 'array <string>', luceneQueryable: true},
'Agent Modification Amount Average': {fieldName: 'Average',
    fieldPath: '/modifications/agentModifications/?/amount/average', finalField: 'average',
    lucenePath: 'root_modifications_agentModifications_amount_average', CVDomain: '',
   displayName: 'Agent Modification Amount Average',
    dataType: 'number', luceneQueryable: true},
'Agent Modification Amount Created': {fieldName: 'Created',
    fieldPath: '/modifications/agentModifications/?/amount/created', finalField: 'created',
    lucenePath: 'root_modifications_agentModifications_amount_created', CVDomain: '',
   displayName: 'Agent Modification Amount Created',
    dataType: 'number', luceneQueryable: true},
'Agent Modification Amount Created By': {fieldName: 'Created By',
    fieldPath: '/modifications/agentModifications/?/amount/createdBy', finalField: 'createdBy',
    lucenePath: 'root_modifications_agentModifications_amount_createdBy', CVDomain: '',
   displayName: 'Agent Modification Amount Created By',
    dataType: 'string', luceneQueryable: true},
'Agent Modification Amount Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/modifications/agentModifications/?/amount/deprecated', finalField: 'deprecated',
    lucenePath: 'root_modifications_agentModifications_amount_deprecated', CVDomain: '',
   displayName: 'Agent Modification Amount Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Agent Modification Amount High': {fieldName: 'High',
    fieldPath: '/modifications/agentModifications/?/amount/high', finalField: 'high',
    lucenePath: 'root_modifications_agentModifications_amount_high', CVDomain: '',
   displayName: 'Agent Modification Amount High',
    dataType: 'number', luceneQueryable: true},
'Agent Modification Amount High Limit': {fieldName: 'High Limit',
    fieldPath: '/modifications/agentModifications/?/amount/highLimit', finalField: 'highLimit',
    lucenePath: 'root_modifications_agentModifications_amount_highLimit', CVDomain: '',
   displayName: 'Agent Modification Amount High Limit',
    dataType: 'number', luceneQueryable: true},
'Agent Modification Amount Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/modifications/agentModifications/?/amount/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_modifications_agentModifications_amount_lastEdited', CVDomain: '',
   displayName: 'Agent Modification Amount Last Edited',
    dataType: 'number', luceneQueryable: true},
'Agent Modification Amount Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/modifications/agentModifications/?/amount/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_modifications_agentModifications_amount_lastEditedBy', CVDomain: '',
   displayName: 'Agent Modification Amount Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Agent Modification Amount Low': {fieldName: 'Low',
    fieldPath: '/modifications/agentModifications/?/amount/low', finalField: 'low',
    lucenePath: 'root_modifications_agentModifications_amount_low', CVDomain: '',
   displayName: 'Agent Modification Amount Low',
    dataType: 'number', luceneQueryable: true},
'Agent Modification Amount Low Limit': {fieldName: 'Low Limit',
    fieldPath: '/modifications/agentModifications/?/amount/lowLimit', finalField: 'lowLimit',
    lucenePath: 'root_modifications_agentModifications_amount_lowLimit', CVDomain: '',
   displayName: 'Agent Modification Amount Low Limit',
    dataType: 'number', luceneQueryable: true},
'Agent Modification Amount Non-Numeric Value': {fieldName: 'Non Numeric Value',
    fieldPath: '/modifications/agentModifications/?/amount/nonNumericValue', finalField: 'nonNumericValue',
    lucenePath: 'root_modifications_agentModifications_amount_nonNumericValue', CVDomain: '',
   displayName: 'Agent Modification Amount Non-Numeric Value',
    dataType: 'string', luceneQueryable: true},
'Agent Modification Amount Type': {fieldName: 'Type',
    fieldPath: '/modifications/agentModifications/?/amount/type', finalField: 'type',
    lucenePath: 'root_modifications_agentModifications_amount_type', CVDomain: 'AMOUNT_TYPE',
   displayName: 'Agent Modification Amount Type',
    dataType: 'string', luceneQueryable: true},
'Agent Modification Amount Units': {fieldName: 'Units',
    fieldPath: '/modifications/agentModifications/?/amount/units', finalField: 'units',
    lucenePath: 'root_modifications_agentModifications_amount_units', CVDomain: 'AMOUNT_UNIT',
   displayName: 'Agent Modification Amount Units',
    dataType: 'string', luceneQueryable: true},
'Agent Modification Created': {fieldName: 'Created',
    fieldPath: '/modifications/agentModifications/?/created', finalField: 'created',
    lucenePath: 'root_modifications_agentModifications_created', CVDomain: '',
   displayName: 'Agent Modification Created',
    dataType: 'number', luceneQueryable: true},
'Agent Modification Created By': {fieldName: 'Created By',
    fieldPath: '/modifications/agentModifications/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_modifications_agentModifications_createdBy', CVDomain: '',
   displayName: 'Agent Modification Created By',
    dataType: 'string', luceneQueryable: true},
'Agent Modification Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/modifications/agentModifications/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_modifications_agentModifications_deprecated', CVDomain: '',
   displayName: 'Agent Modification Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Agent Modification Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/modifications/agentModifications/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_modifications_agentModifications_lastEdited', CVDomain: '',
   displayName: 'Agent Modification Last Edited',
    dataType: 'number', luceneQueryable: true},
'Agent Modification Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/modifications/agentModifications/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_modifications_agentModifications_lastEditedBy', CVDomain: '',
   displayName: 'Agent Modification Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Agent Modification Modification Group': {fieldName: 'Modification Group',
    fieldPath: '/modifications/agentModifications/?/modificationGroup', finalField: 'modificationGroup',
    lucenePath: 'root_modifications_agentModifications_modificationGroup', CVDomain: '',
   displayName: 'Agent Modification Modification Group',
    dataType: 'string', luceneQueryable: true},
'Modification Created': {fieldName: 'Created',
    fieldPath: '/modifications/created', finalField: 'created',
    lucenePath: 'root_modifications_created', CVDomain: '',
   displayName: 'Modification Created',
    dataType: 'number', luceneQueryable: true},
'Modification Created By': {fieldName: 'Created By',
    fieldPath: '/modifications/createdBy', finalField: 'createdBy',
    lucenePath: 'root_modifications_createdBy', CVDomain: '',
   displayName: 'Modification Created By',
    dataType: 'string', luceneQueryable: true},
'Modification Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/modifications/deprecated', finalField: 'deprecated',
    lucenePath: 'root_modifications_deprecated', CVDomain: '',
   displayName: 'Modification Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Modification Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/modifications/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_modifications_lastEdited', CVDomain: '',
   displayName: 'Modification Last Edited',
    dataType: 'number', luceneQueryable: true},
'Modification Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/modifications/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_modifications_lastEditedBy', CVDomain: '',
   displayName: 'Modification Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Physical Modification Access': {fieldName: 'Access',
    fieldPath: '/modifications/physicalModifications/?/access', finalField: 'access',
    lucenePath: 'root_modifications_physicalModifications_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Physical Modification Access',
    dataType: 'array <string>', luceneQueryable: true},
'Physical Modification Created': {fieldName: 'Created',
    fieldPath: '/modifications/physicalModifications/?/created', finalField: 'created',
    lucenePath: 'root_modifications_physicalModifications_created', CVDomain: '',
   displayName: 'Physical Modification Created',
    dataType: 'number', luceneQueryable: true},
'Physical Modification Created By': {fieldName: 'Created By',
    fieldPath: '/modifications/physicalModifications/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_modifications_physicalModifications_createdBy', CVDomain: '',
   displayName: 'Physical Modification Created By',
    dataType: 'string', luceneQueryable: true},
'Physical Modification Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/modifications/physicalModifications/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_modifications_physicalModifications_deprecated', CVDomain: '',
   displayName: 'Physical Modification Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Physical Modification Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/modifications/physicalModifications/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_modifications_physicalModifications_lastEdited', CVDomain: '',
   displayName: 'Physical Modification Last Edited',
    dataType: 'number', luceneQueryable: true},
'Physical Modification Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/modifications/physicalModifications/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_modifications_physicalModifications_lastEditedBy', CVDomain: '',
   displayName: 'Physical Modification Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Physical Modification Modification Group': {fieldName: 'Modification Group',
    fieldPath: '/modifications/physicalModifications/?/modificationGroup', finalField: 'modificationGroup',
    lucenePath: 'root_modifications_physicalModifications_modificationGroup', CVDomain: '',
   displayName: 'Physical Modification Modification Group',
    dataType: 'string', luceneQueryable: true},
'Physical Modification Parameter Access': {fieldName: 'Access',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/access', finalField: 'access',
    lucenePath: 'root_modifications_physicalModifications_parameters_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Physical Modification Parameter Access',
    dataType: 'array <string>', luceneQueryable: true},
'Physical Modification Amount Access': {fieldName: 'Access',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/access', finalField: 'access',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Physical Modification Amount Access',
    dataType: 'array <string>', luceneQueryable: true},
'Physical Modification Amount Average': {fieldName: 'Average',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/average', finalField: 'average',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_average', CVDomain: '',
   displayName: 'Physical Modification Amount Average',
    dataType: 'number', luceneQueryable: true},
'Physical Modification Amount Created': {fieldName: 'Created',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/created', finalField: 'created',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_created', CVDomain: '',
   displayName: 'Physical Modification Amount Created',
    dataType: 'number', luceneQueryable: true},
'Physical Modification Amount Created By': {fieldName: 'Created By',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/createdBy', finalField: 'createdBy',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_createdBy', CVDomain: '',
   displayName: 'Physical Modification Amount Created By',
    dataType: 'string', luceneQueryable: true},
'Physical Modification Amount Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/deprecated', finalField: 'deprecated',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_deprecated', CVDomain: '',
   displayName: 'Physical Modification Amount Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Physical Modification Amount High Limit': {fieldName: 'High Limit',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/highLimit', finalField: 'highLimit',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_highLimit', CVDomain: '',
   displayName: 'Physical Modification Amount High Limit',
    dataType: 'number', luceneQueryable: true},
'Physical Modification Amount Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_lastEdited', CVDomain: '',
   displayName: 'Physical Modification Amount Last Edited',
    dataType: 'number', luceneQueryable: true},
'Physical Modification Amount Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_lastEditedBy', CVDomain: '',
   displayName: 'Physical Modification Amount Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Physical Modification Amount Low Limit': {fieldName: 'Low Limit',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/lowLimit', finalField: 'lowLimit',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_lowLimit', CVDomain: '',
   displayName: 'Physical Modification Amount Low Limit',
    dataType: 'number', luceneQueryable: true},
'Physical Modification Amount Non-Numeric Value': {fieldName: 'Non Numeric Value',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/nonNumericValue', finalField: 'nonNumericValue',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_nonNumericValue', CVDomain: '',
   displayName: 'Physical Modification Amount Non-Numeric Value',
    dataType: 'string', luceneQueryable: true},
'Physical Modification Amount Type': {fieldName: 'Type',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/type', finalField: 'type',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_type', CVDomain: 'AMOUNT_TYPE',
   displayName: 'Physical Modification Amount Type',
    dataType: 'string', luceneQueryable: true},
'Physical Modification Amount Units': {fieldName: 'Units',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/amount/units', finalField: 'units',
    lucenePath: 'root_modifications_physicalModifications_parameters_amount_units', CVDomain: 'AMOUNT_UNIT',
   displayName: 'Physical Modification Amount Units',
    dataType: 'string', luceneQueryable: true},
'Physical Modification Parameter Created': {fieldName: 'Created',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/created', finalField: 'created',
    lucenePath: 'root_modifications_physicalModifications_parameters_created', CVDomain: '',
   displayName: 'Physical Modification Parameter Created',
    dataType: 'number', luceneQueryable: true},
'Physical Modification Parameter Created By': {fieldName: 'Created By',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_modifications_physicalModifications_parameters_createdBy', CVDomain: '',
   displayName: 'Physical Modification Parameter Created By',
    dataType: 'string', luceneQueryable: true},
'Physical Modification Parameter Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_modifications_physicalModifications_parameters_deprecated', CVDomain: '',
   displayName: 'Physical Modification Parameter Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Physical Modification Parameter Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_modifications_physicalModifications_parameters_lastEdited', CVDomain: '',
   displayName: 'Physical Modification Parameter Last Edited',
    dataType: 'number', luceneQueryable: true},
'Physical Modification Parameter Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_modifications_physicalModifications_parameters_lastEditedBy', CVDomain: '',
   displayName: 'Physical Modification Parameter Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Physical Modification Parameter Parameter Name': {fieldName: 'Parameter Name',
    fieldPath: '/modifications/physicalModifications/?/parameters/?/parameterName', finalField: 'parameterName',
    lucenePath: 'root_modifications_physicalModifications_parameters_parameterName', CVDomain: '',
   displayName: 'Physical Modification Parameter Parameter Name',
    dataType: 'string', luceneQueryable: true},
'Physical Modification Physical Modification Role': {fieldName: 'Physicalmodificationrole',
    fieldPath: '/modifications/physicalModifications/?/physicalModificationRole', finalField: 'physicalModificationRole',
    lucenePath: 'root_modifications_physicalModifications_physicalModificationRole', CVDomain: 'PHYSICAL_MODIFICATION_ROLE',
   displayName: 'Physical Modification Physical Modification Role',
    dataType: 'string', luceneQueryable: true},
'Structural Modification Access': {fieldName: 'Access',
    fieldPath: '/modifications/structuralModifications/?/access', finalField: 'access',
    lucenePath: 'root_modifications_structuralModifications_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Structural Modification Access',
    dataType: 'array <string>', luceneQueryable: true},
'Structural Modification Created': {fieldName: 'Created',
    fieldPath: '/modifications/structuralModifications/?/created', finalField: 'created',
    lucenePath: 'root_modifications_structuralModifications_created', CVDomain: '',
   displayName: 'Structural Modification Created',
    dataType: 'number', luceneQueryable: true},
'Structural Modification Created By': {fieldName: 'Created By',
    fieldPath: '/modifications/structuralModifications/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_modifications_structuralModifications_createdBy', CVDomain: '',
   displayName: 'Structural Modification Created By',
    dataType: 'string', luceneQueryable: true},
'Structural Modification Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/modifications/structuralModifications/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_modifications_structuralModifications_deprecated', CVDomain: '',
   displayName: 'Structural Modification Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Structural Modification Extent': {fieldName: 'Extent',
    fieldPath: '/modifications/structuralModifications/?/extent', finalField: 'extent',
    lucenePath: 'root_modifications_structuralModifications_extent', CVDomain: 'EXTENT_TYPE',
   displayName: 'Structural Modification Extent',
    dataType: 'string', luceneQueryable: true},
'Structural Modification Extent Amount Access': {fieldName: 'Access',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/access', finalField: 'access',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Structural Modification Extent Amount Access',
    dataType: 'array <string>', luceneQueryable: true},
'Structural Modification Extent Amount Average': {fieldName: 'Average',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/average', finalField: 'average',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_average', CVDomain: '',
   displayName: 'Structural Modification Extent Amount Average',
    dataType: 'number', luceneQueryable: true},
'Structural Modification Extent Amount Created': {fieldName: 'Created',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/created', finalField: 'created',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_created', CVDomain: '',
   displayName: 'Structural Modification Extent Amount Created',
    dataType: 'number', luceneQueryable: true},
'Structural Modification Extent Amount Created By': {fieldName: 'Created By',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/createdBy', finalField: 'createdBy',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_createdBy', CVDomain: '',
   displayName: 'Structural Modification Extent Amount Created By',
    dataType: 'string', luceneQueryable: true},
'Structural Modification Extent Amount Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/deprecated', finalField: 'deprecated',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_deprecated', CVDomain: '',
   displayName: 'Structural Modification Extent Amount Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Structural Modification Extent Amount High': {fieldName: 'High',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/high', finalField: 'high',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_high', CVDomain: '',
   displayName: 'Structural Modification Extent Amount High',
    dataType: 'number', luceneQueryable: true},
'Structural Modification Extent Amount High Limit': {fieldName: 'High Limit',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/highLimit', finalField: 'highLimit',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_highLimit', CVDomain: '',
   displayName: 'Structural Modification Extent Amount High Limit',
    dataType: 'number', luceneQueryable: true},
'Structural Modification Extent Amount Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_lastEdited', CVDomain: '',
   displayName: 'Structural Modification Extent Amount Last Edited',
    dataType: 'number', luceneQueryable: true},
'Structural Modification Extent Amount Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_lastEditedBy', CVDomain: '',
   displayName: 'Structural Modification Extent Amount Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Structural Modification Extent Amount Low': {fieldName: 'Low',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/low', finalField: 'low',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_low', CVDomain: '',
   displayName: 'Structural Modification Extent Amount Low',
    dataType: 'number', luceneQueryable: true},
'Structural Modification Extent Amount Low Limit': {fieldName: 'Low Limit',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/lowLimit', finalField: 'lowLimit',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_lowLimit', CVDomain: '',
   displayName: 'Structural Modification Extent Amount Low Limit',
    dataType: 'number', luceneQueryable: true},
'Structural Modification Extent Amount Non-Numeric Value': {fieldName: 'Non Numeric Value',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/nonNumericValue', finalField: 'nonNumericValue',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_nonNumericValue', CVDomain: '',
   displayName: 'Structural Modification Extent Amount Non-Numeric Value',
    dataType: 'string', luceneQueryable: true},
'Structural Modification Extent Amount Type': {fieldName: 'Type',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/type', finalField: 'type',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_type', CVDomain: 'AMOUNT_TYPE',
   displayName: 'Structural Modification Extent Amount Type',
    dataType: 'string', luceneQueryable: true},
'Structural Modification Extent Amount Units': {fieldName: 'Units',
    fieldPath: '/modifications/structuralModifications/?/extentAmount/units', finalField: 'units',
    lucenePath: 'root_modifications_structuralModifications_extentAmount_units', CVDomain: 'AMOUNT_UNIT',
   displayName: 'Structural Modification Extent Amount Units',
    dataType: 'string', luceneQueryable: true},
'Structural Modification Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/modifications/structuralModifications/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_modifications_structuralModifications_lastEdited', CVDomain: '',
   displayName: 'Structural Modification Last Edited',
    dataType: 'number', luceneQueryable: true},
'Structural Modification Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/modifications/structuralModifications/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_modifications_structuralModifications_lastEditedBy', CVDomain: '',
   displayName: 'Structural Modification Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Structural Modification Locationtype': {fieldName: 'Location Type',
    fieldPath: '/modifications/structuralModifications/?/locationType', finalField: 'locationType',
    lucenePath: 'root_modifications_structuralModifications_locationType', CVDomain: 'LOCATION_TYPE',
   displayName: 'Structural Modification Locationtype',
    dataType: 'string', luceneQueryable: true},
'Structural Modification Modification Group': {fieldName: 'Modification Group',
    fieldPath: '/modifications/structuralModifications/?/modificationGroup', finalField: 'modificationGroup',
    lucenePath: 'root_modifications_structuralModifications_modificationGroup', CVDomain: '',
   displayName: 'Structural Modification Modification Group',
    dataType: 'string', luceneQueryable: true},
'Molecular Fragment Access': {fieldName: 'Access',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/access', finalField: 'access',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Molecular Fragment Access',
    dataType: 'array <string>', luceneQueryable: true},
'Molecular Fragment Approval ID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/approvalID', finalField: 'approvalID',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_approvalID', CVDomain: '',
   displayName: 'Molecular Fragment Approval ID',
    dataType: 'string', luceneQueryable: true},
'Molecular Fragment Created': {fieldName: 'Created',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/created', finalField: 'created',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_created', CVDomain: '',
   displayName: 'Molecular Fragment Created',
    dataType: 'number', luceneQueryable: true},
'Molecular Fragment Created By': {fieldName: 'Created By',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/createdBy', finalField: 'createdBy',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_createdBy', CVDomain: '',
   displayName: 'Molecular Fragment Created By',
    dataType: 'string', luceneQueryable: true},
'Molecular Fragment Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/deprecated', finalField: 'deprecated',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_deprecated', CVDomain: '',
   displayName: 'Molecular Fragment Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Molecular Fragment Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_lastEdited', CVDomain: '',
   displayName: 'Molecular Fragment Last Edited',
    dataType: 'number', luceneQueryable: true},
'Molecular Fragment Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_lastEditedBy', CVDomain: '',
   displayName: 'Molecular Fragment Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Molecular Fragment Preferred Name': {fieldName: 'Ref Pname',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/refPname', finalField: 'refPname',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_refPname', CVDomain: '',
   displayName: 'Molecular Fragment Preferred Name',
    dataType: 'string', luceneQueryable: true},
'Molecular Fragment Refuuid': {fieldName: 'Refuuid',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/refuuid', finalField: 'refuuid',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_refuuid', CVDomain: '',
   displayName: 'Molecular Fragment Refuuid',
    dataType: 'string', luceneQueryable: true},
'Molecular Fragment Substance Class': {fieldName: 'Substance Class',
    fieldPath: '/modifications/structuralModifications/?/molecularFragment/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_modifications_structuralModifications_molecularFragment_substanceClass', CVDomain: '',
   displayName: 'Molecular Fragment Substance Class',
    dataType: 'string', luceneQueryable: true},
'Structural Modification Residuemodified': {fieldName: 'Residue Modified',
    fieldPath: '/modifications/structuralModifications/?/residueModified', finalField: 'residueModified',
    lucenePath: 'root_modifications_structuralModifications_residueModified', CVDomain: '',
   displayName: 'Structural Modification Residuemodified',
    dataType: 'string', luceneQueryable: true},
'Structural Modification Structural Modification Type': {fieldName: 'Structural Modification Type',
    fieldPath: '/modifications/structuralModifications/?/structuralModificationType', finalField: 'structuralModificationType',
    lucenePath: 'root_modifications_structuralModifications_structuralModificationType', CVDomain: 'STRUCTURAL_MODIFICATION_TYPE',
   displayName: 'Structural Modification Structural Modification Type',
    dataType: 'string', luceneQueryable: true},
'Moiety Access': {fieldName: 'Access',
    fieldPath: '/moieties/?/access', finalField: 'access',
    lucenePath: 'root_moieties_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Moiety Access',
    dataType: 'array <string>', luceneQueryable: true},
'Count Amount Access': {fieldName: 'Access',
    fieldPath: '/moieties/?/countAmount/access', finalField: 'access',
    lucenePath: 'root_moieties_countAmount_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Count Amount Access',
    dataType: 'array <string>', luceneQueryable: true},
'Count Amount Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/moieties/?/countAmount/deprecated', finalField: 'deprecated',
    lucenePath: 'root_moieties_countAmount_deprecated', CVDomain: '',
   displayName: 'Count Amount Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Moiety Created': {fieldName: 'Created',
    fieldPath: '/moieties/?/created', finalField: 'created',
    lucenePath: 'root_moieties_created', CVDomain: '',
   displayName: 'Moiety Created',
    dataType: 'number', luceneQueryable: true},
'Moiety Created By': {fieldName: 'Created By',
    fieldPath: '/moieties/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_moieties_createdBy', CVDomain: '',
   displayName: 'Moiety Created By',
    dataType: 'string', luceneQueryable: true},
'Moiety Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/moieties/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_moieties_deprecated', CVDomain: '',
   displayName: 'Moiety Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Moiety Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/moieties/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_moieties_lastEdited', CVDomain: '',
   displayName: 'Moiety Last Edited',
    dataType: 'number', luceneQueryable: true},
'Moiety Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/moieties/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_moieties_lastEditedBy', CVDomain: '',
   displayName: 'Moiety Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Moiety Stereochemistry': {fieldName: 'Stereochemistry',
    fieldPath: '/moieties/?/stereochemistry', finalField: 'stereochemistry',
    lucenePath: 'root_moieties_stereochemistry', CVDomain: 'STEREOCHEMISTRY_TYPE',
   displayName: 'Moiety Stereochemistry',
    dataType: 'string', luceneQueryable: true},
'Name Access': {fieldName: 'Access',
    fieldPath: '/names/?/access', finalField: 'access',
    lucenePath: 'root_names_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Name Access',
    dataType: 'array <string>', luceneQueryable: true},
'Name Created': {fieldName: 'Created',
    fieldPath: '/names/?/created', finalField: 'created',
    lucenePath: 'root_names_created', CVDomain: '',
   displayName: 'Name Created',
    dataType: 'number', luceneQueryable: true},
'Name Created By': {fieldName: 'Created By',
    fieldPath: '/names/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_names_createdBy', CVDomain: '',
   displayName: 'Name Created By',
    dataType: 'string', luceneQueryable: true},
'Name Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/names/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_names_deprecated', CVDomain: '',
   displayName: 'Name Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Name Display Name': {fieldName: 'Display Name',
    fieldPath: '/names/?/displayName', finalField: 'displayName',
    lucenePath: 'root_names_displayName', CVDomain: '',
   displayName: 'Name Display Name',
    dataType: 'boolean', luceneQueryable: true},
'Name Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/names/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_names_lastEdited', CVDomain: '',
   displayName: 'Name Last Edited',
    dataType: 'number', luceneQueryable: true},
'Name Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/names/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_names_lastEditedBy', CVDomain: '',
   displayName: 'Name Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Any Name': {fieldName: 'Name',
    fieldPath: '/names/?/name', finalField: 'name',
    lucenePath: 'root_names_name', CVDomain: '',
   displayName: 'Any Name',
    dataType: 'string', luceneQueryable: true},
'Naming Organization Access': {fieldName: 'Access',
    fieldPath: '/names/?/nameOrgs/?/access', finalField: 'access',
    lucenePath: 'root_names_nameOrgs_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Naming Organization Access',
    dataType: 'array <string>', luceneQueryable: true},
'Naming Organization Created': {fieldName: 'Created',
    fieldPath: '/names/?/nameOrgs/?/created', finalField: 'created',
    lucenePath: 'root_names_nameOrgs_created', CVDomain: '',
   displayName: 'Naming Organization Created',
    dataType: 'number', luceneQueryable: true},
'Naming Organization Created By': {fieldName: 'Created By',
    fieldPath: '/names/?/nameOrgs/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_names_nameOrgs_createdBy', CVDomain: '',
   displayName: 'Naming Organization Created By',
    dataType: 'string', luceneQueryable: true},
'Naming Organization Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/names/?/nameOrgs/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_names_nameOrgs_deprecated', CVDomain: '',
   displayName: 'Naming Organization Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Naming Organization Deprecated Date': {fieldName: 'Deprecated Date',
    fieldPath: '/names/?/nameOrgs/?/deprecatedDate', finalField: 'deprecatedDate',
    lucenePath: 'root_names_nameOrgs_deprecatedDate', CVDomain: '',
   displayName: 'Naming Organization Deprecated Date',
    dataType: 'number', luceneQueryable: true},
'Naming Organization Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/names/?/nameOrgs/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_names_nameOrgs_lastEdited', CVDomain: '',
   displayName: 'Naming Organization Last Edited',
    dataType: 'number', luceneQueryable: true},
'Naming Organization Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/names/?/nameOrgs/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_names_nameOrgs_lastEditedBy', CVDomain: '',
   displayName: 'Naming Organization Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Naming Organization': {fieldName: 'Name Org',
    fieldPath: '/names/?/nameOrgs/?/nameOrg', finalField: 'nameOrg',
    lucenePath: 'root_names_nameOrgs_nameOrg', CVDomain: 'NAME_ORG',
   displayName: 'Naming Organization',
    dataType: 'string', luceneQueryable: true},
'Name Preferred': {fieldName: 'Preferred Status',
    fieldPath: '/names/?/preferred', finalField: 'preferred',
    lucenePath: 'root_names_preferred', CVDomain: '',
   displayName: 'Name Preferred',
    dataType: 'boolean', luceneQueryable: true},
'Name Type Code': {fieldName: 'Type',
    fieldPath: '/names/?/type', finalField: 'type',
    lucenePath: 'root_names_type', CVDomain: 'NAME_TYPE',
   displayName: 'Name Type Code',
    dataType: 'string', luceneQueryable: true},
'Note Access': {fieldName: 'Access',
    fieldPath: '/notes/?/access', finalField: 'access',
    lucenePath: 'root_notes_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Note Access',
    dataType: 'array <string>', luceneQueryable: true},
'Note Created': {fieldName: 'Created',
    fieldPath: '/notes/?/created', finalField: 'created',
    lucenePath: 'root_notes_created', CVDomain: '',
   displayName: 'Note Created',
    dataType: 'number', luceneQueryable: true},
'Note Created By': {fieldName: 'Created By',
    fieldPath: '/notes/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_notes_createdBy', CVDomain: '',
   displayName: 'Note Created By',
    dataType: 'string', luceneQueryable: true},
'Note Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/notes/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_notes_deprecated', CVDomain: '',
   displayName: 'Note Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Note Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/notes/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_notes_lastEdited', CVDomain: '',
   displayName: 'Note Last Edited',
    dataType: 'number', luceneQueryable: true},
'Note Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/notes/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_notes_lastEditedBy', CVDomain: '',
   displayName: 'Note Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Note': {fieldName: 'Notes',
    fieldPath: '/notes/?/note', finalField: 'note',
    lucenePath: 'root_notes_note', CVDomain: '',
   displayName: 'Note',
    dataType: 'string', luceneQueryable: true},
'Nucleic Acid Access': {fieldName: 'Access',
    fieldPath: '/nucleicAcid/access', finalField: 'access',
    lucenePath: 'root_nucleicAcid_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Nucleic Acid Access',
    dataType: 'array <string>', luceneQueryable: true},
'Nucleic Acid Created': {fieldName: 'Created',
    fieldPath: '/nucleicAcid/created', finalField: 'created',
    lucenePath: 'root_nucleicAcid_created', CVDomain: '',
   displayName: 'Nucleic Acid Created',
    dataType: 'number', luceneQueryable: true},
'Nucleic Acid Created By': {fieldName: 'Created By',
    fieldPath: '/nucleicAcid/createdBy', finalField: 'createdBy',
    lucenePath: 'root_nucleicAcid_createdBy', CVDomain: '',
   displayName: 'Nucleic Acid Created By',
    dataType: 'string', luceneQueryable: true},
'Nucleic Acid Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/nucleicAcid/deprecated', finalField: 'deprecated',
    lucenePath: 'root_nucleicAcid_deprecated', CVDomain: '',
   displayName: 'Nucleic Acid Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Nucleic Acid Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/nucleicAcid/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_nucleicAcid_lastEdited', CVDomain: '',
   displayName: 'Nucleic Acid Last Edited',
    dataType: 'number', luceneQueryable: true},
'Nucleic Acid Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/nucleicAcid/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_nucleicAcid_lastEditedBy', CVDomain: '',
   displayName: 'Nucleic Acid Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Linkage Access': {fieldName: 'Access',
    fieldPath: '/nucleicAcid/linkages/?/access', finalField: 'access',
    lucenePath: 'root_nucleicAcid_linkages_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Linkage Access',
    dataType: 'array <string>', luceneQueryable: true},
'Linkage Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/nucleicAcid/linkages/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_nucleicAcid_linkages_deprecated', CVDomain: '',
   displayName: 'Linkage Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Nucleic Acid Subunit Access': {fieldName: 'Access',
    fieldPath: '/nucleicAcid/subunits/?/access', finalField: 'access',
    lucenePath: 'root_nucleicAcid_subunits_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Nucleic Acid Subunit Access',
    dataType: 'array <string>', luceneQueryable: true},
'Nucleic Acid Subunit Created': {fieldName: 'Created',
    fieldPath: '/nucleicAcid/subunits/?/created', finalField: 'created',
    lucenePath: 'root_nucleicAcid_subunits_created', CVDomain: '',
   displayName: 'Nucleic Acid Subunit Created',
    dataType: 'number', luceneQueryable: true},
'Nucleic Acid Subunit Created By': {fieldName: 'Created By',
    fieldPath: '/nucleicAcid/subunits/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_nucleicAcid_subunits_createdBy', CVDomain: '',
   displayName: 'Nucleic Acid Subunit Created By',
    dataType: 'string', luceneQueryable: true},
'Nucleic Acid Subunit Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/nucleicAcid/subunits/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_nucleicAcid_subunits_deprecated', CVDomain: '',
   displayName: 'Nucleic Acid Subunit Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Nucleic Acid Subunit Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/nucleicAcid/subunits/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_nucleicAcid_subunits_lastEdited', CVDomain: '',
   displayName: 'Nucleic Acid Subunit Last Edited',
    dataType: 'number', luceneQueryable: true},
'Nucleic Acid Subunit Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/nucleicAcid/subunits/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_nucleicAcid_subunits_lastEditedBy', CVDomain: '',
   displayName: 'Nucleic Acid Subunit Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Nucleic Acid Subunit Sequence': {fieldName: 'Sequence',
    fieldPath: '/nucleicAcid/subunits/?/sequence', finalField: 'sequence',
    lucenePath: 'root_nucleicAcid_subunits_sequence', CVDomain: '',
   displayName: 'Nucleic Acid Subunit Sequence',
    dataType: 'string', luceneQueryable: true},
'Nucleic Acid Subunit Subunit Index': {fieldName: 'Subunit Index',
    fieldPath: '/nucleicAcid/subunits/?/subunitIndex', finalField: 'subunitIndex',
    lucenePath: 'root_nucleicAcid_subunits_subunitIndex', CVDomain: '',
   displayName: 'Nucleic Acid Subunit Subunit Index',
    dataType: 'number', luceneQueryable: true},
'Sugar Access': {fieldName: 'Access',
    fieldPath: '/nucleicAcid/sugars/?/access', finalField: 'access',
    lucenePath: 'root_nucleicAcid_sugars_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Sugar Access',
    dataType: 'array <string>', luceneQueryable: true},
'Sugar Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/nucleicAcid/sugars/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_nucleicAcid_sugars_deprecated', CVDomain: '',
   displayName: 'Sugar Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Polymer Access': {fieldName: 'Access',
    fieldPath: '/polymer/access', finalField: 'access',
    lucenePath: 'root_polymer_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Polymer Access',
    dataType: 'array <string>', luceneQueryable: true},
'Polymer Classification Access': {fieldName: 'Access',
    fieldPath: '/polymer/classification/access', finalField: 'access',
    lucenePath: 'root_polymer_classification_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Polymer Classification Access',
    dataType: 'array <string>', luceneQueryable: true},
'Polymer Classification Created': {fieldName: 'Created',
    fieldPath: '/polymer/classification/created', finalField: 'created',
    lucenePath: 'root_polymer_classification_created', CVDomain: '',
   displayName: 'Polymer Classification Created',
    dataType: 'number', luceneQueryable: true},
'Polymer Classification Created By': {fieldName: 'Created By',
    fieldPath: '/polymer/classification/createdBy', finalField: 'createdBy',
    lucenePath: 'root_polymer_classification_createdBy', CVDomain: '',
   displayName: 'Polymer Classification Created By',
    dataType: 'string', luceneQueryable: true},
'Polymer Classification Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/polymer/classification/deprecated', finalField: 'deprecated',
    lucenePath: 'root_polymer_classification_deprecated', CVDomain: '',
   displayName: 'Polymer Classification Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Polymer Classification Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/polymer/classification/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_polymer_classification_lastEdited', CVDomain: '',
   displayName: 'Polymer Classification Last Edited',
    dataType: 'number', luceneQueryable: true},
'Polymer Classification Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/polymer/classification/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_polymer_classification_lastEditedBy', CVDomain: '',
   displayName: 'Polymer Classification Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Polymer Parent Substance Access': {fieldName: 'Access',
    fieldPath: '/polymer/classification/parentSubstance/access', finalField: 'access',
    lucenePath: 'root_polymer_classification_parentSubstance_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Polymer Parent Substance Access',
    dataType: 'array <string>', luceneQueryable: true},
'Polymer Parent Substance Approval ID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/polymer/classification/parentSubstance/approvalID', finalField: 'approvalID',
    lucenePath: 'root_polymer_classification_parentSubstance_approvalID', CVDomain: '',
   displayName: 'Polymer Parent Substance Approval ID',
    dataType: 'string', luceneQueryable: true},
'Polymer Parent Substance Created': {fieldName: 'Created',
    fieldPath: '/polymer/classification/parentSubstance/created', finalField: 'created',
    lucenePath: 'root_polymer_classification_parentSubstance_created', CVDomain: '',
   displayName: 'Polymer Parent Substance Created',
    dataType: 'number', luceneQueryable: true},
'Polymer Parent Substance Created By': {fieldName: 'Created By',
    fieldPath: '/polymer/classification/parentSubstance/createdBy', finalField: 'createdBy',
    lucenePath: 'root_polymer_classification_parentSubstance_createdBy', CVDomain: '',
   displayName: 'Polymer Parent Substance Created By',
    dataType: 'string', luceneQueryable: true},
'Polymer Parent Substance Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/polymer/classification/parentSubstance/deprecated', finalField: 'deprecated',
    lucenePath: 'root_polymer_classification_parentSubstance_deprecated', CVDomain: '',
   displayName: 'Polymer Parent Substance Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Polymer Parent Substance Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/polymer/classification/parentSubstance/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_polymer_classification_parentSubstance_lastEdited', CVDomain: '',
   displayName: 'Polymer Parent Substance Last Edited',
    dataType: 'number', luceneQueryable: true},
'Polymer Parent Substance Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/polymer/classification/parentSubstance/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_polymer_classification_parentSubstance_lastEditedBy', CVDomain: '',
   displayName: 'Polymer Parent Substance Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Polymer Parent Substance Preferred Name': {fieldName: 'Ref Pname',
    fieldPath: '/polymer/classification/parentSubstance/refPname', finalField: 'refPname',
    lucenePath: 'root_polymer_classification_parentSubstance_refPname', CVDomain: '',
   displayName: 'Polymer Parent Substance Preferred Name',
    dataType: 'string', luceneQueryable: true},
'Polymer Parent Substance Refuuid': {fieldName: 'Refuuid',
    fieldPath: '/polymer/classification/parentSubstance/refuuid', finalField: 'refuuid',
    lucenePath: 'root_polymer_classification_parentSubstance_refuuid', CVDomain: '',
   displayName: 'Polymer Parent Substance Refuuid',
    dataType: 'string', luceneQueryable: true},
'Polymer Parent Substance Substance Class': {fieldName: 'Substance Class',
    fieldPath: '/polymer/classification/parentSubstance/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_polymer_classification_parentSubstance_substanceClass', CVDomain: '',
   displayName: 'Polymer Parent Substance Substance Class',
    dataType: 'string', luceneQueryable: true},
'Polymer Class': {fieldName: 'Polymer Class',
    fieldPath: '/polymer/classification/polymerClass', finalField: 'polymerClass',
    lucenePath: 'root_polymer_classification_polymerClass', CVDomain: 'POLYMER_CLASS',
   displayName: 'Polymer Class',
    dataType: 'string', luceneQueryable: true},
'Polymer Geometry': {fieldName: 'Polymer Geometry',
    fieldPath: '/polymer/classification/polymerGeometry', finalField: 'polymerGeometry',
    lucenePath: 'root_polymer_classification_polymerGeometry', CVDomain: 'POLYMER_GEOMETRY',
   displayName: 'Polymer Geometry',
    dataType: 'string', luceneQueryable: true},
'Polymer Classification Source Type': {fieldName: 'Sourcetype',
    fieldPath: '/polymer/classification/sourceType', finalField: 'sourceType',
    lucenePath: 'root_polymer_classification_sourceType', CVDomain: 'POLYMER_SOURCE_TYPE',
   displayName: 'Polymer Classification Source Type',
    dataType: 'string', luceneQueryable: true},
'Polymer Created': {fieldName: 'Created',
    fieldPath: '/polymer/created', finalField: 'created',
    lucenePath: 'root_polymer_created', CVDomain: '',
   displayName: 'Polymer Created',
    dataType: 'number', luceneQueryable: true},
'Polymer Created By': {fieldName: 'Created By',
    fieldPath: '/polymer/createdBy', finalField: 'createdBy',
    lucenePath: 'root_polymer_createdBy', CVDomain: '',
   displayName: 'Polymer Created By',
    dataType: 'string', luceneQueryable: true},
'Polymer Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/polymer/deprecated', finalField: 'deprecated',
    lucenePath: 'root_polymer_deprecated', CVDomain: '',
   displayName: 'Polymer Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Display Structure Access': {fieldName: 'Access',
    fieldPath: '/polymer/displayStructure/access', finalField: 'access',
    lucenePath: 'root_polymer_displayStructure_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Display Structure Access',
    dataType: 'array <string>', luceneQueryable: true},
'Display Structure Charge': {fieldName: 'Charge',
    fieldPath: '/polymer/displayStructure/charge', finalField: 'charge',
    lucenePath: 'root_polymer_displayStructure_charge', CVDomain: '',
   displayName: 'Display Structure Charge',
    dataType: 'number', luceneQueryable: true},
'Display Structure Count': {fieldName: 'Count',
    fieldPath: '/polymer/displayStructure/count', finalField: 'count',
    lucenePath: 'root_polymer_displayStructure_count', CVDomain: '',
   displayName: 'Display Structure Count',
    dataType: 'number', luceneQueryable: true},
'Display Structure Created': {fieldName: 'Created',
    fieldPath: '/polymer/displayStructure/created', finalField: 'created',
    lucenePath: 'root_polymer_displayStructure_created', CVDomain: '',
   displayName: 'Display Structure Created',
    dataType: 'number', luceneQueryable: true},
'Display Structure Created By': {fieldName: 'Created By',
    fieldPath: '/polymer/displayStructure/createdBy', finalField: 'createdBy',
    lucenePath: 'root_polymer_displayStructure_createdBy', CVDomain: '',
   displayName: 'Display Structure Created By',
    dataType: 'string', luceneQueryable: true},
'Display Structure Defined Stereo': {fieldName: 'Defined Stereocenters Count',
    fieldPath: '/polymer/displayStructure/definedStereo', finalField: 'definedStereo',
    lucenePath: 'root_polymer_displayStructure_definedStereo', CVDomain: '',
   displayName: 'Display Structure Defined Stereo',
    dataType: 'number', luceneQueryable: true},
'Display Structure Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/polymer/displayStructure/deprecated', finalField: 'deprecated',
    lucenePath: 'root_polymer_displayStructure_deprecated', CVDomain: '',
   displayName: 'Display Structure Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Display Structure Digest': {fieldName: 'Digest',
    fieldPath: '/polymer/displayStructure/digest', finalField: 'digest',
    lucenePath: 'root_polymer_displayStructure_digest', CVDomain: '',
   displayName: 'Display Structure Digest',
    dataType: 'string', luceneQueryable: true},
'Display Structure Ez Centers': {fieldName: 'E/Z Center Count',
    fieldPath: '/polymer/displayStructure/ezCenters', finalField: 'ezCenters',
    lucenePath: 'root_polymer_displayStructure_ezCenters', CVDomain: '',
   displayName: 'Display Structure Ez Centers',
    dataType: 'number', luceneQueryable: true},
'Display Structure Formula': {fieldName: 'Formula',
    fieldPath: '/polymer/displayStructure/formula', finalField: 'formula',
    lucenePath: 'root_polymer_displayStructure_formula', CVDomain: '',
   displayName: 'Display Structure Formula',
    dataType: 'string', luceneQueryable: true},
'Display Structure Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/polymer/displayStructure/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_polymer_displayStructure_lastEdited', CVDomain: '',
   displayName: 'Display Structure Last Edited',
    dataType: 'number', luceneQueryable: true},
'Display Structure Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/polymer/displayStructure/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_polymer_displayStructure_lastEditedBy', CVDomain: '',
   displayName: 'Display Structure Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Display Structure Mwt': {fieldName: 'Molecular Weight',
    fieldPath: '/polymer/displayStructure/mwt', finalField: 'mwt',
    lucenePath: 'root_polymer_displayStructure_mwt', CVDomain: '',
   displayName: 'Display Structure Mwt',
    dataType: 'number', luceneQueryable: true},
'Display Structure Optical Activity': {fieldName: 'Optical Activity',
    fieldPath: '/polymer/displayStructure/opticalActivity', finalField: 'opticalActivity',
    lucenePath: 'root_polymer_displayStructure_opticalActivity', CVDomain: 'OPTICAL_ACTIVITY',
   displayName: 'Display Structure Optical Activity',
    dataType: 'string', luceneQueryable: true},
'Display Structure Stereo Centers': {fieldName: 'Total Stereocenters Count',
    fieldPath: '/polymer/displayStructure/stereoCenters', finalField: 'stereoCenters',
    lucenePath: 'root_polymer_displayStructure_stereoCenters', CVDomain: '',
   displayName: 'Display Structure Stereo Centers',
    dataType: 'number', luceneQueryable: true},
'Display Structure Stereochemistry': {fieldName: 'Stereochemistry',
    fieldPath: '/polymer/displayStructure/stereochemistry', finalField: 'stereochemistry',
    lucenePath: 'root_polymer_displayStructure_stereochemistry', CVDomain: '',
   displayName: 'Display Structure Stereochemistry',
    dataType: 'string', luceneQueryable: true},
'Idealized Structure Access': {fieldName: 'Access',
    fieldPath: '/polymer/idealizedStructure/access', finalField: 'access',
    lucenePath: 'root_polymer_idealizedStructure_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Idealized Structure Access',
    dataType: 'array <string>', luceneQueryable: true},
'Idealized Structure Charge': {fieldName: 'Charge',
    fieldPath: '/polymer/idealizedStructure/charge', finalField: 'charge',
    lucenePath: 'root_polymer_idealizedStructure_charge', CVDomain: '',
   displayName: 'Idealized Structure Charge',
    dataType: 'number', luceneQueryable: true},
'Idealized Structure Count': {fieldName: 'Count',
    fieldPath: '/polymer/idealizedStructure/count', finalField: 'count',
    lucenePath: 'root_polymer_idealizedStructure_count', CVDomain: '',
   displayName: 'Idealized Structure Count',
    dataType: 'number', luceneQueryable: true},
'Idealized Structure Created': {fieldName: 'Created',
    fieldPath: '/polymer/idealizedStructure/created', finalField: 'created',
    lucenePath: 'root_polymer_idealizedStructure_created', CVDomain: '',
   displayName: 'Idealized Structure Created',
    dataType: 'number', luceneQueryable: true},
'Idealized Structure Created By': {fieldName: 'Created By',
    fieldPath: '/polymer/idealizedStructure/createdBy', finalField: 'createdBy',
    lucenePath: 'root_polymer_idealizedStructure_createdBy', CVDomain: '',
   displayName: 'Idealized Structure Created By',
    dataType: 'string', luceneQueryable: true},
'Idealized Structure Defined Stereo': {fieldName: 'Defined Stereocenters Count',
    fieldPath: '/polymer/idealizedStructure/definedStereo', finalField: 'definedStereo',
    lucenePath: 'root_polymer_idealizedStructure_definedStereo', CVDomain: '',
   displayName: 'Idealized Structure Defined Stereo',
    dataType: 'number', luceneQueryable: true},
'Idealized Structure Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/polymer/idealizedStructure/deprecated', finalField: 'deprecated',
    lucenePath: 'root_polymer_idealizedStructure_deprecated', CVDomain: '',
   displayName: 'Idealized Structure Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Idealized Structure Digest': {fieldName: 'Digest',
    fieldPath: '/polymer/idealizedStructure/digest', finalField: 'digest',
    lucenePath: 'root_polymer_idealizedStructure_digest', CVDomain: '',
   displayName: 'Idealized Structure Digest',
    dataType: 'string', luceneQueryable: true},
'Idealized Structure Ez Centers': {fieldName: 'E/Z Center Count',
    fieldPath: '/polymer/idealizedStructure/ezCenters', finalField: 'ezCenters',
    lucenePath: 'root_polymer_idealizedStructure_ezCenters', CVDomain: '',
   displayName: 'Idealized Structure Ez Centers',
    dataType: 'number', luceneQueryable: true},
'Idealized Structure Formula': {fieldName: 'Formula',
    fieldPath: '/polymer/idealizedStructure/formula', finalField: 'formula',
    lucenePath: 'root_polymer_idealizedStructure_formula', CVDomain: '',
   displayName: 'Idealized Structure Formula',
    dataType: 'string', luceneQueryable: true},
'Idealized Structure Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/polymer/idealizedStructure/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_polymer_idealizedStructure_lastEdited', CVDomain: '',
   displayName: 'Idealized Structure Last Edited',
    dataType: 'number', luceneQueryable: true},
'Idealized Structure Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/polymer/idealizedStructure/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_polymer_idealizedStructure_lastEditedBy', CVDomain: '',
   displayName: 'Idealized Structure Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Idealized Structure Mwt': {fieldName: 'Molecular Weight',
    fieldPath: '/polymer/idealizedStructure/mwt', finalField: 'mwt',
    lucenePath: 'root_polymer_idealizedStructure_mwt', CVDomain: '',
   displayName: 'Idealized Structure Mwt',
    dataType: 'number', luceneQueryable: true},
'Idealized Structure Optical Activity': {fieldName: 'Optical Activity',
    fieldPath: '/polymer/idealizedStructure/opticalActivity', finalField: 'opticalActivity',
    lucenePath: 'root_polymer_idealizedStructure_opticalActivity', CVDomain: 'OPTICAL_ACTIVITY',
   displayName: 'Idealized Structure Optical Activity',
    dataType: 'string', luceneQueryable: true},
'Idealized Structure Stereo Centers': {fieldName: 'Total Stereocenters Count',
    fieldPath: '/polymer/idealizedStructure/stereoCenters', finalField: 'stereoCenters',
    lucenePath: 'root_polymer_idealizedStructure_stereoCenters', CVDomain: '',
   displayName: 'Idealized Structure Stereo Centers',
    dataType: 'number', luceneQueryable: true},
'Idealized Structure Stereochemistry': {fieldName: 'Stereochemistry',
    fieldPath: '/polymer/idealizedStructure/stereochemistry', finalField: 'stereochemistry',
    lucenePath: 'root_polymer_idealizedStructure_stereochemistry', CVDomain: '',
   displayName: 'Idealized Structure Stereochemistry',
    dataType: 'string', luceneQueryable: true},
'Polymer Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/polymer/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_polymer_lastEdited', CVDomain: '',
   displayName: 'Polymer Last Edited',
    dataType: 'number', luceneQueryable: true},
'Polymer Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/polymer/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_polymer_lastEditedBy', CVDomain: '',
   displayName: 'Polymer Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Monomer Access': {fieldName: 'Access',
    fieldPath: '/polymer/monomers/?/access', finalField: 'access',
    lucenePath: 'root_polymer_monomers_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Monomer Access',
    dataType: 'array <string>', luceneQueryable: true},
'Monomer Amount Access': {fieldName: 'Access',
    fieldPath: '/polymer/monomers/?/amount/access', finalField: 'access',
    lucenePath: 'root_polymer_monomers_amount_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Monomer Amount Access',
    dataType: 'array <string>', luceneQueryable: true},
'Monomer Amount Average': {fieldName: 'Average',
    fieldPath: '/polymer/monomers/?/amount/average', finalField: 'average',
    lucenePath: 'root_polymer_monomers_amount_average', CVDomain: '',
   displayName: 'Monomer Amount Average',
    dataType: 'number', luceneQueryable: true},
'Monomer Amount Created': {fieldName: 'Created',
    fieldPath: '/polymer/monomers/?/amount/created', finalField: 'created',
    lucenePath: 'root_polymer_monomers_amount_created', CVDomain: '',
   displayName: 'Monomer Amount Created',
    dataType: 'number', luceneQueryable: true},
'Monomer Amount Created By': {fieldName: 'Created By',
    fieldPath: '/polymer/monomers/?/amount/createdBy', finalField: 'createdBy',
    lucenePath: 'root_polymer_monomers_amount_createdBy', CVDomain: '',
   displayName: 'Monomer Amount Created By',
    dataType: 'string', luceneQueryable: true},
'Monomer Amount Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/polymer/monomers/?/amount/deprecated', finalField: 'deprecated',
    lucenePath: 'root_polymer_monomers_amount_deprecated', CVDomain: '',
   displayName: 'Monomer Amount Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Monomer Amount High': {fieldName: 'High',
    fieldPath: '/polymer/monomers/?/amount/high', finalField: 'high',
    lucenePath: 'root_polymer_monomers_amount_high', CVDomain: '',
   displayName: 'Monomer Amount High',
    dataType: 'number', luceneQueryable: true},
'Monomer Amount High Limit': {fieldName: 'High Limit',
    fieldPath: '/polymer/monomers/?/amount/highLimit', finalField: 'highLimit',
    lucenePath: 'root_polymer_monomers_amount_highLimit', CVDomain: '',
   displayName: 'Monomer Amount High Limit',
    dataType: 'number', luceneQueryable: true},
'Monomer Amount Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/polymer/monomers/?/amount/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_polymer_monomers_amount_lastEdited', CVDomain: '',
   displayName: 'Monomer Amount Last Edited',
    dataType: 'number', luceneQueryable: true},
'Monomer Amount Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/polymer/monomers/?/amount/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_polymer_monomers_amount_lastEditedBy', CVDomain: '',
   displayName: 'Monomer Amount Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Monomer Amount Low': {fieldName: 'Low',
    fieldPath: '/polymer/monomers/?/amount/low', finalField: 'low',
    lucenePath: 'root_polymer_monomers_amount_low', CVDomain: '',
   displayName: 'Monomer Amount Low',
    dataType: 'number', luceneQueryable: true},
'Monomer Amount Low Limit': {fieldName: 'Low Limit',
    fieldPath: '/polymer/monomers/?/amount/lowLimit', finalField: 'lowLimit',
    lucenePath: 'root_polymer_monomers_amount_lowLimit', CVDomain: '',
   displayName: 'Monomer Amount Low Limit',
    dataType: 'number', luceneQueryable: true},
'Monomer Amount Non-Numeric Value': {fieldName: 'Non Numeric Value',
    fieldPath: '/polymer/monomers/?/amount/nonNumericValue', finalField: 'nonNumericValue',
    lucenePath: 'root_polymer_monomers_amount_nonNumericValue', CVDomain: '',
   displayName: 'Monomer Amount Non-Numeric Value',
    dataType: 'string', luceneQueryable: true},
'Monomer Amount Type': {fieldName: 'Type',
    fieldPath: '/polymer/monomers/?/amount/type', finalField: 'type',
    lucenePath: 'root_polymer_monomers_amount_type', CVDomain: 'AMOUNT_TYPE',
   displayName: 'Monomer Amount Type',
    dataType: 'string', luceneQueryable: true},
'Monomer Amount Units': {fieldName: 'Units',
    fieldPath: '/polymer/monomers/?/amount/units', finalField: 'units',
    lucenePath: 'root_polymer_monomers_amount_units', CVDomain: 'AMOUNT_UNIT',
   displayName: 'Monomer Amount Units',
    dataType: 'string', luceneQueryable: true},
'Monomer Created': {fieldName: 'Created',
    fieldPath: '/polymer/monomers/?/created', finalField: 'created',
    lucenePath: 'root_polymer_monomers_created', CVDomain: '',
   displayName: 'Monomer Created',
    dataType: 'number', luceneQueryable: true},
'Monomer Created By': {fieldName: 'Created By',
    fieldPath: '/polymer/monomers/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_polymer_monomers_createdBy', CVDomain: '',
   displayName: 'Monomer Created By',
    dataType: 'string', luceneQueryable: true},
'Monomer Defining': {fieldName: 'Defining',
    fieldPath: '/polymer/monomers/?/defining', finalField: 'defining',
    lucenePath: 'root_polymer_monomers_defining', CVDomain: '',
   displayName: 'Monomer Defining',
    dataType: 'boolean', luceneQueryable: true},
'Monomer Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/polymer/monomers/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_polymer_monomers_deprecated', CVDomain: '',
   displayName: 'Monomer Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Monomer Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/polymer/monomers/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_polymer_monomers_lastEdited', CVDomain: '',
   displayName: 'Monomer Last Edited',
    dataType: 'number', luceneQueryable: true},
'Monomer Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/polymer/monomers/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_polymer_monomers_lastEditedBy', CVDomain: '',
   displayName: 'Monomer Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Monomer Substance Access': {fieldName: 'Access',
    fieldPath: '/polymer/monomers/?/monomerSubstance/access', finalField: 'access',
    lucenePath: 'root_polymer_monomers_monomerSubstance_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Monomer Substance Access',
    dataType: 'array <string>', luceneQueryable: true},
'Monomer Approval ID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/polymer/monomers/?/monomerSubstance/approvalID', finalField: 'approvalID',
    lucenePath: 'root_polymer_monomers_monomerSubstance_approvalID', CVDomain: '',
   displayName: 'Monomer Approval ID',
    dataType: 'string', luceneQueryable: true},
'Monomer Substance Created': {fieldName: 'Created',
    fieldPath: '/polymer/monomers/?/monomerSubstance/created', finalField: 'created',
    lucenePath: 'root_polymer_monomers_monomerSubstance_created', CVDomain: '',
   displayName: 'Monomer Substance Created',
    dataType: 'number', luceneQueryable: true},
'Monomer Substance Created By': {fieldName: 'Created By',
    fieldPath: '/polymer/monomers/?/monomerSubstance/createdBy', finalField: 'createdBy',
    lucenePath: 'root_polymer_monomers_monomerSubstance_createdBy', CVDomain: '',
   displayName: 'Monomer Substance Created By',
    dataType: 'string', luceneQueryable: true},
'Monomer Substance Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/polymer/monomers/?/monomerSubstance/deprecated', finalField: 'deprecated',
    lucenePath: 'root_polymer_monomers_monomerSubstance_deprecated', CVDomain: '',
   displayName: 'Monomer Substance Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Monomer Substance Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/polymer/monomers/?/monomerSubstance/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_polymer_monomers_monomerSubstance_lastEdited', CVDomain: '',
   displayName: 'Monomer Substance Last Edited',
    dataType: 'number', luceneQueryable: true},
'Monomer Substance Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/polymer/monomers/?/monomerSubstance/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_polymer_monomers_monomerSubstance_lastEditedBy', CVDomain: '',
   displayName: 'Monomer Substance Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Monomer Substance Name': {fieldName: 'Ref Pname',
    fieldPath: '/polymer/monomers/?/monomerSubstance/refPname', finalField: 'refPname',
    lucenePath: 'root_polymer_monomers_monomerSubstance_refPname', CVDomain: '',
   displayName: 'Monomer Substance Name',
    dataType: 'string', luceneQueryable: true},
'Monomer Substance Refuuid': {fieldName: 'Refuuid',
    fieldPath: '/polymer/monomers/?/monomerSubstance/refuuid', finalField: 'refuuid',
    lucenePath: 'root_polymer_monomers_monomerSubstance_refuuid', CVDomain: '',
   displayName: 'Monomer Substance Refuuid',
    dataType: 'string', luceneQueryable: true},
'Monomer Substance Substance Class': {fieldName: 'Substance Class',
    fieldPath: '/polymer/monomers/?/monomerSubstance/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_polymer_monomers_monomerSubstance_substanceClass', CVDomain: '',
   displayName: 'Monomer Substance Substance Class',
    dataType: 'string', luceneQueryable: true},
'Monomer Type': {fieldName: 'Type',
    fieldPath: '/polymer/monomers/?/type', finalField: 'type',
    lucenePath: 'root_polymer_monomers_type', CVDomain: 'MONOMER_TYPE',
   displayName: 'Monomer Type',
    dataType: 'string', luceneQueryable: true},
'SRU Access': {fieldName: 'Access',
    fieldPath: '/polymer/structuralUnits/?/access', finalField: 'access',
    lucenePath: 'root_polymer_structuralUnits_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'SRU Access',
    dataType: 'array <string>', luceneQueryable: true},
'SRU Amount Access': {fieldName: 'Access',
    fieldPath: '/polymer/structuralUnits/?/amount/access', finalField: 'access',
    lucenePath: 'root_polymer_structuralUnits_amount_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'SRU Amount Access',
    dataType: 'array <string>', luceneQueryable: true},
'SRU Amount Average': {fieldName: 'Average',
    fieldPath: '/polymer/structuralUnits/?/amount/average', finalField: 'average',
    lucenePath: 'root_polymer_structuralUnits_amount_average', CVDomain: '',
   displayName: 'SRU Amount Average',
    dataType: 'number', luceneQueryable: true},
'SRU Amount Created': {fieldName: 'Created',
    fieldPath: '/polymer/structuralUnits/?/amount/created', finalField: 'created',
    lucenePath: 'root_polymer_structuralUnits_amount_created', CVDomain: '',
   displayName: 'SRU Amount Created',
    dataType: 'number', luceneQueryable: true},
'SRU Amount Created By': {fieldName: 'Created By',
    fieldPath: '/polymer/structuralUnits/?/amount/createdBy', finalField: 'createdBy',
    lucenePath: 'root_polymer_structuralUnits_amount_createdBy', CVDomain: '',
   displayName: 'SRU Amount Created By',
    dataType: 'string', luceneQueryable: true},
'SRU Amount Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/polymer/structuralUnits/?/amount/deprecated', finalField: 'deprecated',
    lucenePath: 'root_polymer_structuralUnits_amount_deprecated', CVDomain: '',
   displayName: 'SRU Amount Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'SRU Amount High': {fieldName: 'High',
    fieldPath: '/polymer/structuralUnits/?/amount/high', finalField: 'high',
    lucenePath: 'root_polymer_structuralUnits_amount_high', CVDomain: '',
   displayName: 'SRU Amount High',
    dataType: 'number', luceneQueryable: true},
'SRU Amount High Limit': {fieldName: 'High Limit',
    fieldPath: '/polymer/structuralUnits/?/amount/highLimit', finalField: 'highLimit',
    lucenePath: 'root_polymer_structuralUnits_amount_highLimit', CVDomain: '',
   displayName: 'SRU Amount High Limit',
    dataType: 'number', luceneQueryable: true},
'SRU Amount Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/polymer/structuralUnits/?/amount/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_polymer_structuralUnits_amount_lastEdited', CVDomain: '',
   displayName: 'SRU Amount Last Edited',
    dataType: 'number', luceneQueryable: true},
'SRU Amount Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/polymer/structuralUnits/?/amount/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_polymer_structuralUnits_amount_lastEditedBy', CVDomain: '',
   displayName: 'SRU Amount Last Edited By',
    dataType: 'string', luceneQueryable: true},
'SRU Amount Low': {fieldName: 'Low',
    fieldPath: '/polymer/structuralUnits/?/amount/low', finalField: 'low',
    lucenePath: 'root_polymer_structuralUnits_amount_low', CVDomain: '',
   displayName: 'SRU Amount Low',
    dataType: 'number', luceneQueryable: true},
'SRU Amount Low Limit': {fieldName: 'Low Limit',
    fieldPath: '/polymer/structuralUnits/?/amount/lowLimit', finalField: 'lowLimit',
    lucenePath: 'root_polymer_structuralUnits_amount_lowLimit', CVDomain: '',
   displayName: 'SRU Amount Low Limit',
    dataType: 'number', luceneQueryable: true},
'SRU Amount Non-Numeric Value': {fieldName: 'Non Numeric Value',
    fieldPath: '/polymer/structuralUnits/?/amount/nonNumericValue', finalField: 'nonNumericValue',
    lucenePath: 'root_polymer_structuralUnits_amount_nonNumericValue', CVDomain: '',
   displayName: 'SRU Amount Non-Numeric Value',
    dataType: 'string', luceneQueryable: true},
'SRU Amount Type': {fieldName: 'Type',
    fieldPath: '/polymer/structuralUnits/?/amount/type', finalField: 'type',
    lucenePath: 'root_polymer_structuralUnits_amount_type', CVDomain: 'AMOUNT_TYPE',
   displayName: 'SRU Amount Type',
    dataType: 'string', luceneQueryable: true},
'SRU Amount Units': {fieldName: 'Units',
    fieldPath: '/polymer/structuralUnits/?/amount/units', finalField: 'units',
    lucenePath: 'root_polymer_structuralUnits_amount_units', CVDomain: 'AMOUNT_UNIT',
   displayName: 'SRU Amount Units',
    dataType: 'string', luceneQueryable: true},
'SRU Attachment Count': {fieldName: 'Attachment Count',
    fieldPath: '/polymer/structuralUnits/?/attachmentCount', finalField: 'attachmentCount',
    lucenePath: 'root_polymer_structuralUnits_attachmentCount', CVDomain: '',
   displayName: 'SRU Attachment Count',
    dataType: 'number', luceneQueryable: true},
'SRU Created': {fieldName: 'Created',
    fieldPath: '/polymer/structuralUnits/?/created', finalField: 'created',
    lucenePath: 'root_polymer_structuralUnits_created', CVDomain: '',
   displayName: 'SRU Created',
    dataType: 'number', luceneQueryable: true},
'SRU Created By': {fieldName: 'Created By',
    fieldPath: '/polymer/structuralUnits/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_polymer_structuralUnits_createdBy', CVDomain: '',
   displayName: 'SRU Created By',
    dataType: 'string', luceneQueryable: true},
'SRU Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/polymer/structuralUnits/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_polymer_structuralUnits_deprecated', CVDomain: '',
   displayName: 'SRU Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'SRU Label': {fieldName: 'Label',
    fieldPath: '/polymer/structuralUnits/?/label', finalField: 'label',
    lucenePath: 'root_polymer_structuralUnits_label', CVDomain: '',
   displayName: 'SRU Label',
    dataType: 'string', luceneQueryable: true},
'SRU Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/polymer/structuralUnits/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_polymer_structuralUnits_lastEdited', CVDomain: '',
   displayName: 'SRU Last Edited',
    dataType: 'number', luceneQueryable: true},
'SRU Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/polymer/structuralUnits/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_polymer_structuralUnits_lastEditedBy', CVDomain: '',
   displayName: 'SRU Last Edited By',
    dataType: 'string', luceneQueryable: true},
'SRU Structure': {fieldName: 'structure',
    fieldPath: '/polymer/structuralUnits/?/structure', finalField: 'structure',
    lucenePath: 'root_polymer_structuralUnits_structure', CVDomain: '',
   displayName: 'SRU Structure',
    dataType: 'string', luceneQueryable: true},
'SRU Type': {fieldName: 'Type',
    fieldPath: '/polymer/structuralUnits/?/type', finalField: 'type',
    lucenePath: 'root_polymer_structuralUnits_type', CVDomain: 'POLYMER_SRU_TYPE',
   displayName: 'SRU Type',
    dataType: 'string', luceneQueryable: true},
'Property Access': {fieldName: 'Access',
    fieldPath: '/properties/?/access', finalField: 'access',
    lucenePath: 'root_properties_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Property Access',
    dataType: 'array <string>', luceneQueryable: true},
'Property Created': {fieldName: 'Created',
    fieldPath: '/properties/?/created', finalField: 'created',
    lucenePath: 'root_properties_created', CVDomain: '',
   displayName: 'Property Created',
    dataType: 'number', luceneQueryable: true},
'Property Created By': {fieldName: 'Created By',
    fieldPath: '/properties/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_properties_createdBy', CVDomain: '',
   displayName: 'Property Created By',
    dataType: 'string', luceneQueryable: true},
'Property Defining': {fieldName: 'Defining',
    fieldPath: '/properties/?/defining', finalField: 'defining',
    lucenePath: 'root_properties_defining', CVDomain: '',
   displayName: 'Property Defining',
    dataType: 'boolean', luceneQueryable: true},
'Property Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/properties/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_properties_deprecated', CVDomain: '',
   displayName: 'Property Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Property Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/properties/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_properties_lastEdited', CVDomain: '',
   displayName: 'Property Last Edited',
    dataType: 'number', luceneQueryable: true},
'Property Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/properties/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_properties_lastEditedBy', CVDomain: '',
   displayName: 'Property Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Property Name': {fieldName: 'Property Name',
    fieldPath: '/properties/?/name', finalField: 'name',
    lucenePath: 'root_properties_name', CVDomain: 'PROPERTY_NAME',
   displayName: 'Property Name',
    dataType: 'string', luceneQueryable: true},
'Property Parameters': {fieldName: 'Property Parameters',
    fieldPath: '/properties/?/parameters', finalField: 'parameters',
    lucenePath: 'root_properties_parameters', CVDomain: '',
   displayName: 'Property Parameters',
    dataType: 'array <PropertyParameter>', luceneQueryable: true},
'Parameter Access': {fieldName: 'Access',
    fieldPath: '/properties/?/parameters/?/access', finalField: 'access',
    lucenePath: 'root_properties_parameters_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Parameter Access',
    dataType: 'array <string>', luceneQueryable: true},
'Parameter Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/properties/?/parameters/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_properties_parameters_deprecated', CVDomain: '',
   displayName: 'Parameter Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Parameter Value Access': {fieldName: 'Access',
    fieldPath: '/properties/?/parameters/?/value/access', finalField: 'access',
    lucenePath: 'root_properties_parameters_value_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Parameter Value Access',
    dataType: 'array <string>', luceneQueryable: true},
'Parameter Value Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/properties/?/parameters/?/value/deprecated', finalField: 'deprecated',
    lucenePath: 'root_properties_parameters_value_deprecated', CVDomain: '',
   displayName: 'Parameter Value Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Property Property Type': {fieldName: 'Property Type',
    fieldPath: '/properties/?/propertyType', finalField: 'propertyType',
    lucenePath: 'root_properties_propertyType', CVDomain: 'PROPERTY_TYPE',
   displayName: 'Property Property Type',
    dataType: 'string', luceneQueryable: true},
'Property Value': {fieldName: 'Property Value',
    fieldPath: '/properties/?/value', finalField: 'value',
    lucenePath: 'root_properties_value', CVDomain: '',
   displayName: 'Property Value',
    dataType: 'object <Amount>', luceneQueryable: true},
'Value Access': {fieldName: 'Access',
    fieldPath: '/properties/?/value/access', finalField: 'access',
    lucenePath: 'root_properties_value_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Value Access',
    dataType: 'array <string>', luceneQueryable: true},
'Value Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/properties/?/value/deprecated', finalField: 'deprecated',
    lucenePath: 'root_properties_value_deprecated', CVDomain: '',
   displayName: 'Value Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Protein Access': {fieldName: 'Access',
    fieldPath: '/protein/access', finalField: 'access',
    lucenePath: 'root_protein_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Protein Access',
    dataType: 'array <string>', luceneQueryable: true},
'Protein Created': {fieldName: 'Created',
    fieldPath: '/protein/created', finalField: 'created',
    lucenePath: 'root_protein_created', CVDomain: '',
   displayName: 'Protein Created',
    dataType: 'number', luceneQueryable: true},
'Protein Created By': {fieldName: 'Created By',
    fieldPath: '/protein/createdBy', finalField: 'createdBy',
    lucenePath: 'root_protein_createdBy', CVDomain: '',
   displayName: 'Protein Created By',
    dataType: 'string', luceneQueryable: true},
'Protein Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/protein/deprecated', finalField: 'deprecated',
    lucenePath: 'root_protein_deprecated', CVDomain: '',
   displayName: 'Protein Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Protein Glycosylation Access': {fieldName: 'Access',
    fieldPath: '/protein/glycosylation/access', finalField: 'access',
    lucenePath: 'root_protein_glycosylation_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Protein Glycosylation Access',
    dataType: 'array <string>', luceneQueryable: true},
'Protein Glycosylation Created': {fieldName: 'Created',
    fieldPath: '/protein/glycosylation/created', finalField: 'created',
    lucenePath: 'root_protein_glycosylation_created', CVDomain: '',
   displayName: 'Protein Glycosylation Created',
    dataType: 'number', luceneQueryable: true},
'Protein Glycosylation Created By': {fieldName: 'Created By',
    fieldPath: '/protein/glycosylation/createdBy', finalField: 'createdBy',
    lucenePath: 'root_protein_glycosylation_createdBy', CVDomain: '',
   displayName: 'Protein Glycosylation Created By',
    dataType: 'string', luceneQueryable: true},
'Protein Glycosylation Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/protein/glycosylation/deprecated', finalField: 'deprecated',
    lucenePath: 'root_protein_glycosylation_deprecated', CVDomain: '',
   displayName: 'Protein Glycosylation Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Protein Glycosylation Type': {fieldName: 'Glycosylation Type',
    fieldPath: '/protein/glycosylation/glycosylationType', finalField: 'glycosylationType',
    lucenePath: 'root_protein_glycosylation_glycosylationType', CVDomain: 'GLYCOSYLATION_TYPE',
   displayName: 'Protein Glycosylation Type',
    dataType: 'string', luceneQueryable: true},
'Protein Glycosylation Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/protein/glycosylation/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_protein_glycosylation_lastEdited', CVDomain: '',
   displayName: 'Protein Glycosylation Last Edited',
    dataType: 'number', luceneQueryable: true},
'Protein Glycosylation Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/protein/glycosylation/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_protein_glycosylation_lastEditedBy', CVDomain: '',
   displayName: 'Protein Glycosylation Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Protein Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/protein/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_protein_lastEdited', CVDomain: '',
   displayName: 'Protein Last Edited',
    dataType: 'number', luceneQueryable: true},
'Protein Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/protein/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_protein_lastEditedBy', CVDomain: '',
   displayName: 'Protein Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Other Link Access': {fieldName: 'Access',
    fieldPath: '/protein/otherLinks/?/access', finalField: 'access',
    lucenePath: 'root_protein_otherLinks_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Other Link Access',
    dataType: 'array <string>', luceneQueryable: true},
'Other Link Created': {fieldName: 'Created',
    fieldPath: '/protein/otherLinks/?/created', finalField: 'created',
    lucenePath: 'root_protein_otherLinks_created', CVDomain: '',
   displayName: 'Other Link Created',
    dataType: 'number', luceneQueryable: true},
'Other Link Created By': {fieldName: 'Created By',
    fieldPath: '/protein/otherLinks/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_protein_otherLinks_createdBy', CVDomain: '',
   displayName: 'Other Link Created By',
    dataType: 'string', luceneQueryable: true},
'Other Link Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/protein/otherLinks/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_protein_otherLinks_deprecated', CVDomain: '',
   displayName: 'Other Link Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Other Link Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/protein/otherLinks/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_protein_otherLinks_lastEdited', CVDomain: '',
   displayName: 'Other Link Last Edited',
    dataType: 'number', luceneQueryable: true},
'Other Link Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/protein/otherLinks/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_protein_otherLinks_lastEditedBy', CVDomain: '',
   displayName: 'Other Link Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Other Link Linkagetype': {fieldName: 'Linkage Type',
    fieldPath: '/protein/otherLinks/?/linkageType', finalField: 'linkageType',
    lucenePath: 'root_protein_otherLinks_linkageType', CVDomain: 'OTHER_LINKAGE_TYPE',
   displayName: 'Other Link Linkagetype',
    dataType: 'string', luceneQueryable: true},
'Protein Protein Subtype': {fieldName: 'Protein Sub Type',
    fieldPath: '/protein/proteinSubType', finalField: 'proteinSubType',
    lucenePath: 'root_protein_proteinSubType', CVDomain: 'PROTEIN_SUBTYPE',
   displayName: 'Protein Protein Subtype',
    dataType: 'string', luceneQueryable: true},
'Protein Proteintype': {fieldName: 'Protein Type',
    fieldPath: '/protein/proteinType', finalField: 'proteinType',
    lucenePath: 'root_protein_proteinType', CVDomain: 'PROTEIN_TYPE',
   displayName: 'Protein Proteintype',
    dataType: 'string', luceneQueryable: true},
'Protein Sequence Origin': {fieldName: 'Sequence Origin',
    fieldPath: '/protein/sequenceOrigin', finalField: 'sequenceOrigin',
    lucenePath: 'root_protein_sequenceOrigin', CVDomain: 'SEQUENCE_ORIGIN',
   displayName: 'Protein Sequence Origin',
    dataType: 'string', luceneQueryable: true},
'Protein Sequence Type': {fieldName: 'Sequence Type',
    fieldPath: '/protein/sequenceType', finalField: 'sequenceType',
    lucenePath: 'root_protein_sequenceType', CVDomain: 'SEQUENCE_TYPE',
   displayName: 'Protein Sequence Type',
    dataType: 'string', luceneQueryable: true},
'Protein Subunit Access': {fieldName: 'Access',
    fieldPath: '/protein/subunits/?/access', finalField: 'access',
    lucenePath: 'root_protein_subunits_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Protein Subunit Access',
    dataType: 'array <string>', luceneQueryable: true},
'Protein Subunit Created': {fieldName: 'Created',
    fieldPath: '/protein/subunits/?/created', finalField: 'created',
    lucenePath: 'root_protein_subunits_created', CVDomain: '',
   displayName: 'Protein Subunit Created',
    dataType: 'number', luceneQueryable: true},
'Protein Subunit Created By': {fieldName: 'Created By',
    fieldPath: '/protein/subunits/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_protein_subunits_createdBy', CVDomain: '',
   displayName: 'Protein Subunit Created By',
    dataType: 'string', luceneQueryable: true},
'Protein Subunit Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/protein/subunits/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_protein_subunits_deprecated', CVDomain: '',
   displayName: 'Protein Subunit Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Protein Subunit Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/protein/subunits/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_protein_subunits_lastEdited', CVDomain: '',
   displayName: 'Protein Subunit Last Edited',
    dataType: 'number', luceneQueryable: true},
'Protein Subunit Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/protein/subunits/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_protein_subunits_lastEditedBy', CVDomain: '',
   displayName: 'Protein Subunit Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Protein Sequence': {fieldName: 'Sequence',
    fieldPath: '/protein/subunits/?/sequence', finalField: 'sequence',
    lucenePath: 'root_protein_subunits_sequence', CVDomain: '',
   displayName: 'Protein Sequence',
    dataType: 'string', luceneQueryable: true},
'Protein Subunit Subunit Index': {fieldName: 'Subunit Index',
    fieldPath: '/protein/subunits/?/subunitIndex', finalField: 'subunitIndex',
    lucenePath: 'root_protein_subunits_subunitIndex', CVDomain: '',
   displayName: 'Protein Subunit Subunit Index',
    dataType: 'number', luceneQueryable: true},
'Reference Access': {fieldName: 'Access',
    fieldPath: '/references/?/access', finalField: 'access',
    lucenePath: 'root_references_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Reference Access',
    dataType: 'array <string>', luceneQueryable: true},
'Reference Text / Citation': {fieldName: 'CITATION',
    fieldPath: '/references/?/citation', finalField: 'citation',
    lucenePath: 'root_references_citation', CVDomain: '',
   displayName: 'Reference Text / Citation',
    dataType: 'string', luceneQueryable: true},
'Reference Created': {fieldName: 'Created',
    fieldPath: '/references/?/created', finalField: 'created',
    lucenePath: 'root_references_created', CVDomain: '',
   displayName: 'Reference Created',
    dataType: 'number', luceneQueryable: true},
'Reference Created By': {fieldName: 'Created By',
    fieldPath: '/references/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_references_createdBy', CVDomain: '',
   displayName: 'Reference Created By',
    dataType: 'string', luceneQueryable: true},
'Reference Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/references/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_references_deprecated', CVDomain: '',
   displayName: 'Reference Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Reference Type': {fieldName: 'Doc Type',
    fieldPath: '/references/?/docType', finalField: 'docType',
    lucenePath: 'root_references_docType', CVDomain: 'DOCUMENT_TYPE',
   displayName: 'Reference Type',
    dataType: 'string', luceneQueryable: true},
'Reference Document Date': {fieldName: 'Document Date',
    fieldPath: '/references/?/documentDate', finalField: 'documentDate',
    lucenePath: 'root_references_documentDate', CVDomain: '',
   displayName: 'Reference Document Date',
    dataType: 'number', luceneQueryable: true},
'Reference ID': {fieldName: 'Id',
    fieldPath: '/references/?/id', finalField: 'id',
    lucenePath: 'root_references_id', CVDomain: '',
   displayName: 'Reference ID',
    dataType: 'string', luceneQueryable: true},
'Reference Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/references/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_references_lastEdited', CVDomain: '',
   displayName: 'Reference Last Edited',
    dataType: 'number', luceneQueryable: true},
'Reference Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/references/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_references_lastEditedBy', CVDomain: '',
   displayName: 'Reference Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Reference Public Domain': {fieldName: 'Public Domain',
    fieldPath: '/references/?/publicDomain', finalField: 'publicDomain',
    lucenePath: 'root_references_publicDomain', CVDomain: '',
   displayName: 'Reference Public Domain',
    dataType: 'boolean', luceneQueryable: true},
'Reference Uploaded File': {fieldName: 'Uploaded File',
    fieldPath: '/references/?/uploadedFile', finalField: 'uploadedFile',
    lucenePath: 'root_references_uploadedFile', CVDomain: '',
   displayName: 'Reference Uploaded File',
    dataType: 'string', luceneQueryable: true},
'Reference URL': {fieldName: 'Url',
    fieldPath: '/references/?/url', finalField: 'url',
    lucenePath: 'root_references_url', CVDomain: '',
   displayName: 'Reference URL',
    dataType: 'string', luceneQueryable: true},
'Relationship Access': {fieldName: 'Access',
    fieldPath: '/relationships/?/access', finalField: 'access',
    lucenePath: 'root_relationships_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Relationship Access',
    dataType: 'array <string>', luceneQueryable: true},
'Relationship Amount Access': {fieldName: 'Access',
    fieldPath: '/relationships/?/amount/access', finalField: 'access',
    lucenePath: 'root_relationships_amount_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Relationship Amount Access',
    dataType: 'array <string>', luceneQueryable: true},
'Relationship Amount Average': {fieldName: 'Average',
    fieldPath: '/relationships/?/amount/average', finalField: 'average',
    lucenePath: 'root_relationships_amount_average', CVDomain: '',
   displayName: 'Relationship Amount Average',
    dataType: 'number', luceneQueryable: true},
'Relationship Amount Created': {fieldName: 'Created',
    fieldPath: '/relationships/?/amount/created', finalField: 'created',
    lucenePath: 'root_relationships_amount_created', CVDomain: '',
   displayName: 'Relationship Amount Created',
    dataType: 'number', luceneQueryable: true},
'Relationship Amount Created By': {fieldName: 'Created By',
    fieldPath: '/relationships/?/amount/createdBy', finalField: 'createdBy',
    lucenePath: 'root_relationships_amount_createdBy', CVDomain: '',
   displayName: 'Relationship Amount Created By',
    dataType: 'string', luceneQueryable: true},
'Relationship Amount Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/relationships/?/amount/deprecated', finalField: 'deprecated',
    lucenePath: 'root_relationships_amount_deprecated', CVDomain: '',
   displayName: 'Relationship Amount Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Relationship Amount High': {fieldName: 'High',
    fieldPath: '/relationships/?/amount/high', finalField: 'high',
    lucenePath: 'root_relationships_amount_high', CVDomain: '',
   displayName: 'Relationship Amount High',
    dataType: 'number', luceneQueryable: true},
'Relationship Amount High Limit': {fieldName: 'High Limit',
    fieldPath: '/relationships/?/amount/highLimit', finalField: 'highLimit',
    lucenePath: 'root_relationships_amount_highLimit', CVDomain: '',
   displayName: 'Relationship Amount High Limit',
    dataType: 'number', luceneQueryable: true},
'Relationship Amount Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/relationships/?/amount/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_relationships_amount_lastEdited', CVDomain: '',
   displayName: 'Relationship Amount Last Edited',
    dataType: 'number', luceneQueryable: true},
'Relationship Amount Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/relationships/?/amount/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_relationships_amount_lastEditedBy', CVDomain: '',
   displayName: 'Relationship Amount Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Relationship Amount Low': {fieldName: 'Low',
    fieldPath: '/relationships/?/amount/low', finalField: 'low',
    lucenePath: 'root_relationships_amount_low', CVDomain: '',
   displayName: 'Relationship Amount Low',
    dataType: 'number', luceneQueryable: true},
'Relationship Amount Low Limit': {fieldName: 'Low Limit',
    fieldPath: '/relationships/?/amount/lowLimit', finalField: 'lowLimit',
    lucenePath: 'root_relationships_amount_lowLimit', CVDomain: '',
   displayName: 'Relationship Amount Low Limit',
    dataType: 'number', luceneQueryable: true},
'Relationship Amount Non-Numeric Value': {fieldName: 'Non Numeric Value',
    fieldPath: '/relationships/?/amount/nonNumericValue', finalField: 'nonNumericValue',
    lucenePath: 'root_relationships_amount_nonNumericValue', CVDomain: '',
   displayName: 'Relationship Amount Non-Numeric Value',
    dataType: 'string', luceneQueryable: true},
'Relationship Amount Type': {fieldName: 'Type',
    fieldPath: '/relationships/?/amount/type', finalField: 'type',
    lucenePath: 'root_relationships_amount_type', CVDomain: 'AMOUNT_TYPE',
   displayName: 'Relationship Amount Type',
    dataType: 'string', luceneQueryable: true},
'Relationship Amount Units': {fieldName: 'Units',
    fieldPath: '/relationships/?/amount/units', finalField: 'units',
    lucenePath: 'root_relationships_amount_units', CVDomain: 'AMOUNT_UNIT',
   displayName: 'Relationship Amount Units',
    dataType: 'string', luceneQueryable: true},
'Relationship Comments': {fieldName: 'Comments',
    fieldPath: '/relationships/?/comments', finalField: 'comments',
    lucenePath: 'root_relationships_comments', CVDomain: '',
   displayName: 'Relationship Comments',
    dataType: 'string', luceneQueryable: true},
'Relationship Created': {fieldName: 'Created',
    fieldPath: '/relationships/?/created', finalField: 'created',
    lucenePath: 'root_relationships_created', CVDomain: '',
   displayName: 'Relationship Created',
    dataType: 'number', luceneQueryable: true},
'Relationship Created By': {fieldName: 'Created By',
    fieldPath: '/relationships/?/createdBy', finalField: 'createdBy',
    lucenePath: 'root_relationships_createdBy', CVDomain: '',
   displayName: 'Relationship Created By',
    dataType: 'string', luceneQueryable: true},
'Relationship Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/relationships/?/deprecated', finalField: 'deprecated',
    lucenePath: 'root_relationships_deprecated', CVDomain: '',
   displayName: 'Relationship Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Relationship Interaction Type': {fieldName: 'Interaction Type',
    fieldPath: '/relationships/?/interactionType', finalField: 'interactionType',
    lucenePath: 'root_relationships_interactionType', CVDomain: 'INTERACTION_TYPE',
   displayName: 'Relationship Interaction Type',
    dataType: 'string', luceneQueryable: true},
'Relationship Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/relationships/?/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_relationships_lastEdited', CVDomain: '',
   displayName: 'Relationship Last Edited',
    dataType: 'number', luceneQueryable: true},
'Relationship Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/relationships/?/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_relationships_lastEditedBy', CVDomain: '',
   displayName: 'Relationship Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Mediator Substance Access': {fieldName: 'Access',
    fieldPath: '/relationships/?/mediatorSubstance/access', finalField: 'access',
    lucenePath: 'root_relationships_mediatorSubstance_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Mediator Substance Access',
    dataType: 'array <string>', luceneQueryable: true},
'Mediator Substance Approval ID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/relationships/?/mediatorSubstance/approvalID', finalField: 'approvalID',
    lucenePath: 'root_relationships_mediatorSubstance_approvalID', CVDomain: '',
   displayName: 'Mediator Substance Approval ID',
    dataType: 'string', luceneQueryable: true},
'Mediator Substance Created': {fieldName: 'Created',
    fieldPath: '/relationships/?/mediatorSubstance/created', finalField: 'created',
    lucenePath: 'root_relationships_mediatorSubstance_created', CVDomain: '',
   displayName: 'Mediator Substance Created',
    dataType: 'number', luceneQueryable: true},
'Mediator Substance Created By': {fieldName: 'Created By',
    fieldPath: '/relationships/?/mediatorSubstance/createdBy', finalField: 'createdBy',
    lucenePath: 'root_relationships_mediatorSubstance_createdBy', CVDomain: '',
   displayName: 'Mediator Substance Created By',
    dataType: 'string', luceneQueryable: true},
'Mediator Substance Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/relationships/?/mediatorSubstance/deprecated', finalField: 'deprecated',
    lucenePath: 'root_relationships_mediatorSubstance_deprecated', CVDomain: '',
   displayName: 'Mediator Substance Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Mediator Substance Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/relationships/?/mediatorSubstance/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_relationships_mediatorSubstance_lastEdited', CVDomain: '',
   displayName: 'Mediator Substance Last Edited',
    dataType: 'number', luceneQueryable: true},
'Mediator Substance Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/relationships/?/mediatorSubstance/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_relationships_mediatorSubstance_lastEditedBy', CVDomain: '',
   displayName: 'Mediator Substance Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Mediator Substance Preferred Name': {fieldName: 'Ref Pname',
    fieldPath: '/relationships/?/mediatorSubstance/refPname', finalField: 'refPname',
    lucenePath: 'root_relationships_mediatorSubstance_refPname', CVDomain: '',
   displayName: 'Mediator Substance Preferred Name',
    dataType: 'string', luceneQueryable: true},
'Mediator Substance Refuuid': {fieldName: 'Refuuid',
    fieldPath: '/relationships/?/mediatorSubstance/refuuid', finalField: 'refuuid',
    lucenePath: 'root_relationships_mediatorSubstance_refuuid', CVDomain: '',
   displayName: 'Mediator Substance Refuuid',
    dataType: 'string', luceneQueryable: true},
'Mediator Substance Substance Class': {fieldName: 'Substance Class',
    fieldPath: '/relationships/?/mediatorSubstance/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_relationships_mediatorSubstance_substanceClass', CVDomain: '',
   displayName: 'Mediator Substance Substance Class',
    dataType: 'string', luceneQueryable: true},
'Relationship Originator UUID': {fieldName: 'Originator UUID',
    fieldPath: '/relationships/?/originatorUuid', finalField: 'originatorUuid',
    lucenePath: 'root_relationships_originatorUuid', CVDomain: '',
   displayName: 'Relationship Originator UUID',
    dataType: 'string', luceneQueryable: true},
'Relationship Qualification': {fieldName: 'Qualification',
    fieldPath: '/relationships/?/qualification', finalField: 'qualification',
    lucenePath: 'root_relationships_qualification', CVDomain: 'QUALIFICATION',
   displayName: 'Relationship Qualification',
    dataType: 'string', luceneQueryable: true},
'Related Substance Access': {fieldName: 'Access',
    fieldPath: '/relationships/?/relatedSubstance/access', finalField: 'access',
    lucenePath: 'root_relationships_relatedSubstance_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Related Substance Access',
    dataType: 'array <string>', luceneQueryable: true},
'Related Substance Approval ID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/relationships/?/relatedSubstance/approvalID', finalField: 'approvalID',
    lucenePath: 'root_relationships_relatedSubstance_approvalID', CVDomain: '',
   displayName: 'Related Substance Approval ID',
    dataType: 'string', luceneQueryable: true},
'Related Substance Created': {fieldName: 'Created',
    fieldPath: '/relationships/?/relatedSubstance/created', finalField: 'created',
    lucenePath: 'root_relationships_relatedSubstance_created', CVDomain: '',
   displayName: 'Related Substance Created',
    dataType: 'number', luceneQueryable: true},
'Related Substance Created By': {fieldName: 'Created By',
    fieldPath: '/relationships/?/relatedSubstance/createdBy', finalField: 'createdBy',
    lucenePath: 'root_relationships_relatedSubstance_createdBy', CVDomain: '',
   displayName: 'Related Substance Created By',
    dataType: 'string', luceneQueryable: true},
'Related Substance Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/relationships/?/relatedSubstance/deprecated', finalField: 'deprecated',
    lucenePath: 'root_relationships_relatedSubstance_deprecated', CVDomain: '',
   displayName: 'Related Substance Deprecated',
    dataType: 'boolean', luceneQueryable: true},
'Related Substance Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/relationships/?/relatedSubstance/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_relationships_relatedSubstance_lastEdited', CVDomain: '',
   displayName: 'Related Substance Last Edited',
    dataType: 'number', luceneQueryable: true},
'Related Substance Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/relationships/?/relatedSubstance/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_relationships_relatedSubstance_lastEditedBy', CVDomain: '',
   displayName: 'Related Substance Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Related Substance Name': {fieldName: 'Ref Pname',
    fieldPath: '/relationships/?/relatedSubstance/refPname', finalField: 'refPname',
    lucenePath: 'root_relationships_relatedSubstance_refPname', CVDomain: '',
   displayName: 'Related Substance Name',
    dataType: 'string', luceneQueryable: true},
'Related Substance Refuuid': {fieldName: 'Refuuid',
    fieldPath: '/relationships/?/relatedSubstance/refuuid', finalField: 'refuuid',
    lucenePath: 'root_relationships_relatedSubstance_refuuid', CVDomain: '',
   displayName: 'Related Substance Refuuid',
    dataType: 'string', luceneQueryable: true},
'Related Substance Substance Class': {fieldName: 'Substance Class',
    fieldPath: '/relationships/?/relatedSubstance/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_relationships_relatedSubstance_substanceClass', CVDomain: '',
   displayName: 'Related Substance Substance Class',
    dataType: 'string', luceneQueryable: true},
'Relationship Type': {fieldName: 'Type',
    fieldPath: '/relationships/?/type', finalField: 'type',
    lucenePath: 'root_relationships_type', CVDomain: 'RELATIONSHIP_TYPE',
   displayName: 'Relationship Type',
    dataType: 'string', luceneQueryable: true},
'Record Status': {fieldName: 'Status',
    fieldPath: '/status', finalField: 'status',
    lucenePath: 'root_status', CVDomain: '',
   displayName: 'Record Status',
    dataType: 'object <SpecifiedSubstance>', luceneQueryable: true},
'Structurally Diverse Access': {fieldName: 'Access',
    fieldPath: '/structurallyDiverse/access', finalField: 'access',
    lucenePath: 'root_structurallyDiverse_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Structurally Diverse Access',
    dataType: 'array <Constituent>', luceneQueryable: true},
'Structurally Diverse Created': {fieldName: 'Created',
    fieldPath: '/structurallyDiverse/created', finalField: 'created',
    lucenePath: 'root_structurallyDiverse_created', CVDomain: '',
   displayName: 'Structurally Diverse Created',
    dataType: 'object <Amount>', luceneQueryable: true},
'Structurally Diverse Created By': {fieldName: 'Created By',
    fieldPath: '/structurallyDiverse/createdBy', finalField: 'createdBy',
    lucenePath: 'root_structurallyDiverse_createdBy', CVDomain: '',
   displayName: 'Structurally Diverse Created By',
    dataType: 'array <string>', luceneQueryable: true},
'Structurally Diverse Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/structurallyDiverse/deprecated', finalField: 'deprecated',
    lucenePath: 'root_structurallyDiverse_deprecated', CVDomain: '',
   displayName: 'Structurally Diverse Deprecated',
    dataType: 'number', luceneQueryable: true},
'Structurally Diverse Developmental Stage': {fieldName: 'Developmental Stage',
    fieldPath: '/structurallyDiverse/developmentalStage', finalField: 'developmentalStage',
    lucenePath: 'root_structurallyDiverse_developmentalStage', CVDomain: 'DEVELOPMENTAL_STAGE',
   displayName: 'Structurally Diverse Developmental Stage',
    dataType: 'number', luceneQueryable: true},
'Structurally Diverse Fraction Material Type': {fieldName: 'Fraction Material Type',
    fieldPath: '/structurallyDiverse/fractionMaterialType', finalField: 'fractionMaterialType',
    lucenePath: 'root_structurallyDiverse_fractionMaterialType', CVDomain: 'FRACTION_MATERIAL_TYPE',
   displayName: 'Structurally Diverse Fraction Material Type',
    dataType: 'string', luceneQueryable: true},
'Structurally Diverse Fraction Name': {fieldName: 'Fraction Name',
    fieldPath: '/structurallyDiverse/fractionName', finalField: 'fractionName',
    lucenePath: 'root_structurallyDiverse_fractionName', CVDomain: '',
   displayName: 'Structurally Diverse Fraction Name',
    dataType: 'boolean', luceneQueryable: true},
'St. Div. Hybrid Parent (m) Access': {fieldName: 'Access',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/access', finalField: 'access',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'St. Div. Hybrid Parent (m) Access',
    dataType: 'number', luceneQueryable: true},
'St. Div. Hybrid Parent (m) Approval ID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/approvalID', finalField: 'approvalID',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_approvalID', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) Approval ID',
    dataType: 'number', luceneQueryable: true},
'St. Div. Hybrid Parent (m) Created': {fieldName: 'Created',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/created', finalField: 'created',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_created', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) Created',
    dataType: 'string', luceneQueryable: true},
'St. Div. Hybrid Parent (m) Created By': {fieldName: 'Created By',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/createdBy', finalField: 'createdBy',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_createdBy', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) Created By',
    dataType: 'number', luceneQueryable: true},
'St. Div. Hybrid Parent (m) Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/deprecated', finalField: 'deprecated',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_deprecated', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) Deprecated',
    dataType: 'number', luceneQueryable: true},
'St. Div. Hybrid Parent (m) Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_lastEdited', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) Last Edited',
    dataType: 'string', luceneQueryable: true},
'St. Div. Hybrid Parent (m) Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_lastEditedBy', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) Last Edited By',
    dataType: 'array <string>', luceneQueryable: true},
'St. Div. Hybrid Parent (m) Substance Name': {fieldName: 'Ref Pname',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/refPname', finalField: 'refPname',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_refPname', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) Substance Name',
    dataType: 'string', luceneQueryable: true},
'St. Div. Hybrid Parent (m) Refuuid': {fieldName: 'Refuuid',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/refuuid', finalField: 'refuuid',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_refuuid', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) Refuuid',
    dataType: 'object <SubstanceReference>', luceneQueryable: true},
'St. Div. Hybrid Parent (m) Substance Class': {fieldName: 'Substance Class',
    fieldPath: '/structurallyDiverse/hybridSpeciesMaternalOrganism/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesMaternalOrganism_substanceClass', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (m) Substance Class',
    dataType: 'array <string>', luceneQueryable: true},
'St. Div. Hybrid Parent (p) Access': {fieldName: 'Access',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/access', finalField: 'access',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'St. Div. Hybrid Parent (p) Access',
    dataType: 'string', luceneQueryable: true},
'St. Div. Hybrid Parent (p) Approval ID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/approvalID', finalField: 'approvalID',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_approvalID', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) Approval ID',
    dataType: 'boolean', luceneQueryable: true},
'St. Div. Hybrid Parent (p) Created': {fieldName: 'Created',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/created', finalField: 'created',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_created', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) Created',
    dataType: 'number', luceneQueryable: true},
'St. Div. Hybrid Parent (p) Created By': {fieldName: 'Created By',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/createdBy', finalField: 'createdBy',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_createdBy', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) Created By',
    dataType: 'string', luceneQueryable: true},
'St. Div. Hybrid Parent (p) Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/deprecated', finalField: 'deprecated',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_deprecated', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) Deprecated',
    dataType: 'string', luceneQueryable: true},
'St. Div. Hybrid Parent (p) Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_lastEdited', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) Last Edited',
    dataType: 'string', luceneQueryable: true},
'St. Div. Hybrid Parent (p) Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_lastEditedBy', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) Last Edited By',
    dataType: 'array <string>', luceneQueryable: true},
'St. Div. Hybrid Parent (p) Substance Name': {fieldName: 'Ref Pname',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/refPname', finalField: 'refPname',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_refPname', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) Substance Name',
    dataType: 'string', luceneQueryable: true},
'St. Div. Hybrid Parent (p) Refuuid': {fieldName: 'Refuuid',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/refuuid', finalField: 'refuuid',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_refuuid', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) Refuuid',
    dataType: 'number', luceneQueryable: true},
'St. Div. Hybrid Parent (p) Substance Class': {fieldName: 'Substance Class',
    fieldPath: '/structurallyDiverse/hybridSpeciesPaternalOrganism/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_structurallyDiverse_hybridSpeciesPaternalOrganism_substanceClass', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent (p) Substance Class',
    dataType: 'string', luceneQueryable: true},
'Structurally Diverse Infraspecific Name': {fieldName: 'Infra Specific Name',
    fieldPath: '/structurallyDiverse/infraSpecificName', finalField: 'infraSpecificName',
    lucenePath: 'root_structurallyDiverse_infraSpecificName', CVDomain: '',
   displayName: 'Structurally Diverse Infraspecific Name',
    dataType: 'number', luceneQueryable: true},
'Structurally Diverse Infraspecific type': {fieldName: 'Infra Specific Type',
    fieldPath: '/structurallyDiverse/infraSpecificType', finalField: 'infraSpecificType',
    lucenePath: 'root_structurallyDiverse_infraSpecificType', CVDomain: 'INFRA_SPECIFIC_TYPE',
   displayName: 'Structurally Diverse Infraspecific type',
    dataType: 'string', luceneQueryable: true},
'Structurally Diverse Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/structurallyDiverse/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_structurallyDiverse_lastEdited', CVDomain: '',
   displayName: 'Structurally Diverse Last Edited',
    dataType: 'array <string>', luceneQueryable: true},
'Structurally Diverse Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/structurallyDiverse/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_structurallyDiverse_lastEditedBy', CVDomain: '',
   displayName: 'Structurally Diverse Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Organism Author': {fieldName: 'Author',
    fieldPath: '/structurallyDiverse/organismAuthor', finalField: 'organismAuthor',
    lucenePath: 'root_structurallyDiverse_organismAuthor', CVDomain: '',
   displayName: 'Organism Author',
    dataType: 'string', luceneQueryable: true},
'Organism Family': {fieldName: 'Family',
    fieldPath: '/structurallyDiverse/organismFamily', finalField: 'organismFamily',
    lucenePath: 'root_structurallyDiverse_organismFamily', CVDomain: '',
   displayName: 'Organism Family',
    dataType: 'object <Structurallydiverse>', luceneQueryable: true},
'Organism Genus': {fieldName: 'Genus',
    fieldPath: '/structurallyDiverse/organismGenus', finalField: 'organismGenus',
    lucenePath: 'root_structurallyDiverse_organismGenus', CVDomain: '',
   displayName: 'Organism Genus',
    dataType: 'array <string>', luceneQueryable: true},
'Organism Species': {fieldName: 'Species',
    fieldPath: '/structurallyDiverse/organismSpecies', finalField: 'organismSpecies',
    lucenePath: 'root_structurallyDiverse_organismSpecies', CVDomain: '',
   displayName: 'Organism Species',
    dataType: 'number', luceneQueryable: true},
'St. Div. Hybrid Parent Access': {fieldName: 'Access',
    fieldPath: '/structurallyDiverse/parentSubstance/access', finalField: 'access',
    lucenePath: 'root_structurallyDiverse_parentSubstance_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'St. Div. Hybrid Parent Access',
    dataType: 'boolean', luceneQueryable: true},
'St. Div. Parent Approval ID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/structurallyDiverse/parentSubstance/approvalID', finalField: 'approvalID',
    lucenePath: 'root_structurallyDiverse_parentSubstance_approvalID', CVDomain: '',
   displayName: 'St. Div. Parent Approval ID',
    dataType: 'string', luceneQueryable: true},
'St. Div. Hybrid Parent Created': {fieldName: 'Created',
    fieldPath: '/structurallyDiverse/parentSubstance/created', finalField: 'created',
    lucenePath: 'root_structurallyDiverse_parentSubstance_created', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent Created',
    dataType: 'string', luceneQueryable: true},
'St. Div. Hybrid Parent Created By': {fieldName: 'Created By',
    fieldPath: '/structurallyDiverse/parentSubstance/createdBy', finalField: 'createdBy',
    lucenePath: 'root_structurallyDiverse_parentSubstance_createdBy', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent Created By',
    dataType: 'string', luceneQueryable: true},
'St. Div. Hybrid Parent Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/structurallyDiverse/parentSubstance/deprecated', finalField: 'deprecated',
    lucenePath: 'root_structurallyDiverse_parentSubstance_deprecated', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent Deprecated',
    dataType: 'object <SubstanceReference>', luceneQueryable: true},
'St. Div. Hybrid Parent Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/structurallyDiverse/parentSubstance/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_structurallyDiverse_parentSubstance_lastEdited', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent Last Edited',
    dataType: 'array <string>', luceneQueryable: true},
'St. Div. Hybrid Parent Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/structurallyDiverse/parentSubstance/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_structurallyDiverse_parentSubstance_lastEditedBy', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent Last Edited By',
    dataType: 'string', luceneQueryable: true},
'St. Div. Parent Substance Name': {fieldName: 'Ref Pname',
    fieldPath: '/structurallyDiverse/parentSubstance/refPname', finalField: 'refPname',
    lucenePath: 'root_structurallyDiverse_parentSubstance_refPname', CVDomain: '',
   displayName: 'St. Div. Parent Substance Name',
    dataType: 'number', luceneQueryable: true},
'St. Div. Hybrid Parent Refuuid': {fieldName: 'Refuuid',
    fieldPath: '/structurallyDiverse/parentSubstance/refuuid', finalField: 'refuuid',
    lucenePath: 'root_structurallyDiverse_parentSubstance_refuuid', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent Refuuid',
    dataType: 'string', luceneQueryable: true},
'St. Div. Hybrid Parent Substance Class': {fieldName: 'Substance Class',
    fieldPath: '/structurallyDiverse/parentSubstance/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_structurallyDiverse_parentSubstance_substanceClass', CVDomain: '',
   displayName: 'St. Div. Hybrid Parent Substance Class',
    dataType: 'string', luceneQueryable: true},
'Structurally Diverse Partlocation': {fieldName: 'Part Location',
    fieldPath: '/structurallyDiverse/partLocation', finalField: 'partLocation',
    lucenePath: 'root_structurallyDiverse_partLocation', CVDomain: 'PART_LOCATION',
   displayName: 'Structurally Diverse Partlocation',
    dataType: 'string', luceneQueryable: true},
'Source Material Class': {fieldName: 'Material Class',
    fieldPath: '/structurallyDiverse/sourceMaterialClass', finalField: 'sourceMaterialClass',
    lucenePath: 'root_structurallyDiverse_sourceMaterialClass', CVDomain: 'SOURCE_MATERIAL_CLASS',
   displayName: 'Source Material Class',
    dataType: 'string', luceneQueryable: true},
'Source Material Source Material State': {fieldName: 'Source Material State',
    fieldPath: '/structurallyDiverse/sourceMaterialState', finalField: 'sourceMaterialState',
    lucenePath: 'root_structurallyDiverse_sourceMaterialState', CVDomain: 'SOURCE_MATERIAL_STATE',
   displayName: 'Source Material Source Material State',
    dataType: 'string', luceneQueryable: true},
'Source Material Type': {fieldName: 'Material Type',
    fieldPath: '/structurallyDiverse/sourceMaterialType', finalField: 'sourceMaterialType',
    lucenePath: 'root_structurallyDiverse_sourceMaterialType', CVDomain: 'SOURCE_MATERIAL_TYPE',
   displayName: 'Source Material Type',
    dataType: 'object <SubstanceReference>', luceneQueryable: true},
'Structure Access': {fieldName: 'Access',
    fieldPath: '/structure/access', finalField: 'access',
    lucenePath: 'root_structure_access', CVDomain: 'ACCESS_GROUP',
   displayName: 'Structure Access',
    dataType: 'number', luceneQueryable: true},
'Structure Has Atropisomerism': {fieldName: 'Atropisomerism',
    fieldPath: '/structure/atropisomerism', finalField: 'atropisomerism',
    lucenePath: 'root_structure_atropisomerism', CVDomain: 'ATROPISOMERISM',
   displayName: 'Structure Has Atropisomerism',
    dataType: 'string', luceneQueryable: true},
'Structure Charge': {fieldName: 'Charge',
    fieldPath: '/structure/charge', finalField: 'charge',
    lucenePath: 'root_structure_charge', CVDomain: '',
   displayName: 'Structure Charge',
    dataType: 'boolean', luceneQueryable: true},
'Structure Count': {fieldName: 'Count',
    fieldPath: '/structure/count', finalField: 'count',
    lucenePath: 'root_structure_count', CVDomain: '',
   displayName: 'Structure Count',
    dataType: 'number', luceneQueryable: true},
'Structure Created': {fieldName: 'Created',
    fieldPath: '/structure/created', finalField: 'created',
    lucenePath: 'root_structure_created', CVDomain: '',
   displayName: 'Structure Created',
    dataType: 'string', luceneQueryable: true},
'Structure Created By': {fieldName: 'Created By',
    fieldPath: '/structure/createdBy', finalField: 'createdBy',
    lucenePath: 'root_structure_createdBy', CVDomain: '',
   displayName: 'Structure Created By',
    dataType: 'string', luceneQueryable: true},
'Structure Defined Stereo': {fieldName: 'Defined Stereocenters Count',
    fieldPath: '/structure/definedStereo', finalField: 'definedStereo',
    lucenePath: 'root_structure_definedStereo', CVDomain: '',
   displayName: 'Structure Defined Stereo',
    dataType: 'string', luceneQueryable: true},
'Structure Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/structure/deprecated', finalField: 'deprecated',
    lucenePath: 'root_structure_deprecated', CVDomain: '',
   displayName: 'Structure Deprecated',
    dataType: 'array <string>', luceneQueryable: true},
'Structure Digest': {fieldName: 'Digest',
    fieldPath: '/structure/digest', finalField: 'digest',
    lucenePath: 'root_structure_digest', CVDomain: '',
   displayName: 'Structure Digest',
    dataType: 'string', luceneQueryable: true},
'Structure Ez Centers': {fieldName: 'E/Z Center Count',
    fieldPath: '/structure/ezCenters', finalField: 'ezCenters',
    lucenePath: 'root_structure_ezCenters', CVDomain: '',
   displayName: 'Structure Ez Centers',
    dataType: 'string', luceneQueryable: true},
'Mol Formua': {fieldName: 'Molecular Formula',
    fieldPath: '/structure/formula', finalField: 'formula',
    lucenePath: 'root_structure_formula', CVDomain: '',
   displayName: 'Mol Formua',
    dataType: 'string', luceneQueryable: true},
'Structure Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/structure/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_structure_lastEdited', CVDomain: '',
   displayName: 'Structure Last Edited',
    dataType: 'string', luceneQueryable: true},
'Structure Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/structure/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_structure_lastEditedBy', CVDomain: '',
   displayName: 'Structure Last Edited By',
    dataType: 'number', luceneQueryable: true},
'Structure Mwt': {fieldName: 'Molecular Weight',
    fieldPath: '/structure/mwt', finalField: 'mwt',
    lucenePath: 'root_structure_mwt', CVDomain: '',
   displayName: 'Structure Mwt',
    dataType: 'string', luceneQueryable: true},
'Structure Optical Activity': {fieldName: 'Optical Activity',
    fieldPath: '/structure/opticalActivity', finalField: 'opticalActivity',
    lucenePath: 'root_structure_opticalActivity', CVDomain: 'OPTICAL_ACTIVITY',
   displayName: 'Structure Optical Activity',
    dataType: 'string', luceneQueryable: true},
'Structure Stereo Centers': {fieldName: 'Total Stereocenters Count',
    fieldPath: '/structure/stereoCenters', finalField: 'stereoCenters',
    lucenePath: 'root_structure_stereoCenters', CVDomain: '',
   displayName: 'Structure Stereo Centers',
    dataType: 'object <SubstanceReference>', luceneQueryable: true},
'Structure Stereochemistry': {fieldName: 'Stereochemistry',
    fieldPath: '/structure/stereochemistry', finalField: 'stereochemistry',
    lucenePath: 'root_structure_stereoChemistry', CVDomain: 'STEREOCHEMISTRY_TYPE',
   displayName: 'Structure Stereochemistry',
    dataType: 'array <string>', luceneQueryable: true},
'Structure Stereo Comments': {fieldName: 'Stereocomments',
    fieldPath: '/structure/stereoComments', finalField: 'stereoComments',
    lucenePath: 'root_structure_stereoComments', CVDomain: '',
   displayName: 'Structure Stereo Comments',
    dataType: 'string', luceneQueryable: true},
'Substance Class': {fieldName: 'Substance Type',
    fieldPath: '/substanceClass', finalField: 'substanceClass',
    lucenePath: 'root_substanceClass', CVDomain: 'SUBSTANCE_CLASS',
   displayName: 'Substance Class',
    dataType: 'number', luceneQueryable: true},
'Record Version': {fieldName: 'Version',
    fieldPath: '/version', finalField: 'version',
    lucenePath: 'root_version', CVDomain: '',
   displayName: 'Record Version',
    dataType: 'number', luceneQueryable: true},
'SpecifiedSubstance Created': {fieldName: 'Created',
    fieldPath: '/specifiedSubstance/created', finalField: 'created',
    lucenePath: 'root_specifiedSubstance_created', CVDomain: '',
   displayName: 'SpecifiedSubstance Created',
    dataType: 'string', luceneQueryable: true},
'SpecifiedSubstance Created By': {fieldName: 'Created By',
    fieldPath: '/specifiedSubstance/createdBy', finalField: 'createdBy',
    lucenePath: 'root_specifiedSubstance_createdBy', CVDomain: '',
   displayName: 'SpecifiedSubstance Created By',
    dataType: 'array <string>', luceneQueryable: true},
'SpecifiedSubstance Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/specifiedSubstance/deprecated', finalField: 'deprecated',
    lucenePath: 'root_specifiedSubstance_deprecated', CVDomain: '',
   displayName: 'SpecifiedSubstance Deprecated',
    dataType: 'string', luceneQueryable: true},
'SpecifiedSubstance Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/specifiedSubstance/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_specifiedSubstance_lastEdited', CVDomain: '',
   displayName: 'SpecifiedSubstance Last Edited',
    dataType: 'string', luceneQueryable: true},
'SpecifiedSubstance Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/specifiedSubstance/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_specifiedSubstance_lastEditedBy', CVDomain: '',
   displayName: 'SpecifiedSubstance Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Constituent Role': {fieldName: 'Role',
    fieldPath: '/specifiedSubstance/constituents/?/role', finalField: '',
    lucenePath: 'root_specifiedSubstance_constituents_role', CVDomain: 'CONSTITUENT_ROLE',
   displayName: 'Constituent Role',
    dataType: 'array <string>', luceneQueryable: true},
'Constituent Substance Approval ID': {fieldName: 'Approval ID (UNII)',
    fieldPath: '/specifiedSubstance/constituents/?/substance/approvalID', finalField: 'approvalID',
    lucenePath: 'root_specifiedSubstance_constituents_substance_approvalID', CVDomain: '',
   displayName: 'Constituent Substance Approval ID',
    dataType: 'string', luceneQueryable: true},
'Constituent Substance Created': {fieldName: 'Created',
    fieldPath: '/specifiedSubstance/constituents/?/substance/created', finalField: 'created',
    lucenePath: 'root_specifiedSubstance_constituents_substance_created', CVDomain: '',
   displayName: 'Constituent Substance Created',
    dataType: 'string', luceneQueryable: true},
'Constituent Substance Created By': {fieldName: 'Created By',
    fieldPath: '/specifiedSubstance/constituents/?/substance/createdBy', finalField: 'createdBy',
    lucenePath: 'root_specifiedSubstance_constituents_substance_createdBy', CVDomain: '',
   displayName: 'Constituent Substance Created By',
    dataType: 'object <Structure>', luceneQueryable: true},
'Constituent Substance Deprecated': {fieldName: 'Deprecated',
    fieldPath: '/specifiedSubstance/constituents/?/substance/deprecated', finalField: 'deprecated',
    lucenePath: 'root_specifiedSubstance_constituents_substance_deprecated', CVDomain: '',
   displayName: 'Constituent Substance Deprecated',
    dataType: 'array <string>', luceneQueryable: true},
'Constituent Substance Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/specifiedSubstance/constituents/?/substance/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_specifiedSubstance_constituents_substance_lastEdited', CVDomain: '',
   displayName: 'Constituent Substance Last Edited',
    dataType: 'string', luceneQueryable: true},
'Constituent Substance Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/specifiedSubstance/constituents/?/substance/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_specifiedSubstance_constituents_substance_lastEditedBy', CVDomain: '',
   displayName: 'Constituent Substance Last Edited By',
    dataType: 'number', luceneQueryable: true},
'Constituent Substance Substance Name': {fieldName: 'Ref Pname',
    fieldPath: '/specifiedSubstance/constituents/?/substance/refPname', finalField: 'refPname',
    lucenePath: 'root_specifiedSubstance_constituents_substance_refPname', CVDomain: '',
   displayName: 'Constituent Substance Substance Name',
    dataType: 'number', luceneQueryable: true},
'Constituent Substance Refuuid': {fieldName: 'Refuuid',
    fieldPath: '/specifiedSubstance/constituents/?/substance/refuuid', finalField: 'refuuid',
    lucenePath: 'root_specifiedSubstance_constituents_substance_refuuid', CVDomain: '',
   displayName: 'Constituent Substance Refuuid',
    dataType: 'boolean', luceneQueryable: true},
'Constituent Substance UUID': {fieldName: 'Uuid',
    fieldPath: '/specifiedSubstance/constituents/?/substance/uuid', finalField: 'uuid',
    lucenePath: 'root_specifiedSubstance_constituents_substance_uuid', CVDomain: '',
   displayName: 'Constituent Substance UUID',
    dataType: 'number', luceneQueryable: true},
'Constituent Amount Average': {fieldName: 'Average',
    fieldPath: '/specifiedSubstance/constituents/?/amount/average', finalField: 'average',
    lucenePath: 'root_specifiedSubstance_constituents_amount_average', CVDomain: '',
   displayName: 'Constituent Amount Average',
    dataType: 'string', luceneQueryable: true},
'Constituent Amount Created': {fieldName: 'Created',
    fieldPath: '/specifiedSubstance/constituents/?/amount/created', finalField: 'created',
    lucenePath: 'root_specifiedSubstance_constituents_amount_created', CVDomain: '',
   displayName: 'Constituent Amount Created',
    dataType: 'number', luceneQueryable: true},
'Constituent Amount Created By': {fieldName: 'Created By',
    fieldPath: '/specifiedSubstance/constituents/?/amount/createdBy', finalField: 'createdBy',
    lucenePath: 'root_specifiedSubstance_constituents_amount_createdBy', CVDomain: '',
   displayName: 'Constituent Amount Created By',
    dataType: 'string', luceneQueryable: true},
'Constituent Amount High': {fieldName: 'High',
    fieldPath: '/specifiedSubstance/constituents/?/amount/high', finalField: 'high',
    lucenePath: 'root_specifiedSubstance_constituents_amount_high', CVDomain: '',
   displayName: 'Constituent Amount High',
    dataType: 'number', luceneQueryable: true},
'Constituent Amount High Limit': {fieldName: 'High Limit',
    fieldPath: '/specifiedSubstance/constituents/?/amount/highLimit', finalField: 'highLimit',
    lucenePath: 'root_specifiedSubstance_constituents_amount_highLimit', CVDomain: '',
   displayName: 'Constituent Amount High Limit',
    dataType: 'string', luceneQueryable: true},
'Constituent Amount Last Edited': {fieldName: 'Last Edited',
    fieldPath: '/specifiedSubstance/constituents/?/amount/lastEdited', finalField: 'lastEdited',
    lucenePath: 'root_specifiedSubstance_constituents_amount_lastEdited', CVDomain: '',
   displayName: 'Constituent Amount Last Edited',
    dataType: 'array <string>', luceneQueryable: true},
'Constituent Amount Last Edited By': {fieldName: 'Last Edited By',
    fieldPath: '/specifiedSubstance/constituents/?/amount/lastEditedBy', finalField: 'lastEditedBy',
    lucenePath: 'root_specifiedSubstance_constituents_amount_lastEditedBy', CVDomain: '',
   displayName: 'Constituent Amount Last Edited By',
    dataType: 'string', luceneQueryable: true},
'Constituent Amount Low': {fieldName: 'Low',
    fieldPath: '/specifiedSubstance/constituents/?/amount/low', finalField: 'low',
    lucenePath: 'root_specifiedSubstance_constituents_amount_low', CVDomain: '',
   displayName: 'Constituent Amount Low',
    dataType: 'number', luceneQueryable: true},
'Constituent Amount Low Limit': {fieldName: 'Low Limit',
    fieldPath: '/specifiedSubstance/constituents/?/amount/lowLimit', finalField: 'lowLimit',
    lucenePath: 'root_specifiedSubstance_constituents_amount_lowLimit', CVDomain: '',
   displayName: 'Constituent Amount Low Limit',
    dataType: 'string', luceneQueryable: true},
'Constituent Amount Non-Numeric Value': {fieldName: 'Non Numeric Value',
    fieldPath: '/specifiedSubstance/constituents/?/amount/nonNumericValue', finalField: 'nonNumericValue',
    lucenePath: 'root_specifiedSubstance_constituents_amount_nonNumericValue', CVDomain: '',
   displayName: 'Constituent Amount Non-Numeric Value',
    dataType: 'string', luceneQueryable: true},
'Constituent Amount Type': {fieldName: 'Type',
    fieldPath: '/specifiedSubstance/constituents/?/amount/type', finalField: 'type',
    lucenePath: 'root_specifiedSubstance_constituents_amount_type', CVDomain: 'AMOUNT_TYPE',
   displayName: 'Constituent Amount Type',
    dataType: 'array <string>', luceneQueryable: true},
'Constituent Amount Units': {fieldName: 'Units',
    fieldPath: '/specifiedSubstance/constituents/?/amount/units', finalField: 'units',
    lucenePath: 'root_specifiedSubstance_constituents_amount_units', CVDomain: 'AMOUNT_UNIT',
   displayName: 'Constituent Amount Units',
    dataType: 'string', luceneQueryable: true}};
