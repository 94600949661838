<div class="domain-references-content">
  <mat-accordion>
    <mat-expansion-panel (closed)="panelClosed()" (opened)="panelOpened()">
      <mat-expansion-panel-header class="domain-reference-header">
        <mat-panel-title>
          <h4 class="title ref-title" matTooltip="Expand references">
            {{card && card === 'definition'? 'Definitional ': ''}}References
            <span [matBadge]="referencesUuids && referencesUuids.length || 0" class = "custom-badge" matBadgeOverlap="false">
            </span>
          </h4>
          <div class="middle-fill"></div>
          <div class="title-actions">
            <button mat-flat-button color="primary" matTooltip="Create new reference"
              (click)="addNewReference(); $event.stopPropagation()">
              Create new
              <mat-icon svgIcon="add_circle_outline"></mat-icon>
            </button>
            <button mat-flat-button color="primary" matTooltip="Reuse existing reference"
              (click)="reuseExistingReference(); $event.stopPropagation()" *ngIf="canReuse">
              Reuse
              <mat-icon svgIcon="move_to_inbox"></mat-icon>
            </button>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <table mat-table *ngIf="referencesUuids" [dataSource]="tableData">
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let reference">
              {{documentTypesDictionary[reference.docType] && documentTypesDictionary[reference.docType].value}} </td>
          </ng-container>

          <ng-container matColumnDef="citation">
            <th mat-header-cell *matHeaderCellDef> Citation </th>
            <td mat-cell *matCellDef="let reference">
              <span *ngIf="!reference.url">{{reference.citation}}</span>
              <a [href]="reference.url" target="_blank" *ngIf="reference.url">{{reference.citation}}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="publicDomain">
            <th mat-header-cell *matHeaderCellDef> Public Domain </th>
            <td mat-cell *matCellDef="let reference">
              {{reference.publicDomain ? 'Yes' : 'No'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="access">
            <th mat-header-cell *matHeaderCellDef> Access </th>
            <td mat-cell *matCellDef="let reference">
              <span *ngIf="!reference.access || reference.access.length === 0">
                Public
              </span>
              <span *ngIf="reference.access && reference.access.length > 0">
                <span *ngFor="let item of reference.access; last as isLast">
                  {{item}}
                  <span *ngIf="!isLast">, </span>
                </span>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="goToReference">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let reference">
              <button mat-icon-button matTooltip="Open reference form" (click)="openExistingReferenceForm(reference)">
                <mat-icon svgIcon="edit"></mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef> Deselect </th>
            <td mat-cell *matCellDef="let reference">
              <button mat-icon-button matTooltip="Remove reference" (click)="removeDomainReference(reference.uuid)">
                <mat-icon svgIcon="clear"></mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="attachment">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let reference">
              <div *ngIf="reference.uploadedFile">
                <a mat-icon-button matTooltip="Download document" (click)="downloadDocument(reference.uploadedFile)">
                  <mat-icon svgIcon="cloud_download"></mat-icon>
                </a>
              </div>
            </td>s
          </ng-container>

          <!-- <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> Delete </th>
            <td mat-cell *matCellDef="let reference">
              <button mat-icon-button matTooltip="Delete reference from entire substance"
                (click)="deleteReference(reference)">
                <mat-icon svgIcon="delete_forever"></mat-icon>
              </button>
            </td>
          </ng-container> -->

          <ng-container matColumnDef="tags">
            <th mat-header-cell *matHeaderCellDef> Tags </th>
            <td mat-cell *matCellDef="let reference">
              <span  *ngIf = "showmore && (reference.tags && reference.tags.length > 0)" ><span class = "badge" *ngFor = "let tag of reference.tags"> {{tag}} </span> <a *ngIf = "reference.tags.length > 1" class = "morelink" (click) = "showmore = !showmore">hide</a> </span>
                <span  *ngIf = "!showmore && (reference.tags && reference.tags.length > 0)" ><span class = "badge" > {{reference.tags.length > 0? reference.tags[0]:null}}</span> <a *ngIf = "reference.tags.length > 1" class = "morelink" (click) = "showmore = !showmore">more...</a> </span>
                </td>
          </ng-container>

          <ng-container matColumnDef="apply">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let reference">
                  <app-apply-reference [subReferenceUuid]="reference.uuid"></app-apply-reference>
              </td>
            </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
