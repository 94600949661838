<div mat-header>
        <button mat-mini-fab mat-dialog-close (click) = "close()">
                <mat-icon svgIcon="close"></mat-icon>
              </button>
    <div *ngIf = "view == 'all'" >
        <H3>Saved Lists {{setUser ? ' - ' + setUser : ''}}</H3>
        <div class ="form-row">
            <a *ngIf = "isAdmin" class = "link" (click) = "getUsers()">Other User's Lists</a>

            <span class ="middle-fill"></span>
            <a *ngIf = "isAdmin" class = "link" (click) = "view = 'import'">Saved List Import</a>
    </div>
    </div>
    <div *ngIf = "view == 'add'" >
            <H3>Add List</H3>
        </div>
        <div *ngIf = "view == 'import'" >
                <H3>Import List</H3>
                <a class = "link" (click) = "view = 'all'">Return to lists view</a> <span class = "middle-fill"></span>
            </div>

        <div *ngIf = "view == 'users'" >
            <H3>Users</H3>
        </div>
    <div *ngIf = "view == 'single'" class = "single-header">
            <H3>Saved Lists: <span style = "font-weight:700;margin-left:10px;font-size:22px">{{activeName}}</span> 
                
            </H3>
            <div class ="form-row" style = "justify-content:space-evenly">
                    <a class = "link" (click) = "view = 'all'">Return to lists view</a> 
                    <a class = "link" [href]="downloadJsonHref" download="{{(setUser ? setUser + '-' : '') + activeName}}.json">Export List</a> 
                    <a class = "link" (click) = "browseView()">View list in browse page</a>
            </div>
        </div>
</div>
<div  mat-dialog-content>

        <div *ngIf = "view === 'add'">
                <br/>
           <div *ngIf = "!showAddButtons">
              <b style = "font-weight:500">Enter a list name to save browse results to</b>  <br/><br/>
            <input  mat-input placeholder="New List Name" [(ngModel)] = "listName" /> &nbsp; &nbsp;
            <button mat-flat-button color = "primary" (click) = "addList()">Add New List</button>
        </div>
        <br/><br/>
        <div *ngIf = "!showAddButtons">
                <b style = "font-weight:500">Or select an existing list to append to</b>  <br/>
                <mat-form-field *ngIf = 'userLists' class = "select-list">
                        <mat-label>Select a List</mat-label>
                        <mat-select [(ngModel)]="listName2" name="Add to List" (selectionChange) = "checkList()">
                          <mat-option *ngFor="let list of userLists" [value]="list">
                            {{list}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      &nbsp; &nbsp;
            <button mat-flat-button color = "primary" (click) = "appendList()" [disabled] ="disabled">Append to List</button>
          </div>
        <div *ngIf = "showAddButtons && loading"> 
                <div class = "loading-spinner" style="margin: auto;">
                        <mat-progress-spinner  [color]="accent" mode="indeterminate" diameter = "50" >
                        </mat-progress-spinner>
                  </div>
            </div>
          
        </div>
        <div *ngIf = "view === 'users'">
            <table mat-table matSort [dataSource]="users" *ngIf = "users.length > 0">
                    <ng-container matColumnDef="identifier">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
                                <td mat-cell *matCellDef="let list"> {{list.identifier}} </td>
                            </ng-container>
                            <ng-container matColumnDef="roles">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> ID </th>
                                <td mat-cell *matCellDef="let list">  <span *ngFor = "let role of list.roles">{{role}} &nbsp; </span></td>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> View </th>
                                <td mat-cell *matCellDef="let list"> <button mat-button (click) = "useUser(list.identifier)">View User Lists</button> </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
                
            </table>
      
    </div>


    <div *ngIf = "view === 'import'">
            <br/><br/><br/>
        <div class = "info">
            Select an exported .json saved list file to import into a new form.
        </div>
        <br/><br/>
        <div class = "full-row"> <input  mat-input placeholder="New List Name" [(ngModel)] = "listName" /></div>
        <br/>
        <div class = "full-row-start">
                <div><button mat-stroked-button class="button-select-file" (click)="openInput()">Select File to Import</button></div>
                <div class = "file-name" [ngClass] = "filename? 'test':'italics'">{{filename? filename: 'no file chosen'}}</div>
                <input id="fileInput" hidden type="file" (change)="uploadFile($event)" name="file1" accept=".json">
               
        </div>
        <br/><br/>
        <button mat-flat-button color = "primary" class="button-import2" [disabled]= "!loaded || (!listName || listName === '')" (click) ="useFile()">Import</button>
        <br/><br/>
    </div>


<div *ngIf = "view == 'all'">
    <div *ngIf = "lists.length == 0">
       <i>No lists were found</i>
    </div>
        <table mat-table matSort [dataSource]="lists" *ngIf = "lists.length > 0">
                <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef> Delete </th>
                        <td mat-cell *matCellDef="let list; index as i"> <button mat-icon-button matTooltip="Delete entry" (click) = "deleteList(list)"> <mat-icon svgIcon="delete_forever"></mat-icon></button> </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
                                <td mat-cell *matCellDef="let list"> {{list}} </td>
                            </ng-container>
                          <!--  <ng-container matColumnDef="ID">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> ID </th>
                                <td mat-cell *matCellDef="let list">  {{list.ID? list.ID : 'N/A'}} </td>
                            </ng-container>-->
                            <ng-container matColumnDef="view">
                                <th mat-header-cell *matHeaderCellDef> View </th>
                                <td mat-cell *matCellDef="let list"> <button mat-button (click) = "useDraft(list)">View List</button> </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
            </table>
</div>

<div *ngIf = "view == 'single'" >
        <table mat-table matSort [dataSource]="filtered" *ngIf = "filtered.length > 0">
                <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef> Delete </th>
                        <td mat-cell *matCellDef="let list"> <button mat-icon-button matTooltip="Delete draft" (click) = "deleteListEntry(list)"> <mat-icon svgIcon="delete_forever"></mat-icon></button> </td>
                    </ng-container>
                    <ng-container matColumnDef="key">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> UUID </th>
                                <td mat-cell *matCellDef="let list"> <a class = "primary-color" [routerLink]="['/substances', list.key]"> {{list.key}} </a></td>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
                                <td mat-cell *matCellDef="let list">  {{list.displayName? list.displayName : 'N/A'}} </td>
                            </ng-container>
                            <ng-container matColumnDef="code">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Code </th>
                                    <td mat-cell *matCellDef="let list">  {{list.displayCode? list.displayCode : 'N/A'}} </td>
                                </ng-container>
                            <ng-container matColumnDef="codeSystem">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Code System </th>
                                    <td mat-cell *matCellDef="let list">  {{list.displayCodeSystem? list.displayCodeSystem : 'N/A'}} </td>
                                 </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                
            </table>
            <mat-paginator [length]="active.lists && active.lists.length || 0" [pageSize]="pagesize" showFirstLastButtons="true" (page)="pageChange($event)"
               [pageSizeOptions]="[5, 10, 25, 100, 250, 1000]">
</mat-paginator>
</div>

<div class = "form-row" style = "flex-direction:column">
    <br/>
    <div style = "width: 100%">{{message}}</div> 
    <br/>
    <div *ngIf = "refreshing"><br/>
        &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; This dialog can safely be closed and the process will continue in the background.</div>
    <span *ngIf = "view === 'import' && viewCreated">
        &nbsp; &nbsp; &nbsp; <a class = "link" (click) = "useCreated()">View new list</a>
    </span>
</div>
<div class = "form-row">
    <div></div>   <span class = "middle-fill"></span> <button color = "primary" mat-flat-button *ngIf = "showAddButtons && !loading" (click) = "viewLists()">View Lists</button>
    </div>


</div>