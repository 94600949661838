<div class = "main-container">
    <mat-card>
    <div class = "form-row">
            You are not authorized to see this resource. Please contact an administrator 
            <span *ngIf = "email" style = "padding-right:5px">or send an email to <a href = "mailto:{{email}}">{{email}}</a> 
                </span> 
                 to be granted access.
    </div>
</mat-card>
</div>
