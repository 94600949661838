<div class = "description">Click the chevrons to expand / collapse a folder. Click on a log file to download</div>


<mat-tree [dataSource]="dataSource" *ngIf = "dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" class = "file-node" matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <a *ngIf = "node.hasLink" class = "log-link" target = "_blank" [href] = "node.hasLink" matTooltip = "click to download">
        {{node.text}}  
      </a>
      <span *ngIf = "!node.hasLink">
        {{node.text}}
      </span>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" class = "file-node" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      {{node.name}}
    </mat-tree-node>
  </mat-tree>

