<br><br><br>
<mat-sidenav-container>

  <mat-sidenav mode="side" opened>
    <app-facets-manager (facetsParamsUpdated)="facetsParamsUpdated($event)" (facetsLoaded)="facetsLoaded($event)"
      [rawFacets]="rawFacets" configName="products" includeFacetSearch="true">
    </app-facets-manager>
  </mat-sidenav>

  <!-- Browse Product Content -->
  <mat-sidenav-content>
    <div class="side-nav-content">

      <!-- NARROW SEARCH SUGGESTIONS BEGIN -->
      <div class="narrow-search-suggestions-container"
        *ngIf="matchTypes && matchTypes.length > 0; else filterParameters">
        <div class="flex-row">
          <div class="mat-elevation-z2 narrow-search-suggestions" style="margin:auto; background-color: white;">
            <div>
              Would you like to restrict this search to a field?
            </div>&nbsp;
            <button mat-flat-button color="primary" [matMenuTriggerFor]="searchSuggestions" style="padding-right: 5px;"
              (menuOpened)="increaseOverlayZindex()" (menuClosed)="decreaseOverlayZindex()">
              <span>Fields </span>&nbsp;
              {{narrowSearchSuggestionsCount}}
              <mat-icon svgIcon="drop_down"></mat-icon>
            </button>
            <button mat-flat-button color="primary" (click)="clearFilters()">
              <span>RESET</span>
            </button>
            <button mat-icon-button color="primary" *ngIf="isSearchEditable" (click)="editAdvancedSearch()">
              <mat-icon svgIcon="edit"></mat-icon>
            </button>
            <mat-menu class="search-suggestions-container" #searchSuggestions="matMenu">
              <div class="narrow-search-dropdown" *ngFor="let matchType of matchTypes">
                <div *ngIf="((matchType) && (matchType === 'WORD' || matchType === 'FULL'))">
                  <div class="match-type">
                    {{matchType == 'WORD' ? 'Contains Match' : 'Exact Match'}}
                  </div>
                  <button mat-menu-item *ngFor="let suggestion of narrowSearchSuggestions[matchType]"
                    (click)="restricSearh(suggestion.luceneQuery)">
                    <div class="suggestion-display">
                      {{suggestion.displayField}}
                    </div>
                    <div class="suggestion-count">
                      {{suggestion.count}}
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
          </div>
        </div>
        <div class="flex-row">
          <div class="mat-elevation-z2 narrow-search-suggestions"
            style="margin:auto; background-color: white; margin-top: 12px; ">
            For more options use the <a routerLink="/advanced-search" style="padding-left: 6px;"> Advanced Search</a>
          </div>
        </div>
      </div>
      <!-- Narrow Search Suggestion End -->

      <ng-template #filterParameters>
        <div class="search-parameters">
          <div class="mat-elevation-z2 selected-parameter" *ngIf="searchTerm">
            <div>
              <span class="capitalized font-medium-bold no-break">Search Query:</span>&nbsp;
              <span>{{searchTerm}}</span>
            </div>
            <div class="actions-container">
              <button mat-icon-button color="primary" *ngIf="isSearchEditable" (click)="editAdvancedSearch()">
                <mat-icon svgIcon="edit"></mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="clearSearch()">
                <mat-icon svgIcon="delete_forever"></mat-icon>
              </button>
            </div>
          </div>

          <!-- Display Facets selection -->
          <div class="mat-elevation-z2 selected-parameter" *ngFor="let facet of displayFacets">
            <div class='selected-container'>
              <span class="capitalized font-medium-bold selected-label">
                <mat-icon class="not-icon" svgIcon="not_interested" *ngIf="!facet.bool"></mat-icon>
                {{facet.type}}:
              </span>
              <span class="no-break selected-value">
                {{facet.val}}
              </span>
            </div>
            <div class="actions-container">
              <button mat-icon-button class="display-button" color="primary"
                (click)="facet.removeFacet(facet.type, facet.bool, facet.val)">
                <mat-icon class="display-icon" svgIcon="delete_forever"></mat-icon>
              </button>
            </div>
          </div>
          <button class="reset-facets-button" mat-raised-button color="primary" (click)="clearFilters()"
            *ngIf="(displayFacets && displayFacets.length > 0) || searchTerm">
            <span>RESET</span>
          </button>
        </div>
      </ng-template>

      <!-- Title, Export, Pagination -->
      <div class="controls-container">

        <div class="title-container">
          <span class="main-title">Browse Products</span>
        </div>

        <!-- Table, Full, and Title Views -->
        <mat-button-toggle-group [value]="view" (change)="updateView($event)">
          <mat-button-toggle value="table" matTooltip="Table View">
            <mat-icon svgIcon="list"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="cards" matTooltip="Full View">
            <mat-icon svgIcon="view_stream"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>

        <!-- Sort By -->
        <mat-form-field class="sort">
          <mat-label>Sort By</mat-label>
          <mat-select (selectionChange)="searchProducts()" [(ngModel)]="order">
            <mat-option *ngFor="let option of sortValues" [value]="option.value">
              {{option.display}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Export Product Button -->
        <div class="export" *ngIf="isAdmin">
          <mat-menu #exportMenu="matMenu">
            <a mat-menu-item *ngFor="let option of exportOptions" (click)="export(option.extension)">
              {{option.displayname}}
            </a>
          </mat-menu>

          <div class="export ">
            <button *ngIf="isLoggedIn && exportOptions && exportOptions.length > 0" matTooltip="Export Search Results"
              mat-button class="mat-button export-button mat-raised-button" [matMenuTriggerFor]="exportMenu">
              <mat-icon svgIcon="get_app"></mat-icon> Export
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          </div>
        </div>

        <div class="break"></div>
        <!-- Pagination -->
        <div class="full-paginator">
          <mat-paginator #paginator [length]="totalProducts" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 50, 100]" [showFirstLastButtons]="true" (page)="changePage($event)">
          </mat-paginator>

          <div class="page-selector">
            <span class="page-label">Page: </span>
            <mat-form-field class="page-input" [ngClass]="{'bad-page':invalidPage}">
              <input matInput matTooltip="Type a page and press enter" [ngClass]="{'bad-page':invalidPage}"
                [value]="pageIndex + 1" #pagenum (keydown.enter)="customPage($event)"
                (input)="validatePageInput($event)? invalidPage = false: invalidPage = true"
                (focusout)="pagenum.value = pageIndex + 1; invalidPage = false">
            </mat-form-field>
            <span class="page-label"> of {{lastPage}}</span>
          </div>
        </div>

      </div> <!-- class=controls-container -->

      <div class="search-text">
        <!-- *** Search Any Text (TYPE AHEAD) *** -->
        <app-product-text-search class="top-search" placeholder="Search Any Text" styling="homePage"
          [searchValue]="searchValue" (searchPerformed)="processSubstanceSearch($event)" eventCategory="productSearch">
        </app-product-text-search>

      </div>

      <!-- ******** Display Content Details ******** -->
      <div [ngClass]="view">

        <!-- **************************************************************** -->
        <!-- **************************************************************** -->
        <!-- TABLE VIEW BEGIN                                                 -->
        <!-- **************************************************************** -->

        <div class="table-view">
          <div class="responsive">

            <table mat-table [dataSource]="products" class="font12px" matSort (matSortChange)="sortData($event)"
              matSortDisableClear>

              <ng-container matColumnDef="viewDetails">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Code</th>
                <td class="width100px" mat-cell *matCellDef="let product">

                  <!-- View GSRS record button -->
                  <a [routerLink]="['/product', product.id]" target="_blank" *ngIf="isAdmin"
                    matTooltip='View GSRS Product Record'>
                    View
                  </a>

                  <!-- Edit GSRS record button -->
                  &nbsp;
                  <a [routerLink]="['/product', product.id, 'edit']" target="_blank" *ngIf="isAdmin"
                    matTooltip='Edit GSRS Product Record'>
                    <mat-icon svgIcon="edit"></mat-icon>
                  </a>

                </td>
              </ng-container>

              <ng-container matColumnDef="productCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Code</th>
                <td class="width120px" mat-cell *matCellDef="let product">

                  <span *ngFor="let prodProv of product.productProvenances; let i = index">

                    <span *ngFor="let prodCode of prodProv.productCodes; let indexCode = index">

                      <span *ngIf="prodCode.productCode; else noProdCode">
                        <span *ngIf="indexCode > 0"><br><br></span>
                        {{prodCode.productCode}}
                      </span>
                      <ng-template #noProdCode>(NO ID)</ng-template>

                    </span> <!-- loop productCode -->
                  </span> <!-- loop productProvenance-->

                </td>
              </ng-container>

              <ng-container matColumnDef="productName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
                <td mat-cell *matCellDef="let product">
                  <ol class="olstyle">
                    <div *ngFor="let prodProv of product.productProvenances; let i = index">
                      <div *ngFor="let prodName of prodProv.productNames; let i = index">
                        <li>
                          {{prodName.productName}}
                        </li>
                      </div>
                    </div>
                  </ol>
                </td>
              </ng-container>

              <ng-container matColumnDef="ingredientName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ingredient Name (Active Ingredient) </th>
                <td valign="top" mat-cell *matCellDef="let product">

                  <ol class="olstyle">
                    <div *ngFor="let prodManuItems of product.productManufactureItems">
                      <div *ngFor="let prodLot of prodManuItems.productLots">
                        <div *ngFor="let prodIngred of prodLot.productIngredients; let indexActiveIngred = index">

                          <span *ngIf=" prodIngred.ingredientType; else noIngredientType">
                            <span
                              *ngIf="prodIngred.ingredientType === 'Active Ingredient' || prodIngred.ingredientType === 'ACTIVE INGREDIENT'">

                              <span *ngIf="prodIngred._ingredientName">
                                <li>

                                  <div *ngIf="prodIngred._substanceUuid; else noActiveSubUuid">
                                    <a [routerLink]="['/substances', prodIngred._substanceUuid]"
                                      target="_blank">{{prodIngred._ingredientName}}
                                    </a>
                                    <button mat-button class="structure-parameters"
                                      (click)="openImageModal(prodIngred._substanceUuid, prodIngred._ingredientName)">
                                      <mat-icon style="padding:0px;margin:0px;">analytics</mat-icon>
                                    </button>
                                  </div>
                                  <ng-template #noActiveSubUuid>
                                    {{prodIngred._ingredientName}}
                                  </ng-template>

                                </li>
                              </span>

                            </span>
                          </span>
                          <!-- No Ingredient Type, Display Ingredient Name -->
                          <ng-template #noIngredientType>
                            <!-- if No Ingredient Type, but have Substance Name, display here. -->
                            <div *ngIf="prodIngred._ingredientName">
                              <li class="">
                                <a [routerLink]="['/substances', prodIngred._substanceUuid]"
                                  target="_blank">{{prodIngred._ingredientName}}
                                </a>
                              </li>
                            </div>
                          </ng-template>


                        </div> <!-- loop Ingredient -->
                      </div> <!-- loop Lot -->
                    </div> <!-- loop ManufactureItems -->
                  </ol>

                </td>
              </ng-container>

              <ng-container matColumnDef="labelerName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Labeler Name </th>
                <td mat-cell *matCellDef="let product"> {{product.labelerName}}
                  <div *ngFor="let prodProv of product.productProvenances">
                    <div *ngFor="let prodComp of prodProv.productCompanies">
                      {{prodComp.companyName}}
                      <div *ngFor="let prodCode of prodComp.productCompanyCodes">
                        <span
                          *ngIf="prodCode.companyCodeType && prodCode.companyCodeType.companyCodeType === 'DUNS NUMBER'">
                          <span *ngIf="prodCode.companyCode">&nbsp;({{prodCode.companyCode}})</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="provenance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Provenance </th>
                <td mat-cell *matCellDef="let product">
                  <ol class="olstyle">
                    <div *ngFor="let prodProv of product.productProvenances; let i = index">
                      <li>
                        {{prodProv.provenance}}
                      </li>
                    </div>
                  </ol>
                </td>
              </ng-container>

              <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
                <td mat-cell *matCellDef="let product">
                  <div *ngFor="let prodProv of product.productProvenances">
                    <div *ngFor="let prodComp of prodProv.productCompanies">
                      {{prodComp.companyCity}}
                      <span *ngIf="prodComp.companyState">,</span>
                      {{prodComp.companyState}}
                      <span *ngIf="prodComp.companyCountry">,</span>
                      {{prodComp.companyCountry}}
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Marketing Status </th>
                <td mat-cell *matCellDef="let product">
                  <div *ngFor="let prodProv of product.productProvenances">
                    {{prodProv.productStatus}}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="productNameType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Type </th>
                <td mat-cell *matCellDef="let product">
                  <div *ngFor="let prodProv of product.productProvenances">
                    {{prodProv.productType}}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="applicationNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Application Number </th>
                <td mat-cell *matCellDef="let product">
                  <span *ngFor="let prodProv of product.productProvenances; let i = index">
                    <span class="font14px" *ngIf="prodProv.applicationType">
                      {{prodProv.applicationType}}
                    </span>
                    <span class="font14px" *ngIf="prodProv.applicationNumber">
                      {{prodProv.applicationNumber}}
                    </span>
                    <!-- COMMENTING THIS OUT RIGHT NOW DUE TO SECURITY ISSUE
                    <a href="{{getAppTypeNumberUrl(prodProv.applicationType, prodProv.applicationNumber)}}"
                      target="_blank">
                      <span class="font14px" *ngIf="prodProv.applicationType">
                        {{prodProv.applicationType}}
                      </span>
                      <span class="font14px" *ngIf="prodProv.applicationNumber">
                        {{prodProv.applicationNumber}}
                      </span>
                    </a>
                    -->
                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

          </div>
        </div> <!-- class="table-view" -->
        <!-- TABLE VIEW END -->


        <!-- **************************************************************** -->
        <!-- **************************************************************** -->
        <!-- CARD VIEW BEGIN -->
        <!-- **************************************************************** -->

        <div class="cards-view" *ngIf="products && products.length; else noRecordProduct">

          <!-- FIRST MAT-CARD  -->
          <mat-card *ngFor="let product of products; let indexProd = index">
            <mat-card-content class="margintopneg15">

              <div class="divflex marginleftneg10px margintop10px">

                <div>
                  &nbsp;&nbsp;&nbsp;
                  <a class="substance-name" [routerLink]="['/product', product.id]" target="_blank">
                    <span class="font18px"><b>View Details</b></span>
                  </a>
                </div>

                <!-- ***** BUTTONS BEGIN ***** -->

                <!-- Edit GSRS record button -->
                <div class="" *ngIf="isAdmin">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a [routerLink]="['/product', product.id, 'edit']" target="_blank"
                    matTooltip='Edit GSRS Product Record'>
                    <mat-icon class="margintopneg10px" svgIcon="edit"></mat-icon>
                  </a>
                </div>

                <div class="margintopneg10" *ngIf="isAdmin">
                  <!-- Show Json -->
                  <span *ngIf="product && product.id">
                    <a button mat-icon-button color="primary" (click)="showJSON(indexProd)" matTooltip='Show JSON'>
                      <mat-icon>article</mat-icon>
                    </a>&nbsp;
                  </span>
                </div>

                <!-- Download Json For this record -->
                <div class="margintopneg10" *ngIf="isAdmin">
                  <span *ngIf="product && product.id">
                    <a button mat-icon-button color="primary" [href]="downloadJsonHref" (click)="saveJSON(indexProd)"
                      download="{{jsonFileName}}.json" matTooltip='Download JSON'>
                      <mat-icon class="" svgIcon="get_app"></mat-icon>
                    </a>
                  </span>
                </div>

                <div class="margintopneg10" *ngIf="isAdmin">
                  <!-- Copy Product to New Register -->
                  <a mat-icon-button [routerLink]="['/product/register']" [queryParams]="{copyId:product.id}"
                    matTooltip='copy this Product to new Product registration form' *ngIf="isAdmin">
                    <mat-icon class="margintopneg10px" svgIcon="file_copy"></mat-icon>
                  </a>
                </div>
                <div>
                  <span>
                    <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window" *ngIf="isAdmin">
                    </mat-icon>
                  </span>
                </div>
                <!-- ***** BUTTONS END ***** -->

              </div> <!-- class=divflex-->

              <!-- GROUP BY PROVENANCE BEGIN -->
              <mat-tab-group class="margintop15px" (selectedTabChange)="tabSelectedUpdated($event)">

                <!-- ######################################################################## -->
                <!-- MAT-TAB: PROVENANCE BEGIN - Display data in each tab for each Provenance -->
                <!-- #########################################################################-->

                <mat-tab class="margintopneg10"
                  *ngFor="let prodProv of product.productProvenances; let indexProv = index">

                  <ng-template mat-tab-label>
                    <span class="font13px colorlightblue">Provenance:</span>&nbsp;
                    <span class="colorred font16px">
                      {{prodProv.provenance}}
                    </span>
                  </ng-template>

                  <!-- Product Provenance TAB Begin -->
                  <!-- INSIDE TAB MAT-CARD BEGIN -->

                  <div class="row margintop10px">
                    <div class="row-property">
                      <div class="row-property-key">
                        Product Names:
                      </div>
                      <div class="row-property-value">
                        <!-- Product Names -->
                        <div *ngIf="prodProv.productNames.length > 0; else noProductName">
                          <div *ngFor="let prodName of prodProv.productNames; let i = index">
                            <div class="divflex">
                              <div class="colorlightblue font16px"><b>{{prodName.productName}}</b>

                                <span *ngIf="prodName.productNameType">
                                  <span class="colorgray font11px">&nbsp;({{prodName.productNameType}})</span>
                                </span>

                              </div>
                              <div *ngIf="prodName.displayName && prodName.displayName == true">
                                &nbsp;
                                <mat-icon class="margintopneg5" matTooltip="Display Name">check</mat-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ng-template #noProductName>
                          (No Product Name)
                        </ng-template>
                      </div>
                    </div>

                    <div class="row-property">
                      <div class="row-property-key">
                        Product Status:
                      </div>
                      <div class="row-property-value">
                        {{prodProv.productStatus}}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="row-property">
                      <div class="row-property-key">
                        Product Codes:
                      </div>
                      <div class="row-property-value">

                        <span *ngFor="let prodCode of prodProv.productCodes; let i = index">
                          <span *ngIf="i > 0"><br></span>
                          <b>{{prodCode.productCodeType}}</b>: {{prodCode.productCode}}

                          <!-- Display Daily Med link, if Product Code Type is 'NDC CODE'-->
                          <span *ngIf="prodCode._dailyMedUrl">
                            <a href="{{prodCode._dailyMedUrl}}" target="_blank" alt="Go to DailyMed website"
                              title="Go to DailyMed website">
                              <img [src]="iconSrcPath">
                            </a>
                            <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window">
                            </mat-icon>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div class="row-property">
                      <div class="row-property-key">
                        Product Type:
                      </div>
                      <div class="row-property-value">
                        {{prodProv.productType}}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="row-property">
                      <div class="row-property-key">
                        Labeler Name:
                      </div>
                      <div class="row-property-value">
                        <div *ngFor="let prodComp of prodProv.productCompanies">
                          {{prodComp.companyName}}
                        </div>
                      </div>
                    </div>
                    <div class="row-property">
                      <div class="row-property-key">
                        Application Type Number:
                      </div>
                      <div class="row-property-value">
                        <span class="font14px" *ngIf="prodProv.applicationType">
                          {{prodProv.applicationType}}
                        </span>
                        <span class="font14px" *ngIf="prodProv.applicationNumber">
                          {{prodProv.applicationNumber}}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="row-property">
                      <div class="row-property-key">
                        City, State, Country:
                      </div>
                      <div class="row-property-value">

                        <div *ngFor="let prodComp of prodProv.productCompanies">
                          {{prodComp.companyCity}}
                          <span *ngIf="prodComp.companyState">,</span>
                          {{prodComp.companyState}}
                          <span *ngIf="prodComp.companyCountry">,</span>
                          {{prodComp.companyCountry}}
                        </div>

                      </div>
                    </div>
                    <div class="row-property">
                      <div class="row-property-key">
                        Public Domain:
                      </div>
                      <div class="row-property-value">
                        {{prodProv.publicDomain}}
                      </div>
                    </div>
                  </div>

                  <!-- INSIDE TAB MAT_CARD END -->

                </mat-tab>
              </mat-tab-group>
              <!-- GROUP BY PROVENANCE END -->


              <div class="row">
                <div class="row-property">
                  <div class="row-property-key">
                    Dosage Form:
                  </div>
                  <div class="row-property-value">
                    <div *ngFor="let prodManuItem of product.productManufactureItems; let indexManuItem = index">
                      <span *ngIf="indexManuItem > 0">|</span>
                      {{prodManuItem.dosageForm}}
                    </div>
                  </div>
                </div>

                <div class="row-property">
                  <div class="row-property-key">
                    Route of Administration:
                  </div>
                  <div class="row-property-value">
                    {{product.routeAdmin}}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="row-property">
                  <div class="row-property-key">
                    Manufacturer Name:
                  </div>
                  <div class="row-property-value">
                    {{product.manufacturerName}}
                  </div>
                </div>
                <div class="row-property">
                  <div class="row-property-key">
                    Total Manufacture Items:
                  </div>
                  <div class="row-property-value">
                    {{product.productManufactureItems.length}}
                  </div>
                </div>
              </div>

              <!-- Display Audit Details -->
              <div class="row" *ngIf="isAdmin">
                <div class="row-property">
                  <div class="row-property-key">
                    Created Date:
                  </div>
                  <div class="row-property-value">
                    {{product.creationDate|date: 'MM/dd/yyyy hh:mm:ss a'}}
                  </div>
                </div>
                <div class="row-property">
                  <div class="row-property-key">
                    Last Modified:
                  </div>
                  <div class="row-property-value">
                    {{product.lastModifiedDate|date: 'MM/dd/yyyy hh:mm:ss a'}}
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="isAdmin">
                <div class="row-property">
                  <div class="row-property-key">
                    Created By:
                  </div>
                  <div class="row-property-value">
                    {{product.createdBy}}
                  </div>
                </div>
                <div class="row-property">
                  <div class="row-property-key">
                    Last Modified By:
                  </div>
                  <div class="row-property-value">
                    {{product.modifiedBy}}
                  </div>
                </div>
              </div>

              <!-- Display Ingredients -->
              <div class="row">
                <div class="row-property">
                  <div class="row-property-key">
                    Substance Name
                    <br>(Active):&nbsp;
                    <span class="colormaroon font16px"><b>({{product._activeIngredients.length}})</b></span>
                  </div>

                  <div class="row-property-value">

                    <!-- Display ACTIVE Substance -->
                    <ol class="olstyle">

                      <div *ngFor="let prodIngred of product._activeIngredients; let indexActiveIngred = index">
                        <div *ngIf="prodIngred._ingredientName; else noActiveIngredientName">

                          <!-- Show/Hide Link, if substance Name has more than 5 records -->
                          <span *ngIf="indexActiveIngred == 5">
                            <br>
                            <a href="" (click)="toggleShowMoreLessActiveIngredArray(indexProd); false">
                              <span *ngIf="showMoreLessActiveIngredArray[indexProd]"><b>Show More</b></span>
                              <span *ngIf="!showMoreLessActiveIngredArray[indexProd]"><b>Show Less</b></span>
                            </a>
                            <br>
                          </span>

                          <div
                            *ngIf="(!showMoreLessActiveIngredArray[indexProd] && indexActiveIngred >= 5) || indexActiveIngred < 5">
                            <br>

                            <li>
                              <div class="divflex">

                                <div *ngIf="prodIngred._substanceUuid; else noActiveSubUuid">
                                  <a [routerLink]="['/substances', prodIngred._substanceUuid]"
                                    target="_blank">{{prodIngred._ingredientName}}
                                  </a>
                                </div>
                                <ng-template #noActiveSubUuid>
                                  {{prodIngred._ingredientName}}
                                </ng-template>

                                <div class="margintopneg15 marginleft10px" *ngIf="prodIngred._substanceUuid">
                                  <button mat-icon-button color="primary" style="padding:0px;margin:0px;"
                                    (click)="openImageModal(prodIngred._substanceUuid, prodIngred._ingredientName, prodIngred._approvalId)">
                                    <mat-icon matTooltip="Show Substance chemical structure">analytics
                                    </mat-icon>
                                  </button>
                                </div>

                              </div> <!-- class=divflex -->

                              <!-- Show Average -->
                              <div *ngIf="prodIngred.average">
                                <span class="font11px">Average:
                                  <b>{{prodIngred.average}}&nbsp;{{prodIngred.unit}}
                                  </b>
                                </span>
                              </div>

                              <div class="divflex">
                                <!-- Original Numerator -->
                                <div>
                                  <span *ngIf="prodIngred.originalNumeratorNumber">
                                    <span class="font11px">Numerator:
                                      <b>{{ prodIngred.originalNumeratorNumber }}&nbsp;{{
                                        prodIngred.originalNumeratorUnit
                                        }}
                                      </b>
                                    </span>
                                  </span>
                                </div>

                                <!-- Original Denominator -->
                                <div class="marginleft5px">
                                  <span *ngIf="prodIngred.originalDenominatorNumber">
                                    <span class="font11px">&nbsp;&nbsp;Denominator:
                                      <b>{{ prodIngred.originalDenominatorNumber }}&nbsp;{{
                                        prodIngred.originalDenominatorUnit
                                        }}
                                      </b>
                                    </span>
                                  </span>
                                </div>
                              </div> <!-- divflex -->

                              <!-- Display Ingredient Type -->
                              <div class="colorgreen" *ngIf="prodIngred.ingredientType">
                                ({{ prodIngred.ingredientType }})
                              </div>
                            </li>

                          </div> <!-- Hide/Show link -->

                        </div> <!-- if _ingredientName exists -->
                        <ng-template #noActiveIngredientName>
                          (No Substance Name)
                        </ng-template>
                      </div> <!-- For Loop: activeIngredients List -->

                    </ol>

                  </div>
                </div> <!-- Active Substance -->


                <!-- Display Inactive Substance -->
                <div class="row-property">
                  <div class="row-property-key">
                    Substance Name
                    <br>(Inactive/Other):&nbsp;
                    <span class="colormaroon font16px"><b>({{product._otherIngredients.length}})</b></span>
                  </div>

                  <div class="row-property-value">
                    <!-- Display Other Substance -->
                    <ol class="olstyle">

                      <div *ngFor="let prodIngred of product._otherIngredients; let indexOtherIngred = index">
                        <div *ngIf=" prodIngred._ingredientName; else noOtherIngredientName">

                          <!-- Show/Hide Link, if substance Name has more than 5 records -->
                          <span *ngIf="indexOtherIngred == 5">
                            <br>
                            <a href="" (click)="toggleShowMoreLessOtherIngredArray(indexProd); false">
                              <span *ngIf="showMoreLessOtherIngredArray[indexProd]"><b>Show More</b></span>
                              <span *ngIf="!showMoreLessOtherIngredArray[indexProd]"><b>Show Less</b></span>
                            </a>
                            <br>
                          </span>

                          <div
                            *ngIf="(!showMoreLessOtherIngredArray[indexProd] && indexOtherIngred >= 5) || indexOtherIngred < 5">
                            <br>

                            <li>
                              <div class="divflex">

                                <div *ngIf="prodIngred._substanceUuid; else noInactiveSubUuid">
                                  <a [routerLink]="['/substances', prodIngred._substanceUuid]"
                                    target="_blank">{{prodIngred._ingredientName}}
                                  </a>
                                </div>
                                <ng-template #noInactiveSubUuid>
                                  {{prodIngred._ingredientName}}
                                </ng-template>

                                <div class="margintopneg15 marginleft10px" *ngIf="prodIngred._substanceUuid">
                                  <button mat-icon-button color="primary" style="padding:0px;margin:0px;"
                                    (click)="openImageModal(prodIngred._substanceUuid, prodIngred._ingredientName, prodIngred._approvalId)">
                                    <mat-icon matTooltip="Click to view Substance chemical structure">analytics
                                    </mat-icon>
                                  </button>
                                </div>

                              </div> <!-- class=divflex -->

                              <!-- Show Average -->
                              <div *ngIf="prodIngred.average">
                                <span class="font11px">Average:
                                  <b>{{prodIngred.average}}&nbsp;{{prodIngred.unit}}
                                  </b>
                                </span>
                              </div>

                              <div class="divflex">
                                <!-- Original Numerator -->
                                <div>
                                  <span *ngIf="prodIngred.originalNumeratorNumber">
                                    <span class="font11px">Numerator:
                                      <b>{{ prodIngred.originalNumeratorNumber }}&nbsp;{{
                                        prodIngred.originalNumeratorUnit
                                        }}
                                      </b>
                                    </span>
                                  </span>
                                </div>

                                <!-- Original Denominator -->
                                <div class="marginleft5px">
                                  <span *ngIf="prodIngred.originalDenominatorNumber">
                                    <span class="font11px">&nbsp;&nbsp;Denominator:
                                      <b>{{ prodIngred.originalDenominatorNumber }}&nbsp;{{
                                        prodIngred.originalDenominatorUnit
                                        }}
                                      </b>
                                    </span>
                                  </span>
                                </div>

                              </div> <!-- divflex -->

                              <!-- Display Ingredient Type -->
                              <div class="colorgreen" *ngIf="prodIngred.ingredientType">
                                ({{ prodIngred.ingredientType }})
                              </div>

                            </li>

                          </div> <!-- Hide/Show link -->

                        </div> <!-- if _ingredientName exists -->
                        <ng-template #noOtherIngredientName>
                          (No Substance Name)
                        </ng-template>
                      </div> <!-- For Loop: otherIngredients List -->

                    </ol>

                  </div>
                </div> <!-- Inactive Substance -->

              </div> <!-- row Substance Name-->

            </mat-card-content>
          </mat-card>
          <!-- FIRST MAT-CARD END -->


        </div> <!-- class=cards -->
      </div> <!-- [ngClass]=view -->

      <!-- Pagination at the bottom of the page -->
      <div class="controls-container">
        <div class="full-paginator">
          <mat-paginator #paginator [length]="totalProducts" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 50, 100]" [showFirstLastButtons]="true" (page)="changePage($event)">
          </mat-paginator>

          <div class="page-selector">
            <span class="page-label">Page: </span>
            <mat-form-field class="page-input" [ngClass]="{'bad-page':invalidPage}">
              <input matInput matTooltip="Type a page and press enter" [ngClass]="{'bad-page':invalidPage}"
                [value]="pageIndex + 1" #pagenum (keydown.enter)="customPage($event)"
                (input)="validatePageInput($event)? invalidPage = false: invalidPage = true"
                (focusout)="pagenum.value = pageIndex + 1; invalidPage = false">
            </mat-form-field>
            <span class="page-label"> of {{lastPage}}</span>
          </div>
        </div>
      </div>

      <ng-template #noRecordProduct>
        <!-- No Product Found. -->
        <!--
      <div class="padleft10px colorred">
        <br>
        No Product records found. Please Reindex to see the Product records here.
      </div>
      -->
      </ng-template>

    </div> <!-- class=side-nav-content -->
  </mat-sidenav-content>
</mat-sidenav-container>