import { NgModule } from '@angular/core';
import { KetcherWrapperComponent } from './ketcher-wrapper.component';

@NgModule({
  imports: [
  ],
  declarations: [KetcherWrapperComponent],
  exports: [KetcherWrapperComponent]
})
export class KetcherWrapperModule { }
