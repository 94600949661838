<div class = 'spinner-container'>
        <mat-progress-spinner  mode="indeterminate" *ngIf="loading" class = "spinner">
        </mat-progress-spinner>
      </div>
      <div *ngIf ="!loading">

    
<div *ngIf = "oldReferences.length > 0">
  
  
    <span class = "explain"> Select a recently submitted reference to populate the form</span>
        <table mat-table matSort [dataSource]="pagedReferences">
            <ng-container matColumnDef="use">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Use </th>
                <td mat-cell *matCellDef="let reference"><a class = "reuse-button" (click) = "selectReference(reference)">select</a> </td>
              </ng-container>
  
            <ng-container matColumnDef="citation">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Citation </th>
              <td mat-cell *matCellDef="let reference">
                <a *ngIf = "reference.url" [href]="reference.url" target = "_blank" >{{reference.citation}} </a>
                <span *ngIf = "!reference.url"> {{reference.citation}} </span>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
              <td mat-cell *matCellDef="let reference"> {{reference.docType}} </td>
            </ng-container>
      
            <ng-container matColumnDef="tags">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tags </th>
              <td mat-cell *matCellDef="let reference">
                <span *ngFor="let tag of reference.tags; last as isLast">{{tag}}<span *ngIf="!isLast">,&nbsp;</span></span>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="dateAcessed">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Accessed </th>
              <td mat-cell *matCellDef="let reference"> {{reference.lastEdited | date : 'short'}} </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class = "narrow-row" *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <div class="full-paginator">
            <mat-paginator #paginator [length]="oldReferences.length" [pageIndex]="pageIndex" [pageSize]="pageSize" 
            (page)="changePage($event)" [showFirstLastButtons]="false">
         </mat-paginator>
          </div>
    </div>
    <div *ngIf = "oldReferences.length === 0">
        <i>No previous references found for this user</i>
        </div>

    </div>



    