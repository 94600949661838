<div class="parameter-form-container">
  <div class="form-row">
    <mat-form-field class="name">
      <input matInput placeholder="Name" [(ngModel)]="parameter.name" required name="name" />
    </mat-form-field>
    <mat-form-field class="type">
      <mat-label>Type</mat-label>
      <mat-select [(ngModel)]="parameter.type" name="type">
        <mat-option *ngFor="let propertyType of propertyTypeList" [value]="propertyType.value">
          {{propertyType.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="amount-form-container">
    <div class="amount-title">Amount</div>
    <app-amount-form [substanceAmount]="parameter.value"></app-amount-form>
  </div>
</div>