import { NgModule } from '@angular/core';
import { JsdrawWrapperComponent } from './jsdraw-wrapper.component';

@NgModule({
  imports: [
  ],
  declarations: [JsdrawWrapperComponent],
  exports: [JsdrawWrapperComponent]
})
export class JsdrawWrapperModule { }
