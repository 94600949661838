<div  class = "monitor-container">
<h2 class = monitor-header>Upload Monitor</h2>
<mat-card>

<div *ngIf = "loadJob">
    <div class = "full-row">
        <div class ="job-title">Importing {{loadJob.name}}</div>
        <span class = "middle-fill"></span>
        <div class = "large-val">Status: {{loadJob.status}}<div class = "ellipses" *ngIf = "loadJob.status === 'RUNNING'">{{ellipses}}</div></div>
    </div>
<div class = "lower-body">
<div class = "loader-container">

  <div class = "progress-container">
    <div class = "bar-label">Progress</div>
        <div>  
    <mat-progress-bar class = "load-progress" color="accent" mode="determinate" [value] = "(dynamic / max)*(100)" >
    </mat-progress-bar>
    <div class = "label-row">
        <div [style.width.%] = "(dynamic / max)*(100)"  class = "count-cont">
                <span class = "count-row-label">{{dynamic}} / {{max}}</span>
        </div>
        </div>
</div>
           
</div>
<button mat-button (click) = "hide = !hide" class = 'hide-button'>{{hide? 'show':'hide'}} details</button>
<div *ngIf = "!hide">
<div class = "progress-container"  *ngIf = "(stats.extractPass && stats.extractPass >= 0) || stats.extractFail && stats.extractFail >= 0">
<div class = "bar-label">Extracted Records</div>  
    <mat-progress-bar class = "load-progress load-success" [color]="accent" mode="determinate" 
    [value] = "stats.extractPass"
    >
    </mat-progress-bar>

    <mat-progress-bar class = "load-progress load-fail" [color]="primary"  mode="determinate" 
    [value] = "stats.extractFail"
     >
    </mat-progress-bar>
    <div class = "label-row">
        <div [style.width.%] = "stats.extractPass"  class = "count-cont" *ngIf = "loadJob.statistics.recordsExtractedSuccess > 0">
                <span class = "count-row-label">{{loadJob.statistics.recordsExtractedSuccess}}</span>
        </div>
        <div [style.width.%] = "stats.extractFail  - stats.extractPass" class = "count-cont" *ngIf = "loadJob.statistics.recordsExtractedFailed > 0">
            <span  class = "count-row-label">{{loadJob.statistics.recordsExtractedFailed}}</span>
        </div>
    </div>
</div>
<div class = "progress-container" *ngIf = "(stats.processedFail && stats.processedFail >=  0) || (stats.processedPass && stats.processedPass >=  0)">
    <div class = "bar-label">Processed Records</div>    
    <mat-progress-bar class = "load-progress load-success" [color]="accent" mode="determinate" 
    [value] = "stats.processedPass" 
     >

    </mat-progress-bar>

    <mat-progress-bar class = "load-progress load-fail"  mode="determinate" 
    [value] = "stats.processedFail"
     >
    </mat-progress-bar>
    <div class = "label-row">
        <div [style.width.%] = "(stats.processedPass)"  class = "count-cont" *ngIf = "loadJob.statistics.recordsProcessedSuccess > 0">
                <span class = "count-row-label">{{loadJob.statistics.recordsProcessedSuccess}}</span>
        </div>
        <div [style.width.%] = "stats.processedFail - stats.processedPass" class = "count-cont" *ngIf = "loadJob.statistics.recordsProcessedFailed > 0">
            <span  class = "count-row-label">{{loadJob.statistics.recordsProcessedFailed }}</span>
        </div>
    </div>
</div>
<div class = "progress-container"*ngIf = "(stats.persistPass && stats.persistPass >=  0) || stats.persistFail && stats.persistFail >=  0" >
    <div class = "bar-label">Persisted Records</div>    
    <mat-progress-bar class = "load-progress load-success"  [color]="accent" mode="determinate" 
    [value] = "stats.persistPass" 
    >
    </mat-progress-bar>

    <mat-progress-bar class = "load-progress load-fail" mode="determinate" 
    [value] = "stats.persistFail"
    >
    </mat-progress-bar>
    <div class = "label-row">
        <div [style.width.%] = "stats.persistPass"  class = "count-cont" *ngIf = "loadJob.statistics.recordsPersistedSuccess > 0">
                <span class = "count-row-label">{{loadJob.statistics.recordsPersistedSuccess}}</span>
        </div>
        <div [style.width.%] = "stats.persistFail - stats.persistPass" class = "count-cont" *ngIf = "loadJob.statistics.recordsPersistedFailed > 0">
            <span  class = "count-row-label">{{loadJob.statistics.recordsPersistedFailed}}</span>
        </div>
    </div>
</div> 


</div>

</div>
<div class = "stat-container">
<div *ngIf="loadJob.start"  class = "label-value">
        <div class = "label">
       Time started: </div>
       <div class = "value" matTooltip = "{{loadJob.start | date: 'medium'}}"><code> {{startedHuman}}</code>
    </div>
    </div>
 <div *ngIf="loadJob.stop"  class = "label-value" >
        <div class = "label">
    Time Finished:</div>
    <div class = "value" matTooltip = "{{loadJob.stop | date: 'medium'}}"><code> {{finishedHuman}}</code>
 </div>
 </div>
<div class = "label-value">
     <div class = "label">Total time running:</div>
        <div class = "value"><code>{{humanTimeTotal}}</code></div>
    </div>
        <div class = "label-value">
                <div class = "label">Average Records per second:</div>
                <div class = "value"> <code>{{averagePersistRate | number}}</code> </div>
    </div>
        <div *ngIf = "!loadJob.stop"  class = "label-value">
                <div class = "label">  Estimated time left: </div>
        <div class = "value"><code>{{humanTimeLeft}}</code></div>
    </div>
            <div *ngIf = "!loadJob.stop"  class = "label-value">
                <div class = "label">Estimated total time: </div>
                <div class = "value"><code>{{humanTimeEstimate}}</code></div>
        
            </div>
</div>
</div>
<div class = "full-row" >
    <button mat-button (click) = "clearJob()">New Data Import</button>
    <span class = "middle-fill"></span>
    <span matTooltip = "{{loadJob.stop? 'The upload is no longer in progress': ''}}">
    <button mat-button *ngIf = "!monitor" [disabled] = "loadJob.stop" (click) = "refresh()" >Update</button>
    </span>
    <button mat-button *ngIf = "monitor" (click) = "monitor = false">Stop Updating</button>
    <button mat-button *ngIf = "!monitor && !loadJob.stop" (click) = "monitor = true; refresh()">Resume Auto Updates</button>
</div>

</div>
</mat-card>
</div>