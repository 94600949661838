import { NgModule } from '@angular/core';
import { FileSelectDirective } from './file-select.directive';

@NgModule({
  imports: [
  ],
  declarations: [FileSelectDirective],
  exports: [FileSelectDirective]
})
export class FileSelectModule { }
