<mat-sidenav-container [hasBackdrop]="hasBackdrop" [ngClass]="{ collapsed: showExactMatches }">
  <mat-sidenav [disableClose]="true" #matSideNavInstance mode="side" opened="false">
    <app-facets-manager
      (facetsParamsUpdated)="facetsParamsUpdated($event)"
      (facetsLoaded)="facetsLoaded($event)"
      [rawFacets]="rawFacets"
      configName="clinicaltrialsus"
      includeFacetSearch="true">
    </app-facets-manager>
  </mat-sidenav>
<mat-sidenav-content>
<div class="example-container mat-elevation-z8 clinical-trials-browse-wrapper">
<h3>Browse Clinical Trials</h3>
<div>
<form class="filter-form clinical-trials-browse-filter-form">
  <mat-form-field class='clinical-trials-browse-filter-form-searchTerm'>
    <input matInput placeholder="Search Term" [(ngModel)]="searchTerm" name="searchTerm" (keyup.enter)="setSearchTermValue();" />
  </mat-form-field>
  
  <mat-form-field class='clinical-trials-browse-filter-form-searchType'>
    <mat-label>Type</mat-label>
    <mat-select  [(ngModel)]="searchType" name="searchType">
      <mat-option *ngFor="let searchType of searchTypes" [value]="searchType.value">
        {{searchType.title}}
     </mat-option>
    </mat-select>
  </mat-form-field>
 <button mat-flat-button color="primary" class="clinical-trials-browse-filter-form-button-open"  matSuffix aria-label="Search Clinical Trials" (click)="setSearchTermValue();">Go</button>
 <span *ngIf="!showHelp">
  <button mat-icon-button aria-label="Show Help" (click)="toggleShowHelp()">?</button>
</span>
</form>
<mat-card class='clinical-trials-browse-show-help' *ngIf="showHelp">
  <div>
  <span class="middle-fill"></span>
  <button class="clinical-trials-browse-filter-form-help-button-close" mat-icon-button (click)="toggleShowHelp()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  </div>
  <div>
    <p><strong>All</strong> - search in all indexed fields; wild cards work (e.g health*).</p>
    <p><strong>Title</strong> - search for full words contained in the title field.</p>
    <p><strong>Trial Number</strong> - search for Trial Number exactly.</p>
    <p><strong>Substance Key</strong> - search for Substance Key exactly.</p>
  </div>
</mat-card>
</div>
<div>
  <mat-paginator #paginator [length]="totalClinicalTrials" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 50, 100]"
  (page)="changePage($event)">
</mat-paginator>
</div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->


 <!-- edit Column -->
 
 <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
        <a class="clinical-trials-browse-edit1"  title="Edit in this tab" [routerLink]="['/edit-clinical-trial', element.trialNumber]" [queryParams]="{}" >Edit</a>&nbsp;<a class="clinical-trials-browse-edit2" target="_blank" title="Edit in new tab" [href]="'edit-clinical-trial/' + element.trialNumber">+</a>
    </td>
  </ng-container>
  
<!-- trialNumber Column -->
  <ng-container matColumnDef="trialNumber">
    <th mat-header-cell *matHeaderCellDef>Trial Number</th>
    <td mat-cell *matCellDef="let element">
      <a target="_blank" title="see clinicaltrials.gov" [href]="'https://clinicaltrials.gov/ct2/show/' + element.trialNumber">{{element.trialNumber}}</a>
    </td>
  </ng-container>

  <!-- title Column -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let element">
      {{element.title}} <span *ngIf="element.clinicalTrialUSDrug"> ({{element.clinicalTrialUSDrug.length}}) </span>
    </td>
  </ng-container>

  <!-- ID Column -->
  <ng-container matColumnDef="lastUpdated">
    <th mat-header-cell *matHeaderCellDef>Last Updated</th>
    <td mat-cell *matCellDef="let element">{{element.lastUpdated | date : 'MM/dd/yyyy':'UTC'}} </td>
  </ng-container>

  <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class ="clinical-trials-browse-button-cell" *matCellDef="let element; let index = index">
          <span class="clinical-trials-browse-del" aria-label="Del" (click)="deleteClinicalTrial(index);">Del</span>
        </td>
    </ng-container>
  
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>  
</table>
<div>
  <mat-paginator #paginator [length]="totalClinicalTrials" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 50, 100]"
  (page)="changePage($event)">
</mat-paginator>
</div>
</div>
</mat-sidenav-content>
</mat-sidenav-container>
