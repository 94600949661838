<h1 mat-dialog-title>
    {{dialogTitle}}
</h1>
<div mat-dialog-content>
    {{dialogMessage}}
</div>
<div class="flex-row dialog-actions" mat-dialog-actions *ngIf="!fileUrl">
    <button mat-flat-button color="primary" (click)="dismissDialog('continue')">Continue Editing</button>

    <!-- Do not show the following buttons, if the Dialog is launching from SSG4 page -->
    <button *ngIf="isCoreSubstance === 'true' && !staging" mat-flat-button color="primary" (click)="dismissDialog('browse')">Go to
        Browse</button>
    <button *ngIf="isCoreSubstance === 'true' && !staging" mat-flat-button color="primary" (click)="dismissDialog('view')">View
        Substance</button>
        <button *ngIf="staging" mat-flat-button color="primary" (click)="dismissDialog('staging')">Return to Staging Area</button>
</div>
<div class="flex-row dialog-actions" mat-dialog-actions *ngIf="fileUrl">
    <span class="middle-fill"></span>
    <!-- Do not show the following buttons, if the Dialog is launching from SSG4 page -->
    <button *ngIf="isCoreSubstance === 'true'" mat-flat-button color="primary" (click)="dismissDialog('browse')">Go to
        Browse</button>
    <button *ngIf="isCoreSubstance === 'true'" mat-flat-button color="primary" (click)="dismissDialog('viewInPfda')">View
        Substance</button>
</div>
