
  <div mat-dialog-header class = "top-label">
      <div class = "form-row">

     
         <mat-form-field class="preset">
                <mat-label class = "config-label"> Use Export Preset Options: </mat-label> 
            <mat-select  name = "fileType" [(ngModel)]="loadedConfig" name="term" (ngModelChange)="switchConfig($event)" >
                  <mat-option *ngFor = "let option of options" [value]="option" > {{option.exporterKey ? option.exporterKey : 'no name'}}
                         </mat-option>
                </mat-select>
            </mat-form-field>
    
       
           <div class = "top-button-container">
               <button *ngIf = "!data.hideOptionButtons" mat-flat-button color="primary" (click) = "toggleShowOptions()">{{ showOptions? 'Hide Preset Options' :'Edit Preset Options'}}</button>
               <button *ngIf = "unsaved" mat-flat-button color="primary" class = "top-button"  (click) = "undo()">Discard Unsaved Changes</button>
              &nbsp; <button class = "top-button" mat-flat-button color="warn" *ngIf = "!data.hideOptionButtons && loadedConfig && loadedConfig.exporterKey !== 'ALL_DATA' && loadedConfig.exporterKey !== 'PUBLIC_DATA_ONLY' " (click) = "deleteConfig()"><mat-icon svgIcon="delete_forever"></mat-icon> Remove Preset</button>

        </div> 
        </div>
        
        </div>
   <mat-dialog-content>
   <mat-tab-group selectedIndex = "1"  class = "tab-group" *ngIf = "showOptions">
       
       
        <mat-tab  label= "Expander" >
                <sf-form [schema]="expanderSchema"  [model]="expanderModel" (onChange)="setValue($event.value, 'expander')" class = "top-margin"></sf-form>


        </mat-tab> 

    <mat-tab  label= "Scrubber" style = "margin-bottom:10px;">
         
    <br/>
    
    <sf-form [schema]="scrubberSchema"  [model]="scrubberModel" (onChange)="setValue($event.value, 'scrubber')" class = "top-margin"></sf-form>
    
    
    <!--   <h2 matDialogTitle>Enter a Filename</h2>
        <div class = "padding">
            Preparing download. Please enter a file name.
        </div>
        <div class = "dialog-row">
            <div>
                <mat-form-field class = "name-form">
                    <input matInput class = "name-field" type="text" placeholder="Filename" [(ngModel)] = "name">
                </mat-form-field>  
            </div>
            <div class = "format">.{{extension}}</div>
        </div>-->
        

    </mat-tab>
    <mat-tab  label= "Exporter Settings">
        <sf-form [schema]="exporterSchema"  [model]="exporterModel" (onChange)="setValue($event.value, 'exporter')" class = "top-margin"></sf-form>
    </mat-tab> 
    </mat-tab-group>
    <div class = "form-row top-margin" >
            <div class = "padding right-margin">
                Please enter a file name:
            </div>
            <div class = "dialog-row">
                <div>
                    <mat-form-field class = "name-form">
                        <input matInput class = "name-field" type="text" placeholder="Filename" [(ngModel)] = "name">
                    </mat-form-field>  
                </div>
                <div class = "format">.{{extension}}</div>
            </div>
            </div>
</mat-dialog-content>
    <mat-dialog-actions align="end">
            <div class = "message-container">
                <div *ngIf = "message" class = "message">{{message}}</div>
            </div>
            <button mat-flat-button color="primary"(click) = "cancel()">Cancel</button>
            <span class="middle-fill"></span>
            <mat-form-field  *ngIf = "showOptions"  class = "right-margin"><input class = "right-margin" matInput placeholder="Config Name" [(ngModel)]="configName" name="Config Name" /></mat-form-field>

            <button *ngIf = "showOptions" mat-flat-button color="primary" (click) = "saveConfig()" class = "right-margin" [disabled] = "!showOptions">Save New Config</button>
            <button  *ngIf = "showOptions" mat-flat-button color="primary" (click) = "updateConfig()" class = "right-margin"
                 [disabled] = "!loadedConfig || loadedConfig.exporterKey === 'PUBLIC_DATA_ONLY' || loadedConfig.exporterKey === 'ALL_DATA'" >Save Changes
            </button>

            <button mat-flat-button color="primary" [disabled] = "unsaved" (click) = "save()" >Export{{loadedConfig? '' : ' (no config)'}}</button>
        </mat-dialog-actions>