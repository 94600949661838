<div *ngIf="!isPolling" class="wrapper">
  <div *ngIf="showTitle && showTitle===true">
  <h2 class="summary-title">Bulk Search Summary (Full Results)</h2>
</div>


<div class="statistics" *ngIf="totalQueries!==null && totalQueries>0">
  <span class="linkish" (click)="setAndFilterData(undefined)">All Queries: {{totalQueries}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
  <span class="linkish" (click)="setAndFilterData('records_length>0')">Matched: {{totalQueriesMatch}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
  <span class="linkish" (click)="setAndFilterData('records_length:0')">Unmatched: {{totalQueriesUnMatch}}</span>
</div>

<div class="statistics" *ngIf="totalQueries!==null && totalQueries>0">
  <span *ngIf="qFilter===undefined">
    Showing all queries
  </span>
  <span *ngIf="qFilter==='records_length>0'">
    Showing {{qFilteredTotal}} matched queries (<span class="linkish" (click)="setAndFilterData(undefined)">show all</span>)
  </span> 
  <span *ngIf="qFilter==='records_length:0'">
    Showing {{qFilteredTotal}} unmatched queries (<span class="linkish" (click)="setAndFilterData(undefined)">show all</span>)
  </span>
</div>

<div class="export">
  <div *ngIf="recordOverviews && recordOverviews.length>0 && isLoggedIn">
    <button matTooltip="Export Bulk Search Results" (click)="getBulkSearchStatusResultsSummaryForDownload()"
      mat-button class="mat-button export-button mat-raised-button">
      <mat-icon svgIcon="get_app"></mat-icon> Download Report
    </button>
  </div>
</div>

<table mat-table [dataSource]="dataSource" class="" matSort (matSortChange)="sortData($event)">
  <ng-container matColumnDef="searchTerm">
    <th mat-header-cell mat-sort-header='searchTerm' *matHeaderCellDef>{{displayedColumnNames['searchTerm']}}</th>
    <td mat-cell *matCellDef="let element" title="{{element.modifiedSearchTerm}}">{{element.searchTerm}}</td>
  </ng-container>
  <ng-container matColumnDef="displayName">
    <th mat-header-cell *matHeaderCellDef>{{displayedColumnNames['displayName']}}</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element.matches===0">
        {{element.displayName}}
      </span>
      <span *ngIf="element.matches===1">
        <a target="_blank" *ngIf="element.displayName" title="View the entity in new tab."  [routerLink]="['/', context, element.id]">
          {{element.displayName}}
        </a>
        <span *ngIf="!(element.displayName)">(<a target="_blank" title="View the entity in new tab."  [routerLink]="['/', context, element.id]">no name</a>)</span>
      </span>
      <span *ngIf="element.matches>1">
        <a target="_blank" title="Perform partial search in new tab."  [routerLink]="['/browse-substance']" [queryParams]="{search: element.modifiedSearchTerm}">{{element.displayName}}</a>
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="matches">
    <th mat-header-cell mat-sort-header='matches' *matHeaderCellDef>{{displayedColumnNames['matches']}}</th>
    <td mat-cell *matCellDef="let element">{{element.matches}}</td>
  </ng-container>
  <ng-container matColumnDef="displayCode">
    <th mat-header-cell *matHeaderCellDef>{{displayedColumnNames['displayCode']}}</th>
    <td mat-cell *matCellDef="let element">{{element.displayCode}}</td>
  </ng-container>  
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
 
</table>


<!-- totalQueries -->
<mat-paginator #paginator [length]="qFilteredTotal" [pageIndex]="qPageIndex" [pageSize]="qPageSize" [pageSizeOptions]="[5, 10, 50, 100]"
(page)="changePage($event)"></mat-paginator>
</div>