<h1 mat-dialog-title>{{isNew ? 'Add' : 'Edit'}} Parameter</h1>
<div mat-dialog-content>
  <div *ngIf="subsParameter">
    <app-property-parameter-form [parameter]="subsParameter" #parameterForm>
    </app-property-parameter-form>
    <div class="actions">
      <span class="middle-fill"></span>
      <button mat-button (click)="cancel()">Cancel</button>
      <button mat-button [disabled]="!parameterForm || !parameterForm.isValid" (click)="save()">
        Save
      </button>
    </div>
  </div>
</div>
