<div class="top-fixed">

  <!-- Buttons -->
  <div class="actions-container">

    <button mat-flat-button color="primary" (click)="showJSON()">Show JSON</button>&nbsp;&nbsp;&nbsp;

    <a mat-flat-button color="primary" [href]="downloadJsonHref" (click)="saveJSON()" download="{{jsonFileName}}.json">
      <span>Export JSON</span>
    </a>&nbsp;&nbsp;&nbsp;

    <!-- Do not show 'Import JSON' button when in Update Invitro Pharamcology page -->
    <!--
    <span *ngIf="assay && !assay.id">
      <button mat-flat-button color="primary" (click)="importJSON()">Import JSON</button>
    </span>
    -->
    <button mat-flat-button class="hide-show-messages" (click)="toggleValidation()" [disabled]="isLoading"
      *ngIf="validationMessages && validationMessages.length > 0">
      {{showSubmissionMessages ? 'Hide' : 'Show'}} messages
      <mat-icon [svgIcon]="showSubmissionMessages ? 'chevron_up' : 'chevron_down'"></mat-icon>
    </button>
    <span class="middle-fill"></span>

    <!-- Assay Details Link -->
    <!--
    <a mat-flat-button color="primary" routerLink="/invitro-pharm/{{assay.id}}" target="_blank"
      matTooltip='Opens in a new window' *ngIf="assay && assay.id">
      View Assay Details
      <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window"></mat-icon>
    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    -->

    <!-- Validate and Submit Button -->
    &nbsp;
    <button mat-flat-button color="warn" (click)="validate()">Validate and Submit</button>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button mat-flat-button color="primary" [routerLink]="['/home']" matTooltip='Close this window'>Close</button>
  </div> <!-- div class=actions-container-->


  <!-- Validation Message Display -->
  <div [ngClass]="{'submission-messages': true, collapsed: !showSubmissionMessages, expanded: showSubmissionMessages}">
    <div class="submission-message" *ngIf="submissionMessage">
      {{submissionMessage}}
    </div>

    <!-- Validation Message Display and Submit Button -->
    <div *ngIf="validationMessages">
      <span *ngIf=" validationMessages.length > 0">
        <div class="submission-message" *ngIf='!serverError && !approving'>
          Please correct or dismiss the following errors and submit again:
        </div>
        <div class="validation-message" *ngFor="let message of validationMessages; index as i">
          <div class="message-type" [ngClass]="message.messageType == 'ERROR' ? 'error-message' : 'warning-message'">
            {{message.messageType}}</div>
          <div class="message">{{message.message}}<span *ngFor="let link of message.links"><br /><a [href]="link.href"
                target="_blank">{{link.text}}</a></span></div>
          <button mat-icon-button matTooltip="Dismiss" *ngIf="message.messageType != 'ERROR' && !approving"
            (click)="dismissValidationMessage(i)">
            <mat-icon svgIcon="cancel"></mat-icon>
          </button>
        </div>
      </span>

      <div class="dismiss-container">
        <span class="middle-fill"></span>
        <button mat-flat-button color="primary" (click)="submit()"
          [disabled]="isLoading || (this.validationResult === false)">{{validationMessages &&
          validationMessages.length > 0?'Dismiss All and ':''}}
          Submit</button>
      </div>
    </div>
    <!-- Validation Message and Submit Button END -->
  </div> <!-- div submission-messages collapsed/expanded -->

</div> <!-- div class=top-fixed -->


<!-- Form Title and Authentication Details -->
<div class="form-content-container mat-form-field-style">
  <div class="scrollable-container">

    <div class="divcenter headerstyle margintop20px bordergray">
      <!-- Display Message-->
      <div class="message-style bordergray" *ngIf="message">
        {{message}}
      </div>

      <div class="divflex margintop10px">

        <div class="width650px font24px">
          <b>{{title}}</b>
          &nbsp;&nbsp;

          <!-- Edit GSRS record button -->
          <!--
          <a [routerLink]="['/invitro-pharm/assay', assay.id, 'edit']" target="_blank" *ngIf="isAdmin"
            matTooltip='Edit GSRS In-vitro Pharmacology Record'>
            <mat-icon svgIcon="edit"></mat-icon>
          </a>&nbsp;&nbsp;
          -->

          <a color="primary" [href]="downloadJsonHref" (click)="saveJSON()" download="{{jsonFileName}}.json"
            matTooltip='Export Json'>
            <mat-icon svgIcon="get_app" [ngStyle]="{'color':'white'}"></mat-icon>
          </a>&nbsp;&nbsp;&nbsp;
        </div>

        <!--
        <div class="width50percent font11px margintop10px" *ngIf="assay && assay.id">
          <span class="colorgray">Created By:</span> {{assay.createdBy}}&nbsp;&nbsp;&nbsp;
          <span class="colorgray">Create Date:</span>{{assay.creationDate|date: 'MM/dd/yyyy hh:mm:ss
          a'}}&nbsp;&nbsp;&nbsp;
          <span class="colorgray">Modified By:</span> {{assay.modifiedBy}}&nbsp;&nbsp;&nbsp;
          <span class="colorgray">Modify Date:</span> {{assay.lastModifiedDate|date: 'MM/dd/yyyy hh:mm:ss a'}}
        </div> -->
      </div> <!-- class=divflex -->

      <!-- Link to All Test Agent page -->
      <div class="marginleft40px margintop10px">
        <a mat-flat-button class="bordergray" color="basic" [routerLink]="['/invitro-pharm']"
          [queryParams]="{view : 'all-testagents'}" target="_blank" matTooltip='Opens in a new window'>
          <mat-icon class="small-icon" svgIcon="open_in_new" alt="View All Test Agents"
            matTooltip="View All Test Agents. Opens in new window"></mat-icon>
          View All Test Agents
        </a>
      </div>

    </div> <!-- class=divcenter -->

    <!-- ############################################################ -->
    <!-- SCREENING/SUMMARY DETAILS FORM BEGIN                                     -->
    <!-- ************************************************************ -->
    <div class="details-container">
      <div class="width100percent">

        <mat-card>

          <mat-card-title>
            <span class="colorblue font20px"><b></b></span>
          </mat-card-title>

          <mat-card-content class="marginright10px">

            <div class="divflex">
              <div class="font18px fontbold">
                Total Summaries: {{screeningList.length}}

                <br><br>
                <!-- Add More Summary Button -->
                <div class="font18px">
                  <button mat-flat-button color="primary" class="marginright30px bordergreen" color="primary"
                    (click)="addNewSummary(); $event.stopPropagation()" matTooltip="Add New Summary">
                    <mat-icon>add_circle_outline</mat-icon><span class="colorgreen">Add More Summary</span>
                  </button>&nbsp;&nbsp;
                </div>
              </div>

              <!-- TEST AGENT - Select Substance -->
              <div class="marginleft100px padtop10px related-substance">
                <app-substance-selector (selectionUpdated)="testAgentUpdated($event)"
                  eventCategory="substanceRelationshipRelatedSub"
                  placeholder="Test Agent - Search by Name/CAS RN/UNII/BDNUM" header="Test Agent"
                  [subuuid]="testAgentSubstanceUuid" [showMorelinks]="true">
                </app-substance-selector>
              </div>

            </div> <!-- class=divflex -->


            <!-- Loop Screening/Summary -->
            <div class="divradius divshadow margintop20px"
              *ngFor="let screening of screeningList; let indexScreening = index">

              <!--
              <div class="divradius divshadow margintop20px"
              *ngFor="let screening of assay.invitroAssayScreenings; let indexScreening = index">
              -->
              <div class="divflex" style="vertical-align:top;">

                <div class="margintop10px width30px">
                  &nbsp;&nbsp;&nbsp;
                  <b>{{(indexScreening+1)}}</b>
                  <br><br>
                  <button mat-icon-button color="primary" (click)="confirmDeleteAssay(indexScreening)"
                    matTooltip="Delete this record">
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </div>

                <!-- START -->
                <div class="marginleft20px">

                  <div class="form-row margintop10px">

                    <div class="divflex">

                      <!-- TARGET NAME - Select Substance -->
                      <div class="padtop10px related-substance">
                        <app-substance-selector (selectionUpdated)="targetNameUpdated($event, indexScreening)"
                          eventCategory="substanceRelationshipRelatedSub"
                          placeholder="Target Name - Search by Name/CAS RN/UNII/BDNUM" header="Target Name"
                          [subuuid]="screening.invitroSummary.targetNameSubstanceKey" [showMorelinks]="true">
                        </app-substance-selector>
                      </div>

                      <!-- TARGET NAME - Select from Existing Assay Target Name -->
                      <!--
                      <mat-form-field class="col-3-1">
                        <mat-label>Select Existing Assay Target Name</mat-label>
                        <mat-select [formControl]="existingAssayControl"
                          (selectionChange)="selectionChangeExistingAssay($event, indexAssay)">
                          <mat-option *ngFor="let asy of assay._existingAssayList; let index = index" [value]="index">
                            ID:{{asy.id}}&nbsp;&nbsp;
                            External Assay Source:{{asy.externalAssaySource}}&nbsp;&nbsp;
                            External Assay ID:{{asy.externalAssayId}}&nbsp;&nbsp;
                            Target Name:{{asy.targetName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      -->

                      <!-- Search Existing Assay -->

                      <!--

                      <div class="col-3-1">
                        <span *ngIf="assay.targetName">
                          <span class="font11px"><b>Selected Target Name:</b></span><br>
                          {{assay.targetName}}
                        </span>
                      </div>
                    -->

                      <div class="width800px marginleft10px">

                        <div class="form-row">
                          <div class="width300px margintop10px">
                            <app-invitro-pharmacology-text-search placeholder="Search for Existing Assay"
                              styling="homePage" [searchValue]="searchValue"
                              (searchPerformed)="processSubstanceSearch($event, indexScreening)"
                              eventCategory="invitroPharmacologySearch">
                            </app-invitro-pharmacology-text-search>
                          </div>

                          <!-- Selected Assay -->
                          <div class="margintop10px" *ngIf="screening._selectedAssay">
                            Assay Target Name: <b>{{screening._selectedAssay.targetName}}</b>&nbsp;
                            <br>
                            External Source: <b>{{screening._selectedAssay.externalAssaySource}}&nbsp;
                              {{screening._selectedAssay.externalAssayId}}</b>
                          </div>

                          <div *ngIf="screening.invitroSummary.isFromResult">
                            <span
                              *ngIf="screening.invitroSummary.isFromResult && screening.invitroSummary.isFromResult == true">
                              <mat-icon matTooltip="This Summary data is from Result data">check_circle_outline
                              </mat-icon>
                            </span>
                          </div>

                        </div> <!-- form-row -->

                        <div class="font11px" *ngIf="screening._assayResults && screening._assayResults.length > 0">
                          <table class="tableStyle">
                            <tr>
                              <th>#</th>
                              <th>Select</th>
                              <th>External Source</th>
                              <th>External Assay ID</th>
                              <th>Assay Target Name</th>
                              <th>Assay Title</th>
                              <th>Total Screening</th>
                            </tr>
                            <tr class="font11px"
                              *ngFor="let assayResult of screening._assayResults; let indexResult = index">
                              <td>{{(indexResult+1)}}</td>
                              <td class="font11px">
                                <mat-radio-group name="radioAssayResult">
                                  <mat-radio-button class="font11px" value="indexResult" [checked]='false'
                                    (change)="changeSelectionRadioAssay($event, indexResult, indexScreening)">
                                  </mat-radio-button>
                                </mat-radio-group>
                              </td>
                              <td class="font11px">{{assayResult.externalAssaySource}}</td>
                              <td class="font11px">{{assayResult.externalAssayId}}</td>
                              <td class="font11px">{{assayResult.targetName}}</td>
                              <td class="font11px">{{assayResult.assayTitle}}</td>
                              <td class="font11px">{{assayResult.invitroAssayScreenings.length}}</td>
                            </tr> <!-- FOR LOOP: assay result -->
                          </table>
                        </div>
                        <br>

                        <div class="form-row">
                          <app-cv-input class="col-2" domain="RELATIONSHIP_TYPE" title="Relationship Type"
                            name="relationshipType" [model]="screening.invitroSummary.relationshipType"
                            (valueChange)="screening.invitroSummary.relationshipType = $event">
                          </app-cv-input>

                          <app-cv-input class="col-2" domain="INTERACTION_TYPE" title="Interaction Type"
                            name="interactionType" [model]="screening.invitroSummary.interactionType"
                            (valueChange)="screening.invitroSummary.interactionType = $event">
                          </app-cv-input>
                        </div>

                      </div> <!-- width85percent -->
                    </div> <!-- divflex -->

                  </div> <!-- form-row -->


                  <div class="form-row">
                    <app-cv-input class="col-5-1" domain="QUALIFICATION" title="Result Type" name="resultType"
                      [model]="screening.invitroSummary.resultType"
                      (valueChange)="screening.invitroSummary.resultType = $event">
                    </app-cv-input>

                    <mat-form-field class="col-5-1">
                      <input matInput placeholder="Result Value Average"
                        [(ngModel)]="screening.invitroSummary.resultValueAverage" name="resultValueAverage" />
                    </mat-form-field>

                    <mat-form-field class="col-5-1">
                      <input matInput placeholder="Result Value Low"
                        [(ngModel)]="screening.invitroSummary.resultValueLow" name="resultValueLow" />
                    </mat-form-field>

                    <mat-form-field class="col-5-1">
                      <input matInput placeholder="Result Value High"
                        [(ngModel)]="screening.invitroSummary.resultValueHigh" name="resultValueHigh" />
                    </mat-form-field>

                    <app-cv-input class="col-5-1" domain="AMOUNT_UNIT" title="Result Value Units"
                      name="resultValueUnits" [model]="screening.invitroSummary.resultValueUnits"
                      (valueChange)="screening.invitroSummary.resultValueUnits = $event">
                    </app-cv-input>
                  </div> <!-- form-row -->


                  <div class="form-row">
                    <mat-form-field class="col-1">
                      <textarea matInput placeholder="Comments" [(ngModel)]="screening.invitroSummary.comments"
                        name="comments" rows="1"></textarea>
                    </mat-form-field>
                  </div> <!-- form-row -->

                  <!-- Reference -->
                  <!--
                    <div class="font13px textalignleft">
                      <b>References</b>
                    </div>

                    <!- - Reference - ->

                    <div class="form-row margintop10px">
                      <app-cv-input class="col-3-1" domain="INVITRO_REFERENCE_SOURCE_TYPE" title="Reference Source Type"
                        name="referenceSourceType" [model]="screening.invitroSummary.referenceSourceType"
                        (valueChange)="screening.invitroSummary.referenceSourceType = $event">
                      </app-cv-input>

                      <mat-form-field class="col-3-1">
                        <input matInput placeholder="Reference Source" [(ngModel)]="screening.invitroSummary.referenceSource"
                          name="referenceSource" />
                      </mat-form-field>

                      <mat-form-field class="col-3-1">
                        <input matInput placeholder="Reference Source Url" [(ngModel)]="screening.invitroSummary.referenceSourceUrl"
                          name="referenceSourceUrl" />
                      </mat-form-field>
                    </div> -->
                  <!-- form-row -->

                </div> <!-- class=marginleft20px -->


              </div>
              <!-- class=divflex -->

              <!--
      </div> -->
              <!-- Loop Summaries -->

            </div> <!-- Loop Assay List -->

            <!-- Add More Summary Button -->
            <div class="font18px margintop20px" *ngIf="screeningList.length > 1">
              <button mat-flat-button color="primary" class="marginright30px bordergreen" color="primary"
                (click)="addNewSummary(indexScreening); $event.stopPropagation()" matTooltip="Add New Summary">
                <mat-icon>add_circle_outline</mat-icon><span class="colorgreen">Add More Summary</span>
              </button>&nbsp;&nbsp;
            </div>

          </mat-card-content>
        </mat-card>
        <br>

      </div> <!-- div class=width100percent-->
    </div> <!-- div class=details-container-->

  </div> <!-- div class=scrollable-container-->
</div> <!-- div class=form-content-container-->