<mat-card>
  <mat-card-title>
    <a class="substance-name" [routerLink]="['/substances', substance.uuid]" matTooltip = "View substance details" >
      <span [innerHTML] = "substance._name"></span>

    </a>
    <mat-icon svgIcon="lock" class="lock-icon" *ngIf="substance.access && substance.access.length > 0">
    </mat-icon>
    <span class="middle-fill"></span>
    <div style = "min-width:260px;">

        <div style = "font-size:16px; margin-top: -15px; margin-right: -15px;">
            <mat-form-field *ngIf = 'userLists' class = "select-list">
              <mat-label>Add to List</mat-label>
              <mat-select [(ngModel)]="selectedList" name="Add to List" >
                <mat-option *ngFor="let list of userLists" [value]="list">
                  {{list}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span >
              <button class = "mat-button" *ngIf = "selectedList" (click) = "addToList()" color = "primary">Add</button>

            </span>
            <span style = "margin-right:50px;" >
                <button class = "mat-button" *ngIf = "canCreate || isAdmin" (click) = "createList()" color = "primary">New List</button>

              </span>

          </div>
          <div class="approval" style = "text-align: end;">
              {{substance.approvalID}}
            </div>
          <br/>


  </div>
  </mat-card-title>

  <mat-card-content>
    <div class="substance-content">
      <div class="structure-container">
        <div *ngIf="substance.structure">
          <div class="mat-chip-list-container" *ngIf="substance.structure.stereochemistry">
            <mat-chip-list selectable="false" multiple="false">
              <mat-chip color="primary" selected>{{substance.structure.stereochemistry}}</mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <div *ngIf="!substance.structure">
          <div class="mat-chip-list-container">
            <mat-chip-list selectable="false" multiple="false">
              <mat-chip color="primary" selected>{{substance.substanceClass | facetDisplay: 'types' | uppercase}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <div class="image-thumbnail">
          <div *ngIf="substance.structure || substance.polymer">
            <a tabindex="0" role="img" aria-label="substance image" class="zoom" (click)="openImageModal(substance)">
              <img appSubstanceImage [entityId]="substance.uuid"
                [atomMaps]="substance._matchContext && substance._matchContext.atomMaps || null">
            </a>
          </div>
          <div *ngIf="!substance.structure && !substance.polymer">
            <img appSubstanceImage [entityId]="substance.uuid"
              [atomMaps]="substance._matchContext && substance._matchContext.atomMaps || null">
          </div>
        </div>
        <div *ngIf="substance._matchContext && substance._matchContext.similarity">
          <span class="simlarity-label">similarity:</span> <span
            class="similarity">{{substance._matchContext.similarity.toFixed(3)}}</span>
        </div>
        <div class='definition' *ngIf="substance.definitionLevel &&  substance.definitionLevel !== 'COMPLETE'">
          {{substance.definitionLevel }} DEFINITION
        </div>
        <div class="button-wrapper">
          <button aria-label="Download" class="summary-button" mat-icon-button [matMenuTriggerFor]="downloadMenu">
            <mat-icon svgIcon="get_app"></mat-icon>
          </button>

          <button class="summary-button" aria-label="Search" *ngIf="substance.structure || substance.polymer"
            mat-icon-button [matMenuTriggerFor]="searchMenu">
            <mat-icon svgIcon="search"></mat-icon>
          </button>

          <!--
          <button class="summary-button" aria-label="Advanced Search" mat-icon-button [matMenuTriggerFor]="advancedSearchMenu">
            <mat-icon svgIcon="youtube"></mat-icon>
          </button>
          -->

          <a class = "mat-icon-button" aria-label = "Advanced Search" *ngIf="substance.structure"
            class = "summary-button" mat-icon-button
            [routerLink]="['/advanced-search']" [queryParams]="{structure : substance.structure.id}" matTooltip='Transfer Structure to Advanced Search'>
            <mat-icon svgIcon="youtube"></mat-icon>
          </a>

          <!--
          <a class="mat-icon-button" matTooltip="edit substance" class="summary-button" aria-label="edit substance"
          </a>
          -->
          <a class="mat-icon-button" matTooltip="edit substance" aria-label="edit substance" class="summary-button"
            *ngIf="isAdmin" mat-icon-button [routerLink]="['/substances', substance.uuid, 'edit']">
            <mat-icon svgIcon="edit"></mat-icon>
          </a>

       <!--  Reverting back from button to link to allow right click -> new tab opening -->

       <!--   <button aria-label="Edit Substance" class="summary-button" *ngIf="isAdmin" mat-icon-button
            [routerLink]="['/substances', substance.uuid, 'edit']">
            <mat-icon svgIcon="edit"></mat-icon>
          </button>-->

          <button aria-label="Copy file" class="summary-button" *ngIf="canCreate" mat-icon-button
            [matMenuTriggerFor]="editMenu">
            <mat-icon svgIcon="file_copy"></mat-icon>
          </button>

          <button aria-label="Sequence Search" class="summary-button" *ngIf="subunits" mat-icon-button
            [matMenuTriggerFor]="sequenceMenu" matTooltip="sequence search">
            <mat-icon svgIcon="search"></mat-icon>
          </button>
        </div>

        <mat-menu #sequenceMenu="matMenu">
          <a mat-menu-item *ngFor="let unit of subunits; index as index" [routerLink]="['/sequence-search']"
            [queryParams]="{substance : substance.uuid, subunit : unit.uuid, seq_type : substance.substanceClass}">
            subunit {{unit.subunitIndex}} ({{unit.sequence && unit.sequence.length || 0}}) similarity search
          </a>
        </mat-menu>

        <mat-menu #downloadMenu="matMenu">

          <a mat-menu-item (click)="downloadJson()">
            <span>Download JSON</span>
          </a>
          <a mat-menu-item (click)="openMolModal()" *ngIf="substance.structure || substance.polymer">
            <span>Show Molfile</span>
          </a>
          <a mat-menu-item *ngIf="substance.structure"
            (click)="getMol(substance.uuid, (substance.approvalID? substance.approvalID.toString() : substance.uuid.toString())+'.mol')">
            <span>Download Molfile</span>
          </a>
          <a mat-menu-item *ngIf="substance.polymer"
            (click)="getMol(substance.uuid, (substance.approvalID? substance.approvalID.toString() : substance.uuid.toString())+'.mol')">
            <span>Download Molfile</span>
          </a>
          <a mat-menu-item *ngIf="(substance.protein || substance.nucleicAcid)"
            (click)="getFasta(substance.uuid, (substance.approvalID? substance.approvalID.toString() : substance.uuid.toString())+'.fas')">
            <span>Download Fasta</span>
          </a>
        </mat-menu>

        <mat-menu #searchMenu="matMenu">
          <a mat-menu-item *ngIf="substance.structure" [routerLink]="['/structure-search']"
            [queryParams]="{structure : substance.structure.id}">
            <span>Search Structure</span>
          </a>
          <a mat-menu-item *ngIf="substance.polymer" [routerLink]="['/structure-search']"
            [queryParams]="{structure : substance.polymer.displayStructure.id}">
            <span>Search Structure</span>
          </a>
        </mat-menu>

        <mat-menu #advancedSearchMenu="matMenu">
          <a mat-menu-item *ngIf="substance.structure" [routerLink]="['/advanced-search']"
            [queryParams]="{structure : substance.structure.id}">
            <span>Advanced Structure</span>
          </a>
        </mat-menu>

        <mat-menu #editMenu="matMenu">
          <a mat-menu-item [routerLink]="['/substances/register',substance.substanceClass]"
            [queryParams]="{copy:substance.uuid}" matTooltip='copy substance to new registration form'>
            <span>Copy Substance to New Form</span>
          </a>
          <a mat-menu-item [routerLink]="['/substances/register',substance.substanceClass]"
            [queryParams]="{copy:substance.uuid,copyType:'definition'}"
            matTooltip='copy definition to new registration form'>
            <span>Copy Definition to New Form</span>
          </a>
        </mat-menu>

      </div>
      <div>
      <div *ngIf = "nameLoading" class = "name-loading">
          <div class="label loading-label">Names: </div>
          <div><mat-progress-spinner
            class="spinner"
            [color]="primary"
            [diameter]="24"
            mode = "indeterminate">
        </mat-progress-spinner></div><div> <i>Loading</i></div>
          </div>
        <div class="substance-data substance-names" *ngIf="privateNames && privateNames.length && !nameLoading">
          <div class="label">Names:</div>
          <div [hidden]="!showLessNames">
            <div class="value space-bottom" *ngFor="let nameObject of privateNames | namesDisplayOrder">
              <span [innerHTML] = "nameObject._nameHTML ? nameObject._nameHTML : nameObject.name"></span>
              <span *ngIf="nameObject.displayName == true" matTooltip="{{ 'displayNameTitle' | elementLabel : 'substance_names_name' }}">
                <mat-icon class="icon-align blue-font" svgIcon="done"></mat-icon>
              </span>
            </div>
            <div [hidden]="!moreThanNumberCount(privateNames, 5)" (click)="showMoreLessNames()" class="show-more">See {{names.length - 4}} More</div>
          </div>
          <div [hidden]="showLessNames">
            <div class="value space-bottom" *ngFor="let nameObject of privateNames">
              <span [innerHTML] = "nameObject._nameHTML ? nameObject._nameHTML : nameObject.name"></span>
                <span *ngIf="nameObject.displayName == true" matTooltip="{{ 'displayNameTitle' | elementLabel : 'substance_names_name' }}">
                <mat-icon class="icon-align blue-font" svgIcon="done"></mat-icon>
              </span>
              <span *ngIf="nameObject.preferred == true" matTooltip="{{ 'preferredTitle' | elementLabel : 'substance_names_name' }}">
                <mat-icon class="icon-align grey-font" svgIcon="done"></mat-icon>
              </span>
            </div>
            <div (click)="showMoreLessNames()" class="show-more">Less</div>
          </div>
        </div>
        <div class="substance-data substance-code-systems" *ngIf="codeSystems">
          <div class="label">Codes:</div>
          <div [hidden]="!showLessCodes">
            <div class="margin-bottom" *ngFor="let codeSystemName of codeSystemNames | take: 5; index as codeindex">
              <div class="code-system">
                <span class="label">{{(codeSystemVocab && codeSystemVocab[codeSystemName]) ? codeSystemVocab[codeSystemName].display : codeSystemName}}

                </span>:&nbsp;
                <span class="value"
                  *ngFor="let codeObject of codeSystems[codeSystemName]; last as isLast; index as index">
                  <span *ngIf="index < 5  || (showAll[codeSystemName] && showAll[codeSystemName] === true)">
                    <span *ngIf="codeObject.url">
                      <a class="ext-link" target="_blank" [href]="codeObject.url" appTrackLinkEvent
                        evCategory="substancesContent" evAction="link:code" aria-label="view site for {{codeObject.code.trim()}} in new tab">
                        <span hidden>view site in new tab</span>{{codeObject.code.trim()}}
                        <!-- <mat-icon class="icon-align" svgIcon="link"></mat-icon>-->
                        <span *ngIf="codeSystems[codeSystemName].length > 1 && codeObject.type !== 'PRIMARY'" style="font-size: 90%;
                          font-weight: 400;">[{{codeObject.type}}]</span>

                      </a>
                    </span>
                    <span *ngIf="!codeObject.url">{{codeObject.code.trim()}}
                      <span *ngIf="codeSystems[codeSystemName].length > 1 && codeObject.type !== 'PRIMARY'" style="font-size: 90%;
                          font-weight: 400;">[{{codeObject.type}}]</span>
                    </span>

                    <span *ngIf="!isLast">, </span>
                  </span>
                </span>
                <span *ngIf="codeSystems[codeSystemName].length > 4">
                  <a class="moreLink"
                    (click)="!showAll[codeSystemName]? showAll[codeSystemName] = true: (showAll[codeSystemName] === true ? showAll[codeSystemName] = false : showAll[codeSystemName] = true)">{{showAll[codeSystemName] ? 'Show Less': 'Show More'}}</a>
                </span>
              </div>
            </div>
            <div [hidden]="!moreThanNumberCount(codeSystemNames, 6)" (click)="showMoreLessCodes()" class="show-more">See {{codeSystemNames.length - 5}} More</div>
          </div>
          <div [hidden]="showLessCodes">
            <div class="margin-bottom" *ngFor="let codeSystemName of codeSystemNames; index as codeindex">
              <div class="code-system">
                <span class="label">{{codeSystemName | codeSystemDisplay | async}}

                  </span>:&nbsp;
                <span class="value" *ngFor="let codeObject of codeSystems[codeSystemName]; last as isLast; index as index">
                  <span *ngIf = "index < 5  || (showAll[codeSystemName] && showAll[codeSystemName] === true)">
                  <span *ngIf="codeObject.url">
                    <a class="ext-link" target="_blank" [href]="codeObject.url" appTrackLinkEvent
                      evCategory="substancesContent" evAction="link:code" aria-label ="view site in new tab">
                      <span hidden>view site in new tab</span>{{codeObject.code.trim()}}
                      <!-- <mat-icon class="icon-align" svgIcon="link"></mat-icon>-->
                      <span *ngIf = "codeSystems[codeSystemName].length > 1 && codeObject.type !== 'PRIMARY'" style = "font-size: 90%;
                          font-weight: 400;">[{{codeObject.type}}]</span>

                    </a>
                  </span>
                  <span *ngIf="!codeObject.url">{{codeObject.code.trim()}}
                      <span *ngIf = "codeSystems[codeSystemName].length > 1 && codeObject.type !== 'PRIMARY'" style = "font-size: 90%;
                          font-weight: 400;">[{{codeObject.type}}]</span>
                  </span>

                  <span *ngIf="!isLast">, </span>
                  </span>
                </span>
                <span *ngIf = "codeSystems[codeSystemName].length > 4">
                  <a class = "moreLink" (click) = "!showAll[codeSystemName]? showAll[codeSystemName] = true: (showAll[codeSystemName] === true ? showAll[codeSystemName] = false : showAll[codeSystemName] = true)">{{showAll[codeSystemName] ? 'Show Less': 'Show More'}}</a>
                </span>
              </div>
            </div>
            <div (click)="showMoreLessCodes()" class="show-more">Less</div>
          </div>
        </div>
        <div class="substance-data substance-relationships"
          *ngIf="substance._relationships && substance._relationships.count != null">
          <div class="label">Relationships:</div>
          <div class="value">
            {{substance._relationships.count}}
          </div>
        </div>
        <div class="substance-data"
          *ngIf="substance.mixture && substance.mixture.components && substance.mixture.components.length">
          <div class="label">Components:</div>
          <div>
            <span class="value">{{substance.mixture.components.length}}</span>
          </div>
        </div>
        <div class="substance-data" *ngIf="substance.specifiedSubstance && substance.specifiedSubstance.constituents">
          <div class="label">Constituents:</div>
          <div>
            <span class="value">{{substance.specifiedSubstance.constituents.length}}</span>
          </div>
        </div>
        <div class="substance-data" *ngIf="substance.nucleicAcid && substance.nucleicAcid.subunits">
          <div class="label">Subunits:</div>
          <div>
            <span class="value">{{substance.nucleicAcid.subunits.length}}</span>
          </div>
        </div>
        <div class="substance-data" *ngIf="substance.structure?.mwt">
          <div class="label">Mol. Weight:</div>
          <div>
            <span class="value">{{substance.structure.mwt  | number: rounding}}</span>
          </div>
        </div>
        <div class="substance-data" *ngIf="substance.structure?.formula">
          <div class="label">Formula:</div>
          <div>
            <span class="value" [innerHTML]="substance.structure.formula"></span>
          </div>
        </div>
        <div class="substance-data" *ngIf="substance.structurallyDiverse?.part">
          <div class="label">Part:</div>
          <div>
            <span class="blue-pill">
              <span *ngFor="let part of substance.structurallyDiverse.part; last as isLast">
                {{part}}
                <span *ngIf="!isLast">, </span>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="right-aligned">
        <div *ngIf="inxightLink" class="inxight-container">
          <a class="ext-link" [href]="inxightUrl" target="_blank"
          attr.aria-label="open {{inxightUrl}} in new tab">
            Inxight Drugs <mat-icon class="icon small-icon" svgIcon="open_in_new"></mat-icon>
          </a>
        </div>
        <div *ngIf="showAudit">
          <div class="substance-data">
            <div class="label">Created: </div>
            <div>
              <span class="value">
                {{substance.created | date : 'shortDate'}}
              </span>
            </div>
          </div>
          <div class="substance-data">
            <div class="label">Created By: </div>
            <div>
              <span class="value">
                {{substance.createdBy }}
              </span>
            </div>
          </div>
          <div class="substance-data">
            <div class="label">Status: </div>
            <div>
              <span class="value">
                {{substance.status | statusDisplay}}
              </span>
            </div>
          </div>
          <div class="substance-data" *ngIf="substance.status === 'approved'">
            <div class="label">Validated By: </div>
            <div>
              <span class="value">
                {{substance.approvedBy }}
              </span>
            </div>
          </div>
          <div class="substance-data" *ngIf="substance.approved">
            <div class="label">Validated Date: </div>
            <div>
              <span class="value">
                {{substance.approved| date : 'short'}}
              </span>
            </div>
          </div>
          <div class="substance-data">
            <div class="label">Last Modified: </div>
            <div>
              <span class="value">
                {{substance.lastEdited | date : 'shortDate'}}
              </span>
            </div>
          </div>
          <div class="substance-data">
            <div class="label">Last Modified By: </div>
            <div>
              <span class="value">
                {{substance.lastEditedBy }}
              </span>
            </div>
          </div>
          <div class="substance-data">
            <div class="label">Version: </div>
            <div>
              <span class="value">
                {{substance.version}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="alignments">
      <app-sequence-alignment *ngFor="let alignment of alignments" [alignmentArray]="alignment">
      </app-sequence-alignment>
    </div>
    <div *ngIf="searchStrategy && searchStrategy==='bulk'">
      <span class="match-context" *ngIf="substance._matchContext?.queries && substance._matchContext.queries.length>0">
        (matched queries: {{_.join(substance._matchContext.queries, ", ")}})
      </span>
    </div>
    <div>
      <app-substance-hierarchy [uuid]="this.substance.uuid" [name]="this.substance._name"
        [approvalID]="this.substance.approvalID"></app-substance-hierarchy>
    </div>
    <div>
      <ng-template appCardDynamicSection></ng-template>
      <!--
      <ng-template appBrowseHeaderDynamicSection></ng-template>
      -->
    </div>
  </mat-card-content>
</mat-card>
