<div class="top-fixed">
  <div class="actions-container">

    <span *ngIf="isAdmin">
      <button mat-flat-button color="primary" (click)="showJSON()">Show JSON</button>
    </span>

  </div> <!-- div class=actions-container -->
</div> <!-- div class=top-fixed -->


<div *ngIf="isAdmin; else noLogin">

  <div class="form-content-container">
    <div class="scrollable-container">

      <div class="divcenter margintop20px marginleft30px">

        <div class="headerstyle margintop20px bordergray">

          <div class="padtop15px font18px fontbold">
            <b>Import Bulk Assays ONLY </b>
          </div>

        </div> <!--  -->

        <br><br>
        <b>Select a file to Import Assays Only:</b>&nbsp;&nbsp;

        <!-- Import EXCEL DATA and convert to JSON -->
        <input type="file" (change)="onFileChange($event)" />

        <!-- -->
        <div id="output"></div>
        <br>
        <div *ngIf="willDownload">
          <a id="download"> Download JSON File </a>
        </div>
        <br>

        <!-- BUTTONS -->
        <div class="divflex">

          <div>
            <button mat-flat-button color="primary" (click)="validate()" [disabled]="disableValidateButton">Validate
              Data</button>
          </div>

          <!-- Import Assay JSON into the Database -->
          <div class="marginleft20px">
            <button mat-flat-button color="primary" (click)="importAssayJSONIntoDatabase()"
              [disabled]="disableImportButton">Import Data
              into Database</button>
          </div>

          <div class="marginleft20px" *ngIf="importSaveMessageArray.length > 0">
            <!-- Link to All Assay page -->
            <div class="marginleft50px">
              <a mat-flat-button color="primary" [routerLink]="['/invitro-pharm']" [queryParams]="{view : 'all-assays'}"
                target="_blank" alt="View All Assay Lists" matTooltip='View All Assay Lists, Opens in a new window'>
                <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window"></mat-icon>
                View All Assay Lists
              </a>
            </div>
          </div> <!-- importSaveMessageArray.length > 0 -->

        </div> <!-- class=divflex -->

        <div class="margintop20px font18px colorgreen" *ngIf="submitMessage">
          <b>{{submitMessage}}</b>
          <br><br>
        </div>

        <!-- VALIDATION MESSAGE BEGIN -->
        <div class="margintop30px" *ngIf="importValidateMessageArray.length > 0">
          <div class="font18px"><b>Validation Results</b></div>

          <div class="margintop20px" *ngIf="isAllRecordValidated && isAllRecordValidated == true">

            <span *ngIf="isAllRecordValid && isAllRecordValid == true; else noValidRecord" class="colorgreen">
              <br>
              <b>All Records are VALID. Please import the Assay records into the database.</b>
              <br><br>
            </span>
            <ng-template #noValidRecord>
              <br>
              <span class="colorred">
                <b>Not all records are valid. Please correct the records in Excel file and validate again</b>
              </span>
              <br><br>
            </ng-template>

          </div> <!-- isAllRecordValidated == true-->


          <!-- Show Validation Message -->
          <div class="">
            <table border="1">
              <tr>
                <th>Record Row</th>
                <th>External Assay Source</th>
                <th>External Assay ID</th>
                <th>Validation Error Message</th>
                <th>Is Valid</th>
              </tr>

              <tr *ngFor="let msg of importValidateMessageArray; let indexMessage = index">
                <td>{{(msg.indexRecord+1)}}</td>
                <td>{{msg.externalAssaySource}}</td>
                <td>{{msg.externalAssayId}}</td>
                <td>
                  <!-- VALIDATION MESSAGE -->
                  <div *ngIf="msg.validationMessages.length > 0">

                    <div class="validation-message" *ngFor="let message of msg.validationMessages; index as i">
                      <div class="message-type"
                        [ngClass]="message.messageType == 'ERROR' ? 'error-message' : 'warning-message'">
                        {{message.messageType}}</div>
                      <div class="message">{{message.message}}<span *ngFor="let link of message.links"><br /><a
                            [href]="link.href" target="_blank">{{link.text}}</a></span></div>
                      <button mat-icon-button matTooltip="Dismiss" *ngIf="message.messageType != 'ERROR' && !approving"
                        (click)="dismissValidationMessage(i)">
                        <mat-icon svgIcon="cancel"></mat-icon>
                      </button>
                    </div>
                  </div>
                </td>
                <td>{{msg.valid}}</td>
              </tr>

            </table>
          </div> <!-- for -->
          <br>
        </div> <!-- importValidateMessageArray.length > 0 -->
        <!-- VALIDATION MESSAGES END -->


        <!-- Records saved into the database -->
        <div class="margintop20px" *ngIf="importSaveMessageArray.length > 0">

          <!-- SAVED RECORD INTO DATABASE RESULT -->
          <div class="font18px"><b>Records Saved ({{importSaveMessageArray.length}})</b></div>
          <br>
          <table border="1">
            <tr>
              <th>Record Row</th>
              <th>External Assay Source</th>
              <th>External Assay ID</th>
              <th>Saved Into The Database</th>
              <th>Saved Assay Id</th>
            </tr>
            <tr *ngFor="let msg of importSaveMessageArray; let indexMessage = index">
              <td>{{(msg.indexRecord+1)}}</td>
              <td>{{msg.externalAssaySource}}</td>
              <td>{{msg.externalAssayId}}</td>
              <td>{{msg.saved}}</td>
              <td>
                <div *ngIf="msg.savedId">
                  <a class="view-detail-link" [routerLink]="['/invitro-pharm', msg.savedId]" target="_blank"
                    matTooltip='View Assay Record'>
                    View Assay Details
                  </a>
                </div>
              </td>
            </tr>

          </table>
        </div>


        <!-- DISPLAY JSON -->
        <!-- Display this table if not imported yet -->
        <div *ngIf="importSaveMessageArray.length == 0">
          <div *ngIf="importDataList.length > 0">

            <br><br>
            <span *ngIf="isAllRecordSaved == false">
              <b>Total Records ready for import into the database:&nbsp;
                <span class="colorgreen font18px">{{importDataList.length}}</span></b>
            </span>

            <br><br>
            <table class="tableStyle bordergray">
              <tr class="font11px">
                <th>#</th>
                <th>Assay Set</th>
                <th>External Assay Source</th>
                <th>External Assay ID</th>
                <th>External Assay Reference URL</th>
                <th>Assay Title</th>
                <th>Assay Format</th>
                <th>Assay Mode</th>
                <th>Bioassay Type</th>
                <th>Bioassay Class</th>
                <th>Study Type</th>
                <th>Detection Method</th>
                <th>Presention Type</th>
                <th>Presentation</th>
                <th>Public Domain</th>
                <th>Assay Target Name</th>
                <th>Assay Target Name Approval ID</th>
                <th>Target Species</th>
                <th>Human Homolog Target Name</th>
                <th>Human homolog Target Name Approval ID</th>
                <th>Ligand/Substate</th>
                <th>Ligand/Substrate Approval ID</th>
                <th>Standard Ligand/Substrate Concentration</th>
                <th>Standard Ligand/Substrate Concentration Units</th>
              </tr>

              <tr valing="top" class="font11px" *ngFor="let assay of importDataList; let index = index">
                <td>{{(index+1)}}</td>
                <td>
                  <div *ngFor="let set of assay.invitroAssaySets">
                    {{set.assaySet}}
                  </div>
                </td>
                <td>{{assay.externalAssaySource}}</td>
                <td>{{assay.externalAssayId}}</td>
                <td>{{assay.externalAssayReferenceUrl}}</td>
                <td>{{assay.assayTitle}}</td>
                <td>{{assay.assayFormat}}</td>
                <td>{{assay.assayMode}}</td>
                <td>{{assay.bioassayType}}</td>
                <td>{{assay.bioassayClass}}</td>
                <td>{{assay.studyType}}</td>
                <td>{{assay.detectionMethod}}</td>
                <td>{{assay.presentationType}}</td>
                <td>{{assay.presentation}}</td>
                <td>{{assay.publicDomain}}</td>
                <td>{{assay.targetName}}</td>
                <td>{{assay.targetNameApprovalId}}</td>
                <td>{{assay.targetSpecies}}</td>
                <td>{{assay.humanHomologTarget}}</td>
                <td>{{assay.humanHomologTargetApprovalId}}</td>
                <td>{{assay.ligandSubstrate}}</td>
                <td>{{assay.ligandSubstrateApprovalId}}</td>
                <td>{{assay.standardLigandSubstrateConcentration}}</td>
                <td>{{assay.standardLigandSubstrateConcentrationUnits}}</td>
              </tr>
            </table>
          </div>
        </div>
        <br>

      </div> <!-- divcenter marginto90px-->
      <br>

      <div class="marginleft30px" *ngIf="importDataList.length > 0">
        <!-- Import Assay JSON into the Database -->
        <span>
          <button mat-flat-button color="primary" (click)="importAssayJSONIntoDatabase()"
            [disabled]="disableImportButton">Import Data
            into Database</button>
        </span>
      </div>
      <br><br>

    </div> <!-- scrollable-container -->
  </div> <!-- form-content-container -->

</div> <!-- isAdmin -->
<ng-template #noLogin>
  <div class="margintop150px marginleft20px font18 colorred">
    Please Login to import Assay Data.
  </div>
</ng-template>

<br><br>