<div class="query-content-container" #contentContainer>
  <mat-card>
    <h2 class="component-title">Bulk Query</h2>
    <div class="editor-container">
      <div class="two">
        <label id="search-on-identifiers">Search Type</label>
        <mat-radio-group aria-labelledby="search-on-identifiers-label" 
        class="search-on-identifiers-radio-group" 
        [(ngModel)]="searchOnIdentifiers">
          <mat-radio-button [value]="true">Identifiers</mat-radio-button>
          <mat-radio-button [value]="false">All Fields</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="three">
        <label id="search-on-entity">Search Entity</label>

        <mat-form-field matInput appearance="fill" class="query-entity-select"> 
          <mat-select required placeholder="Search Entity" aria-labelledby="search-on-entity-label" 
          class="search-on-entity-select-box" [formControl]="searchEntityControl" 
          (selectionChange)="searchEntitySelected($event)"  value="substances">
            <mat-option *ngFor="let entity of searchEntities;" value="{{entity.name}}">
              {{entity.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>   
            <app-text-input-form
              instruction="Enter queries, one per line."
              placeholder="{{textInputFormPlaceholder}}"
            ></app-text-input-form>
        <div class="query-actions">
          <button mat-flat-button color="primary" (click) = "submitText()">Submit</button>

        </div>
      </div>
   </div>
<div>
</div>
  </mat-card>
</div>
