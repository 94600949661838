<div class="menu-container">
  <button
    mat-button
    [matMenuTriggerFor]="accessMenu"
    [matTooltip]="tooltipMessage"
    (menuOpened)="menuOpened()"
    (menuClosed)="menuClosed()">
    <mat-icon svgIcon="lock" *ngIf="access.length"></mat-icon>
    <mat-icon svgIcon="lock_open" *ngIf="!access.length"></mat-icon>
    <span>{{label? label: "Access"}}</span>
  </button>
  <mat-menu #accessMenu="matMenu" xPosition="before">
    <form [formGroup]="accessFormGroup">
      <div mat-menu-item *ngFor="let accessOption of accessOptions" (click)="$event.stopPropagation()">
        <mat-checkbox (change)="updateAccess($event, accessOption)" [formControlName]="accessOption.value">
          {{accessOption.display}}
        </mat-checkbox>
      </div>
    </form>
  </mat-menu>
</div>