<mat-form-field>
    <mat-chip-list #chipList>
        <mat-chip *ngFor="let tag of tags" removable="true" (removed)="remove(tag)">
            {{optionsDictionary[tag] && optionsDictionary[tag].display || tag}}
            <mat-icon matChipRemove svgIcon="cancel"></mat-icon>
        </mat-chip>
        <input [placeholder]="placeholder" 
        #tagInput 
        [formControl]="tagControl" 
        [matAutocomplete]="tagsAuto"
        [matChipInputFor]="chipList" 
        matChipInputAddOnBlur="false"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="tagAdded($event)" 
        (blur)="clearTagsInput()">
    </mat-chip-list>
    <mat-autocomplete
     #tagsAuto="matAutocomplete" 
     (optionSelected)="selected($event)" 
     (closed)="clearTagsInput()">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
            {{option.display}}
        </mat-option>
        <mat-option disabled = "true" [value]="null">
                Press enter to add custom value
            </mat-option>
    </mat-autocomplete>
</mat-form-field>