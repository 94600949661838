
<div class = "user-container">
<div class = "flex-row spaced">
    <div class = "top-button">
        <button mat-button (click) = "addUser()">Add User</button>
    </div>

      <div class = "top-button">
          <button mat-button (click) = "showInactiveUsers()">{{showInactive? 'Show':'Hide'}} Inactive Users</button>
      </div>
      <div class = "top-button">
        <button (click)="toggleActions()" class="dropbtn" mat-button>Action
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <div [ngClass]="showHideActions ? 'show' : ''" class="dropdown-content">
          <a (click)="markSelected('inactive')">Mark Selected Inactive</a>
          <a class="hide"(click)="markSelected('active')">Mark Selected Active</a> <!-- hidden for now-->
        </div>
      </div>
      <div class = "form-row">
        <div class = "top-button">
           
          </div>
        <span class="middle-fill"></span>
  
        <mat-form-field [floatLabel]="'never'" class="search">
          <input matInput placeholder="Search Names / Emails" [formControl]="searchControl">
        </mat-form-field>
      </div>
</div>

<div class = "responsive" *ngIf = "loading === true">
    <div class = "loading-container" >
        <mat-progress-spinner
          class="spinner"
          [color]="primary"
          mode = "indeterminate">
      </mat-progress-spinner>
      </div>
</div>

<div class = "responsive" *ngIf = "loading === false && showAll === true">
<table mat-table [dataSource] = "paged"  matSort (matSortChange)="sortData($event)">
      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef> 
          <input
          (change)="selectUnselectAll()"
          type="checkbox"/> (Un)Select All</th>
        <td mat-cell *matCellDef="let user">
          <input [checked]="isInCheckList(user.user.username)" 
          (change)="checkListToggle(user.user.username)" 
          type="checkbox"/>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> User Name</th>
        <td mat-cell *matCellDef="let user; index as index">
          <a class = "mat-button" matTooltip = "select to edit" (click) = "editUser(user.id, index)">
              <mat-icon svgIcon="edit" class = "small-icon clear-button"></mat-icon> {{user.user.username}}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
        <td mat-cell *matCellDef="let user"> {{user.user.email}} </td>
      </ng-container>
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created</th>
        <td mat-cell *matCellDef="let user"> {{user.user.created | date:'medium'}} </td>
      </ng-container>
      <ng-container matColumnDef="modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified</th>
        <td mat-cell *matCellDef="let user"> {{user.modified | date:'medium'}} </td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active/ Set Inactive</th>
        <td mat-cell *matCellDef="let user; index as index"> 
           {{user.active? 'active': 'inactive'}} <a matTooltip = "set user to inactive" class = "mat-button"  *ngIf ="user.active" (click)="deleteUser(user.user.username, index)" >
                <mat-icon svgIcon="clear" class = "clear-button"></mat-icon>
            </a>  
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div>
<mat-paginator [length]="filtered.data && filtered.data.length || 0" [pageSize]="10000" showFirstLastButtons="true" (page)="pageChange($event)"
               [pageSizeOptions]="[5, 10, 25, 100, 250, 1000, 10000]">
</mat-paginator>
</div>

</div>
</div>
