
<div class="panel panel-default sub-list" class = " job-container" *ngIf = "download && !deleted">
    <div class = "download-header">
          <div class = "download-name">{{download.displayFilename}}</div>
          <div class = "status">
               <span class = "time-label">Status: </span>
               <span class = "time-field large-val">{{download.status}}</span>
          </div>
    </div>

 <div  class = "form-row ">
   <div class = "side-bar text-center">
       <div class = "job-settings">
           <div *ngIf="download.status === 'RUNNING' || download.status === 'PREPARING'">
                   <mat-progress-spinner
                       class="spinner"
                       color="primary"
                       mode="indeterminate"
                   >
                   </mat-progress-spinner>
                   <div>Running task ... </div>
           </div>
          <div *ngIf="download.status === 'CANCELLED'">
               <mat-icon svgIcon = "not_interested" class = "spinner">
               </mat-icon>
               <div>
               <div class = "label"><i>Download Cancelled</i></div>

               </div>
           </div>
           <div *ngIf="download.status === 'COMPLETE'">
                   <mat-icon svgIcon = "done" class = "spinner">
                   </mat-icon>
                   <div>
                   <div class = "label"><i>Export complete</i></div>

                   </div>
               </div>


       </div>

       <div class = "bottom-button">
            <a mat-button  *ngIf="download.complete" class = "mat-flat-button mat-primary" color = "primary"  [href] = "this.download.downloadUrl.url" target = "_blank">
                       <mat-icon svgIcon="get_app"></mat-icon>
                       Download
               </a>
           <button *ngIf="download.status === 'RUNNING'" mat-button  class = "mat-flat-button mat-primary" color = "primary" (click)="cancel()">
                   (click to cancel)
               </button>
       </div>

   </div>
   <div class = "main-bar">

   <div class="main-container">
       <div class = "table-row" *ngIf = "download.numRecords !== null && (download.status === 'RUNNING')" >
           <div class = "label table-label">Records Processed So Far:</div>
           <div class = "table-value">{{download.numRecords}}</div>
       </div>
       <div class = "table-row" *ngIf = "download.numRecords !== null && download.status !== 'RUNNING' && download.status !== 'PREPARING'" >
           <div class = "label table-label">Records Processed:</div>
           <div class = "table-value">{{download.numRecords}}</div>
       </div>
       <div class = "table-row" *ngIf = "browseLink">
           <div class = "label table-label">
                <a *ngIf = "type === 'browse'"
                class = "query-link" [routerLink] = "['/browse-substance']" [queryParams] = "parameters" target = "_blank" matTooltip = "open browse results in new tab">
                    Original Query<mat-icon matListIcon svgIcon="open_in_new" class = "middle-button"></mat-icon> :
                </a>
                <a *ngIf = "type === 'product'"
                class = "query-link" [routerLink] = "['/browse-products']" [queryParams] = "parameters" target = "_blank" matTooltip = "open browse results in new tab">
                    Original Query<mat-icon matListIcon svgIcon="open_in_new" class = "middle-button"></mat-icon> :
                </a>
                <a *ngIf = "type === 'application'"
                class = "query-link" [routerLink] = "['/browse-applications']" [queryParams] = "parameters" target = "_blank" matTooltip = "open browse results in new tab">
                    Original Query<mat-icon matListIcon svgIcon="open_in_new" class = "middle-button"></mat-icon> :
                </a>
                <a *ngIf = "type === 'adverseevent'"
                class = "query-link" [routerLink] = "['/browse-adverse-events']" [queryParams] = "parameters" target = "_blank" matTooltip = "open browse results in new tab">
                    Original Query<mat-icon matListIcon svgIcon="open_in_new" class = "middle-button"></mat-icon> :
                </a>
            </div>
           <div class = "table-value facet-container">
               <div class = "query-row" *ngIf = "parameters.search">
                   <div class = "query-label">Search: </div>
                   <div class = "query-value">{{parameters.search}}</div>
               </div>
               <span *ngFor = "let facet of facetArray">
                    <span *ngFor = "let value of facet.values">
                            <div class = "query-row" >
                                    <div class = "query-label">{{facet.facet}}: </div>
                                    <div class = "query-value">{{value | decodeUri}}</div>
                                </div>
                    </span>
               </span>

            <div class = "query-row" *ngIf = "parameters.order">
                    <div class = "query-label">Sort By: </div>
                    <div class = "query-value">{{displayOrder}}</div>
                </div>


        </div>
       </div>
       <div class = "table-row" *ngIf = "download.size" >
           <div class = "label table-label">Size:</div>
           <div class = "table-value">{{download.size | fileSize}}</div>
       </div>
       <div class = "table-row" *ngIf = "download.sha1" >
               <div class = "label table-label">SHA-1:</div>
               <div class = "table-value">{{download.sha1}}</div>
           </div>

       </div>
   </div>
<div class = "end-bar">
<div class = "job-dates">
   <div *ngIf="download.started" class = "table-row" matTooltip = "{{download.started | date: 'long'}}">
          <span class = "time-label">Started: </span>
           <span class = "time-field">{{download.startedHuman}}</span>
   </div>
   <div *ngIf="download.finished" class = "table-row"  matTooltip = "{{download.finished | date: 'long'}}">
       <span class = "time-label">Ended: </span>
           <span class = "time-field">{{download.finishedHuman}}</span>
   </div>
</div>
<div *ngIf="!download.enabled" class = "bottom-button" >
   <button mat-button class = "mat-flat-button mat-primary" color = "primary"  (click)="deleteDownload()">
       <mat-icon svgIcon="delete_forever"></mat-icon>
       Delete
   </button>
</div>

</div>
</div>
<div class = "form-row padding" *ngIf = "!download.complete">
        The download is preparing on the server. This dialog can be closed and the file downloaded by clicking on 'user downloads' in the user icon menu
</div>
</div>
<div *ngIf = "deleted" class = "form-row">
   <div class = "deleted">
       Deleted Download
   </div>
</div>
<div *ngIf = "!exists" class = "form-row">
       <div class = "deleted">
           Download not found
       </div>
   </div>
