<mat-progress-bar
    mode="buffer"
    *ngIf="isLoading">
</mat-progress-bar>


<mat-progress-spinner
    mode="indeterminate"
    *ngIf="isLoading">
</mat-progress-spinner>
