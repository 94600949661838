<br>
<div *ngIf="assay">
  <div *ngIf="assay.id; else noRecord">
    <div class="details-container details-box margintop50px">

      <div class="divflexrow margintop20px">
        <div class="divflex">
          <div class="title margintop10px width53percent">
            In-vitro Pharmacology Assay/Screening Details&nbsp;&nbsp;
          </div>

          <!-- Edit GSRS record button ASSAY -->
          <div class="margintop10px">
            <a [routerLink]="['/invitro-pharm/assay', assay.id, 'edit']" target="_blank" *ngIf="isAdmin"
              matTooltip='Edit GSRS In-vitro Pharmacology ASSAY Record ONLY'>
              <mat-icon svgIcon="edit"></mat-icon>
            </a>&nbsp;&nbsp;
          </div>

          <!-- Save JSON -->
          <div class="margintop10px">
            <a color="primary" [href]="downloadJsonHref" (click)="saveJSON()" download="{{jsonFileName}}.json"
              matTooltip='Export Json'>
              <mat-icon svgIcon="get_app"></mat-icon>
            </a>&nbsp;
          </div>

          <!-- Show JSON -->
          <div>
            <button mat-icon-button color="primary" (click)="showJSON()" matTooltip="Show JSON">
              <mat-icon>list</mat-icon>
            </button>
          </div>

          <!-- Filler -->
          <div class="width30px">&nbsp;</div>

          <!-- Show Audit Information -->
          <div class="width30percent font11px margintop10px">
            <span class="colorgray">Created By:</span> {{assay.createdBy}}&nbsp;&nbsp;&nbsp;
            <span class="colorgray">Create
              Date:</span>{{assay.createdDate|date: 'MM/dd/yyyy hh:mm:ss a'}}&nbsp;&nbsp;&nbsp;
            <span class="colorgray">Modified By:</span> {{assay.modifiedBy}}&nbsp;&nbsp;&nbsp;
            <span class="colorgray">Modify Date:</span> {{assay.modifiedDate|date: 'MM/dd/yyyy hh:mm:ss
            a'}}
          </div>

        </div> <!-- class=divflex -->
      </div> <!-- class=divflexrow -->
    </div>


    <!-- DETAILS INFORMATION Begin -->

    <mat-card class="margintop10px bordergray">
      <mat-card-title>

      </mat-card-title>

      <mat-card-content>
        <div class="margintop10px marginbottom30px">
          <span class="colorblue font20px"><b>Assay Information</b></span>

          <table class="tableStyle margintop15px">
            <tbody>

              <tr>
                <td class="fieldKey">Assay Sets:</td>
                <td>
                  <span *ngFor="let asySet of assay.invitroAssaySets; let i = index">
                    <span *ngIf="i > 0">, </span>
                    {{ asySet.assaySet }}
                  </span>
                </td>
              </tr>

              <tr>
                <td class="fieldKey">Assay ID:</td>
                <td>{{ assay.assayId }}</td>
              </tr>

              <tr>
                <td class="fieldKey">External Assay ID:</td>
                <td><b>{{ assay.externalAssayId }}</b></td>
              </tr>

              <tr>
                <td class="fieldKey">External Assay Source:</td>
                <td><b>{{ assay.externalAssaySource }}</b></td>
              </tr>

              <tr>
                <td class="fieldKey">External Assay Reference URL:</td>
                <td>
                  <span *ngIf="assay.externalAssayReferenceUrl">
                    <a href="{{assay.externalAssayReferenceUrl}}" target="_blank">
                      {{assay.externalAssayReferenceUrl}}
                    </a>
                  </span>
                </td>
              </tr>

              <tr>
                <td class="fieldKey">Assay Title:</td>
                <td>{{ assay.assayTitle }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Assay Format:</td>
                <td>{{ assay.assayFormat }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Assay Mode:</td>
                <td>{{ assay.assayMode }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Bioassay Type:</td>
                <td>{{ assay.bioassayType }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Bioassay Class:</td>
                <td>{{ assay.bioassayClass }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Study Type:</td>
                <td>{{ assay.studyType }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Detection Method:</td>
                <td>{{ assay.detectionMethod }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Presentation Type:</td>
                <td>{{ assay.presentationType }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Presentation:</td>
                <td>{{ assay.presentation }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Public Domain:</td>
                <td>{{ assay.publicDomain }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Target Species:</td>
                <td>{{ assay.targetSpecies }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Target Name:</td>
                <td>
                  <div *ngIf="assay.targetNameApprovalId; else noTargetNameApprovalId">
                    <!-- Go to Substance Details page -->
                    <a [routerLink]="['/substances', assay.targetNameSubstanceKey]" target="_blank"
                      matTooltip="Go to Substance Details page">
                      <b>{{assay.targetName}}</b>
                    </a>
                    <!-- Display Chemical Structure -->
                    <!--
                    <div class="marginleft30px margintopneg10px width100px">
                      <a role="img" aria-label="substance image" class="zoom"
                        (click)="openImageModal(assay.targetNameSubstanceUuid)">
                        <img class="image-icon" appSubstanceImage size="90"
                          [entityId]="assay.targetNameSubstanceUuid">
                      </a>
                    </div>
                    -->

                  </div>
                  <ng-template #noTargetNameApprovalId>
                    <b>{{assay.targetName}}</b>
                  </ng-template>
                </td>
              </tr>

              <tr>
                <td class="fieldKey">Target Name Approval ID:</td>
                <td>{{ assay.targetNameApprovalId }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Human Homolog Target:</td>
                <td>
                  <div *ngIf="assay.humanHomologApprovalId; else noHumanHomologApprovalId">
                    <!-- Go to Substance Details page -->
                    <a [routerLink]="['/substances', assay.humanHomologApprovalId]" target="_blank"
                      matTooltip="Go to Substance Details page">
                      <b>{{assay.humanHomologTarget}}</b>
                    </a>
                  </div>
                  <ng-template #noHumanHomologApprovalId>
                    <b>{{assay.humanHomologTarget}}</b>
                  </ng-template>
                </td>
              </tr>

              <tr>
                <td class="fieldKey">Human Homolog Target Approval ID:</td>
                <td>{{ assay.humanHomologTargetApprovalId }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Ligand/Substrate:</td>
                <td>
                  <div *ngIf="assay.ligandSubstrateApprovalId; else noLigandApprovalId">
                    <!-- Go to Substance Details page -->
                    <a [routerLink]="['/substances', assay.ligandSubstrateApprovalId]" target="_blank"
                      matTooltip="Go to Substance Details page">
                      <b> {{assay.ligandSubstrate}}</b>
                    </a>
                  </div>
                  <ng-template #noLigandApprovalId>
                    <b> {{assay.ligandSubstrate}}</b>
                  </ng-template>
                </td>
              </tr>

              <tr>
                <td class="fieldKey">Ligand/Substrate Approval ID:</td>
                <td>{{ assay.ligandSubstrateApprovalId }}</td>
              </tr>

              <tr>
                <td class="fieldKey">Standard Ligand/Sub Concentration and Units:</td>
                <td>{{ assay.standardLigandSubstrateConcentration }}&nbsp;
                  {{ assay.standardLigandSubstrateConcentrationUnits}}
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <!-- Display Assay Details End -->

        <div *ngIf="assay._resultInformationList.length > 0; else noResults">
          <div class="colorblue font20px"><b>Screening Results ({{assay.invitroAssayScreenings.length}}), Controls, and Summaries</b></div>
        </div>
        <ng-template #noResults>
          <div class="colorblue font20px"><b>NO Results and Summaries</b></div>
        </ng-template>
        <br>

        <!-- ######################################################################### -->
        <!-- SCREENING DETAILS BEGIN                                                   -->
        <!-- ######################################################################### -->

        <!-- **** MAT-ACCORDIAN BEGIN *** -->
        <mat-accordion multi="true">

          <!-- Assay Information and Reference Details Begin -->
          <div *ngIf="assay._resultInformationList.length > 0">
            <div *ngFor="let screening of assay._resultInformationList; let indexScreening = index">

              <fieldset class="border">

                <legend class="border">
                  Details {{(indexScreening+1)}} of {{assay._resultInformationList.length}}
                </legend>
                <!-- LEGEND END -->


                <!-- invitroAssayResultInformation exists -->
                <div *ngIf="screening.invitroAssayResultInformation">

                  <!-- Display Reference Information Details -->
                  <div class="divflex margintop10px font12px">

                    <!-- Div 1-->
                    <div class="width250px">
                      <!-- ***** REFERENCES ***** -->
                      Reference:
                      <span class=""
                        *ngFor="let reference of screening.invitroAssayResultInformation.invitroReferences; let indexReference = index">
                        <span class="colormaroon" *ngIf="reference.primaryReference == true">
                          <b>{{reference.sourceType}}&nbsp;
                            {{reference.sourceId}}
                          </b>
                        </span>
                      </span> <!-- for Loop -->

                      <!-- LABORATORY -->
                      <br>
                      Laboratory:
                      <span class="colormaroon" *ngIf="screening.invitroAssayResultInformation.invitroLaboratory">
                        <b>{{screening.invitroAssayResultInformation.invitroLaboratory.laboratoryName}}</b>
                      </span>

                    </div> <!-- Div 1-->


                    <!-- Div 2-->
                    <div class="width250px marginleft30px">
                      <!-- ***** SPONSOR ***** -->
                      Sponsor:
                      <span class="colormaroon" *ngIf="screening.invitroAssayResultInformation.invitroSponsor">
                        <b>{{screening.invitroAssayResultInformation.invitroSponsor.sponsorContactName}}</b>
                      </span>

                      <!-- ***** SUBMITTERS ***** -->
                      <span *ngIf="screening.invitroAssayResultInformation.invitroSponsorReport">
                        <span
                          *ngIf="screening.invitroAssayResultInformation.invitroSponsorReport.invitroSponsorSubmitters.length > 0">
                          <br>
                          <span
                            *ngFor="let sub of screening.invitroAssayResultInformation.invitroSponsorReport.invitroSponsorSubmitters; let indexSub = index">
                            <span *ngIf="indexSub > 0"><br></span>
                            Submitter {{(indexSub+1)}}:&nbsp;
                            <span class="colormaroon">
                              <b>{{sub.sponsorReportSubmitterName}}</b>
                            </span>
                          </span>
                        </span>
                      </span>

                      <!-- ***** REPORT ***** -->
                      <br>
                      Report Number:
                      <span class="colormaroon" *ngIf="screening.invitroAssayResultInformation.invitroSponsorReport">
                        <b>{{screening.invitroAssayResultInformation.invitroSponsorReport.reportNumber}}</b>
                      </span>

                    </div> <!-- Div 2-->


                    <!-- Div 3-->
                    <div class="width250px marginleft30px">
                      <!-- ***** TEST AGENT ***** -->
                      Test Agent:
                      <span *ngIf="screening.invitroAssayResultInformation.invitroTestAgent" class="font14px">
                        <span
                          *ngIf="screening.invitroAssayResultInformation.invitroTestAgent.testAgentSubstanceKey; else noTestAgentSubKey">
                          <!-- Go to Substance Details page -->
                          <a [routerLink]="['/substances', screening.invitroAssayResultInformation.invitroTestAgent.testAgentSubstanceKey]"
                            target="_blank" matTooltip="Go to Substance Details page">
                            <span
                              class="colororange"><b>{{screening.invitroAssayResultInformation.invitroTestAgent.testAgent}}</b></span>
                          </a>
                        </span>
                        <ng-template #noTestAgentSubKey>
                          <span
                            class="colororange"><b>{{screening.invitroAssayResultInformation.invitroTestAgent.testAgent}}</b></span>
                        </ng-template>
                      </span>

                      <!-- ***** BATCH NUMBER ***** -->
                      <br>
                      Batch Number:
                      <span class="colormaroon">
                        <b>{{screening.invitroAssayResultInformation.batchNumber}}</b>
                      </span>

                    </div> <!-- Div 3-->


                    <!-- Div 4-->
                    <!-- Edit Screening -->
                    <div class="width120px marginright20px marginleft30px alignright font13px">

                      <div class="divflex margintopneg15px">
                        <div>
                          <mat-icon svgIcon="edit"></mat-icon>
                        </div>
                        <!-- Edit Screening link -->
                        <div>
                          <a [routerLink]="['/invitro-pharm', screening.invitroAssayResultInformation.id, 'edit']"
                            target="_blank" *ngIf="isAdmin" matTooltip='Edit Assay Screening Record'>
                            <span class="font12px">Edit Screening</span>
                          </a>
                        </div>
                      </div> <!-- divflex -->

                      <!-- Show More fields link -->
                      <br>
                      <div>
                        <a href="" (click)="toggleShowMoreLessFieldsArray(indexScreening); false">

                          <!--
                          <span *ngIf="showMoreLessFields"><b>Show More Fields</b></span>
                          <span *ngIf="!showMoreLessFields"><b>Show Less Fields</b></span>
                          -->
                          <span *ngIf="showMoreLessFieldsArray[indexScreening]"><b>Show More Fields</b></span>
                          <span *ngIf="!showMoreLessFieldsArray[indexScreening]"><b>Show Less Fields</b></span>
                        </a>
                      </div>

                    </div> <!-- Div 4 -->

                  </div> <!-- class=divflex -->


                  <!-- ####################################### -->
                  <!-- DISPLAY DETAILS SHOW/HIDE MORE BEGIN -->
                  <div *ngIf="!showMoreLessFieldsArray[indexScreening]">

                    <!-- Invitro Reference Begin -->
                    <div class="margintop20px"
                      *ngIf="screening.invitroAssayResultInformation.invitroReferences.length > 0">

                      <span class=""
                        *ngFor="let reference of screening.invitroAssayResultInformation.invitroReferences; let indexReference = index">

                        <table class="tableStyle">
                          <tbody>
                            <tr class="headerstyle3">
                              <td colspan="2"><span class="font16px">Assay Reference</span></td>
                            </tr>
                            <tr>
                              <td class="fieldKey">Reference Source Type:</td>
                              <td>
                                <div>
                                  {{reference.sourceType}}
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td class="fieldKey">Reference Source Id:</td>
                              <td>
                                <div>
                                  {{reference.sourceId}}
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td class="fieldKey">Digital Object Identifier:</td>
                              <td>
                                <div>
                                  {{reference.digitalObjectIdentifier}}
                                </div>
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </span> <!-- for Loop -->

                    </div> <!-- if invitroReference length > 0  -->


                    <!-- Laboratory Details -->
                    <div class="margintop30px marginbottom30px"
                      *ngIf="screening.invitroAssayResultInformation.invitroLaboratory">

                      <table class="tableStyle">
                        <tbody>
                          <tr class="headerstyle3">
                            <td colspan="2"><span class="font16px">Laboratory</span></td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Laboratory Name:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroLaboratory.laboratoryName}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Laboratory Affiliation:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroLaboratory.laboratoryAffiliation}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Laboratory Type:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroLaboratory.laboratoryType}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Laboratory Street Address:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroLaboratory.laboratoryStreetAddress}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Laboratory City:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroLaboratory.laboratoryCity}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Laboratory State:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroLaboratory.laboratoryState}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Laboratory Zipcode:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroLaboratory.laboratoryZipcode}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Laboratory Country:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroLaboratory.laboratoryCountry}}
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div> <!-- if Laboratory exist -->


                    <!-- Sponsor Details -->
                    <div class="margintop20px" *ngIf="screening.invitroAssayResultInformation.invitroSponsor">
                      <table class="tableStyle">
                        <tbody>
                          <tr class="headerstyle3">
                            <td colspan="2"><span class="font16px">Sponsor</span></td>
                          </tr>
                          <tr>
                            <td class="fieldKey">Sponsor Contact Name:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroSponsor.sponsorContactName}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Sponsor Affiliation:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroSponsor.sponsorAffiliation}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Sponsor Street Address:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroSponsor.sponsorStreetAddress}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Sponsor City:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroSponsor.sponsorCity}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Sponsor State:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroSponsor.sponsorState}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Sponsor Zipcode:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroSponsor.sponsorZipcode}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Sponsor Country:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroSponsor.sponsorCountry}}
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div> <!-- if invitroSponsor exists -->

                    <!-- Report Details -->
                    <div class="margintop20px" *ngIf="screening.invitroAssayResultInformation.invitroSponsorReport">
                      <table class="tableStyle">
                        <tbody>
                          <tr class="headerstyle3">
                            <td colspan="2"><span class="font16px">Sponsor Report</span></td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Report Number:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroSponsorReport.reportNumber}}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Report Date:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroSponsorReport.reportDate|date:
                                'MM/dd/yyyy'}}
                              </div>
                            </td>
                          </tr>


                          <!-- Report Submitter Table BEGIN-->
                          <tr>
                            <td colspan="2">
                              <div class="margintop10px colorbluelight font16px">
                                <b>Sponsor Report Submitters
                                  ({{screening.invitroAssayResultInformation.invitroSponsorReport.invitroSponsorSubmitters.length}})
                                </b>
                              </div>

                              <table class="tableStyle width100percent margintop10px">
                                <tbody>
                                  <tr>
                                    <th>Submitter Name</th>
                                    <th>Submitter Title</th>
                                    <th>Submitter Affiliation</th>
                                    <th>Submitter Email</th>
                                    <th>Submitter Phone Number</th>
                                    <th>Submitter Assay Type</th>
                                  </tr>

                                  <tr
                                    *ngFor="let sub of screening.invitroAssayResultInformation.invitroSponsorReport.invitroSponsorSubmitters; let indexSub = index">
                                    <td>{{sub.sponsorReportSubmitterName}}</td>
                                    <td>{{sub.sponsorReportSubmitterTitle}}</td>
                                    <td>{{sub.sponsorReportSubmitterAffiliation}}</td>
                                    <td>{{sub.sponsorReportSubmitterEmail}}</td>
                                    <td>{{sub.sponsorReportSubmitterPhoneNumber}}</td>
                                    <td>{{sub.sponsorReportSubmitterAssayType}}</td>
                                  </tr> <!-- Submitters Loop -->
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <!-- Table Report Submitter END -->

                        </tbody>
                      </table>
                    </div> <!-- if Report Submitter exist -->


                    <!-- Test Agent Details BEGIN -->
                    <div class="margintop20px" *ngIf="screening.invitroAssayResultInformation.invitroTestAgent">
                      <table class="tableStyle">
                        <tbody>
                          <tr class="headerstyle3">
                            <td colspan="2"><span class="font16px">Test Agent</span>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Test Agent:</td>
                            <td>
                              <span *ngIf="screening.invitroAssayResultInformation.invitroTestAgent" class="font14px">
                                <span
                                  *ngIf="screening.invitroAssayResultInformation.invitroTestAgent.testAgentSubstanceKey; else noTestAgentSubKey">
                                  <!-- Go to Substance Details page -->
                                  <a [routerLink]="['/substances', screening.invitroAssayResultInformation.invitroTestAgent.testAgentSubstanceKey]"
                                    target="_blank" matTooltip="Go to Substance Details page">
                                    <b>{{screening.invitroAssayResultInformation.invitroTestAgent.testAgent}}</b>
                                  </a>
                                </span>
                                <ng-template #noTestAgentSubKey>
                                  <b>{{screening.invitroAssayResultInformation.invitroTestAgent.testAgent}}</b>
                                </ng-template>
                              </span>
                            </td>
                          </tr>

                          <tr>
                            <td class="fieldKey">Test Agent Approval ID:</td>
                            <td>
                              <div>
                                {{screening.invitroAssayResultInformation.invitroTestAgent.testAgentApprovalId}}
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div> <!-- if testAgent Exists -->


                  </div> <!-- Display more Details -->
                  <!-- DISPLAY DETAILS SHOW/HIDE MORE END -->


                </div> <!-- if invitroAssayResultInformation exists -->


                <br>
                <!--
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="">
                      <div class="headerstyle title2 padtop7px padleft10px">
                        Assay Results ({{screening.invitroAssayResultList.length}})
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Summaries ({{screening.invitroSummaryList.length}})
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                -->

                <div *ngIf="i > 0">
                </div>

                <!-- ########################################################## -->

                <!-- LOOP: Assay Information Reference Test Agent Details BEGIN -->

                <!---
              <div class="bordergray margintop10px"
                *ngFor="let infoRefTestAgent of infoRef.invitroAssayInfoRefTestAgents; let indexAgent = index">
              -->

                <!--
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="bordergray">
                      <div class="headerstyle4 title2 padtop7px padleft10px">
                        Test Agent
                        &nbsp;&nbsp;&nbsp;
                        <span *ngIf="screening.invitroTestAgent">
                          {{screening.invitroTestAgent.testAgent}}
                        </span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                -->

                <!-- Assay Result Details BEGIN -->
                <div class="margintop20px">

                  <table class="tableStyle width100percent margintop10px">
                    <tbody>
                      <tr class="headerstyle3">
                        <td colspan="8">
                          <div class="divflex">
                            <div class="font16px">Assay Results
                              ({{(screening.invitroAssayResultList.length)}})
                            </div>

                            <div class="middle-fill">&nbsp;</div>

                            <div>
                              <a href="" (click)="toggleShowMoreLessResultFieldsArray(indexScreening); false">
                                <span *ngIf="showMoreLessResultFieldsArray[indexScreening]"><b>Show More Result
                                    Fields</b></span>
                                <span *ngIf="!showMoreLessResultFieldsArray[indexScreening]"><b>Show Less Result
                                    Fields</b></span>
                              </a>
                            </div>

                          </div> <!-- divflex -->
                        </td>
                      </tr>

                      <tr class="font13px">
                        <th>Test Agent Concentration and Units</th>
                        <th>Result Value and Units</th>
                        <th>Ligand/Substrate Concentration and Units</th>
                        <th>Plasma Protein Added</th>
                        <th>Protein</th>
                        <th>Plasma Protein Concentration and Units</th>
                        <th>Test Date</th>
                      </tr>

                      <ng-container *ngFor="let result of screening.invitroAssayResultList; let indexResult = index">
                        <tr>
                          <td>
                            {{result.testAgentConcentration}}&nbsp;
                            {{result.testAgentConcentrationUnits}}
                          </td>
                          <td>
                            {{result.resultValue}}&nbsp;
                            {{result.resultValueUnits}}
                          </td>
                          <td>
                            {{result.ligandSubstrateConcentration}}&nbsp;
                            {{result.ligandSubstrateConcentrationUnits}}
                          </td>
                          <td>{{result.plasmaProteinAdded}}</td>
                          <td>{{result.protein}}</td>
                          <td>{{result.plasmaProteinConcentration}}&nbsp;
                            {{result.plasmaProteinConcentrationUnits}}
                          </td>
                          <td> {{result.testDate}}</td>
                        <tr>

                          <!--
                          <ng-container *ngIf="!showMoreLessResultFieldsArray[indexScreening]">
                          -->
                        <tr *ngIf="!showMoreLessResultFieldsArray[indexScreening]">
                          <td class=""><b>Number Of Tests:</b></td>
                          <td colspan="8">
                            <div>
                              {{result.numberOfTests}}
                            </div>
                          </td>
                        </tr>

                        <tr *ngIf="!showMoreLessResultFieldsArray[indexScreening]">
                          <td class=""><b>Type of Data:</b></td>
                          <td colspan="8">
                            <div>
                              {{result.dataType}}
                            </div>
                          </td>
                        </tr>

                        <tr *ngIf="!showMoreLessResultFieldsArray[indexScreening]">
                          <td class=""><b>Comments:</b></td>
                          <td colspan="8">
                            <div>
                              {{result.comments}}
                            </div>
                          </td>
                        </tr>

                        <tr *ngIf="!showMoreLessResultFieldsArray[indexScreening]">
                          <td class=""><b>Assay Measurement:</b></td>
                          <td colspan="8">
                            <div>
                              {{result.assayMeasurement}}
                            </div>
                          </td>
                        </tr>
                        <!--
                      </ng-container>
                        -->
                      </ng-container> <!-- LOOP: Assay Results -->


                      <tr *ngIf="!showMoreLessResultFieldsArray[indexScreening]">
                        <td colspan="8">
                          <!-- LOOP: Control List -->
                          <div *ngIf="screening.invitroControlList">
                            <div class="margintop20px">
                              <table class="tableStyle">
                                <tbody>
                                  <tr class="headerstyle3">
                                    <td colspan="6"><span class="font16px">Control
                                        ({{(screening.invitroControlList.length)}})
                                      </span>
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Control</th>
                                    <th>Control Approval ID</th>
                                    <th>Control Type</th>
                                    <th>Control Reference Value</th>
                                    <th>Control Reference Value Units</th>
                                    <th>Result Type</th>
                                  </tr>

                                  <tr *ngFor="let ctr of screening.invitroControlList; let indexControl = index">
                                    <td>
                                      <span *ngIf="ctr.control" class="font14px">
                                        <span
                                          *ngIf="ctr.controlApprovalId; else noConrolApprovalId">
                                          <!-- Go to Substance Details page -->
                                          <a [routerLink]="['/substances', ctr.controlApprovalId]"
                                            target="_blank" matTooltip="Go to Substance Details page">
                                            <b>{{ctr.control}}</b>
                                          </a>
                                        </span>
                                        <ng-template #noConrolApprovalId>
                                          <b>{{ctr.control}}</b>
                                        </ng-template>
                                      </span>
                                    </td>
                                    <td>{{ctr.controlApprovalId}}</td>
                                    <td>{{ctr.controlType}}</td>
                                    <td>{{ctr.controlReferenceValue}}</td>
                                    <td>{{ctr.controlReferenceValueUnits}}</td>
                                    <td>{{ctr.controlResultType}}</td>
                                  <tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <!-- LOOP: Control List END -->
                        </td>

                      </tr>
                    </tbody>
                  </table>

                  <!-- SUMMARY TABLE -->
                  <table class="tableStyle width100percent margintop20px">
                    <tbody>
                      <tr class="headerstyle3">
                        <td colspan="9">
                          <div class="divflex">
                            <div class="font16px">Summary
                              ({{(screening.invitroSummaryList.length)}})
                            </div>

                            <!--
                            <div class="middle-fill">&nbsp;</div>
                            <div>
                              <a href="" (click)="toggleShowMoreLessResultFieldsArray(indexScreening); false">
                                <span *ngIf="showMoreLessResultFieldsArray[indexScreening]"><b>Show More Summary
                                    Fields</b></span>
                                <span *ngIf="!showMoreLessResultFieldsArray[indexScreening]"><b>Show Less Summary
                                    Fields</b></span>
                              </a>
                            </div>
                            -->

                          </div> <!-- divflex -->
                        </td>
                      </tr>

                      <tr class="font13px">
                        <th>Target Name</th>
                        <th>Result Value (Low):</th>
                        <th>Result Value (Average):</th>
                        <th>Result Value (High):</th>
                        <th>Result Value Units:</th>
                        <th>Result Type</th>
                        <th>Relationship Type</th>
                        <th>Interaction Type</th>
                        <th>Is From Result</th>
                      </tr>

                      <ng-container *ngFor="let summary of screening.invitroSummaryList; let indexSummary = index">
                        <tr>
                          <td class="width150px">{{summary.targetName}}</td>
                          <td>{{summary.resultValueLow}}</td>
                          <td>{{summary.resultValueAverage}}</td>
                          <td>{{summary.resultValueHigh}}</td>
                          <td>{{summary.resultValueUnits}}</td>
                          <td>{{summary.resultType}}</td>
                          <td>{{summary.relationshipType}}</td>
                          <td>{{summary.interactionType}}</td>
                          <td><span *ngIf="summary.isFromResult && summary.isFromResult == true">
                              <mat-icon matTooltip="This Summary data is from Result data">
                                check_circle_outline
                              </mat-icon>
                            </span>
                          </td>
                        <tr>

                        <tr>
                          <td class="">
                            <b>Comments:</b>
                          </td>
                          <td colspan="8">
                            {{summary.comments}}
                          </td>
                        </tr>

                      </ng-container>

                    </tbody>
                  </table>
                  <!-- Assay Summary Details END -->

                </div> <!-- div margintop20px -->

                <!--
                </mat-expansion-panel>
              -->

              </fieldset> <!-- fieldset -->
            </div> <!-- LOOP: invitroAssayScreenings -->

          </div> <!-- if _resultInformationList length > 0 -->

          <!-- Assay Information and Screening Details END -->

          <br>
        </mat-accordion>
        <!-- **** MAT-ACCORDIAN END for Information Reference *** -->

      </mat-card-content>
    </mat-card>

    <br><br><br>
  </div> <!-- *ngIf="assay.id -->
  <ng-template #noRecord>
    <br><br><br><br>
    <div class="colororange padtop50px padleft50px">There is no Assay Screening Data found.</div>
  </ng-template>

</div>