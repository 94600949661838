<div class="query-statement-container">
    <div class="condition-column">
        <mat-form-field *ngIf="index > 0">
            <mat-select [formControl]="conditionControl" (openedChange)="openedChange($event)">
                <mat-option 
                    *ngFor="let option of conditionOptions"
                    [value]="option">
                    {{option}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="queryable-property-column">
        <mat-form-field *ngIf="isShowCommonFields">
            <mat-select [formControl]="queryablePropertiesControl" (openedChange)="openedChange($event)">
                <mat-option 
                    *ngFor="let option of queryableOptionsCommon"
                    [value]="option"
                    [matTooltip]="option">
                    {{option}}
                </mat-option>
            </mat-select>
            <span class="middle-fill"></span>
            <mat-hint class="action-hint">
                <a (click)="showAllFields()">Show all fields</a>
            </mat-hint>
        </mat-form-field>
        <div *ngIf="!isShowCommonFields">
            <mat-form-field floatLabel="never">
                <input type="text"
                    matInput
                    [formControl]="queryablePropertiesAutocompleteControl"
                    [matAutocomplete]="queryablePropertyOptions"
                    placeholder="Search for desired field"
                    (blur)="queryablePropertiesAutocompleteBlurred()">
                <a matSuffix (click)="clearSelectedQueryableProperty()" matTooltip="Clear input and show all options">
                    <mat-icon svgIcon="clear"></mat-icon>
                </a>
                <mat-hint class="action-hint">
                    <a (click)="showCommonFields()">Show common fields</a>
                </mat-hint>
            </mat-form-field>
            <mat-autocomplete 
                #queryablePropertyOptions="matAutocomplete"
                (optionSelected)="queryablePropertySelected($event.option.value)"
                (opened)="increaseOverlayZindex()"
                (closed)=queryablePropertiesAutocompleteClosed()>
                <mat-option *ngFor="let option of queryableOptionsAll" [value]="option" [matTooltip]="option">
                    {{option}}
                </mat-option>
            </mat-autocomplete>
        </div>
    </div>
    <div class="command-column">
        <mat-form-field [matTooltip]="selectedCommandOption">
            <mat-select [formControl]="commandControl" (openedChange)="openedChange($event)">
                <mat-option *ngFor="let option of commandOptions" [value]="option">
                    {{option}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="query-column">
        <div class="query-input" *ngFor="let commandInput of commandInputs; index as index">
            <div *ngIf="commandInput.type == 'text' || commandInput.type == 'boolean' || commandInput.type == 'number'">
                <mat-form-field floatLabel="never">
                    <input
                        [type]="commandInput.type"
                        [name]="'input_' + index"
                        matInput
                        [(ngModel)]="commandInputValueDict[commandInput.type][index]"
                        required
                        placeholder="Enter search value"
                        (keyup)="commandInput.constructQuery(
                            $event.target.value.trim(),
                            selectedCondition,
                            selectedQueryableProperty,
                            selectedLucenePath,
                            queryUpdated,
                            queryParts,
                            commandInputValueDict[commandInput.type]
                        )">
                    <mat-error *ngIf="!commandInputValueDict[commandInput.type][index]">Required</mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="commandInput.type == 'datetime'">
                <mat-form-field floatLabel="never">
                    <input
                        matInput
                        required
                        placeholder="m/d/yyyy"
                        [(ngModel)]="commandInputValueDict[commandInput.type][index]"
                        (dateChange)="commandInput.constructQuery(
                            $event.value,
                            selectedCondition,
                            selectedQueryableProperty,
                            selectedLucenePath,
                            queryUpdated,
                            queryParts,
                            commandInputValueDict[commandInput.type]
                        )"
                        (dateInput)="commandInput.constructQuery(
                            $event.value,
                            selectedCondition,
                            selectedQueryableProperty,
                            selectedLucenePath,
                            queryUpdated,
                            queryParts,
                            commandInputValueDict[commandInput.type]
                        )"
                        [matDatepicker]="queryDatepicker">
                    <mat-error *ngIf="!commandInputValueDict[commandInput.type][index]">Required</mat-error>
                    <mat-datepicker-toggle matSuffix [for]="queryDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #queryDatepicker (opened)="increaseOverlayZindex()" (closed)="decreaseOverlayZindex()"></mat-datepicker>
                </mat-form-field>
            </div>
            <div *ngIf="commandInput.type == 'select'">
                <mat-form-field floatLabel="never">
                    <mat-select [(ngModel)]="commandInputValueDict[commandInput.type][index]"
                        required
                        placeholder="Select default option"
                        (openedChange)="openedChange($event)"
                        (selectionChange)="commandInput.constructQuery(
                        $event.value,
                        selectedCondition,
                        selectedQueryableProperty,
                        selectedLucenePath,
                        queryUpdated,
                        queryParts,
                        commandInputValueDict[commandInput.type]
                    )">
                        <mat-option 
                            *ngFor="let option of cvOptions"
                            [value]="option.value"
                            [matTooltip]="option.display">
                            {{option.display}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!commandInputValueDict[commandInput.type][index]">Required</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
