<!-- Buttons: delete, review -->
<div class="divflexend marginbottomminus12px padleft250px">
  <div class="maringright10px">
    <button mat-icon-button [ngStyle]="{'color':'green'}" (click)="copyProductComponent()"
      matTooltip="Copy Manufacture Item Details {{prodComponentIndex + 1}} to New Manufacture Item">
      <mat-icon>file_copy</mat-icon>
    </button>
  </div>
  <div class="maringright10px">
    <button mat-icon-button [ngStyle]="{'color':'green'}" (click)="confirmDeleteComponent(prodComponentIndex)"
      matTooltip="Delete Manufacture Item Details {{prodComponentIndex + 1}}">
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
</div>

<!-- Display Component/Manufacture -->
<fieldset class="border">
  <legend class="border">Manufacture Item Details&nbsp;<b><span class="colorgreen font17px">{{prodComponentIndex +
        1}}</span></b>
  </legend>

  <div class="form-row margintop10px">
    <app-cv-input class="col-2-4" domain="DOSAGE_FORM" title="Dosage Form" name="dosageForm"
      [model]="productComponent.dosageForm" (valueChange)="productComponent.dosageForm = $event">
    </app-cv-input>

    <mat-form-field class="col-1-4">
      <input matInput placeholder="Dosage Form Code" [(ngModel)]="productComponent.dosageFormCode"
        name="dosageFormCode" />
    </mat-form-field>

    <app-cv-input class="col-1-4" domain="DOSAGE_FORM_CODE_TYPE" title="Dosage Form Code Type" name="dosageFormCodeType"
      [model]="productComponent.dosageFormCodeType" (valueChange)="productComponent.dosageFormCodeType = $event">
    </app-cv-input>
  </div>

  <div class="form-row">
    <mat-form-field class="col-2-4">
      <textarea matInput placeholder="Dosage Form Note" [(ngModel)]="productComponent.dosageFormNote"
        name="dosageFormNote"></textarea>
    </mat-form-field>

    <mat-form-field class="col-2-4">
      <textarea matInput placeholder="Composition Note" [(ngModel)]="productComponent.compositionNote"
        name="compositionNote"></textarea>
    </mat-form-field>
  </div>

  <div class="form-row">
    <app-cv-input class="col-1-4" domain="PROD_CHARACTER_SHAPE" title="Shape Name" name="charShape"
      [model]="productComponent.charShape" (valueChange)="productComponent.charShape = $event">
    </app-cv-input>

    <app-cv-input class="col-1-4" domain="PROD_CHARACTER_FRAGMENTS" title="Scoring" name="charNumFragments"
      [model]="productComponent.charNumFragments" (valueChange)="productComponent.charNumFragments = $event">
    </app-cv-input>

    <mat-form-field class="col-1-4">
      <input matInput placeholder="Size" [(ngModel)]="productComponent.charSize" name="charSize" />
    </mat-form-field>

    <mat-form-field class="col-1-4">
      <input matInput placeholder="Imprint Text" [(ngModel)]="productComponent.charImprintText"
        name="charImprintText" />
    </mat-form-field>
  </div>

  <div class="form-row">
    <app-cv-input class="col-2-4" domain="PROD_CHARACTER_COLOR" title="Color Name" name="charColor"
      [model]="productComponent.charColor" (valueChange)="productComponent.charColor = $event">
    </app-cv-input>

    <app-cv-input class="col-2-4" domain="PROD_CHARACTER_FLAVOR" title="Flavor Name" name="charFlavor"
      [model]="productComponent.charFlavor" (valueChange)="productComponent.charFlavor = $event">
    </app-cv-input>
  </div>

  <div class="form-row">
    <mat-form-field class="col-1-4">
      <input matInput placeholder="Amount" [(ngModel)]="productComponent.amount" name="amount" />
    </mat-form-field>

    <app-cv-input class="col-1-4" domain="PROD_UNIT" title="Unit" name="unit" [model]="productComponent.unit"
      (valueChange)="productComponent.unit = $event">
    </app-cv-input>

    <app-cv-input class="col-1-4" domain="PROD_ROUTE_OF_ADMIN" title="Route of Administration"
      name="routeOfAdministration" [model]="productComponent.routeOfAdministration"
      (valueChange)="productComponent.routeOfAdministration = $event">
    </app-cv-input>

    <mat-form-field class="col-1-4">
      <input matInput placeholder="Provenance Manufacture Item ID"
        [(ngModel)]="productComponent.provenanceManufactureItemId" name="provenanceManufactureItemId" />
    </mat-form-field>
  </div>


  <!-- Product MANUFACTURER Begin -->
  <br>
  <span matBadge="{{productComponent.productManufacturers.length}}" matBadgeOverlap="false">
    <span class="titlebrown">Manufacturer Item Code</span>
  </span>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <button mat-raised-button color="primary" (click)="addNewProductManufacturer(prodComponentIndex)"
    matTooltip="Add New Manufacturer Item Code">
    <mat-icon svgIcon="add_circle_outline"></mat-icon>Add Manufacturer Item Code
  </button>
  <br>

  <div class="marginbottom10px"
    *ngFor="let productManufacturer of productComponent.productManufacturers; let prodManuIndex = index">
    <!-- Delete buttons -->
    <div class="divflexend margintopneg10px">
      <div class="middle-fill"></div>
      <button class="padtop9px marginright10px" mat-icon-button color="primary"
        (click)="confirmDeleteProductManufacturer(prodComponentIndex, prodManuIndex)"
        matTooltip="Delete Manufacturer Item Code {{prodManuIndex + 1}}">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>

    <fieldset class="border">
      <legend class="border">Manufacturer Item Code&nbsp;<b>{{(prodManuIndex+1)}}</b>&nbsp;&nbsp;
      </legend>

      <div class="form-row">
        <app-cv-input class="col-3-1" domain="PROD_COMPANY_ROLE" title="Manufacturer Role" name="manufacturerRole"
          [model]="productManufacturer.manufacturerRole" (valueChange)="productManufacturer.manufacturerRole = $event">
        </app-cv-input>

        <mat-form-field class="col-3-1">
          <input matInput placeholder="Manufacturer Name" [(ngModel)]="productManufacturer.manufacturerName"
            name="manufacturerName" />
        </mat-form-field>

        <app-cv-input class="col-3-1" domain="PROD_MANUFACTURE_CODE_TYPE" title="Manufacturer Code Type"
          name="manufacturerCodeType" [model]="productManufacturer.manufacturerCodeType"
          (valueChange)="productManufacturer.manufacturerCodeType = $event">
        </app-cv-input>
      </div>

      <div class="form-row margintop10px">
        <mat-form-field class="col-3-1">
          <input matInput placeholder="Manufacturer Code" [(ngModel)]="productManufacturer.manufacturerCode"
            name="manufacturerCode" />
        </mat-form-field>

        <mat-form-field class="col-3-1">
          <input matInput placeholder="Manufactured Item Code" [(ngModel)]="productManufacturer.manufacturedItemCode"
            name="manufacturedItemCode" />
        </mat-form-field>

        <app-cv-input class="col-3-1" domain="PROD_MANUFACTURED_ITEM_CODE_TYPE" title="Manufactured Item Code Type"
          name="manufacturedItemCodeType" [model]="productManufacturer.manufacturedItemCodeType"
          (valueChange)="productManufacturer.manufacturedItemCodeType = $event">
        </app-cv-input>
      </div>
    </fieldset>
  </div> <!-- loop -->
  <!-- Product MANUFACTURER End -->


  <!-- PRODUCT LOT LIST BEGIN -->
  <div class="">
    <br>
    <hr>
    <br>
    <span matBadge="{{productComponent.productLots.length}}" matBadgeOverlap="false">
      <span class="titlebrown">LOT </span>
    </span>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button mat-raised-button color="primary" (click)="addNewProductLot(prodComponentIndex)" matTooltip="Add New Lot">
      <mat-icon svgIcon="add_circle_outline"></mat-icon>Add Lot
    </button>
    <br>
    <div *ngIf="productComponent.productLots.length > 0">
      <div class="marginbottom10px" *ngFor="let productLot of productComponent.productLots; let prodLotIndex = index">
        <app-product-lot-form [productLot]="productLot" [totalLot]="productComponent.productLots.length"
          [prodLotIndex]="prodLotIndex" [prodComponentIndex]="prodComponentIndex"
          (expiryDateMessageOut)="expiryDateMessageOutChange($event)"
          (manufactureDateMessageOut)="manufactureDateMessageOutChange($event)">
        </app-product-lot-form>
      </div>
    </div>
  </div>
  <!-- PRODUCT LOT LIST END -->

</fieldset>