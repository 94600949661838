<br><br><br>
<mat-sidenav-container>

  <mat-sidenav mode="side" opened>
    <app-facets-manager (facetsParamsUpdated)="facetsParamsUpdated($event)" (facetsLoaded)="facetsLoaded($event)"
      [rawFacets]="rawFacets" configName="invitropharmacology" includeFacetSearch="true">
    </app-facets-manager>
  </mat-sidenav>

  <!-- Browse In-vitro Pharmacology Content -->
  <mat-sidenav-content>
    <div class="side-nav-content">
      <button aria-label="Open Side Navigation" class="expand-sidenav mat-elevation-z4" (click)="openSideNav()">
        <mat-icon svgIcon="chevron_right"></mat-icon>
      </button>

      <!-- NARROW SEARCH SUGGESTIONS BEGIN -->
      <div class="narrow-search-suggestions-container"
        *ngIf="matchTypes && matchTypes.length > 0; else filterParameters">
        <div class="flex-row">
          <div class="mat-elevation-z2 narrow-search-suggestions" style="margin:auto; background-color: white;">
            <div>
              Would you like to restrict this search to a field?
            </div>&nbsp;
            <button mat-flat-button color="primary" [matMenuTriggerFor]="searchSuggestions" style="padding-right: 5px;"
              (menuOpened)="increaseOverlayZindex()" (menuClosed)="decreaseOverlayZindex()">
              <span>Fields </span>&nbsp;
              {{narrowSearchSuggestionsCount}}
              <mat-icon svgIcon="drop_down"></mat-icon>
            </button>
            <button mat-flat-button color="primary" (click)="clearFilters()">
              <span>RESET</span>
            </button>
            <button mat-icon-button color="primary" *ngIf="isSearchEditable" (click)="editAdvancedSearch()">
              <mat-icon svgIcon="edit"></mat-icon>
            </button>
            <mat-menu class="search-suggestions-container" #searchSuggestions="matMenu">
              <div class="narrow-search-dropdown" *ngFor="let matchType of matchTypes">
                <div *ngIf="((matchType) && (matchType === 'WORD' || matchType === 'FULL'))">
                  <div class="match-type">
                    {{matchType == 'WORD' ? 'Contains Match' : 'Exact Match'}}
                  </div>
                  <button mat-menu-item *ngFor="let suggestion of narrowSearchSuggestions[matchType]"
                    (click)="restricSearh(suggestion.luceneQuery)">
                    <div class="suggestion-display">
                      {{suggestion.displayField}}
                    </div>
                    <div class="suggestion-count">
                      {{suggestion.count}}
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
          </div>
        </div>
        <div class="flex-row">
          <div class="mat-elevation-z2 narrow-search-suggestions"
            style="margin:auto; background-color: white; margin-top: 12px; ">
            For more options use the <a routerLink="/advanced-search" style="padding-left: 6px;"> Advanced Search</a>
          </div>
        </div>
      </div>
      <!-- Narrow Search Suggestion End -->


      <!-- SEARCHTERM -->
      <ng-template #filterParameters>
        <div class="search-parameters">
          <div class="mat-elevation-z2 selected-parameter" *ngIf="searchTerm">
            <div>
              <span class="capitalized font-medium-bold no-break">Search Query:</span>&nbsp;
              <span>{{searchTerm}}</span>
            </div>
            <div class="actions-container">
              <button mat-icon-button color="primary" *ngIf="isSearchEditable" (click)="editAdvancedSearch()">
                <mat-icon svgIcon="edit"></mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="clearSearch()">
                <mat-icon svgIcon="delete_forever"></mat-icon>
              </button>
            </div>
          </div>

          <!-- Display Facets selection -->
          <div class="mat-elevation-z2 selected-parameter" *ngFor="let facet of displayFacets">
            <div class='selected-container'>
              <span class="capitalized font-medium-bold selected-label">
                <mat-icon class="not-icon" svgIcon="not_interested" *ngIf="!facet.bool"></mat-icon>
                {{facet.type}}:
              </span>
              <span class="no-break selected-value">
                {{facet.val}}
              </span>
            </div>
            <div class="actions-container">
              <button mat-icon-button class="display-button" color="primary"
                (click)="facet.removeFacet(facet.type, facet.bool, facet.val)">
                <mat-icon class="display-icon" svgIcon="delete_forever"></mat-icon>
              </button>
            </div>
          </div>
          <button class="reset-facets-button" mat-raised-button color="primary" (click)="clearFilters()"
            *ngIf="(displayFacets && displayFacets.length > 0) || searchTerm">
            <span>RESET</span>
          </button>
        </div>
      </ng-template>

      <!-- Title, Export, Pagination -->
      <div class="controls-container">

        <div class="title-container">
          <span class="main-title">Browse In-vitro Pharmacology</span>
        </div>

        <!-- Table, Full, and Summary Views -->
        <mat-button-toggle-group [value]="view" (change)="updateView($event)">
          <!--
          <mat-button-toggle value="table" matTooltip="Screening View">
            <mat-icon svgIcon="list"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="summary" matTooltip="Summary View">
            <mat-icon svgIcon="list"></mat-icon>
          </mat-button-toggle>

          <mat-button-toggle value="cards" matTooltip="Full View">
            <mat-icon svgIcon="view_stream"></mat-icon>
          </mat-button-toggle>
           -->
        </mat-button-toggle-group>

        <!-- Sort By -->
        <mat-form-field class="sort">
          <mat-label>Sort By</mat-label>
          <mat-select (selectionChange)="searchInvitroAssay()" [(ngModel)]="order">
            <mat-option *ngFor="let option of sortValues" [value]="option.value">
              {{option.display}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Export Button -->
        <!--
        <div class="export" *ngIf="isAdmin">
          <button matTooltip="Export Search Results" mat-button (click)="export()"
            class="mat-button export-button mat-raised-button">
            <mat-icon svgIcon="get_app"></mat-icon> Export
          </button>
        </div>
        -->

        <!-- Export Button with dropdown with multiple extension -->
        <div class="export" *ngIf="isAdmin">
          <mat-menu #exportMenu="matMenu">
            <a mat-menu-item *ngFor="let option of exportOptions" (click)="export(option.extension)">
              {{option.displayname}}
            </a>
          </mat-menu>

          <div class="export ">
            <button *ngIf="isLoggedIn && exportOptions && exportOptions.length > 0" matTooltip="Export Search Results"
              mat-button class="mat-button export-button mat-raised-button" [matMenuTriggerFor]="exportMenu">
              <mat-icon svgIcon="get_app"></mat-icon> Export
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          </div>
        </div>

        <div class="break"></div>
        <!-- Pagination -->
        <div class="full-paginator">
          <mat-paginator #paginator [length]="totalCountAssayTarget" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 50, 100, 200, 500, 1000, 5000]" [showFirstLastButtons]="true"
            (page)="changePage($event)">
          </mat-paginator>

          <div class="page-selector">
            <span class="page-label">Page: </span>
            <mat-form-field class="page-input" [ngClass]="{'bad-page':invalidPage}">
              <input matInput matTooltip="Type a page and press enter" [ngClass]="{'bad-page':invalidPage}"
                [value]="pageIndex + 1" #pagenum (keydown.enter)="customPage($event)"
                (input)="validatePageInput($event)? invalidPage = false: invalidPage = true"
                (focusout)="pagenum.value = pageIndex + 1; invalidPage = false">
            </mat-form-field>
            <span class="page-label"> of {{lastPage}}</span>
          </div>
        </div>

      </div> <!-- class=controls-container-->

      <div class="divflex">
        <!-- *** Search Any Text (TYPE AHEAD) *** -->
        <div class="search-text">
          <app-invitro-pharmacology-text-search class="top-search" placeholder="Search Any Text" styling="homePage"
            [searchValue]="searchValue" (searchPerformed)="processSubstanceSearch($event)"
            eventCategory="invitroPharmacologySearch">
          </app-invitro-pharmacology-text-search>
        </div>

        <!-- Link to All Assay page -->
        <div class="marginleft40px margintop10px">
          <a mat-flat-button class="bordergray" color="basic" [routerLink]="['/invitro-pharm']"
            [queryParams]="{view : 'all-assays'}" target="_blank" matTooltip='Opens in a new window'>
            <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window"></mat-icon>
            View All Assay Lists
          </a>
        </div>

        <!-- Link to All Test Agent page -->
        <div class="marginleft40px margintop10px">
          <a mat-flat-button class="bordergray" color="basic" [routerLink]="['/invitro-pharm']"
            [queryParams]="{view : 'all-testagents'}" target="_blank" matTooltip='Opens in a new window'>
            <mat-icon class="small-icon" svgIcon="open_in_new" alt="View All Test Agents"
              matTooltip="View All Test Agents. Opens in new window"></mat-icon>
            View All Test Agents
          </a>
        </div>

      </div> <!-- class=divflex -->


      <!-- ****************************************** -->
      <!-- ********** Display Content Details ******* -->
      <!-- ****************************************** -->

      <!-- BEGIN TAB: Browse by All Assay Target, Browse by All Substance -->
      <div *ngIf="assays">
        <br>

        <!-- TABS -->
        <mat-tab-group class="" [(selectedIndex)]="tabSelectedIndex" (selectedTabChange)="tabSelectedUpdated($event)">

          <!-- ########################################################################## -->
          <!-- ########################################################################## -->
          <!-- Browse BY ALL ASSAY Tab Begin                                              -->
          <!-- ########################################################################## -->

          <mat-tab label="">
            <!-- TAB Label -->
            <ng-template mat-tab-label>
              <div>Browse By All Assay
                <div class="font12px">
                  <div class="font12px">
                    (<span class="colorblack font12px fontbold">{{totalCountBrowseAllAssay}}</span>)
                  </div>
                </div>
              </div>
            </ng-template>

            <div>
              <div class="divflex">
                <div class=" font17px padtop15px padbottom10px">
                  <b>Browse By All Assay</b>
                </div>
              </div> <!-- class=divflex-->
              <br>

              <div class="cards-view" *ngIf="assays && assays.length; else noRecordInvitro">

                <mat-card *ngFor="let assay of assays; let indexAllAssay = index">

                  <mat-card-title>

                    <div class="divflex">
                      <!-- View Details for this record -->
                      <div class="font12px marginleftneg10px margintopneg5px">
                        {{(indexAllAssay+1) + skip}} &nbsp;&nbsp;&nbsp;
                      </div>

                      <!-- View Details for this record -->
                      <div>
                        <a class="view-detail-link" [routerLink]="['/invitro-pharm', assay.id]" target="_blank"
                          matTooltip='View Assay Record'>
                          View Assay Details
                        </a>
                      </div>

                      <!-- Edit/Update ASSAY record -->
                      <div>
                        &nbsp;
                        <a [routerLink]="['/invitro-pharm/assay', assay.id, 'edit']" target="_blank" *ngIf="isAdmin"
                          matTooltip='Edit Assay Record ONLY'>
                          <mat-icon svgIcon="edit"></mat-icon>
                        </a>
                      </div>

                      <!-- Download Json For this record -->
                      <div class="margintopneg5px">
                        <span *ngIf="isAdmin">
                          <span *ngIf="assay.id">
                            <a button mat-icon-button color="primary" [href]="downloadJsonHref"
                              (click)="saveJSON(assay.id)" download="{{jsonFileName}}.json"
                              matTooltip='Download JSON for this record'>
                              <mat-icon class="" svgIcon="get_app"></mat-icon>
                            </a>
                          </span>
                        </span>
                      </div>

                    </div> <!-- class=divflex -->

                    <!--
                      {{assay.assayTarget}}
                      -->
                    <!--
                      <span *ngIf="assay.assayTarget">
                        <a [routerLink]="['/browse-invitro-pharm']"
                          [queryParams]='{search: "root_assayTarget:" + "\"^" + assay.assayTarget + "$\""}'
                          target="_blank">
                          {{assay.assayTarget}}
                        </a>
                      </span>
                      -->

                  </mat-card-title>

                  <mat-card-content>

                    <div class="row">

                      <div class="width30percent">

                        <!--  *ngIf="isAdmin" -->
                        <!--
                        <span>
                          <div class="row">
                            <div class="row-property-key-5">
                              Created By:
                            </div>
                            <div class="row-property-value-5">
                              {{assay.createdBy}}
                            </div>
                          </div>

                          <div class="row">
                            <div class="row-property-key-5">
                              Create Date:
                            </div>
                            <div class="row-property-value-5">
                              {{assay.creationDate|date: 'MM/dd/yyyy h:mm a'}}
                            </div>
                          </div>

                          <div class="row">
                            <div class="row-property-key-5">
                              Edited By:
                            </div>
                            <div class="row-property-value-5">
                              {{assay.modifiedBy}}
                            </div>
                          </div>

                          <div class="row">
                            <div class="row-property-key-5">
                              Edit Date:
                            </div>
                            <div class="row-property-value-5">
                              {{assay.lastModifiedDate|date: 'MM/dd/yyyy h:mm a'}}
                            </div>
                          </div>
                        </span>
                      -->
                      </div> <!-- right side -->

                    </div>
                    <!-- row -->


                    <!-- ***************************************************** -->
                    <!-- ALL ASSAY TAB: DETAILS                                -->
                    <!-- ***************************************************** -->

                    <div class="row">
                      <div class="row-property">
                        <div class="row-property-key">
                          Assay ID:
                        </div>
                        <div class="row-property-value">
                          <span class="colorblue font17px fontbold">{{assay.assayId}}</span>
                        </div>
                      </div>
                      <div class="row-property">
                        <div class="row-property-key">
                          External Assay ID:
                        </div>
                        <div class="row-property-value">
                          {{assay.externalAssayId}}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="row-property">
                        <div class="row-property-key">
                          Assay Target Name:
                        </div>
                        <div class="row-property-value">
                          <div *ngIf="assay.targetNameApprovalId; else noAllTargetNameApprovalId">
                            <a [routerLink]="['/substances', assay.targetNameApprovalId]" target="_blank"
                              matTooltip="Go to Substance Details page">
                              <b>{{assay.targetName}}</b>
                            </a>
                          </div>
                          <ng-template #noAllTargetNameApprovalId>
                            <b>{{assay.targetName}}</b>
                          </ng-template>
                        </div>
                      </div>

                      <div class="row-property">
                        <div class="row-property-key">
                          External Assay Source:
                        </div>
                        <div class="row-property-value">
                          {{assay.externalAssaySource}}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="row-property">
                        <div class="row-property-key">
                          Assay Title:
                        </div>
                        <div class="row-property-value">
                          {{assay.assayTitle}}
                        </div>
                      </div>
                      <div class="row-property">
                        <div class="row-property-key">
                          Bioassay Type:
                        </div>
                        <div class="row-property-value">
                          {{assay.bioassayType}}
                        </div>
                      </div>
                    </div>

                    <!-- SORTING TABLE
                        <table mat-table [dataSource]="assay._assayTargetSummaries" class="font12px" matSort
                          (matSortChange)="sortData($event)" matSortActive="studyType" matSortDirection="asc"
                          matSortDisableClear>
                          -->

                    <!-- *********************************************************** -->
                    <!-- ALL ASSAY TAB: SCREENING VIEW                                     -->
                    <!-- *********************************************************** -->

                    <br>
                    <span class="font14px colorbrown fontfamilyverdana"><b>Screening View</b></span>
                    &nbsp;<b>({{assay._assayTargetSummaries.length}})</b>

                    <table mat-table [dataSource]="assay._assayTargetSummaries" class="font12px">

                      <!-- Edit/Update RESULT/SCREENING record -->
                      <ng-container matColumnDef="viewDetails">
                        <th mat-header-cell *matHeaderCellDef> Edit Screening </th>
                        <td mat-cell *matCellDef="let assayscreening" class="width110px">
                          <!-- Edit SUMMARY Record -->
                          <div class="divflex">
                            <div class="">
                              <mat-icon svgIcon="edit"></mat-icon>
                            </div>
                            <div class="margintop5px">
                              <a [routerLink]="['/invitro-pharm', assayscreening.assayResultInfoId, 'edit']"
                                target="_blank" *ngIf="isAdmin" matTooltip='Edit Assay Screening Record'>
                                <span class="font12px">Edit Screening</span>
                              </a>
                            </div>
                          </div> <!-- divflex -->

                        </td>
                      </ng-container>

                      <ng-container matColumnDef="referenceSource">
                        <th mat-header-cell *matHeaderCellDef> Reference Source</th>
                        <td mat-cell *matCellDef="let assaysum">
                          {{assaysum.referenceSourceTypeAndId}}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="testAgent">
                        <th mat-header-cell *matHeaderCellDef> Test Agent </th>
                        <td mat-cell *matCellDef="let assaysum">
                          <span *ngIf="assaysum.testAgentSubstanceKey; else noAllAssayTestAgentSubKey">
                            <a [routerLink]="['/substances', assaysum.testAgentSubstanceKey]" target="_blank"
                              matTooltip="Go to Substance Details page">
                              {{assaysum.testAgent}}
                            </a>
                          </span>
                          <ng-template #noAllAssayTestAgentSubKey>
                            {{assaysum.testAgent}}
                          </ng-template>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="testAgentConcentration">
                        <th mat-header-cell *matHeaderCellDef> Test Agent Concentration </th>
                        <td mat-cell *matCellDef="let assaysum">
                          {{assaysum.testAgentConcentration}}
                          {{assaysum.testAgentConcentrationUnits}}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="resultValue">
                        <th mat-header-cell *matHeaderCellDef> Result Value </th>
                        <td mat-cell *matCellDef="let assaysum">
                          {{assaysum.resultValue}}&nbsp;
                          {{assaysum.resultValueUnits}}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="calculatedValue">
                        <th mat-header-cell *matHeaderCellDef> Value </th>
                        <td mat-cell *matCellDef="let assaysum">
                          {{assaysum.calculateIC50Value}}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="controls">
                        <th mat-header-cell *matHeaderCellDef> Controls </th>
                        <td mat-cell *matCellDef="let assayscreen" class="width110px">

                          <div *ngIf="assayscreen.controls">

                            <button class="mat-raised-button mat-primary dialog-close marginright10px"
                              (click)="openModalControls(controlTemplate)">Controls
                              ({{assayscreen.controls.length}})
                            </button>

                            <!-- ******************************* -->
                            <!-- POPUP DIALOG for CONTROLS BEGIN -->
                            <ng-template #controlTemplate>

                              <div class="font14px marginbottom10px">Test Agent:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                                  class="fontbold">{{assayscreen.testAgent}}</span></div>

                              <div class="font14px">Target Name: <span
                                  class="fontbold">{{assayscreen.targetName}}</span></div>

                              <br>
                              <div class="font18px fontbold" mat-dialog-title>Controls ({{assayscreen.controls.length}})
                              </div>

                              <div mat-dialog-content>

                                <!-- Control Loop table begin -->
                                <table mat-table [dataSource]="assayscreen.controls" class="font12px">

                                  <ng-container matColumnDef="control">
                                    <th mat-header-cell *matHeaderCellDef> Control </th>
                                    <td mat-cell *matCellDef="let ctrl">
                                      <div *ngIf="ctrl.controlSubstanceUuid; else noAllControlSubUuid">
                                        <a [routerLink]="['/substances', ctrl.controlSubstanceUuid]" target="_blank"
                                          matTooltip="Go to Substance Details page">
                                          {{ ctrl.control }}
                                        </a>
                                      </div>
                                      <ng-template #noAllControlSubUuid>
                                        {{ctrl.control}}
                                      </ng-template>
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="controlType">
                                    <th mat-header-cell *matHeaderCellDef> Control Type </th>
                                    <td mat-cell *matCellDef="let ctrl">
                                      {{ctrl.controlType}}
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="controlReferenceValue">
                                    <th mat-header-cell *matHeaderCellDef> Control Reference Value </th>
                                    <td mat-cell *matCellDef="let ctrl">
                                      {{ctrl.controlReferenceValue}}&nbsp;
                                      {{ctrl.controlReferenceValueUnits}}
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="resultType">
                                    <th mat-header-cell *matHeaderCellDef> Result Type</th>
                                    <td mat-cell *matCellDef="let ctrl">
                                      {{ctrl.controlResultType}}
                                    </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="controlColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: controlColumns;"></tr>
                                </table>
                                <!-- Control Loop table end -->

                              </div> <!-- mat-dialog-content -->

                              <div mat-dialog-actions>
                                <span class="middle-fill"></span>
                                <button class="mat-raised-button mat-primary" (click)="close()"
                                  mat-dialog-close>Close</button>
                              </div>

                            </ng-template>
                            <!-- Popup Dialog for Controls END -->

                          </div> <!-- controls exists -->
                        </td>
                      </ng-container>


                      <tr mat-header-row *matHeaderRowDef="assayAllScreeningDisplayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: assayAllScreeningDisplayedColumns;"></tr>
                    </table>

                    <!-- SUMMARY VIEW END -->

                    <!--
                        </mat-tab>
                      </mat-tab-group>
                      -->

                    <!-- END TAB: Screening, Summary View -->

                  </mat-card-content>
                </mat-card>
              </div>

            </div>
          </mat-tab>


          <!-- ########################################################################## -->
          <!-- ########################################################################## -->
          <!-- Browse By TARGET NAME Tab Begin                                            -->
          <!-- ########################################################################## -->

          <mat-tab label="">
            <!-- TAB Label -->
            <ng-template mat-tab-label>
              <div>View By Target Name
                <div class="font12px">
                  (<span class="font12px fontbold">
                    {{browseByTargetNameList.length}}
                  </span>)
                </div>
              </div>
            </ng-template>

            <div class="font17px padtop15px padbottom10px"><b>View By Target Name</b></div><br>

            <div class="cards-view" *ngIf="browseByTargetNameList.length > 0; else noRecordInvitro">

              <mat-card *ngFor="let assayTar of browseByTargetNameList; let indexTargetName = index">

                <mat-card-title>
                </mat-card-title>

                <mat-card-content>
                  <!-- ****** BEGIN TAB: ASSAY TARGET: Details -->
                  <div>
                    <div class="row">
                      <div class="row-property">
                        <div class="row-property-key">
                          Target Name:
                        </div>

                        <div class="row-property-value">

                          <div class="divflex">
                            <div *ngIf="assayTar.targetNameSubstanceUuid; else noTargetNameSubUuid">
                              <a [routerLink]="['/substances', assayTar.targetNameSubstanceUuid]" target="_blank"
                                matTooltip="Go to Substance Details page">
                                <b>{{assayTar.targetName}}</b>
                              </a>
                            </div>
                            <ng-template #noTargetNameSubUuid>
                              <span class="colorblue font17px fontbold">
                                {{assayTar.targetName}}
                              </span>
                            </ng-template>

                            <div class="marginleft15px">
                              <!-- View Assay Record -->
                              <a class="substance-name" [routerLink]="['/invitro-pharm', assayTar.id]" target="_blank"
                                alt="View Assay/Screening Record" matTooltip='View Assay Screening Record'>View Assay
                                Details
                              </a>&nbsp;&nbsp;&nbsp;
                            </div>

                          </div> <!-- divflex -->
                        </div> <!-- row-property -->

                      </div>
                    </div> <!-- row -->

                    <!-- ***************************************************** -->
                    <!-- ASSAY TARGET NAME TAB: SUMMARY VIEW TABLE BEGIN       -->
                    <!-- ***************************************************** -->
                    <br>
                    <div>
                      <span class="font14px colorbrown fontfamilyverdana">
                        <b>Sceening View</b>
                      </span>&nbsp;
                      <span class="font14px">
                        <b>({{assayTar.targetNameSummaryList.length}})</b>
                      </span>

                      <br><br>
                      <!-- SORTING TABLE
                      <table mat-table [dataSource]="assay._assayTargetSummaries" class="font12px" matSort
                        (matSortChange)="sortData($event)" matSortActive="studyType" matSortDirection="asc"
                        matSortDisableClear>
                        -->

                      <table mat-table [dataSource]="assayTar.targetNameSummaryList" class="font12px">

                        <!-- Edit/Update RESULT/SCREENING record -->
                        <ng-container matColumnDef="viewDetails">
                          <th mat-header-cell *matHeaderCellDef> Edit Screening </th>
                          <td mat-cell *matCellDef="let assayscreening" class="width110px">
                            <div class="divflex">
                              <!-- Edit SUMMARY Record -->
                              <div class="">
                                <mat-icon svgIcon="edit"></mat-icon>
                              </div>
                              <div class="margintop5px">
                                <a [routerLink]="['/invitro-pharm', assayscreening.assayResultInfoId, 'edit']"
                                  target="_blank" *ngIf="isAdmin" matTooltip='Edit Assay Screening Record'>
                                  <span class="font12px">Edit Screening</span>
                                </a>
                              </div>
                            </div> <!-- divflex -->
                          </td>
                        </ng-container>


                        <ng-container matColumnDef="referenceSource">
                          <th mat-header-cell *matHeaderCellDef> Reference Source </th>
                          <td mat-cell *matCellDef="let assayscreen"> {{assayscreen.referenceSourceTypeAndId}} </td>
                        </ng-container>

                        <ng-container matColumnDef="testAgent">
                          <th mat-header-cell *matHeaderCellDef> Test Agent </th>
                          <td mat-cell *matCellDef="let assayscreen">
                            <span *ngIf="assayscreen.testAgentSubstanceKey; else noTargetNameTestAgentSubKey">
                              <a [routerLink]="['/substances', assayscreen.testAgentSubstanceKey]" target="_blank"
                                matTooltip="Go to Substance Details page">
                                {{assayscreen.testAgent}}
                              </a>
                            </span>
                            <ng-template #noTargetNameTestAgentSubKey>
                              {{assayscreen.testAgent}}
                            </ng-template>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="externalAssaySource">
                          <th mat-header-cell *matHeaderCellDef> External Assay Source </th>
                          <td mat-cell *matCellDef="let assayscreen"> {{assayscreen.externalAssaySource}} </td>
                        </ng-container>

                        <ng-container matColumnDef="externalAssayId">
                          <th mat-header-cell *matHeaderCellDef> External Assay ID </th>
                          <td mat-cell *matCellDef="let assayscreen"> {{assayscreen.externalAssayId}} </td>
                        </ng-container>

                        <ng-container matColumnDef="bioassayType">
                          <th mat-header-cell *matHeaderCellDef> Bioassay Type </th>
                          <td mat-cell *matCellDef="let assayscreen"> {{assayscreen.bioassayType}} </td>
                        </ng-container>

                        <ng-container matColumnDef="studyType">
                          <th mat-header-cell *matHeaderCellDef> Study Type </th>
                          <td mat-cell *matCellDef="let assayscreen"> {{assayscreen.studyType}} </td>
                        </ng-container>

                        <ng-container matColumnDef="testAgentConcentration">
                          <th mat-header-cell *matHeaderCellDef> Test Agent Concentration </th>
                          <td mat-cell *matCellDef="let assayscreen">
                            {{assayscreen.testAgentConcentration}}&nbsp;
                            {{assayscreen.testAgentConcentrationUnits}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="resultValue">
                          <th mat-header-cell *matHeaderCellDef> Result Value </th>
                          <td mat-cell *matCellDef="let assayscreen">
                            {{assayscreen.resultValue}}&nbsp;
                            {{assayscreen.resultValueUnits}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="calculatedValue">
                          <th mat-header-cell *matHeaderCellDef> Value </th>
                          <td mat-cell *matCellDef="let assayscreen">
                            {{assayscreen.calculateIC50Value}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="controls">
                          <th mat-header-cell *matHeaderCellDef> Controls </th>
                          <td mat-cell *matCellDef="let assayscreen" class="width110px">

                            <div *ngIf="assayscreen.controls">

                              <button class="mat-raised-button mat-primary dialog-close marginright10px"
                                (click)="openModalControls(controlTemplate)">Controls
                                ({{assayscreen.controls.length}})
                              </button>

                              <!-- ******************************* -->
                              <!-- POPUP DIALOG for CONTROLS BEGIN -->
                              <ng-template #controlTemplate>

                                <div class="font14px marginbottom10px">Test Agent:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                                    class="fontbold">{{assayscreen.testAgent}}</span></div>

                                <div class="font14px">Target Name: <span
                                    class="fontbold">{{assayscreen.targetName}}</span>
                                </div>

                                <br>
                                <div class="font18px fontbold" mat-dialog-title>Controls
                                  ({{assayscreen.controls.length}})
                                </div>

                                <div mat-dialog-content>

                                  <!-- Control Loop table begin -->
                                  <table mat-table [dataSource]="assayscreen.controls" class="font12px">

                                    <ng-container matColumnDef="control">
                                      <th mat-header-cell *matHeaderCellDef> Control </th>
                                      <td mat-cell *matCellDef="let ctrl">
                                        <div *ngIf="ctrl.controlSubstanceUuid; else noAllControlSubUuid">
                                          <a [routerLink]="['/substances', ctrl.controlSubstanceUuid]" target="_blank"
                                            matTooltip="Go to Substance Details page">
                                            {{ ctrl.control }}
                                          </a>
                                        </div>
                                        <ng-template #noAllControlSubUuid>
                                          {{ctrl.control}}
                                        </ng-template>
                                      </td>
                                    </ng-container>

                                    <ng-container matColumnDef="controlType">
                                      <th mat-header-cell *matHeaderCellDef> Control Type </th>
                                      <td mat-cell *matCellDef="let ctrl">
                                        {{ctrl.controlType}}
                                      </td>
                                    </ng-container>

                                    <ng-container matColumnDef="controlReferenceValue">
                                      <th mat-header-cell *matHeaderCellDef> Control Reference Value </th>
                                      <td mat-cell *matCellDef="let ctrl">
                                        {{ctrl.controlReferenceValue}}&nbsp;
                                        {{ctrl.controlReferenceValueUnits}}
                                      </td>
                                    </ng-container>

                                    <ng-container matColumnDef="resultType">
                                      <th mat-header-cell *matHeaderCellDef> Result Type</th>
                                      <td mat-cell *matCellDef="let ctrl">
                                        {{ctrl.controlResultType}}
                                      </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="controlColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: controlColumns;"></tr>
                                  </table>
                                  <!-- Control Loop table end -->

                                </div> <!-- mat-dialog-content -->

                                <div mat-dialog-actions>
                                  <span class="middle-fill"></span>
                                  <button class="mat-raised-button mat-primary" (click)="close()"
                                    mat-dialog-close>Close</button>
                                </div>

                              </ng-template>
                              <!-- Popup Dialog for Controls END -->

                            </div> <!-- controls exists -->
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="assayTargetScreeningDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: assayTargetScreeningDisplayedColumns;"></tr>
                      </table>

                    </div>
                    <!-- Assay Target SUMMARY VIEW END -->

                  </div> <!-- Assay Target Details END -->

                </mat-card-content>
              </mat-card>
            </div>

          </mat-tab>
          <!-- Browse By ASSAY TARGET NAME Tab End -->


          <!-- ########################################################################## -->
          <!-- ########################################################################## -->
          <!-- Browse BY TEST AGENT Tab Begin                                             -->
          <!-- ########################################################################## -->

          <mat-tab label="">
            <!-- TAB label -->
            <ng-template mat-tab-label>
              <div>View By Test Agent
                <div class="font12px fontbold">
                  ({{browseByTestAgentList.length}})
                </div>
              </div>
            </ng-template>

            <div class="divflex">
              <div class="font17px padtop15px padbottom10px">
                <b>View By Test Agent</b>
              </div>
            </div> <!-- class=divflex -->
            <br>

            <div class="cards-view" *ngIf="browseByTestAgentList.length; else noRecordInvitro">
              <mat-card *ngFor="let testAgt of browseByTestAgentList; let index = index">
                <mat-card-title>

                  <div>
                    <!--
                    <span *ngIf="assay.assayTarget">
                      <a [routerLink]="['/browse-invitro-pharm']"
                        [queryParams]='{search: "root_assayTarget:" + "\"^" + assay.assayTarget + "$\""}'
                        target="_blank">
                        {{assay.assayTarget}}
                      </a>
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <a [routerLink]="['/invitro-pharm', assay.id, 'edit']" target="_blank" *ngIf="isAdmin"
                      matTooltip='Edit In-vitro Pharmacology Record'>
                      <mat-icon svgIcon="edit"></mat-icon>
                    </a>
                  -->
                  </div>

                </mat-card-title>

                <mat-card-content>

                  <div class="row">

                    <!-- ***** LEFT SIDE **** -->
                    <div class="width70percent">

                    </div> <!-- LEFT SIDE -->


                    <!-- **** RIGH SIDE ****  -->

                    <div class="width30percent">

                      <!--  *ngIf="isAdmin" -->
                      <!--
                      <span>
                        <div class="row">
                          <div class="row-property-key-5">
                            Created By:
                          </div>
                          <div class="row-property-value-5">
                            {{assay.createdBy}}
                          </div>
                        </div>

                        <div class="row">
                          <div class="row-property-key-5">
                            Create Date:
                          </div>
                          <div class="row-property-value-5">
                            {{assay.creationDate|date: 'MM/dd/yyyy h:mm a'}}
                          </div>
                        </div>

                        <div class="row">
                          <div class="row-property-key-5">
                            Edited By:
                          </div>
                          <div class="row-property-value-5">
                            {{assay.modifiedBy}}
                          </div>
                        </div>

                        <div class="row">
                          <div class="row-property-key-5">
                            Edit Date:
                          </div>
                          <div class="row-property-value-5">
                            {{assay.lastModifiedDate|date: 'MM/dd/yyyy h:mm a'}}
                          </div>
                        </div>
                      </span>
                    -->
                    </div> <!-- right side -->

                  </div>
                  <!-- row -->


                  <!-- ****** BEGIN TAB: TEST AGENT : Summary View -->
                  <div class="row">
                    <div class="row-property">
                      <div class="">
                        <b>Test Agent:</b>
                      </div>
                      <div class="marginleft20px">
                        <span class="colorblue font20px fontbold">
                          <span *ngIf="testAgt.testAgentSubstanceKey; else noTestAgentTestAgentSubKey">
                            <a [routerLink]="['/substances', testAgt.testAgentSubstanceKey]" target="_blank"
                              matTooltip="Go to Substance Details page">
                              {{testAgt.testAgent}}
                            </a>
                          </span>
                          <ng-template #noTestAgentTestAgentSubKey>
                            {{testAgt.testAgent}}
                          </ng-template>
                        </span>
                      </div>

                      <!-- Edit SUMMARY Record -->
                      <div class="marginleft40px">
                        <mat-icon svgIcon="edit"></mat-icon>
                      </div>
                      <div>
                        <a [routerLink]="['/invitro-pharm/summary', testAgt.testAgent, 'edit']" target="_blank"
                          *ngIf="isAdmin" matTooltip='Edit Assay Summary Record'>
                          <span class="font12px">Edit Summary Record</span>
                        </a>
                      </div>

                    </div>
                  </div>

                  <!-- Test Agent Summary Tab -->
                  <div *ngIf="testAgt">
                    <br><br>
                    <!-- MAT TAB Group inside Browse Substance -->
                    <mat-tab-group [(selectedIndex)]="viewTabSelectedIndex"
                      (selectedTabChange)="viewTabSelectedUpdated($event)">

                      <!-- ***************************************************** -->
                      <!-- Test Agent Tab: SCREENING VIEW Tab BEGIN              -->
                      <!-- ***************************************************** -->

                      <mat-tab label="">
                        <ng-template mat-tab-label>
                          <br>
                          <div>Screening View
                            <div class="font12px">
                              ({{testAgt.testAgentScreeningList.length}})
                            </div>
                          </div>
                        </ng-template>

                        <div>
                          <br>
                          <span class="font14px colorbrown fontfamilyverdana"><b>Screening View</b></span>
                          &nbsp;<b>({{testAgt.testAgentScreeningList.length}})</b>
                          <br><br>

                          <table mat-table [dataSource]="testAgt.testAgentScreeningList" class="font12px">

                            <ng-container matColumnDef="viewDetails">
                              <th mat-header-cell *matHeaderCellDef> View Details </th>
                              <td mat-cell *matCellDef="let assayscreen">
                                <div class="divflex width110px">
                                  <div>
                                    <a class="substance-name" [routerLink]="['/invitro-pharm', assayscreen.id]"
                                      target="_blank" alt="View Assay Record"
                                      matTooltip='View Assay Screening Record'>View
                                    </a>
                                  </div>

                                  <!-- Edit SUMMARY Record -->
                                  <div class="divflex">
                                    <div class="margintopneg5px marginleft10px">
                                      <a [routerLink]="['/invitro-pharm', assayscreen.assayResultInfoId, 'edit']"
                                        target="_blank" *ngIf="isAdmin" matTooltip='Edit Assay Screening Record'>
                                        <mat-icon svgIcon="edit"></mat-icon>
                                      </a>
                                    </div>
                                  </div> <!-- divflex -->

                                  <!-- POPUP DIALOG TEST AGENT SUMMARY -->
                                  <!--
                                  <div class="marginleft10px" *ngIf="assayscreen.summary">
                                    <button mat-icon-button color="primary" class="margintopneg20px"
                                      matTooltip="View Summary Details" (click)="openModalSummary(summaryTemplate)">
                                      <mat-icon>list_alt</mat-icon>
                                    </button>


                                    <!- - ******************************* - ->
                                    <!- - POPUP DIALOG for TEST AGENT SUMMARY BEGIN - ->
                                    <ng-template #summaryTemplate>

                                      <div class="font14px marginbottom10px">Test
                                        Agent:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                                          class="fontbold">{{assayscreen.testAgent}}</span></div>

                                      <div class="font14px">Target Name: <span
                                          class="fontbold">{{assayscreen.targetName}}</span></div>

                                      <br>
                                      <div class="font18px fontbold" mat-dialog-title>
                                        Summary
                                      </div>

                                      <div mat-dialog-content>

                                        <table mat-table [dataSource]="assayscreen.summaries" class="font12px">

                                          <ng-container matColumnDef="viewDetails">
                                            <th mat-header-cell *matHeaderCellDef> View Details </th>
                                            <td mat-cell *matCellDef="let assaysum">
                                              <div class="width100px">
                                                <a class="substance-name" [routerLink]="['/invitro-pharm', assaysum.id]"
                                                  target="_blank" alt="View Assay Screening Record"
                                                  matTooltip='View Assay Record'>View
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <a [routerLink]="['/invitro-pharm', assaysum.id, 'edit']"
                                                  target="_blank" *ngIf="isAdmin"
                                                  matTooltip='Edit Assay Summary Record'>
                                                  <mat-icon svgIcon="edit"></mat-icon>
                                                </a>
                                              </div>
                                            </td>
                                          </ng-container>

                                          <ng-container matColumnDef="isFromResult">
                                            <th mat-header-cell *matHeaderCellDef> From Result Data </th>
                                            <td class="width80px" mat-cell *matCellDef="let assaysum">
                                              <span *ngIf="assaysum.isFromResult && assaysum.isFromResult == true">
                                                <mat-icon matTooltip="This Summary data is from Result data">
                                                  check_circle_outline
                                                </mat-icon>
                                              </span>
                                            </td>
                                          </ng-container>

                                          <ng-container matColumnDef="referenceSource">
                                            <th mat-header-cell *matHeaderCellDef> Reference Source </th>
                                            <td mat-cell *matCellDef="let assaysum">
                                              {{assaysum.referenceSourceTypeAndId}} </td>
                                          </ng-container>

                                          <ng-container matColumnDef="assayTarget">
                                            <th mat-header-cell *matHeaderCellDef> Target Name </th>
                                            <td mat-cell *matCellDef="let assaysum">
                                              <div *ngIf="assaysum._assayTargetSubId; else noAssayTargetSubId">
                                                <a [routerLink]="['/substances', assaysum._assayTargetSubId]"
                                                  target="_blank" matTooltip="Go to Substance Details page">
                                                  {{ assaysum.targetName }}
                                                </a>
                                              </div>
                                              <ng-template #noAssayTargetSubId>
                                                {{assaysum.targetName}}
                                              </ng-template>
                                            </td>
                                          </ng-container>

                                          <ng-container matColumnDef="bioassayType">
                                            <th mat-header-cell *matHeaderCellDef> Bioassay Type </th>
                                            <td mat-cell *matCellDef="let assaysum"> {{assaysum.bioassayType}} </td>
                                          </ng-container>

                                          <ng-container matColumnDef="studyType">
                                            <th mat-header-cell *matHeaderCellDef> Study Type </th>
                                            <td mat-cell *matCellDef="let assaysum"> {{assaysum.studyType}} </td>
                                          </ng-container>

                                          <ng-container matColumnDef="resultValue">
                                            <th mat-header-cell *matHeaderCellDef> Result Value </th>
                                            <td mat-cell *matCellDef="let assaysum">
                                              <span *ngIf="assaysum.summaryResultValueAvg">
                                                {{assaysum.summaryResultValueAvg}}&nbsp;(Avg)
                                              </span>
                                              <span *ngIf="assaysum.summaryResultValueLow">
                                                <br>
                                                {{assaysum.summaryResultValueLow}}&nbsp;(Low)
                                              </span>
                                              <span *ngIf="assaysum.summaryResultValueHigh">
                                                <br>
                                                {{assaysum.summaryResultValueLow}}&nbsp;(High)
                                              </span>
                                              <br>
                                              {{assaysum.summaryResultValueUnits}}
                                            </td>
                                          </ng-container>

                                          <ng-container matColumnDef="resultType">
                                            <th mat-header-cell *matHeaderCellDef> Result Type </th>
                                            <td mat-cell *matCellDef="let assaysum"> {{assaysum.resultType}} </td>
                                          </ng-container>

                                          <ng-container matColumnDef="relationshipType">
                                            <th mat-header-cell *matHeaderCellDef> Relationship Type </th>
                                            <td mat-cell *matCellDef="let assaysum">
                                              {{assaysum.relationshipType}}
                                            </td>
                                          </ng-container>

                                          <ng-container matColumnDef="interactionType">
                                            <th mat-header-cell *matHeaderCellDef> Interaction Type </th>
                                            <td mat-cell *matCellDef="let assaysum">
                                              {{assaysum.interactionType}}
                                            </td>
                                          </ng-container>

                                          <tr mat-header-row *matHeaderRowDef="testAgentSummaryColumns"></tr>
                                          <tr mat-row *matRowDef="let row; columns: testAgentSummaryColumns;"></tr>
                                        </table>


                                        <!- - Control Loop table begin - ->
                                        <!- -
                                          <table mat-table [dataSource]="assayscreen.summaries" class="font12px">

                                            <ng-container matColumnDef="control">
                                              <th mat-header-cell *matHeaderCellDef> Control </th>
                                              <td mat-cell *matCellDef="let ctrl">
                                                <div *ngIf="ctrl.controlSubstanceUuid; else noAllControlSubUuid">
                                                  <a [routerLink]="['/substances', ctrl.controlSubstanceUuid]"
                                                    target="_blank" matTooltip="Go to Substance Details page">
                                                    {{ ctrl.control }}
                                                  </a>
                                                </div>
                                                <ng-template #noAllControlSubUuid>
                                                  {{ctrl.control}}
                                                </ng-template>
                                              </td>
                                            </ng-container>

                                            <ng-container matColumnDef="controlType">
                                              <th mat-header-cell *matHeaderCellDef> Control Type </th>
                                              <td mat-cell *matCellDef="let ctrl">
                                                {{ctrl.controlType}}
                                              </td>
                                            </ng-container>

                                            <ng-container matColumnDef="controlReferenceValue">
                                              <th mat-header-cell *matHeaderCellDef> Control Reference Value </th>
                                              <td mat-cell *matCellDef="let ctrl">
                                                {{ctrl.controlReferenceValue}}&nbsp;
                                                {{ctrl.controlReferenceValueUnits}}
                                              </td>
                                            </ng-container>

                                            <ng-container matColumnDef="resultType">
                                              <th mat-header-cell *matHeaderCellDef> Result Type</th>
                                              <td mat-cell *matCellDef="let ctrl">
                                                {{ctrl.resultType}}
                                              </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="controlColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: controlColumns;"></tr>
                                          </table>  - ->
                                        <!- - Control Loop table end - ->

                                      </div> <!- - mat-dialog-content - ->

                                      <div mat-dialog-actions>
                                        <span class="middle-fill"></span>
                                        <button class="mat-raised-button mat-primary" (click)="close()"
                                          mat-dialog-close>Close</button>
                                      </div>

                                    </ng-template>


                                    <!- - Popup Dialog for Controls END - ->

                                  </div>  -->
                                  <!-- Summary Details  -->

                                </div> <!-- class=divflex -->
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="referenceSource">
                              <th mat-header-cell *matHeaderCellDef> Reference Source </th>
                              <td mat-cell *matCellDef="let assayscreen"> {{assayscreen.referenceSourceTypeAndId}}
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="assayTargetName">
                              <th mat-header-cell *matHeaderCellDef> Assay Target Name </th>
                              <td mat-cell *matCellDef="let assayscreen">
                                <div class="row-property-value">
                                  <div *ngIf="assayscreen.targetNameApprovalId; else noAllTargetNameApprovalId">
                                    <a [routerLink]="['/substances', assayscreen.targetNameApprovalId]" target="_blank"
                                      matTooltip="Go to Substance Details page">
                                      <b>{{assayscreen.targetName}}</b>
                                    </a>
                                  </div>
                                  <ng-template #noAllTargetNameApprovalId>
                                    <b>{{assayscreen.targetName}}</b>
                                  </ng-template>
                                </div>
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="bioassayType">
                              <th mat-header-cell *matHeaderCellDef> Bioassay Type </th>
                              <td mat-cell *matCellDef="let assayscreen"> {{assayscreen.bioassayType}} </td>
                            </ng-container>

                            <ng-container matColumnDef="studyType">
                              <th mat-header-cell *matHeaderCellDef> Study Type </th>
                              <td mat-cell *matCellDef="let assayscreen"> {{assayscreen.studyType}} </td>
                            </ng-container>

                            <!--
                            <ng-container matColumnDef="testCompound">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Test Compound </th>
                              <td mat-cell *matCellDef="let assay">
                                <div *ngIf="assay._testCompoundSubId; else noTestCompoundSubId">
                                  <a [routerLink]="['/substances', assay._testCompoundSubId]" target="_blank"
                                    matTooltip="Go to Substance Details page">
                                    {{ assay.testCompound }}
                                  </a>
                                </div>
                                <ng-template #noTestCompoundSubId>
                                  {{assay.testCompound}}
                                </ng-template>
                              </td>
                            </ng-container>
                            -->

                            <ng-container matColumnDef="testAgentConcentration">
                              <th mat-header-cell *matHeaderCellDef>Test Agent Concentration</th>
                              <td mat-cell *matCellDef="let assayscreen">
                                {{assayscreen.testAgentConcentration}}&nbsp;
                                {{assayscreen.testAgentConcentrationUnits}}
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="resultValue">
                              <th mat-header-cell *matHeaderCellDef> Result Value </th>
                              <td mat-cell *matCellDef="let assayscreen">
                                {{assayscreen.resultValue}}&nbsp;
                                {{assayscreen.resultValueUnits}}
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="calculatedValue">
                              <th mat-header-cell *matHeaderCellDef> Value </th>
                              <td mat-cell *matCellDef="let assayscreen">
                                {{assayscreen.calculateIC50Value}}
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="controls">
                              <th mat-header-cell *matHeaderCellDef> Controls </th>
                              <td mat-cell *matCellDef="let assayscreen" class="width110px">

                                <div *ngIf="assayscreen.controls">

                                  <button class="mat-raised-button mat-primary dialog-close marginright10px"
                                    (click)="openModalControls(controlTemplate)" aria-label="View Controls">Controls
                                    ({{assayscreen.controls.length}})
                                  </button>

                                  <!-- ******************************* -->
                                  <!-- POPUP DIALOG for CONTROLS BEGIN -->
                                  <ng-template #controlTemplate>

                                    <div class="font14px marginbottom10px">Test
                                      Agent:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                                        class="fontbold">{{assayscreen.testAgent}}</span></div>

                                    <div class="font14px">Target Name: <span
                                        class="fontbold">{{assayscreen.targetName}}</span></div>

                                    <br>
                                    <div class="font18px fontbold" mat-dialog-title>Controls
                                      ({{assayscreen.controls.length}})
                                    </div>

                                    <div mat-dialog-content>

                                      <!-- Control Loop table begin -->
                                      <table mat-table [dataSource]="assayscreen.controls" class="font12px">

                                        <ng-container matColumnDef="control">
                                          <th mat-header-cell *matHeaderCellDef> Control </th>
                                          <td mat-cell *matCellDef="let ctrl">
                                            <div *ngIf="ctrl.controlSubstanceUuid; else noTestControlSubUuid">
                                              <a [routerLink]="['/substances', ctrl.controlSubstanceUuid]"
                                                target="_blank" matTooltip="Go to Substance Details page">
                                                {{ ctrl.control }}
                                              </a>
                                            </div>
                                            <ng-template #noTestControlSubUuid>
                                              {{ctrl.control}}
                                            </ng-template>
                                          </td>
                                        </ng-container>

                                        <ng-container matColumnDef="controlType">
                                          <th mat-header-cell *matHeaderCellDef> Control Type </th>
                                          <td mat-cell *matCellDef="let ctrl">
                                            {{ctrl.controlType}}
                                          </td>
                                        </ng-container>

                                        <ng-container matColumnDef="controlReferenceValue">
                                          <th mat-header-cell *matHeaderCellDef> Control Reference Value </th>
                                          <td mat-cell *matCellDef="let ctrl">
                                            {{ctrl.controlReferenceValue}}&nbsp;
                                            {{ctrl.controlReferenceValueUnits}}
                                          </td>
                                        </ng-container>

                                        <ng-container matColumnDef="resultType">
                                          <th mat-header-cell *matHeaderCellDef> Result Type</th>
                                          <td mat-cell *matCellDef="let ctrl">
                                            {{ctrl.controlResultType}}
                                          </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="controlColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: controlColumns;"></tr>
                                      </table>
                                      <!-- Control Loop table end -->

                                    </div> <!-- mat-dialog-content -->

                                    <div mat-dialog-actions>
                                      <span class="middle-fill"></span>
                                      <button class="mat-raised-button mat-primary" (click)="close()"
                                        mat-dialog-close>Close</button>
                                    </div>

                                  </ng-template>
                                  <!-- Popup Dialog for Controls END -->

                                </div> <!-- controls exists -->

                              </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="testAgentScreeningColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: testAgentScreeningColumns;"></tr>
                          </table>
                        </div>
                        <!-- TABLE VIEW END -->

                      </mat-tab>
                      <!-- Test Agent Screening View END -->


                      <!-- Test Agent Sumary Tab BEGIN -->
                      <mat-tab label="">
                        <ng-template mat-tab-label>
                          <div>Summary View
                            <div class="font12px">
                              ({{testAgt.testAgentSummaryList.length}})
                            </div>
                          </div>
                        </ng-template>


                        <!-- ************************************************** -->
                        <!-- TEST AGENT TAB: SUMMARY VIEW BEGIN                 -->
                        <!-- ************************************************** -->
                        <br>
                        <span class="font14px colorbrown fontfamilyverdana"><b>Summary View</b></span>
                        &nbsp;<b>({{testAgt.testAgentSummaryList.length}})</b>
                        <br><br>

                        <div>
                          <table mat-table [dataSource]="testAgt.testAgentSummaryList" class="font12px">

                            <ng-container matColumnDef="viewDetails">
                              <th mat-header-cell *matHeaderCellDef> View Details </th>
                              <td mat-cell *matCellDef="let assay">

                                <div class="divflex width100px">
                                  <div>
                                    <a class="substance-name" [routerLink]="['/invitro-pharm', assay.id]"
                                      target="_blank" alt="View Assay Screening Record"
                                      matTooltip='View Assay Record'>View
                                    </a>
                                  </div>

                                  <!-- Edit SUMMARY Record -->

                                  <div class="margintopneg5px marginleft10px">
                                    <a [routerLink]="['/invitro-pharm', assay.assayResultInfoId, 'edit']"
                                      target="_blank" *ngIf="isAdmin" matTooltip='Edit Assay Screening Record'>
                                      <mat-icon svgIcon="edit"></mat-icon>
                                    </a>
                                  </div>

                                </div> <!-- divflex -->
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="isFromResult">
                              <th mat-header-cell *matHeaderCellDef> From Result Data </th>
                              <td class="width80px" mat-cell *matCellDef="let assaysum">
                                <span *ngIf="assaysum.isFromResult && assaysum.isFromResult == true">
                                  <mat-icon matTooltip="This Summary data is from Result data">check_circle_outline
                                  </mat-icon>
                                </span>
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="referenceSource">
                              <th mat-header-cell *matHeaderCellDef> Reference Source </th>
                              <td mat-cell *matCellDef="let assaysum"> {{assaysum.referenceSourceTypeAndId}} </td>
                            </ng-container>

                            <ng-container matColumnDef="assayTarget">
                              <th mat-header-cell *matHeaderCellDef> Target Name </th>
                              <td mat-cell *matCellDef="let assaysum">
                                <div *ngIf="assaysum.targetNameApprovalId; else noAssayTargetApprovalId">
                                  <a [routerLink]="['/substances', assaysum.targetNameApprovalId]" target="_blank"
                                    matTooltip="Go to Substance Details page">
                                    {{ assaysum.targetName }}
                                  </a>
                                </div>
                                <ng-template #noAssayTargetApprovalId>
                                  {{assaysum.targetName}}
                                </ng-template>
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="bioassayType">
                              <th mat-header-cell *matHeaderCellDef> Bioassay Type </th>
                              <td mat-cell *matCellDef="let assaysum"> {{assaysum.bioassayType}} </td>
                            </ng-container>

                            <ng-container matColumnDef="studyType">
                              <th mat-header-cell *matHeaderCellDef> Study Type </th>
                              <td mat-cell *matCellDef="let assaysum"> {{assaysum.studyType}} </td>
                            </ng-container>

                            <ng-container matColumnDef="resultValue">
                              <th mat-header-cell *matHeaderCellDef> Result Value </th>
                              <td mat-cell *matCellDef="let assaysum">
                                <span *ngIf="assaysum.summaryResultValueAvg">
                                  {{assaysum.summaryResultValueAvg}}&nbsp;(Avg)
                                </span>
                                <span *ngIf="assaysum.summaryResultValueLow">
                                  <br>
                                  {{assaysum.summaryResultValueLow}}&nbsp;(Low)
                                </span>
                                <span *ngIf="assaysum.summaryResultValueHigh">
                                  <br>
                                  {{assaysum.summaryResultValueLow}}&nbsp;(High)
                                </span>
                                <br>
                                {{assaysum.summaryResultValueUnits}}
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="resultType">
                              <th mat-header-cell *matHeaderCellDef> Result Type </th>
                              <td mat-cell *matCellDef="let assaysum"> {{assaysum.resultType}} </td>
                            </ng-container>

                            <ng-container matColumnDef="relationshipType">
                              <th mat-header-cell *matHeaderCellDef> Relationship Type </th>
                              <td mat-cell *matCellDef="let assaysum">
                                {{assaysum.relationshipType}}
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="interactionType">
                              <th mat-header-cell *matHeaderCellDef> Interaction Type </th>
                              <td mat-cell *matCellDef="let assaysum">
                                {{assaysum.interactionType}}
                              </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="testAgentSummaryColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: testAgentSummaryColumns;"></tr>
                          </table>

                        </div>

                      </mat-tab>
                      <!-- Test Agent/Substance: SUMMARY VIEW Tab END -->

                    </mat-tab-group>
                  </div>
                  <!-- END TAB  -->


                </mat-card-content>
              </mat-card>
            </div>

          </mat-tab>
          <!-- BROWSE BY SUBSTANCE TAB END-->


          <!-- ########################################################################## -->
          <!-- ########################################################################## -->
          <!-- Browse BY REFERENCE Tab Begin                                  -->
          <!-- ########################################################################## -->

          <mat-tab label="">
            <!-- TAB Label -->
            <ng-template mat-tab-label>
              <div>View By Reference
                <div class="colorblack font12px fontbold">
                  ({{browseByReferenceList.length}})
                </div>
              </div>
            </ng-template>

            <div class="font17px padtop15px padbottom10px"><b>View By Reference</b></div>
            <br>

            <div class="cards-view" *ngIf="browseByReferenceList.length; else noReferenceRecords">
              <mat-card *ngFor="let app of browseByReferenceList; let index = index">
                <mat-card-title>

                  <div>

                    <!--
                    <span *ngIf="assay.assayTarget">
                      <a [routerLink]="['/browse-invitro-pharm']"
                        [queryParams]='{search: "root_assayTarget:" + "\"^" + assay.assayTarget + "$\""}'
                        target="_blank">
                        {{assay.assayTarget}}
                      </a>
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <a [routerLink]="['/invitro-pharm', assay.id, 'edit']" target="_blank" *ngIf="isAdmin"
                      matTooltip='Edit In-vitro Pharmacology Record'>
                      <mat-icon svgIcon="edit"></mat-icon>
                    </a>
                  -->
                  </div>

                </mat-card-title>

                <mat-card-content>

                  <!-- ****** Reference Summary View -->
                  <div *ngIf="app">

                    <div class="row">
                      <div class="row-property">
                        <div class="row-property-key-two">
                          Reference Source Type and Id:
                        </div>
                        <div class="row-property-value-two">
                          <span class="colorblue font20px fontbold"> {{app.referenceSourceTypeNumber}}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- ********************************************** -->
                    <!-- REFERENCE SCREENING VIEW BEGIN                 -->
                    <!-- ********************************************** -->
                    <br>
                    <span class="font14px colorbrown fontfamilyverdana"><b>Screening View</b></span>
                    &nbsp;<b>({{app.referenceSummaryList.length}})</b>
                    <br><br>

                    <!--
                    <table mat-table [dataSource]="app.referenceSummaryList" class="font12px" matSort
                      (matSortChange)="sortData($event)" matSortActive="studyType" matSortDirection="asc"
                      matSortDisableClear>
                    -->
                    <table mat-table [dataSource]="app.referenceSummaryList" class="font12px">

                      <ng-container matColumnDef="viewDetails">
                        <th mat-header-cell *matHeaderCellDef> View Details </th>
                        <td mat-cell *matCellDef="let assaysum">
                          <div class="width100px">
                            <a class="substance-name" [routerLink]="['/invitro-pharm', assaysum.id]" target="_blank"
                              alt="View Assay Screening Record" matTooltip='View Assay Record'>View
                            </a>&nbsp;&nbsp;&nbsp;

                            <a [routerLink]="['/invitro-pharm', assaysum.id, 'edit']" target="_blank" *ngIf="isAdmin"
                              matTooltip='Edit In-vitro Pharmacology Record'>
                              <mat-icon svgIcon="edit"></mat-icon>
                            </a>

                            <!--
                            <a [routerLink]="['/invitro-pharm', assaysum.id, 'edit']" target="_blank" *ngIf="isAdmin"
                              matTooltip='Edit Assay Screening Record'>
                              <mat-icon svgIcon="edit"></mat-icon>
                            </a>
                             -->
                          </div>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="testAgent">
                        <th mat-header-cell *matHeaderCellDef> Test Agent </th>
                        <td mat-cell *matCellDef="let assayscreen">
                          <span *ngIf="assayscreen.testAgentSubstanceUuid; else noReferenceTestAgentSubId">
                            <a [routerLink]="['/substances', assayscreen.testAgentSubstanceUuid]" target="_blank"
                              matTooltip="Go to Substance Details page">
                              {{assayscreen.testAgent}}
                            </a>
                          </span>
                          <ng-template #noReferenceTestAgentSubId>
                            {{assayscreen.testAgent}}
                          </ng-template>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="assayTargetName">
                        <th mat-header-cell *matHeaderCellDef> Assay Target Name</th>
                        <td mat-cell *matCellDef="let assayscreen">
                          <div *ngIf="assayscreen.targetNameApprovalId; else noAssayTargetApprovalId">
                            <a [routerLink]="['/substances', assayscreen.targetNameApprovalId]" target="_blank"
                              matTooltip="Go to Substance Details page">
                              {{ assayscreen.targetName }}
                            </a>
                          </div>
                          <ng-template #noAssayTargetApprovalId>
                            {{assayscreen.targetName}}
                          </ng-template>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="bioassayType">
                        <th mat-header-cell *matHeaderCellDef> Bioassay Type </th>
                        <td mat-cell *matCellDef="let assayscreen"> {{assayscreen.bioassayType}} </td>
                      </ng-container>

                      <ng-container matColumnDef="studyType">
                        <th mat-header-cell *matHeaderCellDef> Study Type </th>
                        <td mat-cell *matCellDef="let assayscreen"> {{assayscreen.studyType}} </td>
                      </ng-container>

                      <ng-container matColumnDef="testAgentConcentration">
                        <th mat-header-cell *matHeaderCellDef> Test Agent Concentration </th>
                        <td mat-cell *matCellDef="let assayscreen">
                          {{assayscreen.testAgentConcentration}}&nbsp;
                          {{assayscreen.testAgentConcentrationUnits}}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="resultValue">
                        <th mat-header-cell *matHeaderCellDef> Result Value </th>
                        <td mat-cell *matCellDef="let assayscreen">
                          {{assayscreen.resultValue}}&nbsp;
                          {{assayscreen.resultValueUnits}}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="calculatedValue">
                        <th mat-header-cell *matHeaderCellDef> Value </th>
                        <td mat-cell *matCellDef="let assayscreen">
                          {{assayscreen.calculateIC50Value}}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="controls">
                        <th mat-header-cell *matHeaderCellDef> Controls </th>
                        <td mat-cell *matCellDef="let assayscreen" class="width110px">

                          <div *ngIf="assayscreen.controls">

                            <button class=" mat-raised-button mat-primary dialog-close marginright10px"
                              (click)="openModalControls(controlTemplate)">Controls
                              ({{assayscreen.controls.length}})
                            </button>

                            <!-- ******************************* -->
                            <!-- POPUP DIALOG for CONTROLS BEGIN -->
                            <ng-template #controlTemplate>

                              <div class="font14px marginbottom10px">Test Agent:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                                  class="fontbold">{{assayscreen.testAgent}}</span></div>

                              <div class="font14px ">Target Name: <span
                                  class="fontbold">{{assayscreen.targetName}}</span>
                              </div>

                              <br>
                              <div class="font18px fontbold" mat-dialog-title>Controls
                                ({{assayscreen.controls.length}})
                              </div>

                              <div mat-dialog-content>

                                <!-- Control Loop table begin -->
                                <table mat-table [dataSource]="assayscreen.controls" class="font12px">

                                  <ng-container matColumnDef="control">
                                    <th mat-header-cell *matHeaderCellDef> Control </th>
                                    <td mat-cell *matCellDef="let ctrl">
                                      {{ctrl.control}}
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="controlType">
                                    <th mat-header-cell *matHeaderCellDef> Control Type </th>
                                    <td mat-cell *matCellDef="let ctrl">
                                      {{ctrl.controlType}}
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="controlReferenceValue">
                                    <th mat-header-cell *matHeaderCellDef> Control Reference Value </th>
                                    <td mat-cell *matCellDef="let ctrl">
                                      {{ctrl.controlReferenceValue}}&nbsp;
                                      {{ctrl.controlReferenceValueUnits}}
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="resultType">
                                    <th mat-header-cell *matHeaderCellDef> Result Type</th>
                                    <td mat-cell *matCellDef="let ctrl">
                                      {{ctrl.controlResultType}}
                                    </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="controlColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: controlColumns;"></tr>
                                </table>
                                <!-- Control Loop table end -->

                              </div> <!-- mat-dialog-content -->

                              <div mat-dialog-actions>
                                <span class="middle-fill"></span>
                                <button class="mat-raised-button mat-primary" (click)="close()"
                                  mat-dialog-close>Close</button>
                              </div>

                            </ng-template>
                            <!-- Popup Dialog for Controls END -->

                          </div> <!-- controls exists -->

                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="referenceScreeningColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: referenceScreeningColumns;"></tr>
                    </table>
                    <!-- Reference/Application Summary Table END -->

                  </div> <!-- *ngIf="app" -->
                  <!-- END TAB  -->


                </mat-card-content>
              </mat-card>
            </div>

          </mat-tab>
          <!-- BROWSE BY APPLICATION TAB END-->


        </mat-tab-group>
        <!-- MAIN TAB GROUP END -->
      </div>


      <!-- Pagination at the bottom of the page -->
      <div class="controls-container-right">
        <div class="full-paginator-right">

          <mat-paginator #paginator [length]="totalCountAssayTarget" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 50, 100, 200, 500, 1000, 5000]" [showFirstLastButtons]="true"
            (page)="changePage($event)">
          </mat-paginator>

          <div class="page-selector-right">
            <span class="page-label">Page: </span>
            <mat-form-field class="page-input" [ngClass]="{'bad-page':invalidPage}">
              <input matInput matTooltip="Type a page and press enter" [ngClass]="{'bad-page':invalidPage}"
                [value]="pageIndex + 1" #pagenum (keydown.enter)="customPage($event)"
                (input)="validatePageInput($event)? invalidPage = false: invalidPage = true"
                (focusout)="pagenum.value = pageIndex + 1; invalidPage = false">
            </mat-form-field>
            <span class="page-label"> of {{lastPage}}</span>
          </div>

        </div> <!-- class=full-paginator-right-->
      </div> <!-- Pagination Page-->

      <ng-template #noRecordInvitro>
        <!-- No In-vitro Pharmacology data Found. -->
        <!--
      <div class="padleft10px colorred">
        <br>
        No in-vitro pharmacology records found. Please re-index in-vitro pharmacology entity to see the records here.
      </div>
      -->
      </ng-template>

    </div> <!-- class=side-nav-content -->
  </mat-sidenav-content>
</mat-sidenav-container>